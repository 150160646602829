import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLink,
  Paragraph,
  BaseButton
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardWrapper = styled.div`
  padding-bottom: 30px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;

  @media screen and (min-width: 768px) {
    padding-bottom: 20px;
  }
`;

const CardBody = styled.div`
  padding: 0 24px;
`;

const BlogLinkWrapper = styled.div`
  background-image: none !important;
  padding: 0 !important;
  background-position: none !important;
  text-decoration: none !important;
  transition: none !important;
`;

const BlogLink = styled.a`
  color: #212121;

  &:hover {
    color: ${(props) => props.theme.colors.blue_60} !important;
    text-decoration: none;
  }
`;

const SquaredUpThumbnailWrapper = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 24px;
`;

const SquaredUpThumbnail = styled.div`
  height: 200px;
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: #f9f9f9;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  padding: 24px;
  transition: 0.2s ease-in-out;
`;

// First we grab the Blog data and create the Postman Blog Cards
const normalizeString = (str) => str.replace(/&#8217;/g, "'");

function Card({ data, hideBody }) {
  const featuredImage = data.image ? `url(${data.image})` : 'none';
  return (
    <div className="col-12 col-sm-6 col-lg-4 mb-5">
      <CardWrapper className="h-100">
        <BlogLinkWrapper>
          <SquaredUpThumbnailWrapper>
            <a href={data.link.url}>
              <SquaredUpThumbnail
                style={{ backgroundImage: `${featuredImage}` }}
              />
            </a>
          </SquaredUpThumbnailWrapper>
        </BlogLinkWrapper>
        <CardBody className="card-body">
          <h3 className="h4 mb-3">
            <BlogLink href={data.link.url} target="_blank" rel="noopener">
              {normalizeString(data.title)}
            </BlogLink>
          </h3>
          {hideBody && hideBody === true ? (
            ''
          ) : (
            <Paragraph>{normalizeString(data.body)}</Paragraph>
          )}
          {data.link.url && data.link.text && (
            <BaseLink
              className="mb-0"
              src={data.link.url}
              target="same-tab"
              linkType="arrowLink"
            >
              Read more
            </BaseLink>
          )}
        </CardBody>
      </CardWrapper>
    </div>
  );
}

// Then we take the BlogTag and remove the hyphen,
// capitlize the first letter of every word then return the dynamic or static title
const normalizeBlogTag = (str) => {
  const removeHyphen = str.replace(/-/g, ' ');
  const titleCase = removeHyphen.replace(/\b\S/g, (titleCased) =>
    titleCased.toUpperCase()
  );
  if (titleCase.includes('Aws')) {
    return titleCase.toUpperCase();
  }
  if (titleCase.includes('Api')) {
    return titleCase.replace('Api', 'API');
  }
  if (titleCase.includes('Openapi')) {
    // Leave space in API
    return titleCase.replace('api', ' API');
  }
  return titleCase;
};
/* eslint-disable */
const ReturnTagTitle = ({ data }) => {
  const { tag, title, tagged, alternative, textAlign } = data;
  return tagged ? (
    alternative ? (
      <h2 className={textAlign !== undefined ? `${textAlign} mb-5 text-white` : `mb-5 text-white` }>
        More on {normalizeBlogTag(tag)} from the Postman Blog
      </h2>
    ) : (
      <h2 className={textAlign !== undefined ? `${textAlign} mb-5`: `mb-5`}>{title}</h2>
    )
  ) : alternative ? (
    <h2 className={textAlign !== undefined ? `${textAlign} mb-5`: `mb-5 text-white`}>
      {title}
    </h2>
  ) : (
    <h2 className={textAlign !== undefined ? `${textAlign} mb-5` : `mb-5`}>{title}</h2>
  );
};
/* eslint-enable */

ReturnTagTitle.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    tagged: PropTypes.bool,
    title: PropTypes.string
  }).isRequired
};

const blogPostCardsDynamic = (data) => (
  <div>
    <SectionStyles>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {data.title && <ReturnTagTitle data={data} />}
            {data.subtitle && <h3 className="mb-5">{data.subtitle}</h3>}
            <div className="row justify-content-center">
              {data.items.map((val) => {
                const cardData = { ...val };
                return (
                  <Card
                    data={cardData}
                    key={pmUuid()}
                    hideBody={data.hideBody && data.hideBody}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {data.link && (
          <div className="row">
            <div className="col-12 text-center">
              <BaseButton
                buttonType="secondary"
                as="a"
                src={data.link.url}
                target={data.link.target}
                id={data.link.id}
                gaCategory={data.link.gaCategory}
                gaLabel={data.link.gaLabel}
                className="mt-2"
              >
                {data.link.text}
              </BaseButton>
            </div>
          </div>
        )}
      </div>
    </SectionStyles>

    {(data.divider === true && <Divider className="d-none d-md-block" />) ||
      null}
  </div>
);
Card.defaultProps = {
  hideBody: false
};

Card.propTypes = {
  hideBody: PropTypes.bool,
  data: PropTypes.shape({
    marginBottom: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    hideBody: PropTypes.bool,
    body: PropTypes.string,
    url: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    date: PropTypes.string
  }).isRequired
};

export default blogPostCardsDynamic;
