import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 60px 0 0;
`;

const heroCentered = (data) => {
  const textAlign = data.textAlign ? `text-${data.textAlign}` : 'text-center';

  // If you do not have data.media & you do NOT want bootstrap mb-5 class
  // For example the case-studies.
  // Defaults to having mb-5 class as this is used on most pages when no data.media
  const mbClass = data.removeMb === true ? '' : 'mb-5';

  return (
    <Section>
      <div className="container">
        {/* <Hello /> */}
        {/* <Hello 
        title="Postman named a Visionary in the 2021 Gartner® Magic Quadrant™ for Full Lifecycle API Management"
        showBar='true' 
        text="Learn more" 
        track="hello"
        target="same-tab"
        url="/product/gartner-magic-quadrant/"
        /> */}
        {data.media ? (
          <div className="row">
            <div className={`col-12 ${textAlign}`}>
              <h1 className="mb-4">{data.titles}</h1>
              <div
                className={`subtitle mb-4 ${textAlign}`}
                dangerouslySetInnerHTML={{ __html: data.body }}
              />
            </div>
            <div className="col-12 pt-5">
              <img
                className="img-fluid"
                src={data.media[0].image}
                alt={data.media[0].alt}
              />
            </div>
          </div>
        ) : (
          <div className={`row ${mbClass}`}>
            <div className={`col-12 ${textAlign}`}>
              {data.titles && <h1 className="mb-4">{data.titles}</h1>}
              {data.body && (
                <div
                  className={`subtitle mb-4 ${textAlign}`}
                  dangerouslySetInnerHTML={{ __html: data.body }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

heroCentered.propTypes = {
  data: PropTypes.shape({
    textAlign: PropTypes.string,
    padding: PropTypes.string,
    alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    removeMb: PropTypes.bool,
    backgroundColor: PropTypes.bool,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        alt: PropTypes.string
      })
    ),
    titles: PropTypes.arrayOf(PropTypes.string),
    body: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.shape({})
  }).isRequired
};

export default heroCentered;
