import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  SectionStyles,
  OrderedListStyles,
  ParagraphStyles,
  Paragraph,
  BaseButton
} from 'aether-marketing';

const Section = styled(SectionStyles)`
  p {
    ${ParagraphStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

const sectionCtaNumberedV6 = (data) => (
  <>
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <h2>{data.title}</h2>
            <Paragraph className="subtitle">{data.subtitle}</Paragraph>
            <div dangerouslySetInnerHTML={{ __html: data.body.join('') }} />
            {data.buttonLeft && data.buttonLeft.text && data.buttonLeft.src && (
              <BaseButton
                buttonType={data.buttonLeft.buttonType}
                as="a"
                src={data.buttonLeft.src}
                target={data.buttonLeft.target}
                id={data.buttonLeft.id}
                gaCategory={data.buttonLeft.gaCategory}
                gaLabel={data.buttonLeft.gaLabel}
                className={
                  data.buttonLeft.buttonType === 'inline' ? 'pl-0' : null
                }
                modal={data.modal || null}
                icon={data.buttonLeft.icon}
              >
                {data.buttonLeft.text}
              </BaseButton>
            )}
            {data.buttonRight && data.buttonRight.text && data.buttonRight.src && (
              <BaseButton
                buttonType={data.buttonRight.buttonType}
                as="a"
                className="ml-3"
                src={data.buttonRight.src}
                target={data.buttonRight.target}
                id={data.buttonRight.id}
                gaCategory={data.buttonRight.gaCategory}
                gaLabel={data.buttonRight.gaLabel}
                icon={data.buttonRight.icon}
              >
                {data.buttonRight.text}
              </BaseButton>
            )}
          </div>
        </div>
      </div>
    </Section>
    {(data.divider === true && <Divider />) || null}
  </>
);

export default sectionCtaNumberedV6;
