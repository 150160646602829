import React from 'react';
/* eslint-enable */

import styled from 'styled-components';
import {
  Divider,
  Paragraph,
  BaseButton,
  SectionHeader
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const Section = styled.section`
  padding: 60px 0;
  h2 {
    line-height: 1;
  }

  a {
    color: #3d99ff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid #3d99ff;
      cursor: pointer;
      text-decoration: none;
      transition: 'all 0.2s ease-in-out';
    }
    &:hover {
      text-decoration: none;
      border-bottom: 1px solid #3d99ff;
      cursor: pointer;
      text-decoration: none;
      transition: 'all 0.2s ease-in-out';
    }
  }
  .video-btn-white {
    color: white !important;
    border: 1px solid white;
    border-radius: ${(props) => props.theme.borderRadius.small};
  }
  .video-btn-white:hover {
    border-bottom: 1px solid white !important;

`;

const Content = styled.div`
  // text-align: center;
`;

const BackgroundImageLeft = styled.img`
  position: absolute;
  left: ${(props) => props.data.backgroundImageLeft.position.left};
  top: ${(props) => props.data.backgroundImageLeft.position.top};

  @media screen and (max-width: 968px) {
    display: none;
    visibility: hidden;
  }
`;

const BackgroundImageRight = styled.img`
  position: absolute;
  right: ${(props) => props.data.backgroundImageRight.position.right};
  top: ${(props) => props.data.backgroundImageRight.position.top};

  @media screen and (max-width: 968px) {
    display: none;
    visibility: hidden;
  }
`;
const metricTwo = (data) => {
  const arrayOfItems = data.items.length;
  const stickyAttribute = data.stickyAttribute ? data.stickyAttribute : null;

  return (
    <>
      <Section
        style={{
          backgroundColor: data.backgroundColor,
          backgroundImage: data.backgroundImage,
          zIndex: data.zIndex,
          position: data.position,
          color: data.className,
          overflow: 'hidden'
        }}
        data-sticky={stickyAttribute}
      >
        <div className="container ">
          <div className="position-relative ">
            {data.backgroundImageLeft && (
              <BackgroundImageLeft
                data={data}
                src={data.backgroundImageLeft.src}
                alt={data.backgroundImageLeft.alt}
              />
            )}
            {data.backgroundImageRight && (
              <BackgroundImageRight
                data={data}
                src={data.backgroundImageRight.src}
                alt={data.backgroundImageRight.alt}
              />
            )}{' '}
          </div>
        </div>
        <div className="container ">
          <div className="position-relative ">
            {data.backgroundImageLeft && (
              <BackgroundImageLeft
                data={data}
                src={data.backgroundImageLeft.src}
                alt={data.backgroundImageLeft.alt}
              />
            )}
            {data.backgroundImageRight && (
              <BackgroundImageRight
                data={data}
                src={data.backgroundImageRight.src}
                alt={data.backgroundImageRight.alt}
              />
            )}{' '}
          </div>

          <div className="row">
            <SectionHeader
              title={data.title}
              body={data.body}
              textAlign={data.textAlign}
              bottomMargin="0"
              className={data.className}
            />
          </div>
          <div className="row mb-5">
            {data.subtitle && (
              <div className="col-12 text-center mb-2">
                <Paragraph className={`h4${data.className}`}>
                  {data.subtitle}
                </Paragraph>
                <Paragraph className={`h4${data.className}`}>
                  {data.subtitle}
                </Paragraph>
              </div>
            )}
            {/* Example ( /so ) */}
            {arrayOfItems === 4 &&
              data.items.map((item) => (
                <Content key={pmUuid()} className="col-sm-6 col-md-3">
                  <h3 className="h1 mb-3">{item.title}</h3>
                  <Paragraph className="subtitle mb-5 mb-md-0">
                    {item.body}
                  </Paragraph>
                </Content>
              ))}
            {/* Example ( homepage ) */}
            {arrayOfItems === 3 &&
              data.items.map((item) => (
                <Content key={pmUuid()} className="col-12 col-md-4 ">
                  {item.iconSrc && (
                    <img
                      src={item.iconSrc}
                      alt="Icon"
                      width="50"
                      height="50"
                      aria-hidden="true"
                      className="mr-5 img-fluid"
                    />
                  )}
                  <h3
                    className={`mb-3 ${data.titleFontSize} ${data.className}`}
                  >
                    {item.title}
                  </h3>
                  <Paragraph
                    className={`mb-5 mb-md-0 ${data.className}`}
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                </Content>
              ))}
          </div>
          {data.bodyHTML && (
            <div className="row">
              <div className={`col-12 col-md-8 mb-4 ${data.className}`}>
                <div dangerouslySetInnerHTML={{ __html: data.bodyHTML }} />
              </div>
            </div>
          )}
          {data.cta && (
            <div className="row mb-5">
              <div className="col-12 col-sm-8">
                <BaseButton
                  buttonType={
                    data.cta.buttonType ? data.cta.buttonType : 'secondary'
                  }
                  as="a"
                  rel="noopener"
                  target="_blank"
                  // event={data.cta.event}
                  // eventProp={data.cta.eventProp}
                  ariaLabel={data.cta.ariaLabel && data.cta.ariaLabel}
                  src={data.cta.src}
                  className="video-btn-white"
                >
                  {data.cta.text}
                </BaseButton>
              </div>
            </div>
          )}
          {data.source && (
            <div className="row">
              <div className="col-12">
                <Paragraph
                  className={`mb-0 small font-italic ${data.className}`}
                >
                  {data.source}
                </Paragraph>
              </div>
            </div>
          )}
        </div>
      </Section>
      {(data.divider === true && <Divider />) || null}
    </>
  );
};

export default metricTwo;
