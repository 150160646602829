/**
 * Logging Middleware
 *
 * Logs all the things
 *
 * @param payload
 * @param next
 */
export const loggingMiddleware = (payload, next) => {
  const jsons = (o) => JSON.stringify(o, undefined, 2);

  /* eslint-disable camelcase, no-console */
  const {
    event: { event_type, event_properties, plan, user_id, device_id }
  } = payload;

  const id = user_id || device_id;
  const propsJson = jsons(event_properties);
  const planJson = plan ? ` plan=${jsons(plan)}` : '';

  console.log(
    `[ampli] id='${id}' event='${event_type}' properties=${propsJson}${planJson}`
  );
  /* eslint-enable */

  next(payload);
};

export default loggingMiddleware;
