import React from 'react';
import styled from 'styled-components';
import { AspectRatio } from 'react-aspect-ratio';
import PropTypes from 'prop-types';
import marginBottomEnumReturner from '../../../utils/marginBottomEnumReturner';

const SquaredUpThumbnailWrapper = styled.div`
  padding: 2rem 2.4rem;
  background-color: #f9f9f9;

  /* CSS Variables */
  /* https://www.npmjs.com/package/react-aspect-ratio */
  [style*='--aspect-ratio'] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  [style*='--aspect-ratio']::before {
    content: '';
    display: block;
  }
  /* Measures padding if the aspect ratio is NOT 1/1 */
  @supports not (aspect-ratio: 1/1) {
    [style*='--aspect-ratio']::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
  /* Measures Aspect Ratio styling if 1/1 */
  @supports (aspect-ratio: 1/1) {
    [style*='--aspect-ratio']::before {
      aspect-ratio: calc(var(--aspect-ratio));
    }
  }

  /* //IE10/11 only */
  @media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ie-polyfill {
      padding-top: 50% !important;
    }
  }
`;

function AspectRatioPluginV6({
  src,
  height,
  ratio,
  alt,
  className,
  borderRadius,
  backgroundSize,
  marginBottom // enum value.  Provides ability to override margin-bottom property
}) {
  const imgHeight = height || '';
  const classNames = className || '';
  const backgroundSizes = backgroundSize || 'contain';
  const borderTopRadius = borderRadius === 1 ? '0px' : '8px';
  // By default (null), has 0 mb
  // marginBottom can be used as values 1-6 (Example: 0=0px, 1=8px, 2=16px and so forth)
  const marginBottomOverride = marginBottomEnumReturner(marginBottom);

  const styles = {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: imgHeight || 'auto',
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: `${backgroundSizes}`,
    borderTopLeftRadius: `${borderTopRadius}`,
    borderTopRightRadius: `${borderTopRadius}`,
    marginBottom: marginBottomOverride
  };

  return (
    <SquaredUpThumbnailWrapper>
      <AspectRatio
        style={styles}
        role="img"
        aria-label={alt || ''}
        ratio={ratio || '1/1'}
        className={`ie-polyfill ${classNames}`}
      />
    </SquaredUpThumbnailWrapper>
  );
}

AspectRatioPluginV6.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  ratio: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
  borderRadius: PropTypes.number,
  backgroundSize: PropTypes.string
};

// Use media: { key: value} for this component
AspectRatioPluginV6.defaultProps = {
  src: null,
  alt: null,
  ratio: '1/1',
  height: null,
  className: null,
  marginBottom: null,
  borderRadius: 0,
  backgroundSize: 'contain'
};

export default AspectRatioPluginV6;
