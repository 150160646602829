import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormError from './forms/components/FormError';

function sanitizeQueryString(userInput) {
  return DOMPurify.sanitize(userInput);
}

const SearchForm = styled.form`
  margin: 0 0 24px 0;
  input[name='FormButton'] {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.orange_50};
    font-weight: 600;
    padding: 9px 10px 9px 10px;
    transition: background-color 50ms ease-in-out, border-color 50ms ease-in-out;
    border-radius: 0 4px 4px 0;
    height: 40px;
    border: 1px solid ${(props) => props.theme.colors.orange_50};

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    :focus {
      border-radius: 0px 4px 4px 0;
      outline: none;
    }
    :hover,
    :active {
      opacity: 0.8;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
`;

const InputField = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.orange_50};
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  box-shadow: none;
  color: ${(props) => props.theme.colors.grey_40};
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  margin: 0;
  outline: 0;
  padding: 0 12px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media screen and (max-width: 576px) {
    font-size: 8px;
  }

  :focus {
    border: 1px solid ${(props) => props.theme.colors.orange_50};
    color: #555;
  }
  /* clears the ‘X' from Internet Explorer */
  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X' from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address, like: yourname@email.com')
    .required('Please enter an email address.')
});

function SignUpPostmanEmail() {
  const [env, setEnv] = useState('');
  useEffect(() => {
    let host;

    switch (true) {
      case !!document.location.host.match('localhost'):
        host = '';
        break;
      case !!document.location.host.match('beta'):
        host = '-beta';
        break;
      case !!document.location.host.match('stage'):
        host = '-stage';
        break;
      case !!document.location.host.match('preview'):
        host = '-preview';
        break;
      case !!document.location.host.match('postman.com'):
        host = '';
        break;
      default:
        host = '';
    }
    setEnv(host);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: (values) => {
      const cleanQuery = sanitizeQueryString(values.email);
      const encodedQuery = encodeURIComponent(cleanQuery).replace(/%20/g, '+');
      const query = `https://identity.getpostman${env}.com/signup?addAccount=1&email=${encodedQuery}`;
      const sLink = document.createElement('a');
      sLink.href = query;
      sLink.click();
    }
  });

  return (
    <>
      <SearchForm onSubmit={formik.handleSubmit} data-test="identity-form">
        <InputWrapper>
          <InputField
            data-test="identity-email"
            name="email"
            type="search"
            placeholder="name@company.com"
            autoComplete="off"
            aria-label="name@company.com"
            id="input"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <input
            data-test="identity-email-submit"
            id="SignUp"
            type="submit"
            name="FormButton"
            value="Sign Up for Free"
            disabled={!formik.isValid}
          />
        </InputWrapper>
      </SearchForm>
      {formik.touched.email && formik.errors.email ? (
        <FormError className="error" data-test="identity-email-error">
          {formik.errors.email}
        </FormError>
      ) : null}
    </>
  );
}

export default SignUpPostmanEmail;
