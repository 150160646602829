const suppress = (select, display) => {
  const runtime = typeof document === 'object';

  if (runtime) {
    const supressions = [...document.body.querySelectorAll('[data-suppress]')];

    /* eslint-disable no-param-reassign */
    supressions.forEach((supress) => {
      if (supress.getAttribute('data-suppress') === select) {
        supress.style.display = display || 'none';
      }
    });
    /* eslint-enable */
  }
};

export default suppress;
