import React from 'react';

const runtime = typeof document === 'object';

const linkedinPixel = `
<script type="text/javascript">
  _linkedin_partner_id = "4496132";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  </script><script type="text/javascript">
  (function(l) {
  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
  window.lintrk.q=[]}
  var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);})(window.lintrk);
  </script>
  <noscript>
  <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4496132&fmt=gif" />
  </noscript>
`;

const googleVisitedPixel = `
  if(window.gtag){
    window.gtag('event', 'conversion', {'send_to': 'AW-821881030/TycKCMO30JgZEMbR84cD'});
  }
`;

let opts;

class Script extends React.Component {
  componentDidMount() {
    if (runtime) {
      [linkedinPixel].forEach((script) => {
        if (opts.linkedinPixel) {
          const fragment = document
            .createRange()
            .createContextualFragment(script);
          document.body.appendChild(fragment);
        }
        if (opts.googleVisitedPixel && window.gtag) {
          const googleScript = document.createElement('script');
          googleScript.innerHTML = googleVisitedPixel;
          document.body.appendChild(googleScript);
        }
      });
    }
  }

  render() {
    return null;
  }
}

function PixelScript(data) {
  opts = data;

  return <Script />;
}

export default PixelScript;
