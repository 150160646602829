import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import {
  Divider,
  SectionStyles,
  VideoComponent,
  SectionHeader,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

const splitVideoSection = ({
  title,
  body,
  link,
  textAlign,
  video,
  videoTwo,
  divider,
  leftCol,
  rightCol,
  paddingBottom,
  paddingTop
}) => {
  return (
    <>
      <SectionStyles paddingBottom={paddingBottom} paddingTop={paddingTop}>
        <div className="container">
          <div className="row">
            {title ||
              (body && (
                <SectionHeader
                  title={title}
                  body={body}
                  link={link}
                  textAlign={textAlign}
                  bottomMargin="30px"
                />
              ))}
          </div>
          <div className="row">
            <div
              className={`${
                leftCol.className
                  ? leftCol.className
                  : 'col-12 col-lg-6 mb-4 mb-lg-0'
              }`}
            >
              {video && video.src && (
                <VideoComponent
                  src={video.src}
                  controls={video.controls}
                  autoPlay={video.autoPlay}
                  hostType={video.hostType}
                  caption={video.caption}
                  paddingBottom={video.paddingBottom}
                  paddingTop={video.paddingTop}
                  loop={video.loop}
                  muted={video.muted}
                  container={video.container}
                  title={video.title}
                  border={video.border}
                  mediaLayout={video.mediaLayout}
                  poster={video.poster}
                />
              )}
              {leftCol && (
                <div className="text-left">
                  <ContentContainer>
                    {leftCol.logo ? (
                      <img
                        height={
                          leftCol.logo.height ? leftCol.logo.height : '32'
                        }
                        className="mb-4"
                        src={leftCol.logo.src}
                        alt={leftCol.logo.alt}
                      />
                    ) : null}
                    {leftCol.body && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: leftCol.body.join('')
                        }}
                      />
                    )}
                  </ContentContainer>
                  {leftCol.ctaLinks &&
                    Array.isArray(leftCol.ctaLinks) &&
                    leftCol.ctaLinks.map((cta) => (
                      <p key={pmUuid()}>
                        <BaseLink
                          linkType={cta.type}
                          src={cta.src}
                          className={cta.className && cta.className}
                          alternative={
                            cta.alternative ? cta.alternative : false
                          }
                          target={cta.target ? cta.target : 'same-tab'}
                          gaCategory={cta.gaCategory && cta.gaCategory}
                          gaLabel={cta.gaLabel && cta.gaLabel}
                          id={cta.id && cta.id}
                          ariaLabel={cta.ariaLabel && cta.ariaLabel}
                        >
                          {cta.text}
                        </BaseLink>
                      </p>
                    ))}
                  {leftCol.ctaButton &&
                    Array.isArray(leftCol.ctaButton) &&
                    leftCol.ctaButton.map((cta) => (
                      <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
                        <BaseButton
                          buttonType={cta.buttonType}
                          as="a"
                          src={cta.src}
                          target={cta.target}
                          id={cta.id}
                          gaCategory={cta.gaCategory}
                          gaLabel={cta.gaLabel}
                          className={cta.type === 'inline' ? 'pl-0' : null}
                          icon={cta.icon}
                          dataTest={cta.id}
                        >
                          {cta.text}
                        </BaseButton>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div
              className={`${
                rightCol.className ? rightCol.className : 'col-12 col-lg-6'
              }`}
            >
              {videoTwo && videoTwo.src && (
                <VideoComponent
                  src={videoTwo.src}
                  container={videoTwo.container}
                  controls={videoTwo.controls}
                  autoPlay={videoTwo.autoPlay}
                  hostType={videoTwo.hostType}
                  caption={videoTwo.caption}
                  paddingBottom={videoTwo.paddingBottom}
                  paddingTop={videoTwo.paddingTop}
                  loop={videoTwo.loop}
                  muted={videoTwo.muted}
                  title={videoTwo.title}
                  border={videoTwo.border}
                  mediaLayout={videoTwo.mediaLayout}
                  poster={videoTwo.poster}
                />
              )}

              {rightCol && (
                <div className="text-left">
                  <ContentContainer>
                    {rightCol.logo ? (
                      <img
                        height={
                          rightCol.logo.height ? rightCol.logo.height : '35'
                        }
                        width={
                          rightCol.logo.width ? rightCol.logo.width : '110'
                        }
                        className="mb-4"
                        src={rightCol.logo.src}
                        alt={rightCol.logo.alt}
                      />
                    ) : null}
                    {rightCol.body && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rightCol.body.join('')
                        }}
                      />
                    )}
                  </ContentContainer>
                  {rightCol.ctaLinks &&
                    Array.isArray(rightCol.ctaLinks) &&
                    rightCol.ctaLinks.map((cta) => (
                      <p key={pmUuid()}>
                        <BaseLink
                          linkType={cta.type}
                          src={cta.src}
                          className={cta.className && cta.className}
                          alternative={
                            cta.alternative ? cta.alternative : false
                          }
                          target={cta.target ? cta.target : 'same-tab'}
                          gaCategory={cta.gaCategory && cta.gaCategory}
                          gaLabel={cta.gaLabel && cta.gaLabel}
                          id={cta.id && cta.id}
                          ariaLabel={cta.ariaLabel && cta.ariaLabel}
                        >
                          {cta.text}
                        </BaseLink>
                      </p>
                    ))}
                  {rightCol.ctaButton &&
                    Array.isArray(rightCol.ctaButton) &&
                    rightCol.ctaButton.map((cta) => (
                      <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
                        <BaseButton
                          buttonType={cta.buttonType}
                          as="a"
                          src={cta.src}
                          target={cta.target}
                          id={cta.id}
                          gaCategory={cta.gaCategory}
                          gaLabel={cta.gaLabel}
                          className={cta.type === 'inline' ? 'pl-0' : null}
                          icon={cta.icon}
                          dataTest={cta.id}
                        >
                          {cta.text}
                        </BaseButton>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
};

export default splitVideoSection;
