import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import { ParagraphStyles, Paragraph } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SpeakerWrapper = styled.div`
  padding: 0;
`;

const SpeakerContainer = styled.div`
  @media screen and (max-width: 992px) {
    display: flex;
    margin-bottom: 24px;
  }
`;

const SpeakerTextWrapper = styled.div`
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding-left: 16px;
  }
`;
const SpeakerRole = styled(ParagraphStyles)`
  &.role {
    color: #797979;
    margin-bottom: 0;
  }
`;

function SplitSpeaker({ data }) {
  return (
    <div className="col-lg-12 m-auto text-center">
      <div className="row">
        {data.speakers.map((speaker) => (
          <SpeakerContainer className="col-lg-12 text-left mb-3" key={pmUuid()}>
            <div className="row">
              <div className="col-3 m-auto">
                <img
                  className="img-fluid"
                  src={speaker.media.url}
                  alt={speaker.media.alt}
                  fetchpriority="low"
                  loading="lazy"
                />
              </div>
              <div className="col-9">
                <SpeakerTextWrapper>
                  <Paragraph className="strong mt-3 mb-0">
                    {speaker.name}
                  </Paragraph>
                  <SpeakerRole className="role">{speaker.role}</SpeakerRole>
                  {speaker.company && (
                    <SpeakerRole className="role">
                      {speaker.company}
                    </SpeakerRole>
                  )}
                </SpeakerTextWrapper>
              </div>
            </div>
          </SpeakerContainer>
        ))}
      </div>
    </div>
  );
}

SplitSpeaker.propTypes = {
  data: PropTypes.shape({
    speakers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    name: PropTypes.string,
    role: PropTypes.string,
    company: PropTypes.string,
    media: PropTypes.objectOf(PropTypes.shape({}))
  }).isRequired
};

function SpeakerCard(props) {
  const { data } = props;
  return (
    <SpeakerWrapper className="row mb-4">
      {data.speakers.length === 1 && (
        <>
          <div className="col-3 m-auto">
            <img
              className="img-fluid"
              src={data.speakers[0].media.url}
              alt={data.speakers[0].media.alt}
              fetchpriority="low"
              loading="lazy"
            />
          </div>
          <div className="col-9 my-auto pl-lg-0 text-left">
            <Paragraph className="strong mt-3 mb-0">
              {data.speakers[0].name}
            </Paragraph>
            <SpeakerRole className="role">{data.speakers[0].role}</SpeakerRole>
            {data.speakers[0].company && (
              <SpeakerRole className="role">
                {data.speakers[0].company}
              </SpeakerRole>
            )}
          </div>
        </>
      )}
      {data.speakers.length === 2 && <SplitSpeaker data={data} />}
      {data.speakers.length === 3 && (
        <div className="col-lg-12 m-auto text-center">
          <div className="row">
            {data.speakers.map((speaker) => (
              <SpeakerContainer
                className="col-lg-4 col-12 text-left text-lg-center mb-3"
                key={pmUuid()}
              >
                <img
                  className="img-fluid"
                  src={speaker.media.url}
                  alt={speaker.media.alt}
                  fetchpriority="low"
                  loading="lazy"
                />
                <SpeakerTextWrapper>
                  <Paragraph className="strong mt-3 mb-0">
                    {speaker.name}
                  </Paragraph>
                  <SpeakerRole className="role">{speaker.role}</SpeakerRole>
                  {speaker.company && (
                    <SpeakerRole className="role">
                      {speaker.company}
                    </SpeakerRole>
                  )}
                </SpeakerTextWrapper>
              </SpeakerContainer>
            ))}
          </div>
        </div>
      )}
      {data.speakers.length === 4 && <SplitSpeaker data={data} />}
    </SpeakerWrapper>
  );
}

SpeakerCard.propTypes = {
  data: PropTypes.shape({
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        }),
        name: PropTypes.string,
        role: PropTypes.string,
        company: PropTypes.string
      })
    ),

    media: PropTypes.objectOf(PropTypes.shape({}))
  }).isRequired
};

export default SpeakerCard;
