/* eslint-disable no-param-reassign */
export function selectFormById(useFormData, id) {
  let filteredForm = useFormData.filter((isMatching) => isMatching.id === id);
  if (filteredForm && filteredForm.length > 0) {
    filteredForm = filteredForm ? filteredForm[0].rows.flat() : [];
    return filteredForm;
  }
  return filteredForm;
}

export function setInitialValues(values, fields) {
  if (fields.length > 0) {
    fields.forEach((field) => {
      if (
        field.Datatype === 'checkbox' &&
        field.PicklistValues &&
        field.PicklistValues.length > 1
      ) {
        values[field.Name] = [];
      } else if (field.Datatype === 'single_checkbox') {
        values[field.Name] = false;
      } else {
        values[field.Name] = '';
      }
      return values;
    });
  }
}
/*
  converts marketo types into standard input types
  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/button
*/
export function updateInitialTypes(type) {
  const typeMaps = {
    string: 'text',
    picklist: 'select',
    number: 'number',
    phone: 'phone',
    radio: 'radio',
    single_checkbox: 'checkbox'
  };

  return typeMaps[type] || type;
}
