// Aether-Marketing SideXSide clone. Image moves under Text in mobile view

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Divider,
  SectionStyles,
  SectionHeader,
  TextSection
} from 'aether-marketing';
// import pmUuid from '../../../../package/@postman/uuid';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

export const getAnchorId = (prop) => {
  const anchorTag = prop.replace(/\s+/g, '-').replace(/\?/g, '').toLowerCase();
  return anchorTag;
};

const Image = styled.img`
  &.fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.small};
    border: 2px solid
      ${(props) =>
        props.borderColor
          ? props.theme.colors[props.borderColor]
          : props.theme.colors.grey_45};
  }
`;
function ImageSection(data) {
  const { media } = data;

  return (
    <Image
      src={media.media.src}
      alt={media.media.alt}
      height="576"
      width="700"
      fetchpriority="auto"
      loading="low"
      borderColor={media.media.borderColor && media.media.borderColor}
      className={`${media.media.border ? media.media.border : ''}
        ${
          media.media.shadow === true
            ? `media-shadow-v6 img-fluid w-100`
            : `img-fluid w-100`
        }`}
    />
  );
}

// display component based on left and right prop
function TextImageSideXSide(props) {
  const {
    layout,
    backgroundColor,
    paddingBottom,
    paddingTop,
    headerTitle,
    headerTextAlign,
    headerPaddingBottom,
    headerBody,
    textTitleRight,
    textTitleLeft,
    left,
    right,
    divider,
    fullWidth,
    headerBottomMargin,
    buttonRight,
    logoRight,
    linkRight,
    textBodyRight,
    textPaddingBottomRight,
    textPaddingTopRight,
    SideXSideTextRight,
    textSubtitleRight
  } = props;
  // this general layout
  let colLeft;
  let colRight;
  if (layout === 'small') {
    colLeft = 'col-lg-4 offset-lg-0';
    colRight = 'col-lg-7 offset-lg-1';
  }
  if (layout === 'medium') {
    colLeft = 'col-lg-5 offset-lg-0';
    colRight = 'col-lg-6 offset-lg-1';
  }
  if (layout === 'large') {
    colLeft = 'col-lg-5 offset-lg-1';
    colRight = 'col-lg-6 offset-lg-0';
  }
  if (layout === 'xlarge') {
    colLeft = 'col-lg-7 offset-lg-0';
    colRight = 'col-lg-4 offset-lg-1';
  }
  if (layout === 'xlarge-no-offset') {
    colLeft = 'col-lg-7 offset-lg-0';
    colRight = 'col-lg-5';
  }
  if (layout === 'equal') {
    colLeft = 'col-lg-6';
    colRight = 'col-lg-6';
  }

  // alignment Media and Video with flexbox
  let flex;
  let orderLeft;
  let orderRight;
  if (
    left === 'media' ||
    right === 'media' ||
    right === 'video' ||
    left === 'video'
  ) {
    flex = 'align-items-center';
  } else {
    flex = '';
  }
  if (right === 'media' || (right === 'video' && layout !== 'equal')) {
    orderRight = 'order-lg-1 order-0 mb-5 mb-lg-0 ';
    orderLeft = 'order-lg-0 order-1 offset-md-1 col-md-8 offset-md-2';
  }
  if (right === 'text' && left === 'media') {
    orderRight = 'col-md-8 offset-md-2';
    orderLeft = 'mb-5 mb-lg-0 ';
  }
  if (right === 'text' && left === 'text') {
    orderRight = 'col-md-6';
    orderLeft = 'col-md-6';
  }
  if (right === 'media' && left === 'input') {
    orderRight = 'offset-md-0';
    orderLeft = 'mb-5 mb-lg-0 col-md-7';
  }
  if (
    (right === 'list' && left === 'text') ||
    (right === 'video' && left === 'video')
  ) {
    orderRight = '';
    orderLeft = 'mb-5 mb-lg-0 ';
  }

  // dynamically add ID to parent section container
  let textTitle;
  if (textTitleRight) {
    textTitle = textTitleRight;
  } else if (textTitleLeft) {
    textTitle = textTitleLeft;
  } else {
    textTitle = null;
  }
  const anchorTag = textTitle ? getAnchorId(textTitle) : null;

  return (
    <>
      <SectionStyles
        id={anchorTag}
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div key={pmUuid()} className="container">
          {headerTitle && (
            <div
              className={`row ${
                headerBottomMargin ? `${headerBottomMargin}` : 'mb-5'
              }`}
            >
              <SectionHeader
                title={headerTitle}
                assignIDtoParent="true"
                body={headerBody}
                textAlign={headerTextAlign}
                bottomMargin="0"
                headerPaddingBottom={headerPaddingBottom}
              />
            </div>
          )}
          <div className={`row ${flex}`}>
            <div className={`${colLeft} ${orderLeft} order-lg-2`}>
              <TextSection
                backgroundColor={backgroundColor || null}
                body={textBodyRight || null}
                button={buttonRight || null}
                logo={logoRight || null}
                link={linkRight || null}
                paddingBottom={textPaddingBottomRight || null}
                paddingTop={textPaddingTopRight || null}
                SideXSideText={SideXSideTextRight || null}
                subtitle={textSubtitleRight || null}
                title={textTitleRight || null}
              />
            </div>

            <div className={`${colRight} ${orderRight} order-lg-1`}>
              <ImageSection media={props} />
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider fullWidth={fullWidth} />) || null}
    </>
  );
}

TextImageSideXSide.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  // layout: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  headerTextAlign: PropTypes.string,
  headerTitle: PropTypes.string,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    border: PropTypes.string
  }),
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  headerBody: PropTypes.arrayOf(PropTypes.string),
  headerPaddingBottom: PropTypes.string,
  textTitleLeft: PropTypes.string,
  textTitleRight: PropTypes.string,
  headerBottomMargin: PropTypes.string,
  buttonRight: PropTypes.string,
  logoRight: PropTypes.string,
  linkRight: PropTypes.string,
  textPaddingBottomRight: PropTypes.string,
  textPaddingTopRight: PropTypes.string,
  SideXSideTextRight: PropTypes.string,
  textSubtitleRight: PropTypes.string,
  layout: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  textBodyRight: PropTypes.string
};

TextImageSideXSide.defaultProps = {
  divider: false,
  fullWidth: false,
  headerTextAlign: '',
  media: {
    src: null,
    alt: null,
    shadow: null,
    border: null
  },
  left: '',
  right: '',
  textTitleLeft: '',
  textTitleRight: '',
  headerTitle: '',
  backgroundColor: null,
  paddingBottom: '',
  paddingTop: '',
  headerBody: null,
  headerPaddingBottom: '60px',
  headerBottomMargin: 'mb-5',
  textBodyRight: '',
  buttonRight: '',
  logoRight: '',
  linkRight: '',
  textPaddingBottomRight: '',
  textPaddingTopRight: '',
  SideXSideTextRight: '',
  textSubtitleRight: '',
  layout: 'medium'
};

export default TextImageSideXSide;
