import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { BaseLink, Paragraph } from 'aether-marketing';
import PropTypes from 'prop-types';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardStyle = styled.div`
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
`;

const CardTitle = styled.h3`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey_90};
  font-size: 24px;
  line-height: 1.16;
  text-align: left;
`;

const ImgPositioning = {
  maxHeight: '100%',
  maxWidth: '100%',
  marginBottom: '1.25rem'
};

function ThumbnailCards(props) {
  const { data } = props;
  return (
    <>
      {data.thumbnailCards.map((thumbnailCard) => (
        <div className="col-lg-6 col-xl-4 mb-4 text-left" key={pmUuid()}>
          <CardStyle className="card h-100">
            <div>
              {thumbnailCard.media && (
                <img
                  style={ImgPositioning}
                  src={`${thumbnailCard.media.url}`}
                  alt={`${thumbnailCard.media.alt}`}
                />
              )}
              <div>
                <CardTitle className="h4 card-title">
                  {thumbnailCard.title}
                </CardTitle>
                {data.subtitle && (
                  <Paragraph>{thumbnailCard.subtitle}</Paragraph>
                )}
              </div>
            </div>
            <div className="d-block">
              {thumbnailCard.link && (
                <div>
                  <BaseLink src={thumbnailCard.link.url}>
                    {thumbnailCard.link.text}
                  </BaseLink>
                </div>
              )}
            </div>
          </CardStyle>
        </div>
      ))}
    </>
  );
}

ThumbnailCards.propTypes = {
  data: PropTypes.shape({
    thumbnailCards: PropTypes.arrayOf(PropTypes.shape({})),
    subtitle: PropTypes.string
  }).isRequired
};

export default ThumbnailCards;
