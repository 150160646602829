import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, SectionStyles, SectionHeader } from 'aether-marketing';

const Image = styled.img`
  border: ${(props) => props.gif && props.theme.border.gif_border};
  border-radius: ${(props) => props.gif && props.theme.borderRadius.smallv2};
`;
function centeredImage({
  size,
  paddingTop,
  paddingBottom,
  backgroundColor,
  title,
  body,
  link,
  textAlign,
  mobile,
  media,
  divider,
  headerAlign,
  display = '',
  useLocalColor
}) {
  // Determine size of image
  const layoutString = size.toLowerCase();
  let colWidth;
  switch (layoutString) {
    case 'small':
      colWidth = 'col-lg-6 offset-lg-3';
      break;
    case 'medium':
      colWidth = 'col-md-8 offset-md-2';
      break;
    case 'large':
      colWidth = 'col-lg-10 offset-lg-1';
      break;
    case 'full-width':
      colWidth = 'col-12';
      break;
    default:
      // everything else defaults to medium
      colWidth = 'col-md-8 offset-md-2';
  }

  const localColor = '#210B30'; // for POST/CON info page

  return (
    <>
      <SectionStyles
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        backgroundColor={useLocalColor ? localColor : backgroundColor}
        style={{
          backgroundColor: useLocalColor ? localColor : backgroundColor
        }}
      >
        {title && (
          <div className="container">
            <div className="row">
              <SectionHeader
                title={title}
                body={body}
                link={link}
                textAlign={textAlign}
                bottomMargin="30px"
                className={headerAlign ? `${headerAlign}` : ''}
              />
            </div>
          </div>
        )}
        <div className="container">
          <div className="row no-gutters">
            <div className={`col-12 ${colWidth} ${display}`}>
              <Image
                className={
                  mobile
                    ? 'd-none d-md-block img-fluid w-100'
                    : 'img-fluid w-100'
                }
                gif={media.gif}
                src={media.url}
                alt={media.alt}
              />
              {mobile && (
                <Image
                  className="img-fluid d-block d-md-none"
                  gif={media.gif}
                  src={mobile.url}
                  alt={mobile.alt}
                />
              )}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

centeredImage.propTypes = {
  size: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string,
  textAlign: PropTypes.string,
  mobile: PropTypes.string,
  media: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  headerAlign: PropTypes.string,
  display: PropTypes.string,
  useLocalColor: PropTypes.bool
};

centeredImage.defaultProps = {
  size: '',
  paddingTop: null,
  paddingBottom: null,
  backgroundColor: null,
  title: null,
  body: null,
  link: null,
  textAlign: 'center',
  mobile: null,
  media: null,
  divider: null,
  headerAlign: null,
  display: 'purple',
  useLocalColor: false
};

export default centeredImage;
