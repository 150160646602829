import React from 'react';
import youtube from '../../var/youtube.json';

function GenerateVideoSchema(data) {
  const { videoID } = data;
  /* eslint-disable global-require, import/no-dynamic-require */
  const content =
    (Array.isArray(youtube) &&
      youtube.length &&
      require(`../../bff-data/video_${videoID}.json`)) ||
    {};
  /* eslint-enable */
  const contentString = JSON.stringify(content);

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `${contentString}`
      }}
    />
  );
}

export default GenerateVideoSchema;
