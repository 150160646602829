import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import Slider from 'react-slick';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SliderWrapper = styled.div`
  position: relative;
  .v5_slick-carousel {
    height: 150px;

    @media (min-width: 992px) {
      height: 300px;
    }
  }
  .slick-prev,
  .slick-next {
    padding: 2rem;
    display: block;
    position: absolute;
    z-index: 1;
    height: 100%;

    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-arrow {
    i {
      position: relative;
      top: 33%;
    }
  }
`;

class SliderContainer extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const css1 = document.createElement('link');
    css1.rel = 'stylesheet';
    css1.type = 'text/css';
    css1.href =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css';
    document.getElementsByTagName('head')[0].appendChild(css1);

    const css2 = document.createElement('link');
    css2.rel = 'stylesheet';
    css2.type = 'text/css';
    css2.href =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css';
    document.getElementsByTagName('head')[0].appendChild(css2);
  }

  render() {
    const { data } = this.state;
    return (
      <SliderWrapper>
        <Slider
          infinite
          speed={300}
          slidesToShow={1}
          centerMode
          variableWidth
          dots={false}
          arrows
        >
          {data.images.map((image) => (
            <div className="slick-slide" key={pmUuid()}>
              <img
                alt=""
                className="v5_slick-carousel"
                src={image}
                fetchpriority="low"
                loading="lazy"
              />
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    );
  }
}

SliderContainer.propTypes = {
  data: PropTypes.shape({}).isRequired
};

const slider = (data) => (
  <div>
    <SliderContainer data={data} />
  </div>
);

export default slider;
