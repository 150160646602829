/* eslint-disable react/prop-types */
import React from 'react';
import CompactFormCheckbox from '../components/CompactFormCheckbox';
import CompactFormLegalText from '../components/CompactFormLegalText';
import FormDropdown from '../components/FormDropdown';
import FormRadio from '../components/FormRadio';
import CompactFormText from '../components/CompactFormText';
import CompactFormTextArea from '../components/CompactFormTextArea';

function handleFormFields({ ...props }) {
  const { type } = props;
  return (
    <>
      {type === 'text' && CompactFormText(props)}
      {type === 'email' && CompactFormText(props)}
      {type === 'number' && CompactFormText(props)}
      {type === 'phone' && CompactFormText(props)}
      {type === 'url' && CompactFormText(props)}
      {type === 'tel' && CompactFormText(props)}
      {type === 'radio' && FormRadio(props)}
      {type === 'select' && FormDropdown(props)}
      {type === 'textarea' && CompactFormTextArea(props)}
      {type === 'checkbox' && CompactFormCheckbox(props)}
      {type === 'htmltext' && CompactFormLegalText(props)}
      {type === 'hidden' && null}
    </>
  );
}
export default handleFormFields;
