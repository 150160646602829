import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'aether-marketing';
import AspectRatioImagePlugin from '../../v5_images/AspectRatioImagePlugin';
/* eslint react/prop-types: 0 */

const CardSection = styled.a`
  &:hover {
    text-decoration: none;
    transform: translateY(-0.5rem);
  }
  -webkit-transition: ${(props) => props.theme.transitions.all};
  transition: ${(props) => props.theme.transitions.all};
  cursor: pointer;
`;

const CardContainer = styled.div`
  margin: 1px -1px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: ${(props) => props.theme.colors.grey_00};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  box-shadow: ${(props) => props.theme.shadows.card_shadow};
  -webkit-box-shadow: ${(props) => props.theme.shadows.card_shadow};
  -moz-box-shadow: ${(props) => props.theme.shadows.card_shadow};
  border-collapse: separate;
`;

const CardBody = styled.div`
  padding: 24px;
  color: ${(props) => props.theme.colors.grey_90};
`;

const activeCompare = (active, card) => {
  let response = false;
  active.forEach((val) => {
    if (card[val]) {
      response = true;
    }
  });
  return response;
};

// This file uses "speakers.json"

function SpeakerCards({ data }) {
  const { card, filteredSession } = data;
  const filteredArr = Object.keys(filteredSession).filter(
    (key) => filteredSession[key]
  );

  return (
    <>
      {/* /* eslint-disable */}
      {card.active && (
        <CardSection
          className={`${card.column} ${
            activeCompare(filteredArr, card.sessions) ? 'mb-5' : ' d-none'
          }`}
          href={card.urlSlug.href}
        >
          <CardContainer className="h-100 d-flex flex-column">
            <AspectRatioImagePlugin
              src={card.media.src}
              role="img"
              ratio="12/6"
              aria-label={card.title || ''}
              marginBottom={card.marginBottom ? card.marginBottom : 0}
            />
            <CardBody className="mb-0">
              <h4 className="mb-3">{card.title}</h4>
              {card.subtitle && (
                <Paragraph className="mb-0">{card.subtitle}</Paragraph>
              )}
            </CardBody>
          </CardContainer>
        </CardSection>
      )}
    </>
  );
}
/* eslint-enable */

export default SpeakerCards;
