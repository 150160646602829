// Returns the appropriate marginBottom value based on enum input.
export default function marginBottomEnumReturner(num) {
  if (num === 0) {
    return '0px';
  }
  if (num === 1) {
    return '8px';
  }
  if (num === 2) {
    return '16px';
  }
  if (num === 3) {
    return '24px';
  }
  if (num === 4) {
    return '32px';
  }
  if (num === 5) {
    return '40px';
  }
  if (num === 6) {
    return '48px';
  }
  if (num === 7) {
    return '56px';
  }
  if (num === 8) {
    return '64px';
  }
  return null;
}
// export default function marginBottomEnumReturner(num) {
//   if (num === 0) {
//     return '0px';
//   } if (num === 1) {
//     return '16px';
//   } if (num === 2) {
//     return '32px';
//   }
//   return null;
// }
