import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'aether-marketing';

//  This file is for Webinar Texts and Registration Pages to use different sizes of text as a header
function WebinarHeroText(props) {
  const { data } = props;
  return (
    <div className="pt-4">
      {data.header.tagline && (
        <Paragraph className="strong h4 text-uppercase pt-2 mb-3 subtitle">
          {data.header.tagline}
        </Paragraph>
      )}
      {data.header.title && <h1 className="pt-2">{data.header.title}</h1>}
      {data.header.subheader && (
        <h2 className="pt-2 pb-2">{data.header.subheader}</h2>
      )}
      {data.header.paragraph && (
        <Paragraph
          dangerouslySetInnerHTML={{ __html: data.header.paragraph }}
        />
      )}
    </div>
  );
}

export default WebinarHeroText;

WebinarHeroText.propTypes = {
  data: PropTypes.shape({
    alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    header: PropTypes.shape({
      tagline: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      subheader: PropTypes.string,
      paragraph: PropTypes.string
    })
  }).isRequired
};
