import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { Paragraph } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  padding: 60px 0;
  background-color: ${(props) => props.theme.colors.grey_90};
  background-position: stretch;
  background-repeat: no-repeat;
  background-image: url('https://voyager.postman.com/illustration/hero-stars-only.svg');
  color: white;
`;

const hero = (data) => {
  const fontFamily = data.fontFamily ? data.fontFamily : '';

  const leftCol = data.leftCol ? data.leftCol : 'col-md-6';
  const rightCol = data.rightCol ? data.rightCol : 'col-md-6';
  // id data.centerTitle is true, it'll center the title.  Can see on 2020 AP Survey page
  const centerTitle = data.centerTitle ? 'd-flex align-items-center' : '';

  // This styles object is for the main container, background image
  const styles = {
    backgroundImage: data.backgroundImage,
    backgroundSize: 'cover'
  };

  // H1 title now accepts an 'xl' prop, which makes font-size 60px if set to true in JSON

  const body = data.body
    ? data.body.map((text) => (
        <Paragraph dangerouslySetInnerHTML={{ __html: text }} key={pmUuid()} />
      ))
    : undefined;
  return (
    <Section style={styles}>
      <div className="container">
        {/* if titleRow is true, render the title in a separate row (see /open-philosophy/) */}
        {data.titleRow && (
          <div className="row pb-5">
            <div className="col-sm-12 text-center text-md-left my-auto">
              <h1 style={{ fontFamily }} className="mb-0 text-white">
                {data.titles}
              </h1>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className={`col-12 ${leftCol} text-md-left text-center my-auto ${centerTitle}`}
          >
            {/* if titleRow is NOT true, then render the title row here */}
            {!data.titleRow && (
              <h1
                style={{ fontFamily }}
                xl={data.titleXl}
                className="text-white"
              >
                {data.titles}
              </h1>
            )}
            {body && body}
          </div>
          <div className={`col-12 ${rightCol}`}>
            {data.media ? (
              <img
                src={data.media[0].image}
                className="img-fluid"
                alt={data.media[0].alt}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default hero;
