function pmGot() {
  const dataPmGot = [...document.querySelectorAll('[data-pmGot]')].shift();
  const env =
    (!!document.location.host.match('localhost') && 'DEV') ||
    (!!document.location.host.match('beta') && 'BETA') ||
    (!!document.location.host.match('stage') && 'STAGE') ||
    (!!document.location.host.match('preview') && 'PREV') ||
    (!!document.location.host.match('postman.com') && 'PROD');
  const MessageBarAlertTop = document.getElementById('message-bar-alert-top');
  const pmtGoogleClientId = dataPmGot.getAttribute('data-pmGot');
  const href = dataPmGot.getAttribute('data-pmGot-href');

  let topZIndex = dataPmGot.getAttribute('data-topZIndex') || 9999999999;

  if (typeof topZIndex === 'string') {
    topZIndex = parseInt(topZIndex, 10);
  }

  const otCallback = (payload) => {
    const { credential } = payload || {};
    const shouldRedirectToCurrentURL = true;

    let qs = `?${href.split('?').pop()}&credential=${credential}`;

    if (shouldRedirectToCurrentURL) {
      qs = qs.replace(
        /continue=[a-zA-Z0-9%.]+/,
        `continue=${encodeURIComponent(document.location.href)}`
      );
    }

    const endpoint = `https://identity.getpostman${
      ((env === 'DEV' || env === 'BETA') && '-beta') ||
      (env === 'STAGE' && '-stage') ||
      (env === 'PREV' && '-preview') ||
      ''
    }.com/google/one-tap/callback${qs}`;

    if (
      document.location.search.indexOf('disableGoogleOneTouchRedirect') === -1
    ) {
      document.location.href = window.pmt('getTraceUrl', [endpoint]);
    }

    return true;
  };
  const adjustDOM = (prompt) => {
    const pad = 5;
    const messageBarAlertTopHeight =
      (MessageBarAlertTop && MessageBarAlertTop.offsetHeight + pad * 0.75) || 0;
    const buttonHeight =
      (dataPmGot && dataPmGot.parentNode.offsetHeight + pad * 1.5) || 0;

    if (prompt) {
      /* eslint-disable no-param-reassign */
      prompt.style.zIndex = topZIndex;
      prompt.style.top = `${messageBarAlertTopHeight + buttonHeight}px`;
      prompt.style.right = 0;
      /* eslint-enable */
    }
  };

  const script = document.createElement('script');
  script.src = 'https://accounts.google.com/gsi/client';
  script.async = true;
  script.defer = true;
  script.onload = () => {
    window.google.accounts.id.initialize({
      client_id: pmtGoogleClientId,
      callback: otCallback
    });

    window.google.accounts.id.prompt();

    setTimeout(() => {
      const prompt = document.getElementById('credential_picker_container');

      if (prompt) {
        adjustDOM(prompt);
      } else {
        /* eslint-disable no-console */
        console.error('No prompt!');
        /* eslint-enable */
      }
    }, 500);
  };

  document.body.appendChild(script);
}

pmGot();
