const cards = [
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '9:00AM-11:00AM PST',
    title: 'Student Workshop, Part 1',
    subtitle:
      'Part 1 of the student workshop will get you started with the essential skills involved in building and testing API requests in Postman.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-student-expert-part-1_web.png'
    },
    speakers: [
      {
        name: 'Amit Tiwary',
        title: 'Postman Student Expert',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/amit.jpg',
          alt: 'Amit Tiwary, Postman Student Expert'
        }
      },
      {
        name: 'Darsh Shah',
        title: 'Postman Student Expert',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/darsh.jpg',
          alt: 'Darsh Shah, Postman Student Expert'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    time: '9:00AM-11:00AM PST',
    date: 'TUES, FEB 2',
    title: '"Leveraging Automation" Workshop',
    subtitle:
      'Learn how to build tests into your collections and leverage automation in your API workflows.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-leveraging-automation_web.png'
    },
    speakers: [
      {
        name: 'John Banning',
        title: 'Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/john-banning-hs.png',
          alt: 'John Banning, Solutions Engineer at Postman.'
        }
      },
      {
        name: 'Valentin Despa',
        title: 'Software Developer & Educator',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/valentin-hs.png',
          alt: 'Valentin Despa, Software Developer and Developer Educator'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    time: '9:00AM-11:00AM PST',
    date: 'TUES, FEB 2',
    title: '"APIs 101" Workshop',
    subtitle:
      "Get hands-on in Postman to get familiar with API fundamentals. You'll learn to build and send requests using a variety of common configurations.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-apis-101-workshop.png'
    },
    speakers: [
      {
        name: 'Sowmya Rajan',
        title: 'Technical Writer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sowmya.jpg',
          alt: 'Sowmya Rajan, Technical Writer at Postman.'
        }
      },
      {
        name: 'Vu Nguyen',
        title: 'Product Advocate',
        company: 'Postman',
        media: {
          src: '/assets/postman-galaxy/speakers/vu-nguyen.jpg',
          alt: 'Vu Nguyen, Product Advocate at Postman.'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '11:00AM–12:00PM PST',
    title: 'Office Hours',
    subtitle:
      'Bring your questions, opinions, and observations about all things API, and we can hash it all out together in this free-form open forum.',
    media: {
      src: ''
    },
    speakers: [
      {
        name: 'Ryan Reynolds',
        title: 'Enterprise Sales Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ryan-reynolds.png',
          alt: 'Ryan Reynolds, Enterprise Sales Manager at Postman'
        }
      },
      {
        name: 'Arlémi Turpault',
        title: 'Developer Advocate',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/arlemi-hs.jpg',
          alt: 'Arlémi Turpault, Developer Advocate at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '11:30AM–12:00PM PST',
    title: 'Guided Stretch Break',
    subtitle:
      "Give your brain a break and do something nice for your body. We'll stretch to keep our minds and bodies supple.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-guided-stretch_web.png'
    },
    speakers: [
      {
        name: 'Annique Roberts',
        title: 'Rehearsal Director & Senior Dancer',
        company: 'Ronald K. Brown/Evidence, A Dance Company',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/annique-roberts.jpg',
          alt: 'Annique Roberts'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '12:00PM–2:00PM PST',
    title: 'Student Workshop, Part 2',
    subtitle:
      'Part 2 of the student workshop will improve your proficiency in a wider range in Postman capabilities.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-student-expert-part-2_web.png'
    },
    speakers: [
      {
        name: 'Sayan Chaudhry',
        title: 'Postman Student Expert',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sayan.jpg',
          alt: 'Sayan Chaudhry, Postman Student Expert'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '12:00PM–2:00PM PST',
    title: '"Working with APIs in an API-First Lifecycle" Workshop',
    subtitle:
      'Walk through the API-first workflow, using OpenAPI specification for robust design, development, testing, and observability of your service.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-api-first_web.png'
    },
    speakers: [
      {
        name: 'Yi-An Tseng',
        title: 'Customer Success Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/yi-an-tseng-hs.jpg',
          alt: 'Yi-An Tseng, Customer Success Engineer at Postman.'
        }
      },
      {
        name: 'Kevin Swiber',
        title: 'Lead Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kevin-swiber-hs.jpg',
          alt: 'Kevin Swiber, Lead Solutions Engineer at Postman.'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'TUES, FEB 2',
    time: '12:00PM–2:00PM PST',
    title: '"Optimize Adoption for Your APIs" Workshop',
    subtitle:
      'Learn how to build a demo to increase adoption and engagement for your consumer-facing API product.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-api-adoption-training_web.png'
    },
    speakers: [
      {
        name: 'Harsha Chelle',
        title: 'Customer Success Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/harsha-hs.jpg',
          alt: 'Harsha Chelle, Customer Success Manager at Postman.'
        }
      },
      {
        name: 'Matt Ball',
        title: 'Lead Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/matt-ball-hs.jpg',
          alt: 'Matt Ball, Lead Solutions Engineer at Postman.'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/keynote-with-postman-cofounder-and-ceo-abhinav-asthana/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    keySessions: true,
    date: 'WED, FEB 3',
    time: '9:00AM–9:45AM PST',
    title: 'Postman Keynote: Abhinav Asthana',
    subtitle:
      'Abhinav, the Co-founder and CEO of Postman, presents the opening Keynote address and demos exciting new tech.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-abhinav_web.png'
    },
    speakers: [
      {
        name: 'Abhinav Asthana',
        title: 'Postman Co-founder and CEO',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/abhinav-asthana-founder-postman.jpg',
          alt: 'Abhinav Asthana, CEO and Co-founder at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/fireside-chat-with-woz/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    keySessions: true,
    date: 'WED, FEB 3',
    time: '9:45AM–10:45AM PST',
    title: 'Fireside Chat with Woz',
    subtitle:
      'Steve Wozniak, Silicon Valley icon and co-founder of Apple, will share his interstellar perspectives with you.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-woz_web.png'
    },
    speakers: [
      {
        name: 'Steve Wozniak',
        title: 'Apple Co-founder',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/steve-crop.jpg',
          alt: 'Steve Wozniak, Apple Co-founder'
        }
      },
      {
        name: 'Kin Lane',
        title: 'Chief Evangelist',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kin-lane.jpg',
          alt: 'Kin Lane, Chief Evangelist at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '10:45AM-1:30PM PST',
    title: 'Ask Us Anything',
    subtitle:
      'Need a break? Stop by this free-flowing discussion as Arlemi and Joyce answer all your burning Postman questions.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-need-a-break_web.png'
    },
    speakers: [
      {
        name: 'Joyce Lin',
        title: 'Director of Developer Relations',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/joyce-lin-hs.png',
          alt: 'Joyce Lin, Head of Developer Relations at Postman'
        }
      },
      {
        name: 'Arlémi Turpault',
        title: 'Developer Advocate',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/arlemi-hs.jpg',
          alt: 'Arlémi Turpault, Developer Advocate at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title:
      'Talk: “Building an Accurate and Complete OpenAPI Description at Scale”',
    subtitle:
      "GitHub's story of how they describe a 12-year old API using the OpenAPI specification.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-building-an-accurate_web.png'
    },
    speakers: [
      {
        name: 'Marc-Andre Giroux',
        title: 'Staff Software Developer',
        company: 'GitHub',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/marc-andrew-hs.jpg',
          alt: 'Marc-Andre Giroux, Staff Software Developer at GitHub'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/devops-test-and-automation/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:45AM PST',
    title: 'Panel: “DevOps, Test, and Automation”',
    subtitle:
      "If you haven't played around with Newman, monitors, or the collection runner in Postman, this is one you won't want to miss.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-devops-test-automation.png'
    },
    speakers: [
      {
        name: 'Sue Smith',
        title: 'Developer Educator',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sue-smith-hs.jpg',
          alt: 'Sue Smith, Developer Educator at Postman'
        }
      },
      {
        name: 'Kevin Harris',
        title: 'API Integrations SME',
        company: 'iQmetrix',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kevin-harris.jpg',
          alt: 'Kevin Harris, API Integrations SME at iQmetrix'
        }
      },
      {
        name: 'Alla Lazareva',
        title: 'Head of QA',
        company: 'ZIWO',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alla-hs.jpeg',
          alt: 'Alla Lazareva, Head of QA at ZIWO'
        }
      },
      {
        name: 'Angel Rivera',
        title: 'Developer Advocate',
        company: 'CircleCI',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/angel-hs.png',
          alt: 'Angel Rivera, Developer Advocate at CircleCI'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/3d-data-visualization-using-three-js/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:15AM PST',
    title: 'Lightning Talk: “3D Data Visualization Using Three.js”',
    subtitle:
      "Get details on how to use Postman's data-visualization feature to create interactive 3D views that can enhance your users' experience.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-3d-data-visualization_web.png'
    },
    speakers: [
      {
        name: 'Karan Balkar',
        title: 'Team Lead',
        company: 'LTI',
        media: {
          src: '/assets/postman-galaxy/speakers/karan-balkar.jpg',
          alt: 'Karan Balkar, Team Lead at LTI'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-and-the-interface-economy/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title:
      'Talk: “Developers Are Pioneers Not Unicorns: Postman and the Interface Economy”',
    subtitle:
      'How Postman replaces people to people information transfers with information transfers through constrained interfaces.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-developers-are-pioneers_web.png'
    },
    speakers: [
      {
        name: 'Kristof Van Tomme',
        title: 'CEO',
        company: 'Pronovix',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kristof-hs.jpeg',
          alt: 'Kristof Van Tomme CEO of Pronovix'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/automated-processes-for-better-apis/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “Automated Processes for Even Better APIs”',
    subtitle:
      'Learn how API gateways, the DevOps approach, and the CAMS model can help you automate (and improve) the development process of APIs.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-automate-your-processes_web.png'
    },
    speakers: [
      {
        name: 'Daniel Kocot',
        title: 'API Expert / Senior Solutions Architect',
        company: 'Codecentric',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/daniel-kocot-hs.png',
          alt: 'Daniel Kocot, API Expert / Senior Solutions Architect at Codecentric'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/designing-secure-apis-in-the-cloud/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “Designing Secure APIs in the Cloud”',
    subtitle:
      'Services are exposed on the Internet and can be accessed by anyone and may have untrusted users.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-designing-secure-apis_web.png'
    },
    speakers: [
      {
        name: 'Adora Nwodo',
        title: 'Software Engineer',
        company: 'Microsoft',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/adora-hs.jpg',
          alt: 'Adora Nwodo, Software Engineer at Microsoft'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/sharing-apis-at-scale/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title:
      'Partner Talk: “Sharing APIs at Scale for a Great Developer Experience”',
    subtitle:
      'Leverage Postman resources to handle enterprise software challenges.',
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    },
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-sharing-apis-scale_web.png'
    },
    speakers: [
      {
        name: 'Philippe Ozil',
        title: 'Principal Developer Evangelist',
        company: 'Salesforce',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/phillipe.jpg',
          alt: 'Philippe Ozil, Principal Developer Evangelist at Salesforce'
        }
      }
    ]
  },
  {
    urlSlug: {
      href: '/postman-galaxy/building-better-online-games/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Partner Talk: “Building Better Online Games with Postman”',
    subtitle:
      'Release new features rapidly while maintaining backward compatibilities.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-building-better_web.png'
    },
    speakers: [
      {
        name: 'Raymond Arifianto',
        title: 'VP of Technology',
        company: 'Accelbyte',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/raymond-hs.jpg',
          alt: 'Raymond Arifianto, VP of Technology at Accelbyte'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/managing-full-api-lifecycle/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Postman Talk: “Full API Lifecycle Management”',
    subtitle: 'Managing the API lifecycle while working with a team.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-full-api-lifecycle_web.png'
    },
    speakers: [
      {
        name: 'Aamir Ahmed',
        title: 'Technical Pre-Sales Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/aamir.jpg',
          alt: 'Aamir Ahmed, Technical Pre-Sales Engineer at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-for-enterprises-evolving-it-and-business/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    category: ['business'],
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Postman for Enterprises',
    subtitle:
      'Understand how API-first enterprises are using Postman to evolve IT and business. Learn how to think about API lifecycle management while ensuring security, compliance, and governance of your APIs.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-enterprises_web.png'
    },
    speakers: [
      {
        name: 'Sanjeev Sisodiya',
        title: 'VP Customer Success',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sanjeev.jpg',
          alt: 'Sanjeev Sisodiya, VP Customer Success at Postman'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-admin-101/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:00AM–11:30AM PST',
    title: 'Postman Talk: Postman Admin 101',
    subtitle:
      'Walk through the basics of Postman administration and how you can make the most of the key Admin role.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-admin-101.png'
    },
    speakers: [
      {
        name: 'Harsha Chelle',
        title: 'Customer Success Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/harsha-hs.jpg',
          alt: 'Harsha Chelle, Customer Success Manager at Postman.'
        }
      }
    ],
    sessions: {
      developers: false,
      business: false,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/upgrade-your-collections-with-documentation/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:15AM–11:30AM PST',
    title: 'Lightning Talk: “Upgrade Your Collections”',
    subtitle:
      'Practical documentation techniques to take your collection to the next level.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-upgrade-your-collections_web.png'
    },
    speakers: [
      {
        name: 'Carson Hunter',
        title: 'Software Developer',
        company: 'Metadata Technology North America',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/carson-hunter-hs.jpg',
          alt: 'Carson Hunter Software Developer at Metadata Technology North America'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/the-future-of-api-specifications/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “The Future of API Specifications”',
    subtitle:
      'The creator of the asyncapi Initiative and a long-term user of openapi and JSON Schema provides insights on the future API specs landscape.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-the-future-of-api_web.png'
    },
    speakers: [
      {
        name: 'Fran Méndez',
        title: 'Founder',
        company: 'AsyncAPI Initiative',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/fran-hs.jpg',
          alt: 'Fran Méndez, Founder of AsyncAPI Initiative'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/why-you-need-a-developer-relations-team-for-your-api/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “Why You Need a Developer Relations Team for Your API”',
    subtitle:
      'Providing community, documentation, tutorials and tools with DevRel Anna Tsolakou.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-why-you-need-devrel_web.png'
    },
    speakers: [
      {
        name: 'Anna Tsolakou',
        title: 'Developer Advocate',
        company: 'Amadeus',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/anna-ts-hs.jpg',
          alt: 'Anna Tsolakou Developer Advocate at Amadeus'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/generating-api-contract-tests-with-openapi-files/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    category: ['developers'],
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title:
      'Talk: “Using OpenAPI Files to Generate API Contract Tests With Newman”',
    subtitle:
      'Automatically convert your OpenAPI or Swagger doc into a Postman contract test using an automated script.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-automatically-generate_web.png'
    },
    speakers: [
      {
        name: 'Paul Cruse III',
        title: 'Technical Lead',
        company: 'Syngenta',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/paul-cruse-hs.png',
          alt: 'Paul Cruse III, Technical Lead at Syngenta'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/making-app-developers-more-productive/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “Making App Developers More Productive”',
    subtitle:
      'Learn about SQL queries that are turned into data APIs, which can be executed from a dedicated REST endpoint.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-making-the-app_web.png'
    },
    speakers: [
      {
        name: 'Nadine Farah',
        title: 'Senior Developer Advocate',
        company: 'Rockset',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/nadine-hs.jpg',
          alt: 'Nadine Farah, Senior Developer Advocate at Rockset'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/api-security-at-scale/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    category: ['business', 'developers'],
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Partner Talk: “Securing your APIs at scale”',
    subtitle:
      'We will walk through some common attack vectors to help increase awareness of potential threats including a demo that uses Postman.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-securing-your-apis_web.png'
    },
    speakers: [
      {
        name: 'Javier Garza',
        title: 'Developer Advocate',
        company: 'Akamai Technologies',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/javier-hs.png',
          alt: 'Javier Garza, Developer Advocate at Akamai Technologies'
        }
      },
      {
        name: 'Mike Elissen',
        title: 'Developer Advocate',
        company: 'Akamai Technologies',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/mike-elissen.png',
          alt: 'Mike Elissen, Developer Advocate at Akamai Technologies'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/get-organized-for-postman-testing-success/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Partner Talk: “Get Organized for Postman Testing Success”',
    subtitle:
      'Manage your workspaces, collections, and more, according to your organizational pattern.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-testing_web.png'
    },
    speakers: [
      {
        name: 'Kristin Jackvony',
        title: 'Principal Engineer, Quality',
        company: 'Paylocity',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kristin-hs.jpg',
          alt: 'Kristin Jackvony, Principal Engineer - Quality at Paylocity'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/improving-engineering-operations/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    category: ['developers'],
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title:
      'Postman Talk: “Automation, Integration, and Orchestration for Better Engineering Operations”',
    subtitle:
      'Automate your API testing with test scripts and integration with CI/CD pipeline.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-automation-integration_web.png'
    },
    speakers: [
      {
        name: 'Evan Lindsey',
        title: 'Lead Software Development Engineer in Test ',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/evan.jpeg',
          alt: 'Evan Lindsey, Lead Software Developer Engineer at Postman'
        }
      },
      {
        name: 'Ranchana Rajasekaran',
        title: 'Senior Software Development Engineer in Test',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ranchana.jpeg',
          alt: 'Ranchana Rajasekaran, Senior SDET at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-platform-overview-be-api-first-not-api-last/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    category: ['business'],
    keySessions: true,
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Postman Platform Overview: “Be API-First, Not API-Last”',
    subtitle:
      "The Postman API Platform lets you embrace API-first software development and transform into an API-first business. In this talk, you'll get an exclusive overview of the accelerating global shift toward API-first development and understand how Postman can help you get there.",
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    },
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-be-api-first_web.png'
    },
    speakers: [
      {
        name: 'Kin Lane',
        title: 'Chief Evangelist',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kin-lane.jpg',
          alt: 'Kin Lane, Chief Evangelist at Postman'
        }
      }
    ]
  },
  {
    urlSlug: {
      href: '/postman-galaxy/adopting-postman-at-kroger/'
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “Adopting Postman at Kroger: Opportunities and Challenges”',
    subtitle:
      'Our approach to increase adoption of Postman across several teams at Kroger Technology & Digital.',
    sessions: {
      developers: false,
      business: false,
      testers: false,
      admins: true
    },
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-building-kroeger_web.png'
    },
    speakers: [
      {
        name: 'James Noes',
        title: 'Senior API Solutions Engineer',
        company: 'Kroger',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/james-noes.jpg',
          alt: 'James Noes, Senior API Solutions Engineer at Kroger'
        }
      },
      {
        name: 'Jessica Johnson',
        title: 'Senior API Solutions Engineer',
        company: 'Kroger',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/jessica-johnson.jpg',
          alt: 'Jessica Johnson, Senior API Solutions Engineer at Kroger'
        }
      }
    ]
  },
  {
    urlSlug: {
      href: '/postman-galaxy/type-safety-in-an-unsafe-world-with-kotlin/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '11:45AM–12:00PM PST',
    title: 'Lightning Talk: “Type Safety in an Unsafe World with Kotlin”',
    subtitle:
      'Kotlin is a type-safe language that can make working with less-stable APIs a hassle. However, it can also be quite enjoyable. See how.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-type-safety-unsafe_web.png'
    },
    speakers: [
      {
        name: 'Cody Engel',
        title: 'Staff Software Engineer',
        company: 'PayPal',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/cody-engel-hs.jpg',
          alt: 'Cody Engel, Staff Software Engineer at PayPal'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '12:30PM–1:00PM PST',
    title: 'Community Workshop: “Movement Games with Play More”',
    subtitle:
      "We'll be playing short games to show you new stress-relieving ways to move your body at home in limited spaces with limited resources.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-movement-games_web.png'
    },
    speakers: [
      {
        name: 'Marria Kee',
        title: 'Play More',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/marria-kee.jpg',
          alt: 'Marria Kee'
        }
      },
      {
        name: 'Zach Fish',
        title: 'Play More',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/zach-fish.jpg',
          alt: 'Zach Fish'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/how-to-build-and-deploy-services-in-the-cloud/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Fireside Chat: “How to Build and Deploy Services in the Cloud”',
    subtitle: 'Choosing the right patterns for building services at scale.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-how-to-build-and-deploy.png'
    },
    speakers: [
      {
        name: 'Robert Krohn',
        title: 'Head of Engineering, DevOps',
        company: 'Atlassian',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/robert.jpg',
          alt: 'Robert Krohn, Head of Engineering, DevOps at Atlassian'
        }
      },
      {
        name: 'Sean McCullough',
        title: 'Engineering Manager',
        company: 'Atlassian',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sean-m.jpg',
          alt: 'Sean McCullough, Engineering Manager for Bitbucket Cloud at Atlassian'
        }
      },
      {
        name: 'Laura Thomson',
        title: 'VP of Engineering',
        company: 'Fastly',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/laura-thompson.jpeg',
          alt: 'Laura Thomson, VP of Engineering at Fastly'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/all-clouds-need-a-sprinkle-of-chaos/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Talk: “All Clouds Need a Sprinkle of Chaos”',
    subtitle:
      "We'll discuss cloud-native complexity and how you can leverage chaos engineering throughout your development lifecycle to help simplify that complexity.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-all-clouds-need_web.png'
    },
    speakers: [
      {
        name: 'Ana M Medina',
        title: 'Senior Chaos Engineer',
        company: 'Gremlin',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ana-m-hs.png',
          alt: 'Ana M Medina, Senior Chaos Engineer at Gremlin'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-visualizer-more-than-meets-the-eye/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:15PM PST',
    title: 'Lightning Talk: “Postman Visualizer: More Than Meets the Eye”',
    subtitle:
      'Examples of how you can create your own “micro” web apps with the visualizer.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-visualizer_web.png'
    },
    speakers: [
      {
        name: 'Orest Danylewycz',
        title: 'Information Security Engineer',
        company: 'Westfield Insurance',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/orest-hs.jpeg',
          alt: 'Orest Danylewycz, Information Security Engineer at Westfield Insurance'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/why-apis-call-for-2xs-the-devops/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Talk: “Why APIs Call for 2xs the DevOps”',
    subtitle:
      "We'll look at customers in three different industries who scaled their DevOps workflow and see how that impacted their APIs and more.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-why-apis-call-for_web.png'
    },
    speakers: [
      {
        name: 'Emmelyn Wang',
        title: 'Global Lead, DevOps, AWS Marketplace',
        company: 'AWS',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/emmelyn-wang-hs.jpg',
          alt: 'Emmelyn Wang, Global Lead, DevOps, AWS Marketplace at AWS'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-collections-facilitate-security-services-interactions/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title:
      'Talk: “Leveraging Postman Collections to Facilitate Security Services Interactions”',
    subtitle:
      'How we leverage Postman Collections and Monitors to create a new integration between Uptycs and Opsgenie.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-security-postman-uptycs_web.png'
    },
    speakers: [
      {
        name: 'Ryan Nolette',
        title: 'Security Team Lead',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ryan-nolette-hs.jpg',
          alt: 'Ryan Nolette, Security Team Lead at Postman'
        }
      },
      {
        name: 'Julian Wayte',
        title: 'Security Solutions Engineer',
        company: 'Uptycs',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/julian-hs.jpg',
          alt: 'Julian Wayte, Security Solutions Engineer at Uptycs'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/partner-talk-teaching-the-elephant-to-fly/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Partner Talk: “Teaching the Elephant to Fly”',
    subtitle: 'API-based integrations in the auto industry.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-teaching-the-elephant_web.png'
    },
    speakers: [
      {
        name: 'David Rajan',
        title: 'Product Manager',
        company: 'Ford',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/david-rajan.jpg',
          alt: 'David Rajan, Product Manager, Foundational Services AP @ Ford'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title:
      'Partner Talk: “From One API Deployment to Thousands, the Journey of an Innersource CI/CD Framework”',
    subtitle:
      'This session will take you on the winding and sometimes fraught journey of a successful Innersource project and the many lessons learned along the way.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-fostering-an-innersource_web.png'
    },
    speakers: [
      {
        name: 'Arthur Maltson',
        title: 'Distinguished Engineer',
        company: 'Capital One',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/arthur.jpg',
          alt: 'Arthur Maltson, Distinguished Engineer @ Capital One'
        }
      },
      {
        name: 'Roderick Randolph',
        title: 'Distinguished Engineer',
        company: 'Capital One',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/roderick.jpg',
          alt: 'Roderick Randolph, Distinguished Engineer @ Capital One'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/public-workspaces-the-massively-multiplayer-api-experience/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title:
      'Postman Talk: “Public Workspaces: The Massively Multiplayer API Experience”',
    subtitle:
      "The ultimate quest of improving every API and the experience of that API's consumers.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-public-workspaces-massively_web.png'
    },
    speakers: [
      {
        name: 'Anudeep Medicharla',
        title: 'Senior Product Designer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/anudeep.jpeg',
          alt: 'Anudeep Medicharla, Senior Product Designer at Postman'
        }
      },
      {
        name: 'Vineet Kumar',
        title: 'Director of UX Design',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/vineet.jpg',
          alt: 'Vineet Kumar, Director of UX Design at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/integrations-using-postman-in-your-workflows/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Postman Talk: “Integrations: Extending Workflows Beyond Postman”',
    subtitle:
      "Integrate Postman with your existing software development tooling and streamline your API development efforts. This session covers existing integrations and a few upcoming ones that we're excited about.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-integrations-using_web.png'
    },
    speakers: [
      {
        name: 'Shashank Awasthi',
        title: 'Product Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/shashank.jpg',
          alt: 'Shashank Awasthi, Product Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/orchestrating-api-programs-for-your-team/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:00PM–1:30PM PST',
    title: 'Postman Talk: “Orchestrating API Programs for your team”',
    subtitle:
      'Managing API workflows within teams has unique challenges. Teams need to maintain multiple versions of an API, assign and control ownership and permissions, and ensure seamless collaboration. An admins role here is to define, structure, and manage this environment.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-orchestrating-api-programs.png'
    },
    speakers: [
      {
        name: 'Yi-An Tseng',
        title: 'Customer Success Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/yi-an-tseng-hs.jpg',
          alt: 'Yi-An Tseng, Customer Success Engineer at Postman.'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/running-interactive-developer-education/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '1:15PM–1:30PM PST',
    title: 'Lightning Talk: “Cisco DevNet Runs Developer Education”',
    subtitle:
      "Cisco's developer program helps every user achieve their first meaningful interaction with a Cisco API.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-how-cisco-devnet_web.png'
    },
    speakers: [
      {
        name: 'Stuart Clark',
        title: 'Technical Leader / Developer Advocate',
        company: 'Cisco',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/stuart-hs.jpg',
          alt: 'Stuart Clark, Technical Leader / Developer Advocate at Cisco'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/building-krogers-first-billion-platform-startup/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    keySessions: true,
    date: 'WED, FEB 3',
    time: '1:30PM–2:00PM PST',
    title: "Keynote: “Building Kroger's first billion dollar platform startup”",
    subtitle:
      "Sanjiv Karani, Head of Kroger's Platform Domain Product Group will share his team's unique journey into building Kroger's successful API program. He will reurlSlug his team's key successes and learnings along the way.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-building-kroeger_web.png'
    },
    speakers: [
      {
        name: 'Sanjiv Karani',
        title: 'Head of Platform Domain Product Group',
        company: 'Kroger',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sanjiv.jpg',
          alt: 'Sanjiv Karani, Head of Platform Domain Product Group at Kroger'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '2:15PM–3:00PM PST',
    title: 'Community Workshop: “Cosmic Happy Hour”',
    subtitle:
      "Join us for a few galaxy-themed mocktails and cocktails. We'll publish recipes in advance so we can all make these ‘tails together.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-cosmic-happy-hour_web.png'
    },
    speakers: [
      {
        name: 'Sanjeev Sisodiya',
        title: 'VP Customer Success',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sanjeev.jpg',
          alt: 'Sanjeev Sisodiya, VP Customer Success at Postman'
        }
      },
      {
        name: 'Hannah Neil',
        title: 'Community Manager',
        company: 'Postman',
        media: {
          src: '/assets/postman-galaxy/speakers/hannah-neil-headshot.jpg',
          alt: 'Hannah Neil, Community Manager at Postman'
        }
      },
      {
        name: 'Rebecca Johnston-Gilbert',
        title: 'Senior Marketing Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/rebecca-hs.jpg',
          alt: 'Rebecca Johnston-Gilbert, Senior Marketing Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'WED, FEB 3',
    time: '2:15PM–3:00PM PST',
    title: 'Game Time',
    subtitle:
      "Get your game on. We'll host engaging virtual games to wind down the day and interact with one another. You can play to win, or just have fun.",
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    },
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-pg-game-time_web.png'
    },
    speakers: [
      {
        name: 'Vu Nguyen',
        title: 'Product Advocate',
        company: 'Postman',
        media: {
          src: '/assets/postman-galaxy/speakers/vu-nguyen.jpg',
          alt: 'Vu Nguyen, Product Advocate at Postman.'
        }
      },
      {
        name: 'Alysia Lucchesi',
        title: 'Senior Project Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alysia.jpeg',
          alt: 'Alysia Lucchesi, Senior Project Manager at Postman'
        }
      }
    ]
  },
  {
    urlSlug: {
      href: '/postman-galaxy/leveraging-postman-for-security-testing/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    apac: true,
    time: '5:30AM–6:00AM PST',
    title: 'Postman Talk: “Leveraging Postman for Security Testing”',
    subtitle:
      'Collections and monitors for secure cloud infrastructure services.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-security-testing_web.png'
    },
    speakers: [
      {
        name: 'Ryan Nolette',
        title: 'Security Tech Lead',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ryan-nolette-hs.jpg',
          alt: 'Ryan Nolette, Security Tech Lead at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/public-workspaces-amplify-your-api-engagement/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    apac: true,
    date: 'THURS, FEB 4',
    time: '5:30AM–6:00AM PST',
    title: 'Postman Talk: “Public Workspaces: Amplify Your API Engagement”',
    subtitle:
      'Workspaces are at heart of organizing your API projects and working with team members.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-public_web.png'
    },
    speakers: [
      {
        name: 'Matt Ball',
        title: 'Lead Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/matt-ball-hs.jpg',
          alt: 'Matt Ball, Lead Solutions Engineer at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/consumer-driven-contract-testing/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    apac: true,
    date: 'THURS, FEB 4',
    time: '6:00AM–6:30AM PST',
    title: 'Postman Talk: “Consumer-Driven Contract Testing With Postman”',
    subtitle: 'CDC testing to maintain growing microservices stacks.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-consumer-driven_web.png'
    },
    speakers: [
      {
        name: 'Preetham M',
        title: 'Director of Product Management',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/illustration/postmanaut-avatar.png',
          alt: 'Preetham M, Director of Product Management at Postman'
        }
      },
      {
        name: 'Numaan Ashraf',
        title: 'Director of Engineering',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/numaan.jpg',
          alt: 'Numaan Ashraf, Director of Engineering at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/response-visualizations-in-postman/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    apac: true,
    date: 'THURS, FEB 4',
    time: '6:00AM–6:30AM PST',
    title: 'Postman Talk: “Response Visualizations in Postman”',
    subtitle:
      'How to visualize data as well as take a tour of our favorite collections.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-response-visualization_web.png'
    },
    speakers: [
      {
        name: 'John Banning',
        title: 'Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/john-banning-hs.png',
          alt: 'John Banning, Solutions Engineer at Postman'
        }
      },
      {
        name: 'Meenakshi Dhanani',
        title: 'Developer Advocate',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/meenakshi-hs.jpg',
          alt: 'Meenakshi Dhanani, Developer Advocate at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/keynote-with-postman-cofounder-ankit-sobti/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    keySessions: true,
    date: 'THURS, FEB 4',
    time: '9:00AM–10:00AM PST',
    title: 'Postman Keynote: “Building the API-first Organization”',
    subtitle:
      "Postman Co-founder Ankit Sobti, who is presently the company's Chief Technology Officer, will take you on an exclusive deep dive into Postman's technology.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-ankit_web.png'
    },
    speakers: [
      {
        name: 'Ankit Sobti',
        title: 'Co-founder and CTO',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/ankit-sobti-founder-postman.jpg',
          alt: 'Ankit Sobti, CTO and Co-founder, Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/the-best-new-developer-tools-of-2020/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM–10:45AM PST',
    title: "Panel: “What's New in Developer Tools?”",
    subtitle:
      'Some of our favorite tools come together in this lively debate around challenges and trends in building tools for the developer community.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-whats-new-in-developer-tools.png'
    },
    speakers: [
      {
        name: 'Yonas Beshawred',
        title: 'Founder & CEO',
        company: 'StackShare',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/yonas-hs.jpeg',
          alt: 'Yonas Beshawred Founder & CEO at StackShare'
        }
      },
      {
        name: 'Phil Sturgeon',
        title: 'Architect',
        company: 'Stoplight.io',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/phil-sturg-hs.jpeg',
          alt: 'Phil Sturgeon, Architect at Stoplight.io'
        }
      },
      {
        name: 'Alianna Inzana',
        title: 'Senior Director, Product Management',
        company: 'SmartBear',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alianna-hs.jpg',
          alt: 'Alianna Inzana, Senior Director, Product Management at SmartBear'
        }
      },
      {
        name: 'Greg Koberger',
        title: 'Founder',
        company: 'ReadMe',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/greg-hs.jpg',
          alt: 'Greg Koberger, Founder at ReadMe'
        }
      },
      {
        name: 'Kevin Swiber',
        title: 'Lead Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kevin-swiber-hs.jpg',
          alt: 'Kevin Swiber, Lead Solutions Engineer at Postman.'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/writing-api-docs-worth-reading/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    time: '10:00AM–10:45AM PST',
    date: 'THURS, FEB 4',
    title:
      'Talk: “Writing Docs Worth Reading: Using Data to Create Effective and Empathetic Developer Docs”',
    subtitle:
      'How we created an automated workflow to generate our documentation in minutes.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-writing-docs-worth_web.png'
    },
    speakers: [
      {
        name: 'Kaylyn Sigler',
        title: 'Team Lead, Documentation',
        company: 'Datadog',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kaylyn-sigler-hs.jpeg',
          alt: 'Kaylyn Sigler, Team Lead - Documentation at Datadog'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/testing-your-apis/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM–10:30AM PST',
    title: 'Talk: “Testing Your APIs: Postman, Newman, and Beyond”',
    subtitle:
      'API testing means taking an “outside-in” approach to writing and executing those tests.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-testing-your-apis_web.png'
    },
    speakers: [
      {
        name: 'Mike Amundsen',
        title: 'Amundsen.com, Inc.',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/mike-a-hs.jpg',
          alt: 'Mike Amundsen, Amundsen.com, Inc'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/api-security-for-enterprises/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM–10:45AM PST',
    title: 'Fireside Chat: “API Security for Enterprises”',
    subtitle:
      'How is API security and threat protection different for large-scale enterprises? This intimate discussion tackles challenges and offers solutions for mitigating risk and reducing your attack surface in modern architectures. Well-known breaches such as Parler or Starbucks are used to illustrate some of the key challenges faced by enterprises when protecting APIs.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-api-security_web.png'
    },
    speakers: [
      {
        name: 'Jeanine Jue',
        title: 'Head of Global Developer Relations',
        company: 'R3',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/jeanine.jpg',
          alt: 'Jeanine Jue, Head of Global Developer Relations at R3'
        }
      },
      {
        name: 'Bernard Harguindeguy',
        title: 'CTO',
        company: 'Ping Identity',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/bernard.jpg',
          alt: 'Bernard Harguindeguy, CTO at Ping Identity'
        }
      },
      {
        name: 'Isabelle Mauny',
        title: 'Co-founder and Field CTO',
        company: '42Crunch',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/isabelle-mauny.jpg',
          alt: 'Isabelle Mauny, Co-founder and Field CTO of 42Crunch'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/easy-integration-testing-using-postman-and-newman/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM–10:15AM PST',
    title:
      'Lightning Talk: “Easy Integration Testing Using Postman and Newman”',
    subtitle:
      "In this session, we'll cover the ups and downs of designing an enterprise system that requires massive data correlation and runs on multiple platforms, using Postman across the API lifecycle with multiple teams.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-the-ups-and-downs.png'
    },
    speakers: [
      {
        name: 'Alexandre Costa',
        title: 'Senior Software Developer',
        company: 'Farfetch',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alexandre-hs.jpg',
          alt: 'Alexandre Costa, Senior Software Developer @ Farfetch'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/enhancing-collaboration-and-productivity/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM-10:30AM PST',
    title:
      'Postman Talk: “Collaboration and Productivity: The Missing Links in API Development”',
    subtitle: 'How you can enhance productivity of your teams with Postman.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-collaboration-productivity_web.png'
    },
    speakers: [
      {
        name: 'Sankalp Agarwal',
        title: 'Product Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sankalp-hs.png',
          alt: 'Sankalp Agarwal, Product Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-api-development-platform-overview/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:00AM–10:30AM PST',
    title: 'Postman Talk: “The Postman API Development Environment”',
    subtitle:
      'Postman allows you design, develop, test, and monitor APIs. This talk will provide an overview of the interfaces, runtime, and variables. ',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-api-development.png'
    },
    speakers: [
      {
        name: 'Vivek Thuravupala',
        title: 'Engineering Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/vivek-hs.jpg',
          alt: 'Vivek Thuravupala, Engineering Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/integrating-api-security-testing-into-postman-lifecycle/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '10:30AM–10:45AM PST',
    title:
      'Partner Talk: “Integrating API Security Testing into Postman Lifecycle”',
    subtitle: 'Learn about security vulnerabilities impacting APIs.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-security-vulnerabilities_web.png'
    },
    speakers: [
      {
        name: 'Eric Sheridan',
        title: 'Chief Scientist & Distinguished Engineer',
        company: 'WhiteHat Security',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/eric-hs.jpg',
          alt: 'Eric Sheridan, Chief Scientist & Distinguished Engineer @ WhiteHat Security'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/state-of-the-api-insights-into-the-future-of-apis/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title: 'Postman Talk: “State of the API: Insights into the Future”',
    subtitle:
      'There are some surprises waiting for you when we go through the largest and most comprehensive survey of the API industry.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-state-of-the-api_web.png'
    },
    speakers: [
      {
        name: 'Rebecca Johnston-Gilbert',
        title: 'Senior Marketing Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/rebecca-hs.jpg',
          alt: 'Rebecca Johnston-Gilbert, Senior Marketing Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/how-do-people-find-and-use-technology/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–12:00PM PST',
    title: 'Panel: “How do people find and use technology?”',
    subtitle:
      "Learn how APIs—along with tools for empowering users—are changing the technology-discovery game, and what's most important in building awareness, onboarding, and retention for your technology.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-how-do-people-find.png'
    },
    speakers: [
      {
        name: 'Alex Walling',
        title: 'Head of Developer Relations',
        company: 'RapidAPI',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alex-walling.jpg',
          alt: 'Alex Walling, Head of Developer Relations at RapidAPI'
        }
      },
      {
        name: 'Tina Huang',
        title: 'Founder and CTO',
        company: 'Transposit',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/tina-huang.jpg',
          alt: 'Tina Huang, Founder and CTO at Transposit'
        }
      },
      {
        name: 'Mary Thengvall',
        title: 'Director of Developer Relations',
        company: 'Camunda',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/mary-thengvall.jpeg',
          alt: 'Mary Thengvall, Director of Developer Relations at Camunda'
        }
      },
      {
        name: 'Elmer Thomas',
        title: 'Staff Software Engineer ',
        company: 'Twilio',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/elmer-thomas.jpg',
          alt: 'Elmer Thomas, Staff Software Engineer at Twilio'
        }
      },
      {
        name: 'John Bohannon',
        title: 'Senior Partner Engineer',
        company: 'GitHub',
        media: {
          src: '/assets/postman-galaxy/speakers/johnbohannon.jpeg',
          alt: 'John Bohannon, Senior Partner Engineer at GitHub'
        }
      },
      {
        name: 'Gigi Zanolli',
        title: 'Developer Advocate',
        company: 'Tableau',
        media: {
          src: '/assets/postman-galaxy/speakers/Gigi-Zanolli.jpg',
          alt: 'Gigi Zanolli, Developer Advocate at Tableau'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/postman-collections-curating-your-developer-experience/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–12:00PM PST',
    title: 'Panel: “Postman Collections as Part of Your Developer Experience”',
    subtitle:
      'Authors of public Postman Collections share tips and insights for optimizing the educational experience for new and experienced users.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postman-collections-as-part.png'
    },
    speakers: [
      {
        name: 'Jayson DeLancey',
        title: 'Developer Relations',
        company: 'Dolby Laboratories',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/jayson-hs.jpg',
          alt: 'Jayson DeLancey, Developer Relations at Dolby Laboratories'
        }
      },
      {
        name: 'Marcel Ribas',
        title: 'Developer Evangelist',
        company: 'Dropbox',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/marcel-hs.png',
          alt: 'Marcel Ribas, Developer Evangelist at Dropbox'
        }
      },
      {
        name: 'Nicolas Grenié',
        title: 'Developer Advocate',
        company: 'Typeform',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/nicolas-hs.jpeg',
          alt: 'Nicolas Grenié, Developer Advocate at Typeform'
        }
      },
      {
        name: 'Julia Biro',
        title: 'Developer Advocate',
        company: 'Vonage',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/julia-hs.jpg',
          alt: 'Julia Biro, Developer Advocate at Vonage'
        }
      },
      {
        name: 'Jessica Garson',
        title: 'Developer Advocate',
        company: 'Twitter',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/jessica-hs.jpg',
          alt: 'Jessica Garson, Developer Advocate at Twitter'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/api-testing-in-production/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “Testing in Production”',
    subtitle:
      "At the end of the day, we don't care if your features work in staging, we care if they work in production.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-testing-in-production_web.png'
    },
    speakers: [
      {
        name: 'Talia Nassi',
        title: 'Developer Advocate',
        company: 'Split Software',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/talia-hs.png',
          alt: 'Talia Nassi, Developer Advocate at Split Software'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/using-postman-and-osquery/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “Turn on the Lights”',
    subtitle:
      'Learn how to tap into what your employer sees using Postman + osquery, an open source API for asking questions about devices like laptops, servers, and Docker containers.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-turn-on-the-lights_web.png'
    },
    speakers: [
      {
        name: 'Mike McNeil',
        title: 'CEO',
        company: 'Fleet',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/mike-mcneil.jpeg',
          alt: 'Mike McNeil, CEO of Fleet'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/the-future-of-graphql/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–12:00PM PST',
    title: 'Panel: “The Future of GraphQL”',
    subtitle:
      'Industry leaders and practitioners come together to imagine the future of GraphQL, and the role this technology plays in an API landscape of legacy SOAP, mainstay REST, and other alternatives.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-the-future-graph-ql_web.png'
    },
    speakers: [
      {
        name: 'Evan Weaver',
        title: 'CTO and Co-founder',
        company: 'Fauna',
        media: {
          src: '/assets/postman-galaxy/speakers/evan-weaver-headshot.jpg',
          alt: 'Evan Weaver, CTO and Cofounder at Fauna'
        }
      },
      {
        name: 'Pragati Sharma',
        title: 'Senior Quality Consultant',
        company: 'ThoughtWorks',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/pragati.png',
          alt: 'Pragati Sharma, Senior Quality Consultant at ThoughtWorks'
        }
      },
      {
        name: 'Tanmai Gopal',
        title: 'CEO and Co-founder',
        company: 'Hasura',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/tanmai.jpeg',
          alt: 'Tanmai Gopal, CEO and co-founder of Hasura'
        }
      },
      {
        name: 'Sara Du',
        title: 'Co-founder',
        company: 'Alloy Automation',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sara-du-hs.jpeg',
          alt: 'Sara Du, CEO & Cofounder of Alloy Automation'
        }
      },
      {
        name: 'Roy Derks',
        title: 'Engineering Manager',
        company: 'Vandebron',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/roy-derks-hs.jpeg',
          alt: 'Roy Derks, Leading the Engineering teams at Vandebron'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/api-security-with-postman-and-qualys/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “API Security with Postman and Qualys”',
    subtitle:
      'With the release of Postman Collection support in Qualys Web Application Scanning (WAS), anyone with a Postman Collection can perform API security scans by simply uploading a file.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-api-security-with-qualys_web.png'
    },
    speakers: [
      {
        name: 'Ed Arnold',
        title: 'Security Solution Architect',
        company: 'Qualys',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ed-arnold-hs.jpg',
          alt: 'Ed Arnold, Security Solution Architect at Qualys'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/api-governance/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title: 'Talk: “API Governance in an Anti-Governance World”',
    subtitle:
      "Governance is often seen as a dirty word. However, good governance processes can provide superior control and protection with minimal impact to the development team. In this session, we'll cover good governance practices, from designing APIs to linting, validation, and control.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-good-governance_web.png'
    },
    speakers: [
      {
        name: 'Chris Phillips',
        title: 'Master Inventor',
        company: 'IBM',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/chris-phillips.jpg',
          alt: 'Chris Phillips, Master Inventor at IBM'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/search-and-discovery-with-api-networks/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:00AM–11:30AM PST',
    title:
      "Postman Talk: “Search and Discovery With Postman's Public and Private API Networks”",
    subtitle: 'How to publish and search Postman workspaces.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-postmans-public-private_web.png'
    },
    speakers: [
      {
        name: 'Sapna Nayak',
        title: 'Product Designer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/sapna.jpeg',
          alt: 'Sapna Nayak, Product Designer at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/the-economic-benefits-of-the-postman-api-platform/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:30AM–12:00PM PST',
    title: 'Postman Talk: “The Economic Benefits of the Postman API Platform”',
    subtitle:
      'The economic benefits that organizations can expect by adopting Postman for their API programs',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-the-economic-benefits_web.png'
    },
    speakers: [
      {
        name: 'Ryan Reynolds',
        title: 'Enterprise Sales Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ryan-reynolds.png',
          alt: 'Ryan Reynolds, Enterprise Sales Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/make-sure-your-api-tests-fail/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    time: '11:30AM–12:00PM PST',
    date: 'THURS, FEB 4',
    title: 'Talk: “Make Sure Your Tests Fail”',
    subtitle:
      "Walk through badly written tests and see why they don't work as expected.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-make-sure-your-tests_web.png'
    },
    speakers: [
      {
        name: 'Valentin Despa',
        title: 'Software Developer & Educator',
        company: '',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/valentin-hs.png',
          alt: 'Valentin Despa, Software Developer and Developer Educator'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/automating-ecommerce-using-postman-and-bigcommerce/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:30AM–11:45AM PST',
    title:
      'Lightning Talk: “Automating Ecommerce using Postman and BigCommerce”',
    subtitle:
      "Automate the creation of a BigCommerce catalog using a collection curated for Postman. This collection will contain the automation and testing code snippets you need to get started, and you'll see your storefront come to life before your eyes using the test runner.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-automating-e-commerce_web.png'
    },
    speakers: [
      {
        name: 'Rachael Thompson',
        title: 'Developer Advocate',
        company: 'BigCommerce',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/rachael-hs.jpg',
          alt: 'Rachael Thompson, Developer Advocate at BigCommerce'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/dynamically-generate-tests-from-open-api-specs/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “Dynamic Test Generation with Open API Specification 3.0”',
    subtitle:
      'In this presentation, participants will learn how to dynamically generate tests from the OpenAPI Specification in Postman using the Postman API.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-dynamically-generate_web.png'
    },
    speakers: [
      {
        name: 'Allen Helton',
        title: 'Software Engineering Manager',
        company: 'Tyler Tech',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/allen-hs.jpg',
          alt: 'Allen Helton, Software Engineering Manager @ Tyler Tech'
        }
      }
    ],
    sessions: {
      developers: false,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/driving-pipeline-automation-with-newman-and-postman-api/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:30AM-12:00AM PST',
    title:
      'Postman Talk: “Driving Pipeline Automation with Newman and the Postman API”',
    subtitle:
      'Strategies for integrating Postman workflows in your CI/CD pipelines.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-driving-pipeline_web.png'
    },
    speakers: [
      {
        name: 'Kevin Swiber',
        title: 'Lead Solutions Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kevin-swiber-hs.jpg',
          alt: 'Kevin Swiber, Lead Solutions Engineer at Postman.'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/why-developers-struggle-with-application-and-api-security/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '11:30AM–12:00PM PST',
    title: 'Talk: “Why Devs Struggle with Application and API Security”',
    subtitle:
      'StackHawk CSO Scott Gerlach shares DevSecOps practices and tools at companies such as GoDaddy, SendGrid, and Twilio. Learn why developers struggle with AppSec.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-why-devs-struggle-with-application-and-api-security_web.png'
    },
    speakers: [
      {
        name: 'Scott Gerlach',
        title: 'CSO and Co-founder',
        company: 'StackHawk',
        media: {
          src: '/assets/postman-galaxy/speakers/scott-gerlach.jpg',
          alt: 'Scott Gerlach, CSO and Co-founder at StackHawk.'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '12:30PM–1:00PM PST',
    title: 'Community Workshop: “Self-Care with Dominic Ouellette, CMT, NMT”',
    subtitle:
      'This workshop will demonstrate massage techniques you can use at home to alleviate pain and tension.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-self-care_web.png'
    },
    speakers: [
      {
        name: 'Dominic Ouellette',
        title: 'Certified Massage Therapist,',
        company: 'Neuromuscular Therapist',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/dominic-hs.jpg',
          alt: 'Dominic Ouellette, Certified Massage Therapist, Neuromuscular Therapist'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/low-code-for-developers/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:30PM PST',
    title: "Talk: “Low-Code: It's Also for Developers”",
    subtitle:
      'The low-code software ecosystem is taking off. See how low-code products can fit into your own toolbox, and how companies like Slack design low-code products with developers in mind.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-low-code-its_web.png'
    },
    speakers: [
      {
        name: 'Bear Douglas',
        title: 'Director of Developer Relations',
        company: 'Slack',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/bear-douglas-hs.jpg',
          alt: 'Bear Douglas, Director of Developer Relations at Slack'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/reclaiming-time-by-generating-collections/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:30PM PST',
    title:
      'Talk: "Writing Docs Is a Chore: How Generating Collections Reclaimed Our Time"',
    subtitle:
      "See how MTNA generated documentation in minutes using MTNA's Rich Data Services platform, Postman Collections, Newman, and the Postman API.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-writing-docs-is_web.png'
    },
    speakers: [
      {
        name: 'Andrew DeCarlo',
        title: 'Software Developer',
        company: 'Metadata Technology North America',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/andrew-dc-hs.jpg',
          alt: 'Andrew DeCarlo, Software Developer at Metadata Technology North America'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/why-shopify-automatically-generates-postman-collections/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:15PM PST',
    title:
      'Lightning Talk: “Why Shopify Automatically Generates Postman Collections”',
    subtitle:
      'Automatically generating API collections from your code is easier than you think. Learn more.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-how-shopify-uses_web.png'
    },
    speakers: [
      {
        name: 'Shayne Parmelee',
        title: 'Developer Advocate',
        company: 'Shopify',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/shayne-hs.png',
          alt: 'Shayne Parmelee, Developer Advocate at Shopify'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/using-cookies-to-store-your-postman-secrets/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:15PM PST',
    title: 'Lightning Talk: “Using Cookies to Store Your Postman Secrets”',
    subtitle:
      'Storing your secrets in Postman environments might not meet your security requirements. See how you can levarage Postman cookies instead.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-using-cookies-to-store_web.png'
    },
    speakers: [
      {
        name: 'Miguel A. Calles',
        title: 'Cybersecurity Engineer',
        company: 'VeriToll',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/miguel-calles.jpg',
          alt: 'Miguel Calles, Cybersecurity Engineer at VeriToll'
        }
      }
    ],
    sessions: {
      developers: true,
      business: false,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/a-newbies-guide-to-postman/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM-1:15PM PST',
    title:
      "Partner Talk: “Don't Let It Scare You! A Newbie's Guide to Postman by a Newbie Postman User!”",
    subtitle: "A newbie's guide to Postman by a newbie Postman user.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-a-newbies-guide_web.png'
    },
    speakers: [
      {
        name: 'Beth Marshall',
        title: 'Senior Test Lead',
        company: 'Smoothwall',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/beth-hs.jpg',
          alt: 'Beth Marshall, Senior Test Lead @ Smoothwall'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/mitigating-common-security-api-issues/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:30PM PST',
    title:
      'Postman Talk: “Common API Vulnerabilities: How to Effectively Use Postman for API Security Testing”',
    subtitle:
      'Learn how to use Postman to identify and mitigate common API security issues such as those from the OWASP Top 10 API vulnerabilities list.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-mitigating-common_web.png'
    },
    speakers: [
      {
        name: 'Ronak Odhaviya',
        title: 'Security Engineer',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/ronak.jpeg',
          alt: 'Ronak Odhaviya, Security Engineer at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/drive-api-adoption-reach-over-13-million-developers/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:30PM PST',
    title: 'Talk: "Drive API Adoption, Reach Over 13 Million Developers"',
    subtitle:
      'Creating a great API is just half the work. Driving its adoption is where most producers struggle. Join us to learn how to drive adoption of your APIs and how the Postman API Network can help.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-driving-adoption_web.png'
    },
    speakers: [
      {
        name: 'Avinash Choudhary',
        title: 'Engineering Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/avinash.png',
          alt: 'Avinash Choudhary, Engineering Manager at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: false,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/fireside-chat-with-jeff-barr-of-aws/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:00PM–1:30PM PST',
    title: 'Fireside Chat with Jeff Barr of AWS',
    subtitle:
      'Jeff Barr, Chief Evangelist for AWS explains the benefits of cloud computing and web services.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-jeff-barr-aws_web.png'
    },
    speakers: [
      {
        name: 'Jeff Barr',
        title: 'Chief Evangelist',
        company: 'AWS',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/jeff-barr.jpg',
          alt: 'Kin Lane, VP and Chief Evangelist at AWS'
        }
      },
      {
        name: 'Kin Lane',
        title: 'Chief Evangelist',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/kin-lane.jpg',
          alt: 'Kin Lane, Chief Evangelist at Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: '/postman-galaxy/0-100-a-graphql-tutorial/'
    },
    ratio: '16/9',
    active: true,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '1:15PM-1:30PM PST',
    title: 'Lightning Talk: “0 to 100: A GraphQL tutorial”',
    subtitle: 'Introducing GraphQL and how to manage GraphQL APIs.',
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/sessions-0-100-a-graphql_web.png'
    },
    speakers: [
      {
        name: 'Tanmai Gopal',
        title: 'CEO and co-founder',
        company: 'Hasura',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/tanmai.jpeg',
          alt: 'Tanmai Gopal, CEO and co-founder of Hasura'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: false
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    keySessions: true,
    date: 'THURS, FEB 4',
    time: '1:30PM-2:00PM PST',
    title: 'Fireside Chat with Dr. Ellen Ochoa',
    subtitle:
      "Ellen Ochoa, a trailblazing astronaut and former director of NASA's Johnson Space Center, will share her galactic perspectives with you.",
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/keynote-dr-ochoa_web.png'
    },
    speakers: [
      {
        name: 'Dr. Ellen Ochoa',
        title: 'Astronaut and Former NASA Executive',
        company: 'NASA',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/img-headshot-ellen.png',
          alt: 'Dr. Ellen Ochoa, Astronaut, Nasa'
        }
      },
      {
        name: 'Nick Tran',
        title: 'VP of Marketing',
        company: 'Postman',
        media: {
          src: '/assets/postman-galaxy/speakers/nick-tran.jpg',
          alt: 'Nick Tran, VP of Marketing, Postman'
        }
      }
    ],
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    }
  },
  {
    urlSlug: {
      href: ''
    },
    ratio: '16/9',
    active: false,
    column: 'col-sm-12 col-md-6 col-xl-4',
    date: 'THURS, FEB 4',
    time: '2:15PM–3:00PM PST',
    title: 'Game Time',
    subtitle:
      "Get your game on. We'll host engaging virtual games to wind down the day and interact with one another. You can play to win, or just have fun.",
    sessions: {
      developers: true,
      business: true,
      testers: true,
      admins: true
    },
    media: {
      src: 'https://voyager.postman.com/illustration/galaxy/fun-pg-game-time_web.png'
    },
    speakers: [
      {
        name: 'Vu Nguyen',
        title: 'Product Advocate',
        company: 'Postman',
        media: {
          src: '/assets/postman-galaxy/speakers/vu-nguyen.jpg',
          alt: 'Vu Nguyen, Product Advocate at Postman.'
        }
      },
      {
        name: 'Alysia Lucchesi',
        title: 'Senior Project Manager',
        company: 'Postman',
        media: {
          src: 'https://voyager.postman.com/photo/galaxy/alysia.jpeg',
          alt: 'Alysia Lucchesi, Senior Project Manager at Postman'
        }
      }
    ]
  }
];
export default cards;
