import React from 'react';
import styled from 'styled-components';
import { BaseLinkStyles, Paragraph, BaseButton } from 'aether-marketing';

const Section = styled.section`
  padding-top: 60px;
  background-color: ${(props) => props.theme.colors.grey_90};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://voyager.postman.com/illustration/hero-stars-only.svg');
  color: white;
  padding-bottom: 60px;
`;

const TitleBody = styled.div`
  p {
    color: white;
    margin-bottom: 0;

    a {
      ${BaseLinkStyles.componentStyle.rules}
      color: #ffffff !important;

      &:hover {
        color: #ffffff !important;
        border-bottom: 1px solid #ffffff !important;
      }
    }
  }
`;

const HorizontalCard = styled.div`
  color: ${(props) => props.theme.colors.grey_90};
  padding: 24px;
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  width: 100%;
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    @media (max-width: 992px) {
      margin-bottom: 24px;
    }
  }
`;

const featuredItemHorizontal = (data) => (
  <Section>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="text-white">{data.titles[0]}</h2>
          <TitleBody
            className="mb-5"
            dangerouslySetInnerHTML={{ __html: data.body[0] }}
          />
        </div>
        <div className="col-md-10 mx-auto">
          <HorizontalCard>
            <div className="row">
              <div className="col-6 col-lg-2 mx-auto d-flex align-items-center justify-content-center justify-content-lg-start">
                <ImageContainer>
                  <img src={data.media[0].src} alt={data.media[0].alt} />
                </ImageContainer>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 text-center text-lg-left">
                <h4>{data.titles[1]}</h4>
                <Paragraph className="mb-3">{data.body[1]}</Paragraph>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 text-center d-flex align-items-center justify-content-center justify-content-lg-end">
                <BaseButton
                  buttonType="secondary"
                  as="a"
                  src={data.link[0].src}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  View Documentation
                </BaseButton>
              </div>
            </div>
          </HorizontalCard>
        </div>
      </div>
    </div>
  </Section>
);

export default featuredItemHorizontal;
