import React from 'react';
import PropTypes from 'prop-types';
import { BaseLink } from 'aether-marketing';
import styled from 'styled-components';

export const LinkWrapper = styled.div`
  a {
    ${(props) => (props.isDisabled === true ? `&:disabled` : '')}
    &:disabled,
    &.isDisabled,
    &:not([href]) {
      color: #556267;
      cursor: not-allowed;
      border-bottom: none;
    }
  }
`;
function PrevNextLinks({ leftButton, rightButton }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="d-flex justify-content-between  mb-5">
            <LinkWrapper>
              <BaseLink
                linkType="reversedArrowLink"
                src={!leftButton.isDisabled ? leftButton.src : undefined}
                target={leftButton.target}
                id={leftButton.id}
                gaLabel={leftButton.gaLabel}
                gaCategory={leftButton.gaCategory}
                as="a"
              >
                {leftButton.text}
              </BaseLink>
            </LinkWrapper>
            <LinkWrapper>
              <BaseLink
                linkType="arrowLink"
                src={!rightButton.isDisabled ? rightButton.src : undefined}
                target={rightButton.target}
                id={rightButton.id}
                gaLabel={rightButton.gaLabel}
                gaCategory={rightButton.gaCategory}
                as="a"
              >
                {rightButton.text}
              </BaseLink>
            </LinkWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

PrevNextLinks.propTypes = {
  leftButton: PropTypes.shape({
    src: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    gaLabel: PropTypes.string,
    gaCategory: PropTypes.string
  }),
  rightButton: PropTypes.shape({
    src: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    gaLabel: PropTypes.string,
    gaCategory: PropTypes.string
  })
};

PrevNextLinks.defaultProps = {
  leftButton: {
    src: '',
    target: '',
    text: '',
    isDisabled: false,
    id: '',
    gaLabel: '',
    gaCategory: ''
  },
  rightButton: {
    src: '',
    target: '',
    text: '',
    isDisabled: false,
    id: '',
    gaLabel: '',
    gaCategory: ''
  }
};

export default PrevNextLinks;
