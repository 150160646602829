/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

// Error Validation (Positioning)
export const FormErrorStyled = styled.div`
  align-content: center;
  background: transparent;
  color: ${(props) => props.mode === 'light' ? '#FF0A00' : props.theme.colors.red_error};
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  position: ${(props) => (props.isEmail ? 'absolute' : '')};

  @media screen and (max-width: 576px) {
    width: ${(props) => (props.isEmail ? '375px' : '')};
    font-size: ${(props) => (props.isEmail ? '12px' : '')};
  }

  &.inline-errormsg:before {
    border: 10px solid transparent;
    border-right-color: ${(props) => props.theme.colors.red_error};
    content: ' ';
    height: 0;
    left: 0;
    position: absolute;
    top: 19px;
    transform: rotate(-90deg);
    width: 0;
  }

  &.inline-errormsg {
    font-size: 14px;
  }
`;

function FormError({ ...props }) {
  return <FormErrorStyled className="inline-errormsg" {...props} />;
}

export default FormError;
