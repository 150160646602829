import React from 'react';
import Text from './Text';

const textRow = (data) => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <Text data={data} />
      </div>
    </div>
  </div>
);

// items
// alternative (optional)

export default textRow;
