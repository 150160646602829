import { useEffect, useMemo } from 'react';

// @example
// {
// {
//   "type": "GoogleEntitySchema",
//   "entityType": "WebPage",
//   "aboutType": {
//     "names": ["API"]
//   },
//   "mentionType": {
//     "names": ["Webhook", "gRPC", "SOAP", "GraphQL"]
//   }
// },

// @desc Google entity schema list
const schemas = [
  {
    name: 'API',
    description:
      'An application programming interface (API) is a way for two or more computer programs or components to communicate with each other. It is a type of software interface offering a service to other pieces of software',
    SameAs: [
      'https://www.wikidata.org/wiki/Q165194',
      'http://en.wikipedia.org/wiki/API'
    ]
  },
  {
    name: 'Data',
    SameAs: [
      'https://www.wikidata.org/wiki/Q42848',
      'http://en.wikipedia.org/wiki/Data'
    ]
  },
  {
    name: 'Design',
    SameAs: [
      'https://www.wikidata.org/wiki/Q82604',
      'http://en.wikipedia.org/wiki/Design'
    ]
  },
  {
    name: 'GraphQL',
    SameAs: [
      'https://www.wikidata.org/wiki/Q25104949',
      'http://en.wikipedia.org/wiki/GraphQL'
    ]
  },
  {
    name: 'gRPC',
    SameAs: [
      'https://www.wikidata.org/wiki/Q26356541',
      'http://en.wikipedia.org/wiki/GRPC'
    ]
  },
  {
    name: 'Internet of things',
    SameAs: [
      'https://www.wikidata.org/wiki/Q251212',
      'http://en.wikipedia.org/wiki/Internet_of_things'
    ]
  },
  {
    name: 'JSON',
    SameAs: [
      'https://www.wikidata.org/wiki/Q2063',
      'http://en.wikipedia.org/wiki/JSON'
    ]
  },
  {
    name: 'ISBN',
    SameAs: [
      'https://www.wikidata.org/wiki/Q33057',
      'http://en.wikipedia.org/wiki/ISBN'
    ]
  },
  {
    name: 'Representational state transfer',
    SameAs: [
      'https://www.wikidata.org/wiki/Q749568',
      'http://en.wikipedia.org/wiki/Representational_state_transfer'
    ]
  },
  {
    name: 'Ruby on Rails',
    SameAs: [
      'https://www.wikidata.org/wiki/Q190478',
      'http://en.wikipedia.org/wiki/Ruby_on_Rails'
    ]
  },
  {
    name: 'SOAP',
    SameAs: [
      'https://www.wikidata.org/wiki/Q189620',
      'http://en.wikipedia.org/wiki/SOAP'
    ]
  },
  {
    name: 'System',
    SameAs: [
      'https://www.wikidata.org/wiki/Q58778',
      'http://en.wikipedia.org/wiki/System'
    ]
  },
  {
    name: 'Webhook',
    SameAs: [
      'https://www.wikidata.org/wiki/Q2553302',
      'http://en.wikipedia.org/wiki/Webhook'
    ]
  },
  {
    name: 'XML',
    SameAs: [
      'https://www.wikidata.org/wiki/Q2115',
      'http://en.wikipedia.org/wiki/XML'
    ]
  }
];

function createSchema(nameValue, description) {
  let validSchema = {};
  validSchema = schemas.map(
    (schema) =>
      schema.name === nameValue && {
        '@type': 'Thing',
        name: schema.name,
        ...(description && { description }),
        SameAs: schema.SameAs
      }
  );
  // @desc remove empty arrays from map method
  return validSchema.find((hasValues) => hasValues);
}

function addSchema(type, emptyArray) {
  if (type && type.names) {
    type.names.map((name, index) =>
      emptyArray.push(
        createSchema(name, type.description ? type.description[index] : null)
      )
    );
  }
  return null;
}

const runtime = typeof document === 'object';

function GoogleEntitySchema(data) {
  const { entityType, aboutType, mentionType } = data;

  const TEMPLATE = useMemo(() => {
    const template = {
      '@context': 'https://schema.org',
      '@type': entityType,
      about: [],
      mentions: []
    };

    addSchema(aboutType, template.about);
    addSchema(mentionType, template.mentions);

    return template;
  }, [entityType, aboutType, mentionType]);

  useEffect(() => {
    if (!runtime) return () => {};

    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('id', 'entitySchema');
    scriptElement.setAttribute('type', 'application/ld+json');
    scriptElement.textContent = JSON.stringify(TEMPLATE);
    document.head.appendChild(scriptElement);

    return () => {
      document.head.removeChild(scriptElement);
    };
  }, [TEMPLATE]);

  return null;
}
export default GoogleEntitySchema;
