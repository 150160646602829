import React from 'react';
import styled from 'styled-components';
import { SectionStyles } from 'aether-marketing';

const Section = styled.section`
  ${SectionStyles.componentStyle.rules}
  &.galaxy {
    background-color: #6d7f91;
    background-image: url('https://voyager.postman.com/illustration/bg-learn-section.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.blue_10 {
    background-color: ${(props) => props.theme.colors.blue_10};
  }
  &.grey_05 {
    background-color: ${(props) => props.theme.colors.grey_05};
  }
`;
const EmbedContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
`;
const recapViewSlides = (data) => (
  <Section className={data.backgroundImage ? data.backgroundImage : null}>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2
            className={`mb-5 text-center${
              data.backgroundImage === 'galaxy' ? ' text-white' : null
            }`}
          >
            {data.titles}
          </h2>
          <EmbedContainer className="embed-responsive embed-responsive-16by9">
            <iframe
              src={data.embed}
              title="Postman Galaxy"
              width="1018"
              height="572"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
            />
          </EmbedContainer>
        </div>
      </div>
    </div>
  </Section>
);

export default recapViewSlides;
