import React from 'react';

const structuredData = `{
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Postman",
    "image": "https://voyager.postman.com/illustration/san-francisco-office-postman-illustration.svg",
    "@id": "",
    "url": "",
    "telephone": "+1 415 796 6470",
    "priceRange": "$$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "201 Mission Street, Suite 2375",
      "addressLocality": "San Francisco",
      "addressRegion": "CA",
      "postalCode": "94105",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 37.7910341,
      "longitude": -122.3970198
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "department": {
      "@type": "LocalBusiness",
      "name": "Postman",
      "image": "https://voyager.postman.com/illustration/bengaluru-office-postman-illustration.svg",
  "telephone": "+91 80 4110 6902",
    "priceRange": "$$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Building no 310 6th Main Road",
      "addressLocality": "Bangalore",
      "postalCode": "560038",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 12.973717,
      "longitude": 77.644375
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    } 
  }
  }
}`;

class Schema extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      (() => {
        const spt = document.createElement('script');
        spt.type = 'application/ld+json';
        spt.innerHTML = structuredData;
        document.getElementsByTagName('head')[0].appendChild(spt);
      })();
    }
  }

  render() {
    return null;
  }
}

const localBusinessSchema = (data) => <Schema data={data} />;

export default localBusinessSchema;
