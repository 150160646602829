import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, SectionStyles } from 'aether-marketing';
import FeatureHighlights from './featureHighlights';
import Button from '../v5_buttons/Button';

const PricingColumn = styled.div`
  display: flex;
  flex-direction: column;
  sup {
    position: relative;

    font-size: 28px;
  }
  p {
    margin-bottom: 0;
    color: #212121;
  }
  span.dollars {
    font-size: 48px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.Degular};
  }
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

function SideXSidePricing({
  backgroundColor,
  divider,
  cards,
  paddingTop,
  paddingBottom,
  media
}) {
  return (
    <>
      <SectionStyles
        className="mb-0"
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <img
                src={media.src}
                className="img-fluid mr-0 mr-lg-5"
                alt={media.alt}
              />
            </div>
            <div className="col-lg-6 ">
              <h2>{cards.title}</h2>
              {cards.subtitle && <p>{cards.subtitle}</p>}
              <PricingColumn>
                <div
                  dangerouslySetInnerHTML={{ __html: cards.body.join('') }}
                />

                <div className="row align-items-center mt-4 ">
                  <div className={cards.ctaTwo ? 'mr-3 px-0 pl-2' : 'col-12'}>
                    <Button
                      buttonType={
                        cards.cta.buttonType ? cards.cta.buttonType : 'primary'
                      }
                      id={cards.cta.id}
                      href={cards.cta.href}
                      rel={cards.cta.rel}
                      target={cards.cta.target}
                      data-url={cards.cta.test}
                      data-test={cards.cta.test}
                      event={cards.cta.event}
                      eventProp={cards.cta.eventProp}
                      onClick={() => {
                        triggerGA(cards.cta.category, cards.cta.label);
                      }}
                      aria-label={cards.cta.ariaLabel}
                    >
                      {cards.cta.title}
                    </Button>
                  </div>

                  {cards.ctaTwo && (
                    <div className="pl-md-0 pl-2 pl-md-0">
                      <Button
                        buttonType={cards.ctaTwo.buttonType}
                        id={cards.ctaTwo.id}
                        href={cards.ctaTwo.href}
                        rel={cards.ctaTwo.rel}
                        target={cards.ctaTwo.target}
                        data-url={cards.ctaTwo.test}
                        data-test={cards.ctaTwo.test}
                        event={cards.ctaTwo.event}
                        eventProp={cards.ctaTwo.eventProp}
                        onClick={() => {
                          triggerGA(cards.ctaTwo.category, cards.ctaTwo.label);
                        }}
                        aria-label={cards.ctaTwo.ariaLabel}
                      >
                        {cards.ctaTwo.title}
                      </Button>
                    </div>
                  )}
                </div>
              </PricingColumn>
              {!cards.itemsRightTitle && (
                <p className="mb-0 mt-4">What is in the solution:</p>
              )}
              <div className="row">
                <div className="col-6">
                  {cards.itemsRightTitle && (
                    <p className="mb-0 mt-4">What is in the solution:</p>
                  )}
                  <FeatureHighlights data={cards.itemsLeft} />
                </div>
                <div className="col-6">
                  {cards.itemsRightTitle && (
                    <p className="mb-0 mt-4">{cards.itemsRightTitle}</p>
                  )}
                  <FeatureHighlights data={cards.itemsRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionStyles>

      {(divider === true && <Divider />) || null}
    </>
  );
}

SideXSidePricing.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  cards: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  divider: PropTypes.bool,
  title: PropTypes.string,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string
  })
};

SideXSidePricing.defaultProps = {
  backgroundColor: null,
  paddingTop: null,
  paddingBottom: null,
  body: null,
  cards: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  divider: false,
  title: null,
  media: null
};

export default SideXSidePricing;
