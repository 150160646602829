import React from 'react';
import styled from 'styled-components';
import { SectionStyles, Divider, SectionHeader } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
/* eslint-enable */

const Table = styled.table`
  & th {
    border: none;
  }
  & td {
    border-bottom: 1px solid #dee2e6;
  }
  & th,
  td {
    padding: 1.6rem;
    position: relative;
  }
  & thead {
    border-top-color: transparent;
    th {
      border-bottom-width: 1px;
    }
  }
`;

const table = (data) => {
  const padding = data.padding ? data.padding : '60px';
  return (
    <>
      <SectionStyles
        backgroundColor={data.backgroundColor}
        paddingBottom={padding}
      >
        <div className="container">
          {data.title && (
            <div className="row">
              <SectionHeader title={data.title} bottomMargin="30px" />
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="table-responsive table mb-2">
                <Table className="table">
                  <thead>
                    <tr>
                      {data.headers.map((h) => (
                        <th key={pmUuid()} scope="col">
                          <p className="h3">{h}</p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((item) => (
                      <tr key={pmUuid()}>
                        {Object.keys(item).map((key) => {
                          if (key === 'url') {
                            return (
                              <td key={pmUuid()}>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer nofollow"
                                >
                                  {item.url}
                                </a>
                              </td>
                            );
                          }
                          if (key === 'link') {
                            return (
                              <td key={pmUuid()}>
                                <a
                                  href={item.link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.link.title}
                                </a>
                              </td>
                            );
                          }
                          if (key === 'notAvailable') {
                            return (
                              <td key={pmUuid()}>
                                <p className="mb-0 small">
                                  <img
                                    style={{ opacity: '.6', height: '16px' }}
                                    alt="Unavailable"
                                    src="https://voyager.postman.com/icon/x-icon-grey.svg"
                                  />
                                </p>
                              </td>
                            );
                          }
                          if (key === 'available') {
                            return (
                              <td key={pmUuid()}>
                                <p className="mb-0 small">
                                  <img
                                    style={{ height: '16px' }}
                                    alt="Available"
                                    src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                                  />
                                </p>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={pmUuid()}
                              dangerouslySetInnerHTML={{ __html: item[key] }}
                            />
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </SectionStyles>
      {(data.divider === true && <Divider />) || null}
    </>
  );
};

export default table;
