import styled from 'styled-components';
/* eslint-disable */
export const PricingParagraph = styled.p`
  color: ${(props) =>
    props.alternative ? props.theme.colors.white : props.theme.colors.grey_90};
  box-sizing: inherit;
  display: block;
  font-weight: 400;
  line-height: 25px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-size: ${(props) =>
    (props.subtitle ? '20px' : '16px') &&
    (props.subtext ? '14px' : '16px;') &&
    (props.small ? '12px' : '16px;')};
  line-height: ${(props) => (props.subtitle ? '1.35' : '1.5')};
  /* margin-bottom: 16px; */
  letter-spacing: ${(props) => (props.small ? '0px' : '')};
  font-style: ${(props) => (props.italic ? 'italic' : '')};

  &.subtext {
    font-size: 14px;
  }
  &.small {
    font-size: 12px;
    color: #6b6b6b;
  }
  &.grey {
    color: #6b6b6b;
  }

  &.h1 {
    font-size: 48px !important;
    line-height: 1.1666666667 !important; /* 56 px */
    margin: 0 0 32px 0 !important;

    @media (min-width: 768px) {
      font-size: 60px !important;
      line-height: 1.1666666667 !important; /* 70 px */
    }
  }
`;
/* eslint-enable */
