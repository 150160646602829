import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import {
  Divider,
  BaseLink,
  SectionStyles,
  SectionHeader
} from 'aether-marketing';
import CardBasic from './v5_cards/CardBasic';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ToggleButton = styled(BaseLink)`
  color: ${(props) => props.theme.colors.blue_60} !important;

  & > svg {
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Arrow Transitions Up on .show */
    &.show {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
    }
  }
`;
function Card({ data }) {
  return (
    <CardBasic
      key={pmUuid()}
      className="mb-5"
      col="col-lg-6 col-xl-4"
      textPadding={data.textPadding}
      textAlign={data.textAlign}
      ratio={data.media.ratio}
      fullWidthImage={data.fullWidthImage}
      media={data.media}
      header={data.header}
      body={data.body}
      link={data.link}
      hover={data.hover}
      href={data.link.href}
      target={data.link.target}
      roundedCorners={data.roundedCorners && data.roundedCorners}
      clickableCardLink={data.clickableCardLink}
    />
  );
}
Card.propTypes = {
  data: PropTypes.shape({
    textPadding: PropTypes.string,
    textAlign: PropTypes.string,
    media: PropTypes.shape({
      src: PropTypes.string,
      ratio: PropTypes.string
    }),
    header: PropTypes.shape({
      text: PropTypes.string
    }),
    body: PropTypes.shape({
      text: PropTypes.string
    }),
    link: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string
    }),
    hover: PropTypes.bool,
    clickableCardLink: PropTypes.bool,
    roundedCorners: PropTypes.bool,
    fullWidthImage: PropTypes.bool
  }).isRequired
};

class Cards extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data,
      isToggled: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled
    }));
  }

  render() {
    const { data, isToggled } = this.state;

    // Set up our items based on toggle, if toggled combine the two items arrays
    const { items } = data;

    return (
      <>
        <SectionStyles
          paddingTop={data.paddingTop}
          paddingBottom={data.paddingBottom || '30px'}
          backgroundColor={data.backgroundColor}
        >
          <div className="container" id={data.id ? data.id : ''}>
            <div className="row">
              <SectionHeader
                title={data.header.headerText}
                textAlign={data.textAlign}
                bottomMargin="30px"
              />
            </div>

            <div className="row">
              {items.map((item) => (
                <Card key={pmUuid()} data={item} />
              ))}
            </div>

            {isToggled && (
              <>
                <div className="row">
                  <SectionHeader
                    title={data.toggle.title}
                    textAlign={data.textAlign}
                    bottomMargin="30px"
                  />
                </div>
                <div className="row">
                  {data.toggle.items.map((item) => (
                    <Card key={pmUuid()} data={item} />
                  ))}
                </div>
              </>
            )}

            <div className="row">
              <div className="col-12 text-center">
                <ToggleButton
                  key={pmUuid()}
                  onClick={this.handleToggle}
                  href={null}
                >
                  {isToggled ? data.toggle.toggleText : data.toggle.text}

                  <svg
                    className={`arrow-icon ml-2 ${isToggled && 'show'}`}
                    id="toggle-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="#0265D2"
                  >
                    <g>
                      <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                    </g>
                  </svg>
                </ToggleButton>
              </div>
            </div>
          </div>
        </SectionStyles>
        {(data.divider === true && <Divider />) || null}
      </>
    );
  }
}

Cards.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    backgroundColor: PropTypes.string,
    header: PropTypes.shape({
      headerText: PropTypes.string
    }),
    toggle: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      toggleText: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          media: PropTypes.shape({
            src: PropTypes.string,
            ratio: PropTypes.string
          }),
          header: PropTypes.shape({
            text: PropTypes.string
          }),
          body: PropTypes.shape({
            text: PropTypes.string
          }),
          link: PropTypes.shape({
            href: PropTypes.string,
            target: PropTypes.string
          }),
          hover: PropTypes.bool,
          clickableCardLink: PropTypes.bool
        })
      )
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.shape({
          src: PropTypes.string,
          ratio: PropTypes.string
        }),
        header: PropTypes.shape({
          text: PropTypes.string
        }),
        body: PropTypes.shape({
          text: PropTypes.string
        }),
        link: PropTypes.shape({
          href: PropTypes.string,
          target: PropTypes.string
        }),
        hover: PropTypes.bool,
        clickableCardLink: PropTypes.bool
      })
    )
  })
};
Cards.defaultProps = {
  data: {
    id: null,
    paddingTop: '30px',
    paddingBottom: '30px',
    backgroundColor: null,
    header: {
      headerText: null
    },
    toggle: {
      title: null,
      text: null,
      toggleText: null,
      items: []
    },
    items: []
  }
};
const cardToggle = (data) => (
  <div>
    <Cards data={data} />
  </div>
);

export default cardToggle;
