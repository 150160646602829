import React from 'react';
import PropTypes from 'prop-types';

// Pass a HTML element and append to the page without modifying a frontend component
// Used for script tags like pixels or google fonts
class ScriptTags extends React.Component {
  constructor(props) {
    super(props);
    const { markup } = props;
    this.state = {
      markup
    };
  }

  componentDidMount() {
    const { markup } = this.state;
    const string = markup;
    const fragment = document.createRange().createContextualFragment(string);
    document.body.appendChild(fragment);
  }

  render() {
    return null;
  }
}
ScriptTags.propTypes = {
  markup: PropTypes.string.isRequired
};

const externalScriptTag = (data) => <ScriptTags markup={data.markup} />;

export default externalScriptTag;
