/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import addOnsData from './pricingAddOnsData.json';
import resourceData from './pricingResourcesData.json';
import cardIconChecklistSection from './cardIconChecklistSection';
import PricingAddonsTable from './pricingAddOnsTable';

const FixedWidthColumnLeft = styled.div`
  flex: 0 0 auto;
  width: 35%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const FixedWidthColumnRight = styled.div`
  flex: 0 0 auto;
  width: 60%;
  margin-left: 30px;
  padding: 0;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
    padding: 0 24px;
  }
`;

const TabContainer = styled.ul`
  border-bottom: none;

  svg.arrow-icon {
    height: 16px;
    position: absolute;
    right: -10px;
    top: 41%;
    transform: rotate(-90deg);
  }
`;

const Tab = styled.li`
  display: flex;
  align-items: start;
`;

const TabLink = styled.a`
  display: flex;
  align-items: start;
  padding: 20px 24px;
  font-weight: 600;
  background-color: ${({ isActive }) => (isActive ? '#f8f9fa' : 'transparent')};
`;

const TabIcon = styled.img`
  height: 30px;
  margin-right: 20px;
`;

const TabGroup = styled.ul`
  border: 1px solid #e6e6e6;
  background-color: ${(props) => props.theme.colors.grey_00};
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  top: 0px;
  width: 100%;
  z-index: 10;
  position: sticky !important;

  .show > .nav-link {
    // skips the blue background on page load
    background-color: ${(props) => props.theme.colors.grey_00} !important;
    color: ${(props) => props.theme.colors.grey_90};
    font-weight: 600;
    font-size: 16px;
    &:focus {
      outline: 0;
    }
    &:hover {
      color: ${(props) => props.theme.colors.grey_90};
      cursor: pointer;
    }
    .active {
      background-color: ${(props) => props.theme.colors.grey_00} !important;
    }
  }
`;

const PricingTableDropdownMenu = styled.li`
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  width: 100%;
  a {
    white-space: normal;
  }
  a,
  a.active {
    color: #212121;
  }

  a.dropdown-item {
    padding: 8px 16px;
    font-size: 14px;
  }

  .nav-link {
    padding: 12px 16px;
  }

  .nav-link.active,
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:focus {
    background-color: ${(props) => props.theme.colors.grey_00};
    color: ${(props) => props.theme.colors.grey_90};
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
  }

  .nav-link {
    > svg {
      display: inline-block;
      margin-left: 6px;
      width: 10px;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      /* Arrow Transitions Up on .show */
      &.show {
        display: inline-block;
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
      }

      @media screen and (max-width: 992px) {
        float: right;
        position: relative;
        top: 6px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

const PricingTabsMobileLinks = styled.div`
  background-color: ${(props) => props.theme.colors.grey_00};
  border: 1px solid ${(props) => props.theme.colors.grey_30};
  border-radius: 4px;
  margin-top: 4px;
  padding: 12px;
  text-align: left;

  a {
    padding: 0;
    white-space: normal;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

const runtime = typeof document === 'object';
function PricingTabs({ id, data }) {
  const defaultTab = id === 'add-ons' ? 'postbot' : 'mock-calls';
  const [activeTab, setActiveTab] = useState(defaultTab);
  const source = id === 'add-ons' ? addOnsData : resourceData;

  useEffect(() => {
    const targetId = `${id}-tab`;
    const target = document.getElementById(targetId);
    target.setAttribute('data-event-view', data.event.viewed);
    if (runtime) {
      [...document.querySelectorAll('[data-toggle="tooltip"]')].forEach(
        (dom) => {
          // eslint-disable-next-line no-undef
          $(dom).tooltip({
            html: true,
            trigger: 'hover focus'
          });
        }
      );

      // Check if the current URL matches any tab href and open the tab if it does
      source.forEach((item) => {
        if (window.location.href.includes(item.id)) {
          setActiveTab(item.id);
        }
      });
    }
  }); // do not use [] or popover breaks

  return (
    <div id={`${id}-tab`} className="row">
      <FixedWidthColumnLeft>
        <TabContainer
          className="nav nav-tabs d-none d-lg-block"
          id="PricingTabs"
          role="tablist"
        >
          {source.map((item) => (
            <Tab key={item.id} role="presentation">
              <TabLink
                id={`${item.id}-tab`} // for tab link
                data-toggle="tab"
                data-target={`#${item.id}`} // for tab content
                type="button"
                role="tab"
                aria-controls={item.id}
                aria-selected={item.id === activeTab}
                onClick={() => {
                  setActiveTab(item.id);
                  if (window.history && window.history.pushState) {
                    window.history.pushState({}, '', `#${item.id}`);
                  }
                }}
                isActive={item.id === activeTab}
              >
                <TabIcon src={item.icon} alt="Postman icon" />
                <div className="position-relative">
                  {item.title}
                  <p className="small mt-2 mb-0 pr-2">
                    {item.description}{' '}
                    {item.id === activeTab && (
                      <svg
                        className="arrow-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="#6b6b6b"
                      >
                        <g>
                          <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                        </g>
                      </svg>
                    )}
                  </p>
                </div>
              </TabLink>
            </Tab>
          ))}
        </TabContainer>
        <div className="col-12 d-lg-none d-block  position-relative">
          <TabGroup
            className="nav nav-pills sticky-top"
            id="mobilePricingTabs"
            role="tablist"
          >
            <PricingTableDropdownMenu className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="##"
                role="button"
                aria-expanded="false"
              >
                {source.find((item) => item.id === activeTab).title}
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#212121"
                >
                  <g>
                    <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                  </g>
                </svg>
              </a>
              <PricingTabsMobileLinks className="dropdown-menu w-100">
                {source.map((dropdownItem) => (
                  <a
                    className="dropdown-item border-none"
                    href={`#${dropdownItem.id}-pricing-tab`}
                    key={dropdownItem.id}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(dropdownItem.id);
                      if (window.history && window.history.pushState) {
                        window.history.pushState({}, '', `#${dropdownItem.id}`);
                      }
                    }}
                    id={`${dropdownItem.id}-tab`}
                    data-toggle="tab"
                    role="tab"
                    aria-controls={`${dropdownItem.id}-tab`}
                    aria-selected={dropdownItem.id === activeTab}
                  >
                    {dropdownItem.title}
                  </a>
                ))}
              </PricingTabsMobileLinks>
            </PricingTableDropdownMenu>
          </TabGroup>
        </div>
      </FixedWidthColumnLeft>
      <FixedWidthColumnRight>
        <div className="tab-content">
          {source.map((item) => (
            <div
              key={item.id}
              className={
                item.id === activeTab ? 'tab-pane active show' : 'tab-pane '
              }
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              {item.data.type === 'pricingAddOnsTable' && (
                <PricingAddonsTable data={item.data} />
              )}
              {item.data.type === 'cardIconChecklistSection' &&
                cardIconChecklistSection(item.data)}
            </div>
          ))}
        </div>
      </FixedWidthColumnRight>
    </div>
  );
}

export default PricingTabs;
