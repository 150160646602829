import parseQuery from '../components/Shared/parseQuery';

const dcidQuery = () => {
  let value = '';
  if (typeof window !== 'undefined') {
    const { search } = window.location;
    const queries = parseQuery(search);
    if (queries.dcid) {
      value = queries.dcid;
      const ref = `?ref=${value}`;
      return ref;
    }
    return value;
  }
  return value;
};

export default dcidQuery;
