import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles,
  SectionHeader
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const FeatureWrapper = styled.div`
  margin-bottom: 5rem;

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
`;

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 3rem;

  &.spot {
    height: 12rem;
  }
  &.icon {
    height: 5rem;
  }
`;

const DisabledLink = styled(BaseLink)`
  color: #666 !important;
  cursor: not-allowed;
  background-image: none !important;
  border-color: transparent;
  pointer-events: none;

  &:hover {
    transition: none;
  }
`;

const ToggleButton = styled(BaseLink)`
  color: ${(props) => props.theme.colors.blue_60} !important;

  & > svg {
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /* Arrow Transitions Up on .show */
    &.show {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
    }
  }
`;

const dataShape = {
  backgroundColor: PropTypes.string,
  body: PropTypes.array,
  items: PropTypes.array,
  link: PropTypes.object,
  links: PropTypes.array,
  media: PropTypes.object,
  subtitle: PropTypes.array,
  title: PropTypes.string,
  containerClass: PropTypes.string,
  imageSize: PropTypes.string,
  ctaLinks: PropTypes.array
};

function Card({ data }) {
  const linkData = data.link ? data.link : '';
  if (linkData) {
    linkData.alternative = false;
  }
  const colWidth = data.colWidth ? data.colWidth : 'col-lg-6 col-xl-4';

  return (
    <FeatureWrapper
      className={`${colWidth} pr-md-5 flex-column justify-content-between`}
    >
      <div>
        {data.media && data.imageSize !== null && (
          <ImageWrapper
            className={data.imageSize}
            style={{
              backgroundImage: `url("${data.media.url}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center left'
            }}
          />
        )}
        {data.title && <h3 className="h4 card-title mb-4">{data.title}</h3>}
        {data.subtitle && (
          <ContentContainer
            dangerouslySetInnerHTML={{ __html: data.subtitle.join('') }}
          />
        )}
        {data.body && Array.isArray(data.body) && (
          <div dangerouslySetInnerHTML={{ __html: data.body.join('') }} />
        )}
      </div>
      {data.link && (
        <div>
          {/* USE THIS KEY IN YOUR JSON IF YOU WANT A DISABLED LINK (For Webinars) */}
          {data.link.isComingSoon ? (
            <DisabledLink href={linkData.src} target="new-tab-postman">
              {linkData.text}
            </DisabledLink>
          ) : (
            <BaseLink
              linkType="arrowLink"
              src={linkData.src}
              target={linkData.type ? linkData.type : 'new-tab-postman'}
            >
              {linkData.text}
            </BaseLink>
          )}
        </div>
      )}
    </FeatureWrapper>
  );
}

Card.propTypes = {
  data: PropTypes.shape(dataShape).isRequired
};

class Cards extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data,
      isToggled: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled
    }));
  }

  render() {
    const { data, isToggled } = this.state;
    const divider = data.divider ? data.divider : 'true';
    //  If nesting this component in a splitTemplate,
    //  Use containerClass to give it container-fluid - full width 100%
    const containerClass = data.containerClass
      ? data.containerClass
      : 'container';
    // Set up our items based on toggle, if toggled combine the two items arrays
    let { items } = data;
    if (isToggled) {
      items = items.concat(data.toggle.items);
    }
    return (
      <>
        <SectionStyles
          paddingTop={data.paddingTop}
          paddingBottom={data.paddingBottom || '30px'}
          backgroundColor={data.backgroundColor}
        >
          <div className={containerClass} id={data.id ? data.id : ''}>
            <div className="row">
              <SectionHeader
                title={data.title}
                body={data.body}
                link={data.link}
                textAlign={data.textAlign}
                toggleZendesk={data.toggleZendesk}
                bottomMargin="30px"
              />
            </div>
            <div className="row">
              {items.map((item) => (
                <Card key={pmUuid()} data={item} />
              ))}
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <ToggleButton
                  key={pmUuid()}
                  onClick={this.handleToggle}
                  href={null}
                >
                  {isToggled ? data.toggle.toggleText : data.toggle.text}
                  <svg
                    className={`arrow-icon ml-2 ${isToggled && 'show'}`}
                    id="toggle-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="#0265D2"
                  >
                    <g>
                      <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                    </g>
                  </svg>
                </ToggleButton>
              </div>
            </div>
            {data.ctaLinks &&
              Array.isArray(data.ctaLinks) &&
              data.ctaLinks.map((cta) => (
                <p key={pmUuid()}>
                  <BaseLink
                    linkType={cta.type}
                    src={cta.src}
                    className={cta.className && cta.className}
                    alternative={cta.alternative ? cta.alternative : false}
                    target={cta.target ? cta.target : 'same-tab'}
                    gaCategory={cta.gaCategory && cta.gaCategory}
                    gaLabel={cta.gaLabel && cta.gaLabel}
                    id={cta.id && cta.id}
                    ariaLabel={cta.ariaLabel && cta.ariaLabel}
                  >
                    {cta.text}
                  </BaseLink>
                </p>
              ))}
            {data.links && (
              <div className="row text-center">
                {data.links.map((link) => (
                  <div className="col-12 mt-5" key={pmUuid()}>
                    <BaseLink linkType="arrowLink">{link}</BaseLink>
                  </div>
                ))}
              </div>
            )}
          </div>
        </SectionStyles>
        {(divider === true && <Divider />) || null}
      </>
    );
  }
}

Card.propTypes = {
  data: PropTypes.shape(dataShape).isRequired
};

Cards.propTypes = {
  data: PropTypes.shape(dataShape).isRequired
};

const featureV6toggle = (data) => (
  <div>
    <Cards data={data} />
  </div>
);

export default featureV6toggle;
