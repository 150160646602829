import React from 'react';
import styled from 'styled-components';
import { UnorderedListStyles, Paragraph } from 'aether-marketing';

const Section = styled.section`
  padding: 60px 0 250px 0;
  background-color: #02070f;
  background-position: center center;
  background-repeat: none;
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  .alternative p {
    color: white;
  }
`;

const recapHero = (data) => (
  <Section
    id={data.id}
    style={{
      padding: data.padding,
      margin: data.margin,
      backgroundImage: data.background,
      backgroundSize: 'cover',
      color: data.color,
      zIndex: data.zIndex,
      position: data.position
    }}
  >
    <div className="container">
      <div className="row mb-5">
        <div className="col-12 text-center">
          {data.header && <h1 className="mb-3 text-white">{data.header}</h1>}
          {data.subtitle && (
            <Paragraph className="subtitle text-white mb-4">
              {data.subtitle}
            </Paragraph>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-12 mb-5">
          <img
            className="img-fluid"
            src={data.media.src}
            alt={data.media.alt}
          />
        </div>
        <div className="col-lg-7 col-12">
          <h2 className="text-white">{data.body.title}</h2>
          <Paragraph className="text-white mb-4">
            {data.body.subtitle}
          </Paragraph>
          <div
            className="alternative"
            alternative
            dangerouslySetInnerHTML={{ __html: data.body.description.join('') }}
          />
        </div>
      </div>
    </div>
  </Section>
);

export default recapHero;
