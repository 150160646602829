import React from 'react';
import styled from 'styled-components';
import PricingTabs from './pricingTabs';

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 30px 0;
`;

function PricingTabsSection(data) {
  const { title, id, subtitle } = data;

  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-12 offset-0 col-lg-10 offset-lg-1 text-center mb-3">
            <h2 id={id} className="mb-2">
              {title}
            </h2>
            <p>{subtitle}</p>
          </div>

          <PricingTabs id={id} data={data} />
        </div>
      </div>
    </Section>
  );
}

export default PricingTabsSection;
