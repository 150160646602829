import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  SectionStyles,
  Paragraph,
  BaseButton
} from 'aether-marketing';
import '../../../styles/config/_pm-icons.css';

const LogoWrapper = styled.div`
  & img {
    height: 50px;
    width: auto;
  }
`;

// as seen on /open-philosophy - Our Open Projects
const canaryCards = (data) => (
  <>
    <SectionStyles>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h2>{data.title}</h2>
          </div>
          {/* MAC OS CARD */}
          <div className="col-12 col-sm-6 col-lg-4 mb-5">
            <LogoWrapper className="mb-5">
              <img
                src="https://voyager.postman.com/logo/external/appl-logo-grey.svg"
                alt="Apple"
                className="img-fluid"
                fetchpriority="low"
                loading="lazy"
              />
            </LogoWrapper>
            <h2 className="h3">{data.items[0].title}</h2>
            <Paragraph className="small">{data.items[0].subtitle}</Paragraph>
            <BaseButton
              as="a"
              buttonType="secondary"
              src={data.items[0].linkIntel.src}
              className="mb-4 mr-3"
              dataTest={data.items[0].linkIntel.dataTest}
            >
              {data.items[0].linkIntel.text}
            </BaseButton>
            <BaseButton
              as="a"
              buttonType="secondary"
              src={data.items[0].linkApple.src}
              className="mb-4"
              dataTest={data.items[0].linkApple.dataTest}
            >
              {data.items[0].linkApple.text}
            </BaseButton>
          </div>
          {/* {WINDOWS CARD} */}
          <div className="col-12 col-sm-6 col-lg-4 mb-5">
            <LogoWrapper className="mb-5">
              <img
                src="https://voyager.postman.com/logo/external/microsoft-logo.svg"
                alt="Windows"
                className="img-fluid"
                fetchpriority="low"
                loading="lazy"
              />
            </LogoWrapper>
            <h2 className="h3">{data.items[1].title}</h2>
            <Paragraph className="small">{data.items[1].subtitle}</Paragraph>
            <BaseButton
              as="a"
              buttonType="secondary"
              src={data.items[1].link.src}
              className="mb-4"
              dataTest={data.items[1].link.dataTest}
            >
              {data.items[1].link.text}
            </BaseButton>
          </div>
          {/* LINUX CARD */}
          <div className="col-12 col-sm-6 col-lg-4 mb-5">
            <LogoWrapper className="mb-5">
              <img
                src="https://voyager.postman.com/logo/external/linux-logo.png"
                alt="Linux"
                className="img-fluid"
                fetchpriority="low"
                loading="lazy"
              />
            </LogoWrapper>
            <h2 className="h3">{data.items[2].title}</h2>
            <Paragraph className="small">{data.items[2].subtitle}</Paragraph>
            <BaseButton
              as="a"
              buttonType="secondary"
              src={data.items[2].linkX64.src}
              className="mb-4"
              dataTest={data.items[2].linkX64.dataTest}
            >
              {data.items[2].linkX64.text}
            </BaseButton>
            {/* TODO: remove this guard when postman-agent is release for arm64 */}
            {(data.items[2].linkArm64 && (
              <BaseButton
                as="a"
                buttonType="secondary"
                src={data.items[2].linkArm64.src}
                className="mb-4"
                dataTest={data.items[2].linkArm64.dataTest}
              >
                {data.items[2].linkArm64.text}
              </BaseButton>
            )) ||
              null}
          </div>
          <div className="col-12">
            <Paragraph className="small">{data.subtitle}</Paragraph>
          </div>
        </div>
      </div>
    </SectionStyles>
    {(data.divider === true && <Divider />) || null}
  </>
);

export default canaryCards;
