import React from 'react';
import {
  Divider,
  SectionStyles,
  BaseButton,
  SectionHeader
} from 'aether-marketing';
import LogoWall from './LogoWall';

// Logo Wall with Button
// {
//   "type": "logoWallSectionV6",
//   "backgroundColor": "",
//   "colX": "2", Bootstrap number for `col-lg-2`. Default is `col-lg`. If more than 6 logos, recommend you add a value here, otherwise null works well.
//   "divider": true, adds divider to section
//   "linkedImages": true, boolean for if you need the images / logos to be clickable
//   "paddingBottom": "", apply user-defined padding bottom
//   "textAlign": "left", default (left aligned), and accepts "center", "right" or "left"
//   "toggleHover": true, enables a hover animation on mouseover
//   "title": "",
//   "body": [
//     "<p></p>"
//    ],
//   "media": [
//    {
//     src: "",
//     alt: "",
//     href: ""
//    }
//  ],
// "buttonLeft": {
//   "buttonType": "primary",
//   "text": "",
//   "href": "",
//   "target": "",
//   "id": "",
//   "gaCategory": "",
//   "gaLabel": ""
// },
// "buttonRight": {
//   "buttonType": "secondary",
//   "text": "",
//   "href": "",
//   "target": "",
//   "id": "",
//   "gaCategory": "",
//   "gaLabel": ""
// }
// }

const runtime = typeof document === 'object';
let viewed;

const logoWallSectionV6 = (data) => {
  const {
    buttonLeft,
    buttonRight,
    backgroundColor,
    className,
    body,
    divider,
    link,
    paddingTop,
    paddingBottom,
    paddingBottomHeader,
    textAlign,
    title,
    textColor
  } = data;

  // Left-aligned is default, but center is supported
  const textAlignToggle =
    textAlign === 'center' ? ' offset-md-2 text-center' : null;

  // Amplitude - "scroll into view"  events
  const { event } = data;
  const target = runtime && document.getElementById('CustomersSection');
  if (runtime && target && event && !viewed) {
    target.setAttribute('data-event-view', event);
    viewed = true;
  }
  return (
    <>
      <SectionStyles
        id="CustomersSection"
        paddingTop={paddingTop || '60px'}
        paddingBottom={
          buttonLeft ? paddingBottom : `calc(${paddingBottom || '30px'} - 2rem)`
        }
        style={{
          color: textColor || '',
          background: backgroundColor || ''
        }}
        textColor={`${textColor || ''}`}
      >
        <div className="container">
          <div className="row">
            <SectionHeader
              title={title}
              className={className}
              body={body}
              link={link}
              textAlign={textAlign}
              paddingBottom={paddingBottomHeader}
              bottomMargin="60px"
            />
          </div>
          <LogoWall data={data} />
          {buttonLeft && (
            <div className="row mt-5">
              <div className={`col-sm-12 col-md-8${textAlignToggle}`}>
                {buttonLeft && buttonLeft.text && buttonLeft.src && (
                  <BaseButton
                    buttonType={buttonLeft.buttonType}
                    as="a"
                    src={buttonLeft.src}
                    target={buttonLeft.target}
                    id={buttonLeft.id}
                    gaCategory={buttonLeft.gaCategory}
                    gaLabel={buttonLeft.gaLabel}
                    event={buttonLeft.event}
                    eventProp={buttonLeft.eventProp}
                    className="mb-3 mb-md-0"
                    icon={buttonLeft.icon}
                  >
                    {buttonLeft.text}
                  </BaseButton>
                )}
                {buttonRight && buttonRight.text && buttonRight.src && (
                  <BaseButton
                    buttonType={buttonRight.buttonType}
                    as="a"
                    className="mr-0"
                    src={buttonRight.src}
                    target={buttonRight.target}
                    id={buttonRight.id}
                    gaCategory={buttonRight.gaCategory}
                    gaLabel={buttonRight.gaLabel}
                    event={buttonRight.event}
                    eventProp={buttonRight.eventProp}
                    icon={buttonRight.icon}
                  >
                    {buttonRight.text}
                  </BaseButton>
                )}
              </div>
            </div>
          )}
        </div>
      </SectionStyles>
      {divider ? <Divider /> : null}
    </>
  );
};
export default logoWallSectionV6;
