/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import 'yup-phone-lite';

import { ValidationField, isValidString, isValidUrl } from './ValidationSchema';

const DefaultValidation = {
  string: ValidationField('Required field cannot be empty'),
  email: ValidationField(
    'Email is required. e.g., name@domain.com',
    isValidString.email('Please enter a valid email address.')
  ),
  picklist: ValidationField('Please select a valid option'),
  radio: ValidationField('Required field cannot be empty'),
  number: {
    required: Yup.mixed()
      .required('Required field must be a number')
      .test('type', 'Field must be a number', (value) => !Number.isNaN(value)),
    optional: Yup.mixed().test(
      'type',
      'Field must be a number',
      (value) => !Number.isNaN(value)
    )
  },
  phone: {
    // https://www.npmjs.com/package/yup-phone-lite - ${originalValue} is the value entered by the user
    required: Yup.string()
      .phone('US', '${originalValue} must be a valid phone number')
      .required(
        'Please enter a valid phone number. e.g., 415-456-7890 (US), +91 33 12345678 (IN), +81 (03) 1234-5678 (JP)'
      ),
    optional: Yup.string().phone(
      'US',
      '${originalValue} must be a valid phone number'
    )
  },
  single_checkbox: {
    required: Yup.boolean().oneOf([true], 'Required field must be checked'),
    optional: Yup.boolean()
  },
  textarea: ValidationField('Required field cannot be empty'),
  url: {
    required: Yup.string()
      .matches(
        isValidUrl,
        'Please enter a valid URL. Limit 1. (e.g. https://www.postman.com)'
      )
      .required(
        'Please enter a valid URL. Limit 1. (e.g. https://www.postman.com)'
      ),
    optional: Yup.string().matches(
      isValidUrl,
      'Please enter a valid URL. Limit 1. (e.g. https://www.postman.com)'
    )
  }
};

export default DefaultValidation;
