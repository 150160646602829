import React from 'react';
import styled from 'styled-components';
import { BaseLink } from 'aether-marketing';

const Section = styled.section`
  padding: 30px 0 10px 0;
  background-color: ${(props) => props.theme.colors.white};

  a {
    color: white !important;
  }

  a:hover {
    border-bottom: 1px solid white;
  }
`;

const backLinkSectionDarkBackground = (data) => (
  <Section style={{ backgroundColor: data.backgroundColor }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-sm-12 mb-4">
          <BaseLink src={data.link.src} linkType="reversedArrowLink">
            {data.link.text}
          </BaseLink>
        </div>
      </div>
    </div>
  </Section>
);

export default backLinkSectionDarkBackground;
