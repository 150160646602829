import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/* eslint-disable no-nested-ternary */
const LogoWrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.colX === '3' ? '' : 'center center'};
  height: ${(props) => {
    if (props.height) {
      return props.height;
    }
    if (props.colX === '1') {
      return '46px';
    }
    if (props.colX === '2' || props.colX === '3') {
      return '42px';
    }
    if (props.colX === 'B') {
      return '200px';
    }
    return '75px';
  }};
  width: ${(props) => (props.colX === '1' ? '76px' : null)};
  margin: ${(props) => {
    if (props.colX === '1') {
      return '0 auto';
    }
    if (props.colX === '2') {
      return '0 0px';
    }
    if (props.colX === '3') {
      return '0 3rem';
    }
    return '0 4rem';
  }};
  @media screen and (max-width: 768px) {
    display: flex;
    height: ${(props) => {
      if (props.colX === 'B') {
        return '100px';
      }
      return '75px';
    }};
  }
  &:hover {
    &.hover {
      transform: translateY(-0.5rem);
      transition: 0.3s linear;
    }
  }
`;

function LogoWall({ data }) {
  const {
    colX,
    colMargin,
    media,
    linkedImages,
    toggleHover,
    logoPaddingBottom
  } = data;

  return (
    // "linkedImages" boolean toggles between clickable and static logos
    // "toggleHover" boolean adds a logo animation on mouseover
    <div className={`row d-flex ${logoPaddingBottom || 'mb-5'}`}>
      {linkedImages
        ? media.map((img) => (
            <React.Fragment key={pmUuid()}>
              <div
                className={`${
                  colX === '1'
                    ? 'col-4 col-sm-4 col-md-2'
                    : colX === '2'
                    ? ' col-4 col-sm-4 col-md-3'
                    : colX === '3'
                    ? 'col-4 col-sm-4 col-md-4'
                    : 'col-6 col-sm-6 col-md-4'
                } col-lg${colX ? `-${colX}` : ''}     ${
                  colMargin ? `${colMargin}` : 'mb-5'
                }`}
              >
                <a
                  href={img.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-labelledby="listen"
                >
                  <LogoWrapper
                    style={{
                      backgroundImage: `url(${img.src})`
                    }}
                    height={img.height}
                    className={toggleHover ? 'hover' : ''}
                    colX={colX}
                  />
                </a>
                {img.text && (
                  <p className="text-white text-center mt-3">{img.text}</p>
                )}
              </div>
            </React.Fragment>
          ))
        : media.map((img) => (
            <React.Fragment key={pmUuid()}>
              <div
                className={`${
                  colX === '1'
                    ? 'col-4 col-sm-4 col-md-2'
                    : colX === '2'
                    ? ' col-4 col-sm-4 col-md-3'
                    : colX === '3'
                    ? 'col-4 col-sm-4 col-md-4'
                    : 'col-6 col-sm-6 col-md-4'
                } col-lg${colX ? `-${colX}` : ''}     ${
                  colMargin ? `${colMargin}` : 'mb-5'
                }`}
              >
                <LogoWrapper
                  style={{
                    backgroundImage: `url(${img.src})`
                  }}
                  height={img.height}
                  title={img.alt}
                  colX={colX}
                  className={toggleHover ? 'hover' : ''}
                />
              </div>
            </React.Fragment>
          ))}
    </div>
  );
}

LogoWall.propTypes = {
  data: PropTypes.shape({
    colX: PropTypes.string,
    colMargin: PropTypes.string,
    toggleHover: PropTypes.bool,
    linkedImages: PropTypes.bool,
    logoPaddingBottom: PropTypes.string,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        href: PropTypes.string,
        alt: PropTypes.string,
        text: PropTypes.string
      })
    )
  })
};

LogoWall.defaultProps = {
  data: {
    colX: '2',
    colMargin: 'mb-5',
    linkedImages: true,
    logoPaddingBottom: 'mb-5',
    media: [],
    toggleHover: null
  }
};
export default LogoWall;
