import React from 'react';

const structuredData = `{
  "@context":"https://schema.org",
  "@type":"Organization",
  "name": "Postman",
  "description":
    "Postman is a collaboration platform for API development. Postman's features simplify each step of building an API and streamline collaboration so you can create better APIs—faster.",
  "url": "https://www.postman.com/",
  "logo": "",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "4157966470",
    "contactType": "customer service"
  },
  "sameAs": [
    "https://www.facebook.com/getpostman/",
    "https://twitter.com/getpostman",
    "https://github.com/postmanlabs",
    "https://www.postman.com/",
    "https://www.linkedin.com/company/postman-api-tools/",
    "https://www.youtube.com/c/Postman"
  ]
}`;

class Schema extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      (() => {
        const s = document.createElement('script');
        s.type = 'application/ld+json';
        s.innerHTML = structuredData;
        document.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }

  render() {
    return null;
  }
}

const organizationSchema = (data) => <Schema data={data} />;

export default organizationSchema;
