import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BaseButton } from 'aether-marketing';

const SectionHeaderStyles = styled.div`
  padding-bottom: ${(props) => props.paddingBottom || '60px '};
  max-width: 1884px;
  color: white;
  .h1 {
    font-size: 48px;
    line-height: 1.2;
  }
  /* .h3 {
    font-size: 35px;
  } */
  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }
  .sun {
    border-radius: 50%;
    width: 166px;
    height: 166px;
    background: rgba(255, 180, 34, 0.81);
    background: radial-gradient(circle, #ff6d38 40%, #ffb422);
    position: absolute;
    top: 6%;
    left: 0;
    margin-left: -113px;
    overflow: hidden;
    -webkit-box-shadow: 0 10px 6px -6px #ff6c37;
    -moz-box-shadow: 0 10px 6px -6px #ff6c37;
    box-shadow: 0 0px 26px 6px #ff6c37;
    z-index: 2;
    @media (max-width: 1280px) {
      display: none;
    }
    @media (min-width: 1800px) {
      position: relative;
      margin-left: 0px;
      top: 45%;
      left: -20%;
    }
  }
  @media (max-width: 900px) {
    .h1 {
      font-size: 32px;
    }
    .h3 {
      font-size: 28px;
    }
  }
`;

function PostconSectionHeader({
  title,
  assignIDtoParent,
  body,
  link,
  paddingBottom,
  preTitle,
  sun,
  headerClass
}) {
  const anchorTag = title
    .replace(/[,']/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

  return (
    <SectionHeaderStyles paddingBottom={paddingBottom}>
      {sun ? <div className="sun " /> : null}
      <div className={headerClass || 'col-lg-8'}>
        {preTitle && <p className="gradientText mb-0 h3">{preTitle}</p>}
        {title && (
          <h2 className="h1" id={assignIDtoParent ? '' : anchorTag}>
            {title}
          </h2>
        )}

        {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
        {link && (
          <BaseButton
            buttonType={link.buttonType}
            as={'' || 'a'}
            className={link.buttonType === 'inline' ? '' : null}
            gaCategory={link.gaCategory}
            gaLabel={link.gaLabel}
            src={link.src}
            target={link.target}
            icon={link.icon}
            id={link.id}
          >
            {link.text}
          </BaseButton>
        )}
      </div>
    </SectionHeaderStyles>
  );
}

PostconSectionHeader.defaultProps = {
  title: '',
  headerClass: '',
  preTitle: '',
  body: null,
  assignIDtoParent: null, // dynamic - if true, assign ID to parent section container insead of child
  paddingBottom: '60px',
  link: null,
  sun: false
};

PostconSectionHeader.propTypes = {
  title: PropTypes.string,
  headerClass: PropTypes.string,
  preTitle: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  assignIDtoParent: PropTypes.bool,
  paddingBottom: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        button: PropTypes.shape({
          buttonType: PropTypes.string,
          className: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          icon: PropTypes.string,
          id: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ]),
  sun: PropTypes.bool
};

export default PostconSectionHeader;
