import React from 'react';
import { SectionStyles, BaseButton } from 'aether-marketing';
import styled from 'styled-components';
import Button from '../v5_buttons/Button';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const LeftColumn = styled.div`
  background: #f9f9f9;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: none;
  border-radius: 7px 0 0 7px;
  padding: 30px !important;
  @media screen and (max-width: 991px) {
    border-radius: none;
    border: none;
    padding: 0 180px !important;
  }
  @media screen and (max-width: 678px) {
    padding: 0 30px !important;
  }
`;

const RightColumn = styled.div`
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-left: none;
  border-radius: 0 7px 7px 0;
  padding: 30px 24px !important;
  @media screen and (max-width: 991px) {
    padding: 0 180px !important;
    border: none;
  }
  @media screen and (max-width: 678px) {
    padding: 0 30px !important;
  }
`;

const LeftCard = styled.div`
  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
    border: 1px solid #e6e6e6;
    border-radius: 7px;
    padding: 30px !important;
  }
  @media screen and (max-width: 576px) {
    padding: 24px 30px !important;
  }
`;

const RightCard = styled.div`
  /* @media screen and (max-width: 991px) {
    margin-bottom: 24px;
    border: 1px solid #e6e6e6;
    border-radius: 7px;
    padding: 30px !important;
  } */
  @media screen and (max-width: 576px) {
    padding: 24px 30px !important;
  }
`;

const PricingColumn = styled.div`
  .plan-price {
    font-size: 14px;
    margin-left: -10px;
  }

  p {
    margin-bottom: 0;
    color: #212121;
  }
  p.enterprise-essential-space {
    margin-bottom: -2px !important;
  }
`;
const ButtonSectionStyles = styled.div`
  margin-top: 20px;
  margin-left: 0px !important;
  p {
    margin-bottom: 0 !important;
  }
  span.dollars {
    font-size: 28px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.Degular};
  }
  sup {
    position: relative;
    top: 0rem !important;
    font-size: 28px;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

// eslint-disable-next-line react/function-component-definition
/* eslint-disable react/prop-types */
function CTASection({ card }) {
  return (
    <div className="row align-items-center mt-4">
      <div className="col-12 col-sm-5 col-md-4 col-lg-6 pl-1">
        <Button
          buttonType={card.cta.buttonType ? card.cta.buttonType : 'primary'}
          id={card.cta.id}
          href={card.cta.href}
          rel={card.cta.rel}
          target={card.cta.target}
          data-url={card.cta.test}
          dataTest={card.cta.test}
          event={card.cta.event}
          eventProp={card.cta.eventProp}
          onClick={() => {
            triggerGA(card.cta.category, card.cta.label);
          }}
          aria-label={card.cta.ariaLabel}
        >
          {card.cta.title}
        </Button>
      </div>
      <div className="col-12 col-sm-6 pl-md-0 mt-2 ">
        <div
          dangerouslySetInnerHTML={{
            __html: card.ctaText.join('')
          }}
        />
      </div>
    </div>
  );
}

function cardPostbotSection(data) {
  const LeftCards = [];
  const rightCard = [];
  data.cards.forEach((card) => {
    if (card.id === 'postbot-ai-illustration') {
      rightCard.push(card);
    } else {
      LeftCards.push(card);
    }
  });

  const anchorTag = data.title
    .replace(/[,']/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

  return (
    <SectionStyles id={data.id || ''}>
      <div className="container ">
        <div className="row mb-4">
          <div className="col-12">
            <h2 id={anchorTag} className="text-center">
              {data.title}
            </h2>
            <p className="text-center">{data.subtitle}</p>
          </div>
        </div>
        <div className="row">
          <LeftColumn className="col-lg-9 col-12">
            <div className="row">
              {LeftCards.map((card) => (
                <LeftCard
                  className="col-lg-4 col-12  mt-4 mt-lg-0"
                  key={pmUuid()}
                >
                  <PricingColumn props={card}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.body.join('')
                      }}
                    />
                    {card.cta && <CTASection card={card} />}
                  </PricingColumn>
                </LeftCard>
              ))}
              <ButtonSectionStyles className="row align-items-center">
                {data.cta && (
                  <BaseButton
                    buttonType={data.cta.buttonType}
                    as="a"
                    src={data.cta.href}
                    target={data.cta.target}
                    id={data.cta.id}
                    ariaLabel={data.cta.ariaLabel}
                    dataTest={data.cta.id}
                    className=""
                  >
                    {data.cta.title}
                  </BaseButton>
                )}
                {data.ctaText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.ctaText.join('')
                    }}
                  />
                )}
              </ButtonSectionStyles>
            </div>
          </LeftColumn>
          <RightColumn className="col-lg-3 col-12 mt-4 mt-lg-0">
            <div className="d-none d-lg-block row justify-content-center align-self-center">
              {rightCard.map((card) => (
                <RightCard className="col-8 " key={pmUuid()}>
                  {card.image && (
                    <img
                      className="img-fluid mt-lg-2"
                      src={card.image.src}
                      alt={card.image.alt}
                    />
                  )}
                </RightCard>
              ))}
            </div>
          </RightColumn>
        </div>
      </div>
    </SectionStyles>
  );
}

export default cardPostbotSection;
