/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import suppress from '../../utils/suppress';

const Switch = styled.div`
  span {
    font-size: 14px;
  }

  .preload {
    padding: 103px 0 0 !important;
  }

  input {
    height: 0.1px;
    width: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    position: relative;
    top: 55px !important;
    height: 42px;
    width: 100%;
    z-index: 3;
  }

  label {
    position: relative;
    width: 280px;
  }

  .toggle {
    display: none;
    text-align: center;
  }

  .slider {
    display: flex;
    position: relative;
    width: 100%;
    padding: 4px 0;
    border: 1px solid #e6e6e6;
    background-color: #f2f2f2;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
  }

  .slider-background {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    transition: left 0.3s;
    border-radius: 0px;
  }
  // monthly
  input + .slider .slider-background {
    position: absolute;
    left: 0%;
    background-color: #ffffff;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #e6e6e6;
    border-left: none;
  }
  // annual
  input:checked + .slider .slider-background {
    left: 50%;
    background-color: #ffffff;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #e6e6e6;
    border-right: none;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #e6e6e6;
  }

  .option {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #6b6b6b;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .active {
    font-weight: 600;
    color: #212121;
  }

  .savings {
    margin: 0;
    font-size: 12px;
    color: #a6a6a6;
  }

  .savings.active {
    color: #027f31;
    font-size: 12px;
    text-shadow: none;
    font-weight: 400;
  }

  #Month {
    left: 0;
  }

  #Annual {
    right: 0;
  }
`;

let toggled;
let value;

const runtime = typeof document === 'object';

const toggleBuyHrefs = (clear, option) => {
  const hrefs = [...document.body.querySelectorAll('[href]')];
  const buyHrefElements = hrefs.filter(
    (h) =>
      h.href.includes('/buy?plan=basic') ||
      h.href.includes('/buy?plan=professional')
  );
  const buyHrefs = buyHrefElements.map((bhe) => bhe.getAttribute('href'));

  buyHrefs.forEach((href) => {
    const elem = document.body.querySelector(`[href='${href}']`);
    const toggledHref =
      (href.match('interval=') && href.replace(clear, option)) ||
      (href.indexOf('?') !== -1 && `${href}&interval=${option}`) ||
      `${href}?interval=${option}`;

    elem.setAttribute('href', toggledHref);
  });
};

const select = (option) => {
  const Annual = document.getElementById('Annual');
  const AnnualSmall = Annual.querySelector('.savings');
  const Month = document.getElementById('Month');
  const changed = option !== value;

  if (changed) {
    switch (option) {
      case 'month':
        Annual.className = Annual.className.replace(' active', '');
        AnnualSmall.className = AnnualSmall.className.replace(' active', '');

        Month.className = `${Month.className} active`;
        toggleBuyHrefs('annual', 'month');

        break;
      case 'annual':
        // Previously, this was the logic for 'annual'

        Month.className = Month.className.replace(' active', '');
        Annual.className = `${Annual.className} active`;

        AnnualSmall.className = `${AnnualSmall.className} active`;

        toggleBuyHrefs('month', 'annual');

        break;
      default:
      // eslint-disable-next-line no-console
    }
  }

  value = option;

  return changed;
};

const toggle = (e) => {
  let { target } = e;
  while (target != null) {
    const option = target.getAttribute('data-option');
    if (option) {
      const update = select(option);

      if (update) {
        document.getElementById('MonthAnnual').click();
      }
      break;
    }
    target = target.parentElement;
  }

  if (!target) {
    const monthAnnual = document.getElementById('MonthAnnual');
    const monthAnnualInput = monthAnnual.querySelector('input');
    const isChecked = monthAnnualInput.checked;
    select((isChecked && 'annual') || 'month');
  }

  if (typeof toggled === 'function') {
    toggled(value);
  }
};
function MonthAnnualToggle(data) {
  setTimeout(() => {
    if (runtime) {
      const oPlanTypeToggle = window.planTypeToggle;

      window.planTypeToggle = (val) => {
        if (typeof oPlanTypeToggle === 'function') {
          oPlanTypeToggle(val);
        }

        if (val === 'tab2') {
          suppress('MonthAnnualToggle', 'none');
        } else {
          suppress('MonthAnnualToggle', 'block');
        }
      };

      const { action, on } = data;

      if (typeof window[action] === 'function') {
        toggled = window[action];
      }

      select('annual'); // Set 'annual' as the default selection

      if (on) {
        document.getElementById('Annual').click();
        window.planTypeToggle('tab2');
      }

      document.querySelector('.toggle').style.display = 'block';
      document.querySelector('.preload').style.display = 'none';
      document.querySelector('.preload').style.padding = '0';
    }
  }, 500);
  return (
    <Switch>
      <div className="container">
        <div className="row mt-5 mb-0">
          <div className="col-12 text-center">
            <h1 className="mb-0">Postman API Platform plans and pricing</h1>
          </div>
        </div>
      </div>
      <div className="preload" />
      <div className="toggle" data-suppress="MonthAnnualToggle">
        <label className="switch" id="MonthAnnual" htmlFor="toggleInput">
          <input onChange={toggle} type="checkbox" defaultChecked />{' '}
          <span className="slider">
            <span className="slider-background" />
            <span
              role="button"
              className="option"
              data-option="month"
              id="Month"
              tabIndex={0}
              onClick={toggle}
              onKeyDown={toggle}
            >
              Monthly
            </span>
            <span
              className="option"
              tabIndex={0}
              data-option="annual"
              id="Annual"
              role="button"
              onClick={toggle}
              onKeyDown={toggle}
            >
              Annual
              <span data-option="annual" className="d-block savings">
                (Save up to 25%)
              </span>
            </span>
          </span>
        </label>
      </div>
    </Switch>
  );
}

export default MonthAnnualToggle;
