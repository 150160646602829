/* eslint-disable react/function-component-definition */
import React from 'react';

/*
 * User-defined form title, subtitle and hardcoded fail text
 * "form": {
 *    "title": "",
 *    "subtitle": [""],
 *   }
 *
 */
export const FormTitle = (data) => {
  const { title, mode } = data;
  return title && title !== undefined ? (
    <h3 className={`${mode === 'dark' && 'text-white'} text-left`}>{title}</h3>
  ) : null;
};

export const FormSubtitle = (data) => {
  const { subtitle, mode } = data;
  return subtitle && subtitle.length > 0 ? (
    <div
      className={`${mode === 'dark' && 'text-white'}  text-left mb-3`}
      dangerouslySetInnerHTML={{
        __html: subtitle.join('')
      }}
    />
  ) : null;
};
export const FormFailText = (data) => {
  const {mode} = data
  return (
<p className="subtitle" style={{ color: mode === 'dark' ? '#FFFFFF' : '#6b6b6b' }}>
  Sorry! Something went wrong loading the form. Please refresh your browser,
  disable ad-blockers or try again later.
</p>
  );
};
