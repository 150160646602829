const getHref = (a) => {
  let { href } = a;
  const isLocal = href && !!href.match(document.location.host);

  if (isLocal) {
    href = href.split(document.location.host);
    href.shift();
    href = href.join('/');
  }

  return {
    url: href,
    isLocal
  };
};

export default getHref;
