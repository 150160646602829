import React from 'react';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';

const Content = styled.div`
  text-align: center;
`;

const runtime = typeof document === 'object';

const groupIdentify = () => {
  window.pmt('ampli').groupIdentify('test group', 'browser-js-ampli', {
    requiredBoolean: true
  });
};

const isPricingTestPage =
  runtime && document.location.pathname.match('pr1c1ng-t3st');

const amplitudeExample = (data) => {
  if (runtime) {
    const enterpriseContactSales = document.getElementById(
      'enterprise-contact-sales-1'
    );

    if (isPricingTestPage && enterpriseContactSales) {
      if (
        !document.body.getAttribute('data-test_pricingPageContactSalesClicked')
      ) {
        document.body.addEventListener(
          'mousedown',
          (e) => {
            if (e.target.innerText.toLowerCase() === 'contact sales') {
              window
                .pmt('ampli')
                .pricingPageContactSalesClicked(window.pmt('userId'));
              e.preventDefault();
            }
          },
          true
        );

        document.body.setAttribute(
          'data-test_pricingPageContactSalesClicked',
          'true'
        );
      }
    }
  }

  return (
    (isPricingTestPage && (
      <>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <Content className="col-12 col-md-4">
                  <div className={data.type}>
                    <button onClick={groupIdentify} type="button">
                      Group Identify
                    </button>
                  </div>
                </Content>
              </div>
            </div>
          </div>
        </div>
        {(data.divider === true && <Divider />) || null}
      </>
    )) ||
    null
  );
};

export default amplitudeExample;
