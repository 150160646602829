import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton,
  SectionHeader
} from 'aether-marketing';

// import { PricingLink } from './pricing/PricingLinks';

import ModalOverlay from './v5_modal-overlays/ModalOverlay';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }

  .language-powershell {
    margin-bottom: 5rem !important;
  }
`;

function SideBySideDataServiceSection({
  backgroundColor,
  paddingBottom,
  layout,
  body,
  ctaButton,
  ctaLinks,
  media,
  modal,
  divider,
  title,
  headerBody,
  textAlign
}) {
  // Determine column widths
  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-5';
      colText = 'col-lg-6 offset-lg-1';
      break;
    case '2R':
      colMedia = 'col-lg-4';
      colText = 'col-lg-7 offset-lg-1';
      break;
    case '3R':
      colMedia = 'col-lg-7';
      colText = 'col-lg-4 offset-lg-1';
      break;
    case '1L':
      colText = 'col-lg-6 offset-lg-0 order-lg-0 order-1';
      colMedia = 'col-lg-5 offset-lg-1 order-lg-1 order-0';
      break;
    case '2L':
      colText = 'col-lg-7 offset-lg-0 order-lg-0 order-1';
      colMedia = 'col-lg-4 offset-lg-1 order-lg-1 order-0';
      break;
    case '3L':
      colText = 'col-lg-4 offset-lg-0 order-lg-0 order-1';
      colMedia = 'col-lg-7 offset-lg-1 order-lg-1 order-0';
      break;
    default:
      // everything else defaults to layout 1R
      colMedia = 'col-lg-5';
      colText = 'col-lg-6 offset-lg-1';
  }

  const key = 'data-service="';
  const bodyStr = body.join('');
  const bodyHasDataService = Array.isArray(body) && bodyStr.indexOf(key) !== -1;

  if (bodyHasDataService) {
    const service = bodyStr.split(key).pop().split('"').shift();
    /* eslint-disable import/no-dynamic-require, global-require */
    const { content } = require(`../../../bff-data/${service}`);
    /* eslint-enable */

    if (Array.isArray(content)) {
      content.forEach((item) => {
        body.push(item);
      });

      body.push('<br/>');
    }
  }

  const textSection = (
    <div className={`col-sm-12 col-md-8 offset-md-2 ${colText} `}>
      <div className="text-left">
        <ContentContainer>
          {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
        </ContentContainer>
        {ctaLinks &&
          Array.isArray(ctaLinks) &&
          ctaLinks.map((cta) => (
            <p key={pmUuid()}>
              <BaseLink
                linkType={cta.type}
                src={cta.src}
                className={cta.className && cta.className}
                alternative={cta.alternative ? cta.alternative : false}
                target={cta.target ? cta.target : 'same-tab'}
                gaCategory={cta.gaCategory && cta.gaCategory}
                gaLabel={cta.gaLabel && cta.gaLabel}
                id={cta.id && cta.id}
                ariaLabel={cta.ariaLabel && cta.ariaLabel}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseLink>
            </p>
          ))}
        {ctaButton &&
          Array.isArray(ctaButton) &&
          ctaButton.map((cta) => (
            <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
              <BaseButton
                buttonType={cta.buttonType}
                as="a"
                src={cta.src}
                target={cta.target}
                id={cta.id}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                className={cta.type === 'inline' ? 'pl-0' : null}
                modal={modal || null}
                icon={cta.icon}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseButton>
            </div>
          ))}
      </div>
    </div>
  );

  const mediaSection = (
    <div
      className={`col-12 mb-5 mb-lg-0 ${colMedia} align-items-center justify-content-center ${
        (!bodyHasDataService && 'd-flex') || ''
      }`}
    >
      <img
        alt={media.alt}
        height="576"
        src={media.src}
        width="700"
        className={
          media.shadow === true
            ? `media-shadow-v6 img-fluid w-100`
            : `img-fluid w-100`
        }
      />
    </div>
  );

  return (
    <>
      <SectionStyles
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
      >
        {modal && modal.type === 'youtube' && (
          <ModalOverlay type={modal.type} src={modal.src} />
        )}
        <div className="container">
          {title ? (
            <div className="row mb-5">
              <SectionHeader
                title={title}
                body={headerBody}
                textAlign={textAlign}
                bottomMargin="0"
              />
            </div>
          ) : null}
          {['', '1R', '2R', '3R'].some((letter) => letter === layoutString) && (
            <div className="row">
              {mediaSection}
              {textSection}
            </div>
          )}
          {['1L', '2L', '3L'].some((letter) => letter === layoutString) && (
            <div className="row">
              {textSection}
              {mediaSection}
            </div>
          )}
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

const sideBySideDataService = (data) => {
  const {
    layout,
    backgroundColor,
    paddingBottom,
    media,
    ctaButton,
    ctaLinks,
    body,
    modal,
    divider,
    title,
    headerBody,
    textAlign
  } = data;

  return (
    <SideBySideDataServiceSection
      layout={layout}
      backgroundColor={
        backgroundColor !== '' && backgroundColor ? backgroundColor : '#FFFFFF'
      }
      paddingBottom={paddingBottom}
      title={title}
      textAlign={textAlign}
      headerBody={headerBody}
      body={body}
      ctaButton={ctaButton || null}
      ctaLinks={ctaLinks || null}
      media={media}
      modal={modal}
      divider={divider}
    />
  );
};

SideBySideDataServiceSection.propTypes = {
  layout: PropTypes.string,
  textAlign: PropTypes.string,
  backgroundColor: PropTypes.string,
  paddingBottom: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  headerBody: PropTypes.arrayOf(PropTypes.string),
  ctaButton: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  ctaLinks: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    shadow: PropTypes.bool
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    src: PropTypes.string
  }),
  divider: PropTypes.bool
};

SideBySideDataServiceSection.defaultProps = {
  layout: '1R',
  backgroundColor: null,
  paddingBottom: null,
  body: null,
  textAlign: null,
  headerBody: null,
  title: null,
  ctaButton: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  ctaLinks: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  modal: null,
  divider: null
};

export default sideBySideDataService;
