import React from 'react';
import styled from 'styled-components';
import { BaseLink } from 'aether-marketing';

const Section = styled.section`
  /* padding: 30px  0; */
`;

const subprocessorTable = (data) => (
  <Section style={{ backgroundColor: data.backgroundColor }}>
    <div className="container-fluid px-0">
      <div className="row mb-3">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-striped mb-2">
              <thead>
                <tr>
                  {data.titles.map((title) => (
                    <th scope="col" key={title}>
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.items.map((item) => (
                  <tr key={item.vendor}>
                    <th scope="row">{item.vendor}</th>
                    {item.purpose && <td>{item.purpose}</td>}
                    <td>{item.location}</td>
                    {item.link && (
                      <td>
                        <BaseLink
                          src={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.link}
                        </BaseLink>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data.footer && <p>{data.footer}</p>}
        </div>
      </div>
    </div>
  </Section>
);

export default subprocessorTable;
