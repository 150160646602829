import React from 'react';
import styled from 'styled-components';
import {
  VideoComponent,
  BaseButton,
  UnorderedListStyles
} from 'aether-marketing';
// Text Components
import PropTypes from 'prop-types';
import WebinarHeroText from '../../texts/WebinarHeroText';
import AiredText from '../../texts/AiredText';
import Text from '../../texts/Text';
// Cards
import SpeakerCard from '../../cards/SpeakerCard';
import ThumbnailCards from '../../cards/ThumbnailCards';

const Section = styled.section`
  .content p {
    margin-bottom: 25px;
  }
  padding: 60px 0;

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
`;

class WebinarRecap extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  render() {
    const { data } = this.state;

    return (
      <Section
        style={{
          backgroundImage: data.backgroundImage,
          padding: '0 0 30px 0'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <VideoComponent
                    src={data.video.src}
                    controls={data.video.controls}
                    autoPlay={data.video.autoPlay}
                    hostType={data.video.hostType}
                    caption={data.video.caption}
                    paddingBottom={data.video.paddingBottom}
                    paddingTop={data.video.paddingTop}
                    loop={data.video.loop}
                    muted={data.video.muted}
                    container={data.video.container}
                    title={data.video.title}
                    border="fullwrap"
                    mediaLayout={data.video.mediaLayout}
                  />
                </div>
              </div>
              <WebinarHeroText data={data} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 order-lg-0 order-1">
              <div className="content">
                <Text data={data} />
              </div>
              <BaseButton
                buttonType="secondary"
                as="a"
                src={data.src}
                target="new-tab-external-company"
              >
                {data.text}
              </BaseButton>
              <div className="row mt-5 mb-5">
                <div className="col-12">
                  <AiredText data={data} />
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-lg-1 order-0">
              <SpeakerCard data={data} />
            </div>
          </div>
          {data.additionalText && (
            <div className="row mb-4">
              <div className="col-12">
                <h2>{data.additionalText}</h2>
              </div>
            </div>
          )}
          {data.thumbnailCards && (
            <div className="row">
              <ThumbnailCards data={data} />
            </div>
          )}
        </div>
      </Section>
    );
  }
}

const webinarRecapHero = (data) => (
  <div>
    <WebinarRecap data={data} />
  </div>
);

WebinarRecap.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export default webinarRecapHero;
