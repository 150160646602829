import axios from 'axios';
import qs from 'qs';
import DOMPurify from 'dompurify';

function disinfect(userInput) {
  return DOMPurify.sanitize(userInput);
}

function addFormValuesToMarketo(data, inputs) {
  const { id } = data.form.marketoForm;
  const MY_FORM = document.getElementById(`form-${id}`);
  const MARKETO_FORM = document.getElementById(`mktoForm_${id}`);
  window.MktoForms2.whenReady((form) => {
    Object.keys(inputs).forEach((input) => {
      if (MY_FORM && MARKETO_FORM) {
        // handles string, number, email, textarea types
        try {
          form.setValues({
            [input]: MY_FORM[input] && disinfect(MY_FORM[input].value)
          });

          // Single checkbox
          try {
            const SINGLE_CHECKBOX = document.getElementById(`${input}_`);
            const MARKETO_CHECKBOX = document.getElementById(`${input}`);
            if (
              SINGLE_CHECKBOX &&
              MARKETO_CHECKBOX &&
              SINGLE_CHECKBOX.checked !== MARKETO_CHECKBOX.checked
            ) {
              MARKETO_CHECKBOX.click();
            }
          } catch (error) {
            /* eslint-disable */
            console.log(error);
            /* eslint-enable */
          }
          // Multi checkboxes
          try {
            const MULTICHECKBOXES = MY_FORM.querySelectorAll(
              `input[type="checkbox"][name='${input}']`
            );
            const MK_MULTICHECKBOXES = MARKETO_FORM.querySelectorAll(
              `input[type="checkbox"][name='${input}']`
            );
            for (let i = 0; i < MULTICHECKBOXES.length; i += 1) {
              if (
                MULTICHECKBOXES[i].checked &&
                !MK_MULTICHECKBOXES[i].checked
              ) {
                MK_MULTICHECKBOXES[i].click();
              }
            }
          } catch (error) {
            // Checkbox for old GDPR Consent for forms before 2021
            const mktoCheckbox = document.querySelectorAll(
              'input[name="gDPRConsent"]'
            );
            if (mktoCheckbox.length !== 0) {
              mktoCheckbox.forEach((box) => {
                if (input.OldOptInConsent) {
                  box.click(this);
                }
              });
            }
            // Checkbox for GDPR Consent
            const TermsValue = document.querySelectorAll(
              'input[name="gDPROptIn"]'
            );
            // Run only if GDPR field is present
            if (TermsValue.length > 0) {
              // Success for First and Second GDPR Checkbox on page
              if (input.OptInConsent || input.OptInConsent2) {
                if (input.OptInConsent && TermsValue[0]) {
                  TermsValue[0].click();
                }
                if (input.OptInConsent2 && TermsValue[2]) {
                  TermsValue[2].click();
                }
              }
              // Fail
              if (!input.OptInConsent || !input.OptInConsent2) {
                if (!input.OptInConsent && TermsValue[1]) {
                  TermsValue[1].click();
                }
                if (!input.OptInConsent2 && TermsValue[3]) {
                  TermsValue[3].click();
                }
              }
            }
          }
          // Radio buttons
          try {
            const RADIO_BUTTONS = MY_FORM.querySelectorAll(
              `input[type="radio"][name='${input}']`
            );
            const MKTO_RADIO_BUTTONS = MARKETO_FORM.querySelectorAll(
              `input[type="radio"][name='${input}']`
            );
            for (let i = 0; i < RADIO_BUTTONS.length; i += 1) {
              if (RADIO_BUTTONS[i].checked !== MKTO_RADIO_BUTTONS[i].checked) {
                MKTO_RADIO_BUTTONS[i].click();
              }
            }
          } catch (error) {
            /* eslint-disable */
            console.log(error);
            /* eslint-enable */
          }
          return form;
        } catch (error) {
          /* eslint-disable */
          console.log(error)
          /* eslint-enable */
        }
      }
      return form;
    });
  });
}

function handleFormSubmission(id, data, inputs, setSubmitting, validSubmit) {
  // queue custom form and marketo form
  const getFormID = document.getElementById(`form-${id}`);
  const myFormSubmit = [...getFormID.querySelectorAll('button')].pop();
  const mktoSubmit = document.querySelector(`#mktoForm_${id} .mktoButton`);
  if (window.MktoForms2) {
    addFormValuesToMarketo(data, inputs);

    setTimeout(() => {
      mktoSubmit.click(this);
      myFormSubmit.disabled = true;
      myFormSubmit.innerHTML = 'Thank you';
      getFormID.className += 'submitted';
      mktoSubmit.click(this);
      setSubmitting(true);
    }, 400);
    validSubmit(true);
  } else {
    const req = {
      formVid: id,
      munchkinId: '067-UMD-991'
    };
    addFormValuesToMarketo(data, inputs);
    axios
      .post('/mktosubmit', qs.stringify(req), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01'
        }
      })
      .then((response) => {
        window.location.href = response.data.followUpUrl;
      })
      .catch((error) => {
        /* eslint-disable */
        console.log(error);
        /* eslint-enable */
      });
  }
}

export default handleFormSubmission;
