import React from 'react';
import styled from 'styled-components';
/* eslint-enable */ // root relative link
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const FooterStyles = styled.section`
  background-color: #210b30;
  color: white;
  padding-top: 60px;

  /* height: 440px; */
  overflow-y: clip;
  a {
    color: white;
    text-decoration: none;
  }
  .link:hover {
    border-bottom: 1px solid white !important;
  }

  .socials:hover {
    color: #ff6c37;
  }
  .socials:active {
    color: #e05320;
  }
  .gradientText {
    background-image: linear-gradient(90.02deg, #ff6c37 20.03%, #c64dda 50.03%);
    transition: background-image 8s ease-in-out;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ul {
    padding-inline-start: 13px;
  }

  li {
    font-size: 13px;
  }
  li:first-of-type {
    list-style-type: none;
  }
  @media (max-width: 375px) {
    ul li:nth-child(3) {
      list-style-type: none; // Remove the default bullet point
    }
    ul li:nth-child(3)::before {
      content: '•'; // Add a custom bullet point
      display: inline-block;
      width: 1em;
    }
  }
  .planet-container {
    overflow-y: clip;
  }

  .planet {
    background: linear-gradient(180deg, #160720 0%, #210b30 100%);
    background-image: url('https://voyager.postman.com/post-con/postcon-planet.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 300px;
    right: 0%;
  }

  @media (min-width: 600px) {
    .planet {
      bottom: 0px;
      height: 350px;
      left: -180px;
      background-size: cover;
    }
  }
  @media (min-width: 1200px) {
    .planet {
      left: -350px;
    }
  }
`;
const LogoWrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 90px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  &:hover {
    &.hover {
      transform: translateY(-0.5rem);
      transition: 0.3s linear;
    }
  }
`;
function PostconFooter({ items }) {
  const currentDate = new Date();
  const data = {
    media: [
      {
        src: 'https://voyager.postman.com/logo/external/amazon-aws-logo-white.svg',
        alt: 'AWS',
        smallLogo: true
      },
      {
        src: 'https://voyager.postman.com/logo/external/hashicorp-logo.svg',
        alt: 'HashiCorp'
      },
      {
        src: 'https://voyager.postman.com/logo/external/slack-white-logo.svg',
        alt: 'Slack'
      },
      {
        src: 'https://voyager.postman.com/logo/external/1password-logo-white.svg',
        alt: '1Password'
      },
      {
        src: 'https://voyager.postman.com/logo/external/github-logo-word-white.png',
        alt: 'GitHub',
        smallLogo: true
      },
      {
        src: 'https://voyager.postman.com/logo/external/cprime-logo-white.svg',
        alt: 'CPrime'
      },
      {
        src: 'https://voyager.postman.com/logo/external/discord-logo-white.svg',
        alt: 'Discord'
      },
      {
        src: 'https://voyager.postman.com/logo/external/microsoft-white-logo.svg',
        alt: 'Microsoft'
      },
      {
        src: 'https://voyager.postman.com/logo/external/circle-ci-logo-white.svg',
        alt: 'CircleCI'
      }
    ]
  };
  return (
    <FooterStyles className="">
      <div className="container">
        <div className="col-12">
          <div className="row">
            <div className="col-12 mb-4">
              <h2 className="h1 text-center">POST/CON partners</h2>
            </div>
          </div>
        </div>
        <div className="col-12 mb-5">
          <div className="row justify-content-center">
            {data.media.map((img) => (
              <div
                className={`col-md-6 mb-5  ${
                  img.smallLogo ? 'col-lg-3' : 'col-lg-4 px-lg-5'
                }`}
                key={pmUuid()}
              >
                <LogoWrapper
                  style={{
                    backgroundImage: `url(${img.src})`
                  }}
                  title={img.alt}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 order-lg-0 order-1">
            <div className="planet-container">
              <div className="planet" />
            </div>
          </div>
          <div className="col-lg-6 order-lg-1 order-0 mt-5">
            <p className="h3 gradientText mb-4">
              Follow #postcon updates on our socials
            </p>
            <div className="row mb-5">
              {items &&
                items.map((link) => (
                  <div key={pmUuid()} className="col-2 col-md-1">
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      className="socials"
                    >
                      {link.icon && (
                        <div dangerouslySetInnerHTML={{ __html: link.icon }} />
                      )}
                    </a>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="row">
                  <li>
                    <a href="/legal/privacy-policy/" className="mr-4 link">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="/legal/terms/" className="mr-4 link">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/events-code-of-conduct/"
                      className="mr-4 link"
                    >
                      Code of Conduct
                    </a>
                  </li>
                  <li className="ml-2 ml-md-0">
                    <a href="/legal/cookies/" className="link">
                      Cookie notice
                    </a>
                  </li>
                </ul>
                <p className="small text-white">
                  &copy;{currentDate.getFullYear()}, Postman Inc. or its
                  affiliates. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}

PostconFooter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string
    })
  )
};
PostconFooter.defaultProps = {
  items: null
};

export default PostconFooter;
