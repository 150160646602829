import React from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */

import { SideWrap, SideLink } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

function SidebarNavLinks({ data }) {
  const backgroundColor = data.backgroundColor ? data.backgroundColor : null;
  const padding = data.padding ? data.padding : null;
  const className = data.className
    ? data.className
    : 'text-center text-lg-left';

  return (
    <SideWrap className={className} style={{ backgroundColor, padding }}>
      {data.sidetitle && <h4 className="mb-3">{data.sidetitle}</h4>}
      {data.leftLinks
        ? data.leftLinks.map((link) =>
            link.active ? (
              <SideLink
                className="text-dark text-left current-page"
                key={pmUuid()}
                href={link.url}
              >
                {link.title}
              </SideLink>
            ) : (
              <SideLink className="text-left" key={pmUuid()} href={link.url}>
                {link.title}
              </SideLink>
            )
          )
        : ''}
    </SideWrap>
  );
}

const sideBar = (data) => <SidebarNavLinks data={data} />;

export function Sidebar(data) {
  return <SidebarNavLinks data={data} />;
}

SidebarNavLinks.propTypes = {
  data: PropTypes.shape({
    leftLinks: PropTypes.arrayOf(PropTypes.shape({})),
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    sidetitle: PropTypes.string,
    className: PropTypes.string
  }).isRequired
};

export default sideBar;
