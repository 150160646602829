import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import suppress from '../../../utils/suppress';
/* eslint-enable */

import { PricingLink } from './PricingLinks';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${(props) => props.theme.colors.grey_05};

  .cards-margin {
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    .cards-margin:nth-child(1) {
      margin-bottom: 30px;
    }
  }
`;

const Topper = styled.div`
  border-top-left-radius: ${(props) => props.theme.borderRadius.medium};
  border-top-right-radius: ${(props) => props.theme.borderRadius.medium};
  background-color: ${(props) => props.theme.colors.grey_20};
  padding: 24px;
`;
const CardContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  outline: 1px #979797;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 24px;
  background: 0 0;
  background-color: white;
  position: static;
  width: 100%;
  border-radius: inherit;

  p.small {
    color: ${(props) => props.theme.colors.grey_90};
  }
  button {
    border: none;
    background: none;
  }
  button:focus {
    outline: none;
  }
`;

const BorderRightResponsive = styled.div`
  border-right: 0;
  p {
    /* @media screen and (min-width: 1199px) {
      white-space: nowrap;
    } */

    &.mb-16px {
      margin-bottom: 16px;
    }
  }
`;

const runtime = typeof document === 'object';

function Card({ data }) {
  // const { data, planName } = this.state;
  const { event } = data;
  const itemTooltip = (event && event.itemTooltip) || '';

  return (
    <div
      className="col-sm-12 col-md-6 cards-margin"
      data-suppress={(data.suppress && 'AddOnsSection') || null}
    >
      <CardContainer>
        <Topper className="d-flex justify-content-between">
          <h3 className="h4 mb-0">{data.title}</h3>
          {data.cta && (
            <div>
              <PricingLink
                type={data.cta.type}
                href={data.cta.href}
                className={data.cta.className && data.cta.className}
                alternative={
                  data.cta.alternative ? data.cta.alternative : false
                }
                target={data.cta.target ? data.cta.target : 'same-tab'}
                gaCategory={data.cta.gaCategory && data.cta.gaCategory}
                gaLabel={data.cta.gaLabel && data.cta.gaLabel}
                id={data.cta.id && data.cta.id}
                ariaLabel={data.cta.ariaLabel && data.cta.ariaLabel}
                event={data.cta.event}
              >
                {data.cta.text}
              </PricingLink>
            </div>
          )}
        </Topper>
        {data.items ? (
          <ContentWrapper>
            {data.items[0] && (
              <div className="row pb-3">
                <BorderRightResponsive className="col-10 col-sm-9 col-xl-8 text-left">
                  <p className="strong mb-16px">{data.items[0].title}</p>
                  <div className="d-flex align-items-start mb-3 mb-lg-0">
                    <img
                      className="mr-2 d-flex align-self-center"
                      src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                      alt="Available. Icon."
                    />

                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: data.items[0].body
                      }}
                    />

                    {data.items[0].popover && (
                      <button
                        type="button"
                        tabIndex="0"
                        className="pm-tooltip ml-1"
                        // role="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={data.items[0].popover}
                      >
                        <img
                          src="https://voyager.postman.com/icon/i-info-icon-postman.svg"
                          alt="Question. Icon."
                          style={{ height: '15px', opacity: '0.7' }}
                          onFocus={() => {
                            window.pmt('amplitudeEvent', [
                              `${itemTooltip}:${data.items[0].body}`
                            ]);
                          }}
                          onMouseOver={() => {
                            window.pmt('amplitudeEvent', [
                              `${itemTooltip}:${data.items[0].body}`
                            ]);
                          }}
                        />
                      </button>
                    )}
                  </div>
                </BorderRightResponsive>
                <div className="col-12 col-sm-3 col-xl-4 text-left text-sm-right d-flex align-items-center flex-sm-column align-items-sm-end">
                  <p className="h4 mr-2 mr-sm-0">{data.items[0].price}</p>
                  <p className="small mb-0">{data.items[0].pricingInfo}</p>
                </div>
              </div>
            )}

            {data.items[1] && (
              <div className="row pt-3 border-top">
                <BorderRightResponsive className="col-12 col-sm-9 col-xl-8 text-left">
                  <p className="strong mb-16px">{data.items[1].title}</p>
                  <div className="d-flex align-items-center mb-3 mb-lg-0">
                    <img
                      className="mr-2 d-flex align-self-center"
                      src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                      alt="Available. Icon."
                    />
                    <p className="mb-0">
                      {data.items[1].body}
                      {data.items[1].popover && (
                        <button
                          type="button"
                          tabIndex="0"
                          className="pm-tooltip ml-1"
                          // role="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={data.items[1].popover}
                        >
                          <img
                            src="https://voyager.postman.com/icon/i-info-icon-postman.svg"
                            alt="Question. Icon."
                            style={{ height: '15px', opacity: '0.7' }}
                            onFocus={() => {
                              window.pmt('amplitudeEvent', [
                                `${itemTooltip}:${data.items[0].body}`
                              ]);
                            }}
                            onMouseOver={() => {
                              window.pmt('amplitudeEvent', [
                                `${itemTooltip}:${data.items[1].body}`
                              ]);
                            }}
                          />
                        </button>
                      )}
                    </p>
                  </div>
                </BorderRightResponsive>
                <div className="col-12 col-sm-3 col-xl-4 text-left text-sm-right d-flex align-items-center flex-sm-column align-items-sm-end">
                  <p className="h4 mr-2 mr-sm-0">{data.items[1].price}</p>
                  <p className="small mb-0">{data.items[1].pricingInfo}</p>
                </div>
              </div>
            )}
          </ContentWrapper>
        ) : (
          ''
        )}
      </CardContainer>
    </div>
  );
}

let priceToggle;
let planTypeToggle;
let viewed;

function AddOnsPricingCards(data) {
  const { event, footer, items, title } = data;
  const target = runtime && document.getElementById('AddOnsSection');

  if (runtime) {
    const oPlanTypeToggle = window.planTypeToggle;

    window.planTypeToggle = (value) => {
      if (typeof oPlanTypeToggle === 'function') {
        oPlanTypeToggle(value);
      }

      planTypeToggle = value;

      const display =
        ((priceToggle === 'month' || planTypeToggle === 'tab2') && 'none') ||
        'inline-block';

      suppress('AddOnsSection', display);
    };

    const oPriceToggle = window.priceToggle;

    window.priceToggle = (value) => {
      if (typeof oPriceToggle === 'function') {
        oPriceToggle(value);
      }

      priceToggle = value;

      const display =
        ((priceToggle === 'month' || planTypeToggle === 'tab2') && 'none') ||
        'inline-block';

      suppress('AddOnsSection', display);
    };

    if (target && !viewed) {
      target.setAttribute('data-event-view', event);
      viewed = true;
    }
  }

  return (
    <Section id="AddOnsSection">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          {items.map((card, index) => (
            <Card data={card} index={index} key={pmUuid()} />
          ))}
        </div>
        <div
          key={pmUuid()}
          className="text-center mt-4"
          dangerouslySetInnerHTML={{ __html: footer.body }}
        />
      </div>
    </Section>
  );
}

Card.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.arrayOf(
      PropTypes.shape({
        itemTooltip: PropTypes.string
      })
    ),
    suppress: PropTypes.bool,
    title: PropTypes.string,
    cta: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.array
      ])
    ).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        popover: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string,
        price: PropTypes.string,
        pricingInfo: PropTypes.string
      })
    )
  }).isRequired
};

export default AddOnsPricingCards;
