/* global $ */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import { BaseButton } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const StickyContainer = styled.div`
  background-color: #f9f9f9;
  position: fixed;
  top: 53px;
  display: block;
  width: 100%;
  z-index: 1;
  text-align: center;
  padding: 10px 0;
  opacity: 0;
  transition: 0.3s;
  line-height: 1.75;

  #contact-sales {
    border: none;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 24%) inset;
    background-color: #ffffff;
    padding: 6px 12px !important;
    color: #212121 !important;
    font-size: 1.4rem;

    :hover {
      box-shadow: 0 0 0 1px rgb(0 0 0 / 64%) inset;
      border: none !important;
    }
  }
`;

class StickyBarClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      visible: false,
      dataAttributeStickyTrigger: props.data.dataAttributeStickyTrigger
        ? props.data.dataAttributeStickyTrigger
        : null
    };
  }

  componentDidMount() {
    const { dataAttributeStickyTrigger } = this.state;
    if (dataAttributeStickyTrigger) {
      window.addEventListener('scroll', () => {
        // finding the data-attribute of the section in the section below,
        // subtracting 80px to clear top sticky nav, and nudge it up a bit. personal preference.  Feels more fluid
        // This puts us slightly above the top of the next section.
        // But adjust the amount subtracted to move the bar appear sooner, as needed to taste.
        if (
          $(window).scrollTop() >=
          $(`section[data-sticky='${dataAttributeStickyTrigger}']`).offset()
            .top -
            80
        ) {
          // This second if checks if there is an end element, and ifwe are passed it.  If so, hide sticky, or else keep it visible.
          if ($(`section[data-end-sticky='${dataAttributeStickyTrigger}']`)) {
            if (
              $(window).scrollTop() >=
              $(
                `section[data-end-sticky='${dataAttributeStickyTrigger}']`
              ).offset().top -
                80
            ) {
              this.setState(() => ({
                visible: false
              }));
            } else {
              this.setState(() => ({
                visible: true
              }));
            }
          } else {
            // If there isn't an end element, show the sticky.
            this.setState(() => ({
              visible: true
            }));
          }
        } else {
          // Else if we are not passed the starting point, hide.
          this.setState(() => ({
            visible: false
          }));
        }
      });
    }
  }

  render() {
    const { data } = this.state;
    const { visible } = this.state;
    let opacityValue = 0;
    if (visible) {
      opacityValue = 100;
    } else {
      opacityValue = 0;
    }
    return (
      <StickyContainer style={{ opacity: opacityValue }}>
        {data.ctaButton &&
          Array.isArray(data.ctaButton) &&
          data.ctaButton.map((cta) => (
            <div className="text-center" key={pmUuid()}>
              {cta.body}&nbsp;&nbsp;&nbsp;&nbsp;
              <BaseButton
                buttonType={cta.buttonType}
                as="a"
                src={cta.src}
                target={cta.target}
                id={cta.id}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                className={cta.type === 'inline' ? 'pl-0' : null}
                icon={cta.icon}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseButton>
            </div>
          ))}
      </StickyContainer>
    );
  }
}

const stickyBar = (data) => {
  return <StickyBarClass data={data} />;
};

StickyBarClass.propTypes = {
  data: PropTypes.shape([
    PropTypes.shape({
      type: PropTypes.string,
      ctaButton: PropTypes.arrayOf({
        body: PropTypes.string,
        buttonType: PropTypes.string,
        text: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string
      }),
      dataAttributeStickyTrigger: PropTypes.string
    })
  ]).isRequired
};

export default stickyBar;
