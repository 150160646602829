import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */
import Card from './pricingCards';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
// Together with pricingCards

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white} !important;
  padding: 30px 0 0 0;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }
`;

const runtime = typeof document === 'object';

class PricingPage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };

    if (runtime) {
      if (window.pmt('ampli')) {
        setTimeout(() => {
          document
            .getElementById('PlansSection')
            .setAttribute('data-event-view', data.event);
        }, 1);
      }
    }
  }

  render() {
    const { data } = this.state;
    return (
      <Section id="PlansSection">
        <div className="container">
          <div className="row no-gutters justify-content-center">
            {data.items.map((item) => (
              <Card key={pmUuid()} data={item} />
            ))}
          </div>
          <p className="small text-right mt-0" />
        </div>
      </Section>
    );
  }
}

const platformPricingSection = (data) => (
  <div>
    <PricingPage data={data} />
  </div>
);

PricingPage.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.string
  }).isRequired
};

export default platformPricingSection;
