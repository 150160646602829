import * as Yup from 'yup';
import CustomValidation from '../validation/CustomValidation';
import DefaultValidation from '../validation/DefaultValidation';

function applySchema(name, type, required) {
  let schema;
  // custom schema for unique fields
  if (CustomValidation[name] && required) {
    schema = CustomValidation[name].required;
  } else if (CustomValidation[name] && !required) {
    schema = CustomValidation[name].optional;
    // default schema for standard fields
  } else if (DefaultValidation[type] && required) {
    schema = DefaultValidation[type].required;
  } else if (DefaultValidation[type] && !required) {
    schema = DefaultValidation[type].optional;
  }
  return schema;
}
const validateFields = (data) => {
  const ValidationSchema = {};
  data.map((field) => {
    const { Name, Datatype, IsRequired } = field;
    ValidationSchema[Name] = applySchema(Name, Datatype, IsRequired);
    return ValidationSchema;
  });
  return ValidationSchema;
};

function handleErrorValidation(data) {
  return Yup.object().shape(validateFields(data));
}

export default handleErrorValidation;

/*
  Resources:
  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
  https://formik.org/docs/guides/validation#validationschema
  https://github.com/jquense/yup
  https://stackoverflow.com/questions/65613573/yup-how-to-validate-field-only-when-it-exists
*/
