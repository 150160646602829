import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { BaseButton, SectionHeader } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  padding: 30px 0 60px 0;
`;

const overheadImageWithIconSection = (data) => {
  const {
    cta,
    id,
    desktop,
    mobile,
    items,
    title,
    body,
    link,
    backgroundColor
  } = data;
  return (
    <div style={{ padding: '60x 0' }}>
      <div className="container text-center" id={id}>
        <div className="row">
          <div className="col-12">
            <img
              className="img-fluid d-md-block d-none"
              src={desktop.src}
              alt={desktop.alt}
            />
            <img
              className="img-fluid d-md-none d-block"
              src={mobile.src}
              alt={mobile.alt}
            />
          </div>
        </div>
      </div>
      <Section style={{ backgroundColor }}>
        <div className="container">
          {title && (
            <div className="row">
              <SectionHeader
                title={title}
                body={body}
                link={link}
                textAlign="center"
                bottomMargin="30px"
              />
            </div>
          )}
          <div className="row">
            {items.map((item) => (
              <div
                className="col-lg-3 col-sm-12 text-center mb-3"
                key={pmUuid()}
              >
                <img
                  className="mb-3"
                  height="65"
                  width="90"
                  src={item.media.url}
                  alt={item.media.alt}
                />
                {item.title && <h4>{item.title}</h4>}

                {/* eslint-disable react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: item.body }} />
                {/*  eslint-enable */}
              </div>
            ))}
          </div>
          {cta && cta.text && cta.src && (
            <div className="row mt-3">
              <div className="col-12 text-center">
                <BaseButton
                  buttonType="primary"
                  as="a"
                  src={cta.src}
                  target={cta.target}
                  id={cta.id ? cta.id : null}
                  gaLabel={cta.gaLabel ? cta.gaLabel : null}
                  gaCategory={cta.gaCategory ? cta.gaCategory : null}
                >
                  {cta.text}
                </BaseButton>
              </div>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default overheadImageWithIconSection;
