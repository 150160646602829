import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: '0px';
  background-clip: border-box;
  background-color: ${(props) => props.theme.colors.grey_00};
  border: ${(props) => (props.fullWidthImage ? 'none' : '2px solid #E6E6E6')};
  border: ${(props) => (props.altBoxShadow ? 'none' : '2px solid #E6E6E6')};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  border-collapse: separate;
  overflow: hidden;
`;

const CardStylesWithHover = styled(CardStyles)`
  &:hover {
    box-shadow: ${(props) =>
      props.altBoxShadow
        ? props.theme.shadows.card_shadow
        : props.theme.shadows.card_shadow};
    -webkit-box-shadow: ${(props) =>
      props.altBoxShadow
        ? props.theme.shadows.card_shadow
        : props.theme.shadows.card_shadow};
    -moz-box-shadow: ${(props) =>
      props.altBoxShadow
        ? props.theme.shadows.card_shadow
        : props.theme.shadows.card_shadow};
    text-decoration: none;
    transform: translateY(-0.5rem);
  }
  -webkit-transition: 0.25s ease;
  transition: ${(props) => props.theme.transitions.all};
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

function CardWrapperV6({
  hover,
  href,
  className,
  fullWidthImage,
  gaCategory,
  gaLabel,
  altBoxShadow,
  style = null,
  children
}) {
  // Option to make entire card an a tag and link / clickable.
  let targetHref = null;
  let renderedTag = 'div';
  // Provide href prop to do so
  if (href) {
    targetHref = href;
    renderedTag = 'a';
  }
  let targetImage = false;
  if (fullWidthImage) {
    targetImage = true;
  } else {
    targetImage = false;
  }

  let targetBoxShadow = false;
  if (altBoxShadow) {
    targetBoxShadow = true;
  } else {
    targetBoxShadow = false;
  }

  const onClickHandler = () => {
    if (gaCategory && gaLabel) {
      triggerGA(gaCategory, gaLabel);
    }

    // If we need additional scripts to fire off onClick in the future,
    // We can add a key to the json, check for them, and add the needed logic
    // Below this comment. so it will fire in the onClickHandler fires
    // Important to note, we don't pass in variables via the onClick function,
    // it grabs straight from the props above
  };

  // Option to have Card Image Full Width or Not
  // As you can see from above, this renders the entire CardWrapperV6 component as an A tag instead of a Div
  // Example seen on /customers/
  if (hover) {
    return (
      <CardStylesWithHover
        as={renderedTag}
        href={targetHref}
        className={className}
        fullWidthImage={targetImage}
        altBoxShadow={targetBoxShadow}
        style={style && style}
        onClick={onClickHandler}
      >
        {children}
      </CardStylesWithHover>
    );
  }
  return (
    <CardStyles
      as={renderedTag}
      fullWidthImage={targetImage}
      altBoxShadow={targetBoxShadow}
      href={targetHref}
      className={className}
      style={style && style}
    >
      {children}
    </CardStyles>
  );
}

CardWrapperV6.propTypes = {
  hover: PropTypes.bool,
  fullWidthImage: PropTypes.bool,
  altBoxShadow: PropTypes.bool,
  href: PropTypes.string,
  gaCategory: PropTypes.string,
  gaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.string)
};

CardWrapperV6.defaultProps = {
  hover: false,
  fullWidthImage: false,
  altBoxShadow: false,
  gaCategory: null,
  gaLabel: null,
  href: null,
  className: '',
  style: null
};

export default CardWrapperV6;
