import React from 'react';
/* eslint-enable */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UnorderedListStyles, BaseButton } from 'aether-marketing';
import PostconSectionHeader from './PostconSectionHeader';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SectionStyles = styled.section`
  padding-bottom: 30px;
  padding-top: ${(props) => props.paddingTop || '30px'};
  background: ${(props) => props.backgroundColor};
  color: white;
  width: 100%;
  overflow-x: clip;
  @media (min-width: 768px) {
    padding-bottom: ${(props) => props.paddingBottom || '60px'};
    padding-top: ${(props) => props.paddingTop || '60px'};
    ul {
      padding-left: 20px;
    }
  }
  .descriptionBox {
    margin-bottom: 30px;
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
    padding-left: 0;
  }
  li {
    color: white !important;
  }
  p > a {
    color: white;
    border-bottom: 1px solid white;
  }
  p > a:hover {
    color: #ff6c37;
    border-bottom: 1px solid #ff6c37;
    text-decoration: none;
  }
  /* Ribbon */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    border: 5px solid #c2a757;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #f7d982;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #000000;
    font: 700 18px/1;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: 2px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
`;

const BorderStyles = styled.div`
  p {
    color: white;
  }
  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    width: fit-content;
  }

  .logo {
    height: 25px;
  }

  background: linear-gradient(#210b30, #210b30) padding-box,
    linear-gradient(255deg, #ff6c37, #c74eda) border-box;
  border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  overflow: hidden;

  .icons {
    padding: 0 15px;
    height: 25px;
    fill: white;
  }
`;

const LogoImage = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
`;

const CardBasic = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-clip: border-box;
  background: ${(props) => props.contentBackgroundColor};
  border-collapse: separate;
  border-radius: 12px;
`;

const SVG = styled.svg`
  fill: #fff;
  &:hover {
    fill: #ff6c37 !important;
  }
`;

function PostconCards({
  preTitle,
  title,
  items,
  paddingTop,
  paddingBottomHeader,
  fullWidthImage,
  textAlign,
  body,
  backgroundColor,
  anchorId,
  headerClass,
  contentBackgroundColor,
  allSpeakers
}) {
  return (
    <SectionStyles
      className=" "
      paddingTop={paddingTop}
      backgroundColor={backgroundColor}
      id={anchorId}
    >
      <div className="container">
        <div className="row">
          {title && (
            <PostconSectionHeader
              title={title}
              preTitle={preTitle}
              body={body}
              paddingBottom={paddingBottomHeader}
              headerClass={headerClass}
            />
          )}
        </div>
        <div className="row ">
          {items.map((item) => (
            <div className={`${item.colWidth} mb-4 `} key={pmUuid()}>
              {item.ribbon && (
                <div className="ribbon ribbon-top-right">
                  <span>SOLD OUT!</span>
                </div>
              )}
              <BorderStyles className="d-flex contentBox h-100">
                <CardBasic
                  col={item.colWidth}
                  textPadding={item.textPadding}
                  textAlign={textAlign}
                  header={item.header}
                  body={item.body}
                  ratio={item.ratio}
                  fullWidthImage={fullWidthImage}
                  roundedCorners={item.roundedCorners && item.roundedCorners}
                  borderRadius={item.borderRadius}
                  backgroundColor={item.backgroundColor}
                  speaker={item.speaker}
                  className="d-flex flex-column flex-grow-1"
                  contentBackgroundColor={contentBackgroundColor}
                >
                  {!item.speaker && item.media && (
                    <img
                      src={item.media.src}
                      alt={item.media.alt}
                      className="img-fluid"
                      style={{
                        aspectRatio: item.media.ratio,
                        paddingTop: item.media.paddingTopImage
                      }}
                    />
                  )}

                  {item.speaker && item.media && (
                    <img
                      src={item.media.src}
                      alt={item.media.alt}
                      className="img-fluid"
                    />
                  )}
                  <div className={`p-3 ${item.speaker ? 'h-100' : 'mb-4'}`}>
                    {item.speaker && (
                      <p className="gradientText mb-0">
                        {item.speaker.talkType}
                      </p>
                    )}
                    {item.speaker && (
                      <p className="h3 p-0">{item.speaker.name}</p>
                    )}
                    {item.preHeader && (
                      <h4 className="gradientText">{item.preHeader}</h4>
                    )}
                    {item.header && <h4>{item.header}</h4>}
                    {item.description && (
                      <div
                        className="descriptionBox"
                        dangerouslySetInnerHTML={{
                          __html: item.description.join('')
                        }}
                      />
                    )}
                  </div>

                  {item.speaker && (
                    <div className="px-3 row mb-3 justify-content-between">
                      <div
                        className={item.speaker.logoLong ? 'col-6' : 'col-8'}
                      >
                        {item.speaker.linkedin && (
                          <a
                            href={item.speaker.linkedin}
                            className="pr-1"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            title={`Follow ${item.speaker.name} on LinkedIn`}
                          >
                            <SVG
                              width="30"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                d="M5.134 7.363H.537v14.044h4.597zM5.437 3.019C5.407 1.642 4.44.593 2.866.593 1.293.593.265 1.642.265 3.019c0 1.348.998 2.427 2.541 2.427h.03c1.603 0 2.601-1.079 2.601-2.427zM21.714 13.354c0-4.313-2.268-6.32-5.293-6.32-2.44 0-3.534 1.362-4.144 2.319v-1.99H7.68c.06 1.318 0 14.044 0 14.044h4.598v-7.843c0-.42.03-.839.151-1.139.333-.839 1.09-1.707 2.36-1.707 1.664 0 2.329 1.288 2.329 3.175v7.514h4.597v-8.053z"
                                fillRule="evenodd"
                              />
                            </SVG>
                          </a>
                        )}
                        {item.speaker.twitter && (
                          <a
                            href={item.speaker.twitter}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className=""
                            title={`Follow ${item.speaker.name} on Twitter`}
                          >
                            <SVG
                              width="34"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path
                                d="M25.955 10.483c-.032.25-.097.492-.162.734-.583 2.175-1.647 4.222-2.986 6.01-3.93 5.163-9.79 7.257-15.664 5.683-2.76-.74-5.257-2.334-7.143-4.542.376.138.784.285 1.17.388 2.31.619 4.747.384 6.952-.654-2.141-.61-3.651-2.68-3.733-5.07a4.73 4.73 0 0 0 2.339.516c-2.237-1.156-3.4-3.873-2.643-6.445l.019-.07a5.09 5.09 0 0 0 2.126 1.31C5.063 6.921 4.682 5.005 5.172 3.175c.26-.967.743-1.873 1.414-2.582 1.684 4.115 4.95 7.21 8.961 8.545.015-.44.094-.863.205-1.278.814-3.038 3.75-4.879 6.575-4.122 1.38.37 2.562 1.352 3.229 2.715a10.32 10.32 0 0 0 3.594-.443c-.719 1.14-1.817 2.03-3.052 2.402 1.055.172 2.1.119 3.152-.08a10.71 10.71 0 0 1-3.295 2.151z"
                                fillRule="evenodd"
                              />
                            </SVG>
                          </a>
                        )}
                      </div>
                      {item.speaker.logo && (
                        <div
                          className={`logo text-right ${
                            item.speaker.logoLong ? 'col-6' : 'col-4 '
                          }`}
                        >
                          <LogoImage
                            src={item.speaker.logo}
                            alt="logo"
                            className="img-fluid "
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {item.workshop && (
                    <div
                      className="pl-3"
                      style={{ position: 'absolute', bottom: '15px' }}
                    >
                      <BaseButton
                        as="a"
                        buttonType="postcon"
                        className=""
                        src={item.src}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.btnText || 'Learn More'}
                      </BaseButton>
                    </div>
                  )}
                </CardBasic>
              </BorderStyles>
            </div>
          ))}
        </div>
        {allSpeakers && (
          <div className="row justify-content-center mt-4">
            <BaseButton
              as="a"
              buttonType="postcon"
              className=""
              src="/postcon/speakers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              See All Speakers
            </BaseButton>
          </div>
        )}
      </div>
    </SectionStyles>
  );
}

export default PostconCards;
PostconCards.propTypes = {
  preTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  paddingTop: PropTypes.string,
  paddingBottomHeader: PropTypes.string,
  fullWidthImage: PropTypes.bool,
  textAlign: PropTypes.string,
  body: PropTypes.string,
  anchorId: PropTypes.string,
  headerClass: PropTypes.string,
  contentBackgroundColor: PropTypes.string,
  allSpeakers: PropTypes.bool
};
PostconCards.defaultProps = {
  preTitle: '',
  backgroundColor: null,
  title: '',
  items: [],
  paddingBottomHeader: '60px',
  paddingTop: '30px',
  fullWidthImage: false,
  textAlign: 'left',
  body: '',
  anchorId: '',
  headerClass: '',
  contentBackgroundColor: 'transparent',
  allSpeakers: false
};
