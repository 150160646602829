import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import Button from '../v5_buttons/Button';
import { PricingLink } from './PricingLinks';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/* eslint-disable no-useless-escape, react/prop-types, jsx-a11y/mouse-events-have-key-events, jsx-a11y/anchor-is-valid  */
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const TableRow = styled.tr`
  background: ${(props) => {
    let color;
    if (props.feature.highlightedSubGroup) {
      color = '#FDFDFE';
    } else if (props.feature.highlightedGroup) {
      color = '#F9F9F9';
    }
    return color;
  }};

  p {
    font-weight: ${(props) =>
      props.feature.highlightedSubGroup || props.feature.highlightedGroup
        ? '600'
        : '400 !important'};
  }
`;

class PricingTableSmall extends React.Component {
  constructor(props) {
    super(props);
    const { data, planName } = this.props;
    this.state = { data, planName };
  }

  render() {
    const { data, planName } = this.state;
    const { event } = data;
    const itemTooltip = (event && event.itemTooltip) || '';
    const itemClicked = (event && event.itemClicked) || '';
    return (
      <>
        <table className="table table-light">
          <tbody>
            {data.features.map((feature) => {
              // eslint-disable-next-line array-callback-return, consistent-return
              const cells = feature.plans.map((plan) => {
                // For all non-enterprise columns, need border-right
                if (plan.name === planName) {
                  // Is it boolean or is it string?
                  if (plan.name && plan.topAlign) {
                    return (
                      <td
                        className="border-top-0 border-bottom text-right"
                        key={pmUuid()}
                      >
                        <p className="text-right mb-0">{plan.included}</p>
                      </td>
                    );
                  }
                  if (
                    plan.addCheckmarkNextToText &&
                    typeof plan.included === 'string' &&
                    !plan.topAlign
                  ) {
                    return (
                      <td
                        className="border-top-0 border-bottom text-right"
                        key={pmUuid()}
                      >
                        <img
                          className="mr-2"
                          alt="Available"
                          src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                        />
                        <p
                          className="text-right mb-0"
                          dangerouslySetInnerHTML={{ __html: plan.included }}
                        />
                        {plan.includedTwo && (
                          <p
                            className="text-right mb-0"
                            dangerouslySetInnerHTML={{
                              __html: plan.includedTwo
                            }}
                          />
                        )}
                      </td>
                    );
                  }
                  if (typeof plan.included === 'string' && !plan.topAlign) {
                    return (
                      <td className="border-top-0 border-bottom" key={pmUuid()}>
                        <p
                          className="text-right mb-0"
                          dangerouslySetInnerHTML={{ __html: plan.included }}
                        />
                        {plan.includedTwo && (
                          <p
                            className="text-right mb-0"
                            dangerouslySetInnerHTML={{
                              __html: plan.includedTwo
                            }}
                          />
                        )}
                      </td>
                    );
                  }
                  if (plan.included) {
                    // if plan.included === true, green checkmark
                    return (
                      <td className="border-top-0 border-bottom" key={pmUuid()}>
                        <p className="text-right mb-0">
                          <img
                            alt="Available"
                            src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                          />
                        </p>
                      </td>
                    );
                  }
                  return (
                    <td className="border-top-0 border-bottom" key={pmUuid()}>
                      <p className="text-right mb-0">
                        <img
                          className="v5_opacity-50"
                          alt="Unavailable"
                          src="https://voyager.postman.com/icon/x-icon-grey.svg"
                        />
                      </p>
                    </td>
                  );
                }
              });
              return (
                /* Row */
                <TableRow key={pmUuid()} feature={feature}>
                  {feature.highlightedGroup && (
                    /* Highlighted Category Title (Add tooltip if needed ) */
                    <td
                      colSpan="12"
                      className="border-bottom text-left align-middle"
                    >
                      <p className="mb-0 feature-text text-left">
                        {feature.highlightedTitle}
                        {feature.popover && (
                          <a
                            tabIndex="0"
                            className="pm-tooltip"
                            role="button"
                            data-toggle="popover"
                            data-trigger="focus"
                            title="More info"
                            data-content={feature.popover}
                            style={{ color: 'inherit' }}
                          >
                            <img
                              src="https://voyager.postman.com/icon/question-mark-icon-postman.svg"
                              alt="Question. Icon."
                              style={{ height: '15px', opacity: '0.7' }}
                              onMouseOver={() => {
                                window.pmt('amplitudeEvent', [
                                  `${itemTooltip}:${feature.title}`
                                ]);
                              }}
                            />
                          </a>
                        )}
                      </p>
                    </td>
                  )}
                  {!feature.highlightedGroup && (
                    /* Plan features inside category ( A tag and P element ) */
                    <th
                      scope="row"
                      className="border-top-0 border-bottom text-left"
                    >
                      {feature.href ? (
                        <>
                          <PricingLink
                            type="tertiary"
                            href={feature.href}
                            className="bold mb-0 feature-text text-left"
                            ariaLabel={feature.ariaLabel}
                            style={{
                              fontWeight: feature.highlightedGroup && '600'
                            }}
                            event={feature.event}
                            eventProp={feature.eventProp}
                          >
                            {feature.title}
                          </PricingLink>
                          {feature.popover && (
                            <a
                              tabIndex="0"
                              className="pm-tooltip"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={feature.popover}
                            >
                              <img
                                src="https://voyager.postman.com/icon/question-mark-icon-postman.svg"
                                alt="Question. Icon."
                                style={{ height: '15px', opacity: '0.7' }}
                                onMouseOver={() => {
                                  window.pmt('amplitudeEvent', [
                                    `${itemTooltip}:${feature.title}`
                                  ]);
                                }}
                              />
                            </a>
                          )}
                        </>
                      ) : (
                        <p className="bold mb-0 feature-text text-left">
                          {feature.title}
                          {feature.popover && (
                            <a
                              tabIndex="0"
                              className="pm-tooltip ml-1"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={feature.popover}
                            >
                              <img
                                src="https://voyager.postman.com/icon/question-mark-icon-postman.svg"
                                alt="Question. Icon."
                                style={{ height: '15px', opacity: '0.7' }}
                                onMouseOver={() => {
                                  window.pmt('amplitudeEvent', [
                                    `${itemTooltip}:${feature.title}`
                                  ]);
                                }}
                              />
                            </a>
                          )}
                        </p>
                      )}
                    </th>
                  )}
                  {cells}
                </TableRow>
              );
            })}
            <tr>
              <td colSpan="2" className="border-top text-center">
                {data.plans.map(
                  (plan) =>
                    plan.id === planName && (
                      <p key={pmUuid()}>
                        {plan.button && (
                          <Button
                            className={plan.buttonTwo ? 'mr-1 mb-lg-2' : ''}
                            buttonType={plan.button.buttonType}
                            id={plan.button.id}
                            data-test={plan.button.test}
                            aria-label={plan.button.ariaLabel}
                            href={plan.button.href}
                            target={plan.button.target}
                            title={plan.button.title}
                            rel={plan.button.rel}
                            event={itemClicked}
                            eventProp={`{\"cta_link_to\":\"${plan.button.href}\",\"cta_style\":\"${plan.button.buttonType}\",\"cta_text\":\"${plan.button.title}\",\"cta_type\":\"button\"}`}
                            onClick={() => {
                              triggerGA('pricing-page', `${plan.button.id}`);
                            }}
                          >
                            {plan.button.title}
                          </Button>
                        )}
                        {plan.buttonTwo && (
                          <Button
                            buttonType={plan.buttonTwo.buttonType}
                            id={plan.buttonTwo.id}
                            data-test={plan.buttonTwo.test}
                            aria-label={plan.buttonTwo.ariaLabel}
                            href={plan.buttonTwo.href}
                            target={plan.buttonTwo.target}
                            title={plan.buttonTwo.title}
                            rel={plan.buttonTwo.rel}
                            event={itemClicked}
                            eventProp={`{\"cta_link_to\":\"${plan.buttonTwo.href}\",\"cta_style\":\"${plan.buttonTwo.buttonTwoType}\",\"cta_text\":\"${plan.buttonTwo.title}\",\"cta_type\":\"button\"}`}
                            onClick={() => {
                              triggerGA('pricing-page', `${plan.buttonTwo.id}`);
                            }}
                          >
                            {plan.buttonTwo.title}
                          </Button>
                        )}
                      </p>
                    )
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="small text-right" />
      </>
    );
  }
}

PricingTableSmall.propTypes = {
  data: PropTypes.shape({
    planName: PropTypes.string
  }).isRequired,
  planName: PropTypes.string.isRequired
};

export default PricingTableSmall;
