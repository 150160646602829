import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLink,
  Paragraph,
  BaseButton
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardWrapper = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 30px;

  @media screen and (min-width: 768px) {
    border-bottom: none;
    padding-bottom: 20px;
  }
`;

const BlogLinkWrapper = styled.div`
  background-image: none !important;
  padding: 0 !important;
  background-position: none !important;
  text-decoration: none !important;
  transition: none !important;
`;

const BlogLink = styled.a`
  color: #212121;

  &:hover {
    color: ${(props) => props.theme.colors.blue_60} !important;
    text-decoration: none;
  }
`;

const SquaredUpThumbnailWrapper = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 24px;
`;

const SquaredUpThumbnail = styled.div`
  height: 200px;
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: #f9f9f9;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  padding: 24px;
  transition: 0.2s ease-in-out;
`;

function Card({ data }) {
  const featuredImage = data.image ? `url(${data.image})` : 'none';
  return (
    <div className="col-xs-12 col-md-6 col-lg-4 mb-5">
      <CardWrapper>
        <BlogLinkWrapper>
          <SquaredUpThumbnailWrapper>
            <a href={data.link.src}>
              <SquaredUpThumbnail
                style={{ backgroundImage: `${featuredImage}` }}
              />
            </a>
          </SquaredUpThumbnailWrapper>
        </BlogLinkWrapper>
        <div className="card-body p-0">
          <h3 className="mb-4">
            <BlogLink href={data.link.src} target="_blank" rel="noopener">
              {data.title}
            </BlogLink>
          </h3>
          {data.body && <Paragraph>{data.body}</Paragraph>}
        </div>
        <div className="row">
          <div className="col-12">
            {data.link.src && data.link.text && (
              <BaseLink
                className="mb-0"
                src={data.link.src}
                target="same-tab"
                linkType="arrowLink"
              >
                Read more
              </BaseLink>
            )}
          </div>
        </div>
      </CardWrapper>
    </div>
  );
}

const blogPostCardsStatic = (data) => (
  <>
    <SectionStyles>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-5">{data.title}</h2>
            {data.subtitle && <h3 className="mb-5">{data.subtitle}</h3>}
            <div className="row justify-content-center">
              {data.cardContent.map((val) => {
                const cardData = { ...val };
                return <Card data={cardData} key={pmUuid()} />;
              })}
            </div>
          </div>
        </div>
        {data.ctaButton && (
          <div className="row mt-5">
            <div className="col-12 text-center">
              <BaseButton
                buttonType="primary"
                as="a"
                src={data.ctaButton.src}
                target={data.ctaButton.target}
                id={data.ctaButton.id}
                gaCategory={data.ctaButton.gaCategory}
                gaLabel={data.ctaButton.gaLabel}
                className="mt-2"
              >
                {data.ctaButton.text}
              </BaseButton>
            </div>
          </div>
        )}
      </div>
    </SectionStyles>
    {(data.divider === true && <Divider className="d-none d-md-block" />) ||
      null}
  </>
);

Card.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired
    })
  }).isRequired
};

export default blogPostCardsStatic;
