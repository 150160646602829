import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import marginBottomEnumReturner from '../../../utils/marginBottomEnumReturner';

const ImageWrapper = styled.div`
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1px;
`;

function ImageContainer({
  src,
  height,
  className,
  backgroundSize,
  marginBottom // enum value.  Provides ability to override margin-bottom property
}) {
  const imgHeight = height || '150px';
  // By default (null), has 0 mb
  const marginBottomOverride = marginBottomEnumReturner(marginBottom);
  const backgroundSizeOverride = backgroundSize || 'contain';
  const styles = {
    backgroundImage: `url(${src})`,
    height: imgHeight,
    marginBottom: marginBottomOverride,
    backgroundSize: backgroundSizeOverride
  };

  return <ImageWrapper style={styles} className={className} />;
}

ImageContainer.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
  backgroundSize: PropTypes.string
};

ImageContainer.defaultProps = {
  height: '120px',
  className: null,
  marginBottom: null,
  backgroundSize: 'contain'
};

export default ImageContainer;

// Simple component for displaying images.
// Renders full width div, sets image as div background.
// Use this component as an <img> tag.
