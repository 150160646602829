/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from 'styled-components';
import { BaseButton } from 'aether-marketing';
// Runbook: https://postmanlabs.atlassian.net/wiki/spaces/MT/pages/4473422614/Runbook+Hellobar+Supreme+and+Slim+components+with+Lottie+animations

const Section = styled.div`
  background-color: #210b30;
`;
const Container = styled.div`
  position: relative;
  z-index: 100;
  padding: 16px 0;

  @media screen and (max-width: 1199px) {
    padding: 16px;
  }
`;

const CTAButton = styled(BaseButton)`
  display: inline-block;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 12px;
  padding: 6px 12px;
  margin: 0 !important;
  transition: all 0.2s ease-in-out 0s;
  text-align: center;
  border: none;
  outline: none;
  position: relative;
  background: linear-gradient(
    90deg,
    rgb(255, 108, 55) 0%,
    rgb(198, 77, 218) 100%
  );
  cursor: pointer;
  z-index: 1;
  color: white !important;

  ::before {
    content: '';
    position: absolute;
    z-index: 1000;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background: #15071f;
    z-index: -1;
  }

  ::after {
    content: '';
  }

  :hover {
    transition: 0.3s ease-in-out;
    background: white;
    text-decoration: none;
    box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
    -webkit-box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
  }
`;

const Icon = styled.img`
  width: 100%;
`;

// const Content = styled(Paragraph)`
//   display: flex;
//   align-items: center;
//   font-size: 14px !important;
//   margin-bottom: 0;
//   color: ${(props) => props.theme.colors.grey_00};
// `;

const CloseButtonStyle = styled.span`
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 15px;
  color: white;

  @media screen and (max-width: 992px) {
    top: 4px;
  }

  & img {
    border-radius: ${(props) => props.theme.borderRadius.small};
    opacity: 0.6;
    padding: 8px;
    transition: ${(props) => props.theme.transitions.all};
  }

  & img:hover {
    background-color: #e6e6e6;
    opacity: 1;
    transition: ${(props) => props.theme.transitions.all};
  }
`;

const GradientText = styled.p`
  background-image: linear-gradient(
    to right,
    rgb(255, 108, 55),
    rgb(198, 77, 218)
  );
  background-clip: text;
  font-family: ${(props) => props.theme.fonts.Degular};
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin-bottom: 0;
  font-size: 18px;
`;

const button = {
  buttonType: 'postcon',
  text: 'Watch Live',
  src: '/postcon/#livestream-starts-soon',
  target: 'same-tab',
  id: '',
  gaCategory: 'postcon',
  gaLabel: 'learn-more'
};

function AnnouncementSlim() {
  // check local storage count
  function closeButton() {
    let announceViewCount = JSON.parse(
      localStorage.getItem('announcement_count')
    );
    const closeButtonElement =
      document.getElementById('close-announcement-button') || null;
    if (closeButtonElement) {
      announceViewCount = 100;
      localStorage.setItem(
        'announcement_count',
        JSON.stringify(announceViewCount)
      );
      const hideSlimSection = document.getElementById(
        'pm-slim-announce-section'
      );
      hideSlimSection.style.display = 'none';
    }
  }

  return (
    <Section id="pm-slim-announce-section">
      <Container className="container">
        <div className="row align-items-center">
          <div className="col-5 col-sm-3 offset-0 col-lg-3 offset-lg-1">
            <Icon
              className="img-fluid"
              src="https://voyager.postman.com/post-con/postcon24-logo.svg"
            />
          </div>
          <div className=" col-12 offset-0 offset-md-1 col-md-5 col-lg-4 offset-lg-2 offset-xl-2 col-xl-4 mt-1 mt-lg-0 justify-content-center">
            <GradientText className="ml-xl-auto">
              Join the POST/CON 24 livestream&mdash;happening now.
            </GradientText>
            {/* <Content className="mb-0">
              April 30 - May 1, 2024 · San Francisco, CA
              {''}
            </Content> */}
          </div>
          <div className="col-12 col-md-2 mt-1 mt-lg-0">
            <CTAButton
              buttonType={button.buttonType}
              as="a"
              href={button.src}
              id={button.id}
              gaCategory={button.gaCategory}
              gaLabel={button.gaLabel}
            >
              {button.text}
            </CTAButton>
          </div>
        </div>
        <CloseButtonStyle id="close-announcement-button" onClick={closeButton}>
          <img
            alt="X"
            src="https://voyager.postman.com/announcement/x-grey-icon.svg"
          />
        </CloseButtonStyle>
      </Container>
    </Section>
  );
}

export default AnnouncementSlim;
