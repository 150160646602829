import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */

import styled from 'styled-components';
import { Paragraph } from 'aether-marketing';
import parseQuery from '../../parseQuery';
/* eslint react/prop-types: 0 */
import cards from './speakers';
import SpeakerCards from './SpeakerCards';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const querystring = require('querystring');

const Section = styled.section`
  padding: 60px 0;
  background: ${(props) => props.theme.colors.grey_00};

  @media screen and (max-width: 992px) {
    padding: 16px;
  }
`;

const ScheduleButton = styled.button`
  color: ${(props) => props.theme.colors.grey_00};
  border: none;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  &:hover {
    opacity: 0.8;
    border: none;
    color: ${(props) => props.theme.colors.grey_00};
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
    transition: none;
    border: none;
    outline: none;
  }
  &.dropdown-toggle:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  &:active,
  &.activated {
    opacity: 0.5;
    border: none;
    color: ${(props) => props.theme.colors.white} !important;
    outline: none;
    box-shadow: none;
  }
  &.developers-bttn {
    background-color: #36a5af;
  }
  &.testers-bttn {
    background-color: #ff9403;
  }
  &.business-bttn {
    background-color: #6d7f91;
  }
  &.admins-bttn {
    background-color: #9666d2;
  }
`;

const SessionButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  @media screen and (max-width: 992px) {
    padding: 4px;
    padding-left: 0 !important;
  }
`;

const ClearSessionButton = styled.a`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey_50};
  &:hover {
    color: ${(props) => props.theme.colors.grey_50};
    opacity: 0.8;
    text-decoration: none;
  }
`;

class FilteredSpeakerCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredSession: {
        developers: true,
        business: true,
        testers: true,
        admins: true
      }
    };
    this.onClickFilterBySession = this.onClickFilterBySession.bind(this);
    this.onClickClearSession = this.onClickClearSession.bind(this);
  }

  componentDidMount() {
    const queries = parseQuery(window.location.search);
    const { session } = queries;
    const sessions = ['developers', 'business', 'testers', 'admins'];
    const stateObj = {};

    if (session && sessions.includes(session.toLowerCase())) {
      const initObj = {
        developers: false,
        business: false,
        testers: false,
        admins: false
      };
      initObj[session.toLowerCase()] = true;
      stateObj.filteredSession = initObj;
    } else {
      stateObj.filteredSession = {
        developers: true,
        business: true,
        testers: true,
        admins: true
      };
    }
    this.setState(stateObj);
  }

  onClickFilterBySession(event) {
    const { filteredSession } = this.state;
    const filtered = Object.keys(filteredSession).filter(
      (key) => filteredSession[key]
    );
    const activeSession = filtered.length > 1 ? '' : filtered[0];
    if (activeSession === event.target.id) {
      this.setState(
        {
          filteredSession: {
            developers: true,
            business: true,
            testers: true,
            admins: true
          }
        },
        this.setQuery
      );
    } else {
      this.setState(
        {
          filteredSession: {
            [event.target.id]: true
          }
        },
        this.setQuery
      );
    }
  }

  onClickClearSession() {
    this.setState(
      {
        filteredSession: {
          developers: true,
          business: true,
          testers: true,
          admins: true
        }
      },
      this.setQuery
    );
  }

  setQuery() {
    const { filteredSession } = this.state;
    const filtered = Object.keys(filteredSession).filter(
      (key) => filteredSession[key]
    );
    const activeSession = filtered.length > 1 ? '' : filtered[0];
    const queryObject = {};
    if (activeSession) {
      queryObject.session = activeSession;
    }
    const queryString = querystring.stringify(queryObject);
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }${queryString ? '?' : ''}${queryString}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  render() {
    const { filteredSession } = this.state;
    return (
      <>
        <div>
          <div className="container">
            <div className="row text-center">
              <div className="col-12 text-center mb-3">
                <h2>On-demand sessions, talks, and panels</h2>
              </div>
              {/* eslint-disable*/}
              <div className="col-12 text-left text-lg-center">
                <Paragraph
                  className="strong text-uppercase mb-4"
                  id="sessionsHeader"
                >
                  Filter by Interest
                </Paragraph>
                <SessionButtonWrapper className="pl-2 pr-2">
                  <ScheduleButton
                    type="button"
                    className={
                      filteredSession.business
                        ? 'btn business-bttn'
                        : 'btn activated business-bttn'
                    }
                    id="business"
                    onClick={this.onClickFilterBySession}
                  >
                    Business
                  </ScheduleButton>
                </SessionButtonWrapper>
                <SessionButtonWrapper className="pl-2 pr-2">
                  <ScheduleButton
                    type="button"
                    className={
                      filteredSession.developers
                        ? 'btn developers-bttn'
                        : 'btn activated developers-bttn'
                    }
                    id="developers"
                    onClick={this.onClickFilterBySession}
                  >
                    Developers
                  </ScheduleButton>
                </SessionButtonWrapper>
                <SessionButtonWrapper className="pl-2 pr-2">
                  <ScheduleButton
                    type="button"
                    className={
                      filteredSession.testers
                        ? 'btn testers-bttn'
                        : 'btn activated testers-bttn'
                    }
                    id="testers"
                    onClick={this.onClickFilterBySession}
                  >
                    Testers
                  </ScheduleButton>
                </SessionButtonWrapper>
                <SessionButtonWrapper className="pl-2 pr-2">
                  <ScheduleButton
                    type="button"
                    className={
                      filteredSession.admins
                        ? 'btn admins-bttn'
                        : 'btn  activated admins-bttn'
                    }
                    id="admins"
                    onClick={this.onClickFilterBySession}
                  >
                    Admins
                  </ScheduleButton>
                </SessionButtonWrapper>
              </div>
              <div className="col-12 text-lg-center text-left mt-3">
                <ClearSessionButton
                  role="button"
                  onClick={this.onClickClearSession}
                >
                  X Clear
                </ClearSessionButton>
              </div>
            </div>
          </div>
        </div>
        <Section>
          <div className="container">
            <div className="row justify-content-center">
              {cards.map((card) => (
                <SpeakerCards data={{ card, filteredSession }} key={pmUuid()} />
              ))}
            </div>
          </div>
        </Section>
      </>
    );
  }
}

FilteredSpeakerCards.propTypes = {
  data: PropTypes.shape({}).isRequired
};

const recapFilter = (data) => <FilteredSpeakerCards data={data} />;

export default recapFilter;
