/* global mapboxgl */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
// This component is specifically made for /student-program/
// It is not reusable.  And will need some work to make it so, but can serve as reference code
const MapContainer = styled.div`
  #map-2 {
    width: 100%;
    height: 700px;

    @media (max-width: 768px) {
      height: 70vh;
    }
  }
  .marker {
    background-image: url('https://voyager.postman.com/icon/postmanaut-location.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 45px;
    cursor: pointer;
  }

  .mapboxgl-ctrl-zoom-in {
    width: 100px;
    height: 35px;
    text-align: center;
    margin: auto;
  }
  .mapboxgl-ctrl-zoom-out {
    width: 100px;
    height: 35px;
    text-align: center;
    margin: auto;
  }
`;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rawUniversitiesData: [
        {
          'City Coordinates': '9.893,78.08',
          'Organization Name': 'Thiagarajar College of Engineering'
        },
        {
          'City Coordinates': '9.748,-83.753',
          'Organization Name': 'U. Hispanoamericana'
        },
        {
          'City Coordinates': '8.982,-79.519',
          'Organization Name': 'Universidad Tecnológica de Panamá'
        },
        {
          'City Coordinates': '64.999,25.51',
          'Organization Name': 'Oulun ammattikorkeakoulu'
        },
        {
          'City Coordinates': '60.201,24.935',
          'Organization Name': 'Business College Helsinki'
        },
        { 'City Coordinates': '6.914,79.972', 'Organization Name': 'SLIIT' },
        {
          'City Coordinates': '6.868,79.89',
          'Organization Name': 'University of Sri Jayewardenepura'
        },
        { 'City Coordinates': '6.865,79.862', 'Organization Name': 'IIT' },
        {
          'City Coordinates': '6.801,79.899',
          'Organization Name': 'University of Moratuwa'
        },
        {
          'City Coordinates': '6.524,3.379',
          'Organization Name': 'Venture Garden Group'
        },
        {
          'City Coordinates': '6.519,3.399',
          'Organization Name': 'University of Lagos'
        },
        { 'City Coordinates': '59.907,10.759', 'Organization Name': 'DNB' },
        {
          'City Coordinates': '57.685,11.978',
          'Organization Name': 'Chalmers University of Technology'
        },
        {
          'City Coordinates': '54.976,-1.606',
          'Organization Name': 'Northumbria University'
        },
        { 'City Coordinates': '54.654,-8.104', 'Organization Name': 'LYIT' },
        {
          'City Coordinates': '54.01,-2.786',
          'Organization Name': 'Lancaster University'
        },
        {
          'City Coordinates': '53.644,-1.779',
          'Organization Name': 'University of Huddersfield'
        },
        {
          'City Coordinates': '53.551,9.993',
          'Organization Name': 'University of Hamburg'
        },
        {
          'City Coordinates': '53.349,-6.243',
          'Organization Name': 'National College of Ireland'
        },
        {
          'City Coordinates': '53.304,-6.218',
          'Organization Name': 'University College Dublin'
        },
        {
          'City Coordinates': '53.276,-9.059',
          'Organization Name': 'NUI Galway'
        },
        {
          'City Coordinates': '52.767,-1.222',
          'Organization Name': 'Loughborough University'
        },
        {
          'City Coordinates': '52.668,-8.63',
          'Organization Name': 'University of Limerick'
        },
        {
          'City Coordinates': '52.493,13.525',
          'Organization Name': 'HTW Berlin'
        },
        {
          'City Coordinates': '52.49,13.36',
          'Organization Name': 'SPICED Academy'
        },
        {
          'City Coordinates': '52.475,-1.883',
          'Organization Name': 'School of Code'
        },
        {
          'City Coordinates': '52.408,16.916',
          'Organization Name': 'Uniwersytet im. Adama Mickiewicza w Poznaniu'
        },
        {
          'City Coordinates': '52.367,4.883',
          'Organization Name': 'Payvision'
        },
        {
          'City Coordinates': '52.22,21.011',
          'Organization Name': 'Politechnika Warszawska'
        },
        {
          'City Coordinates': '52.208,21.008',
          'Organization Name': 'SGH Warsaw School of Economics'
        },
        {
          'City Coordinates': '52.167,4.47',
          'Organization Name': 'Hogeschool Leiden'
        },
        {
          'City Coordinates': '51.919,19.145',
          'Organization Name':
            'Wyższa Szkoła Przedsiębiorczości i Administracji w Lublinie'
        },
        {
          'City Coordinates': '51.523,-0.087',
          'Organization Name': 'Makers Academy'
        },
        {
          'City Coordinates': '51.521,-0.127',
          'Organization Name': 'University of London'
        },
        {
          'City Coordinates': '51.5,-2.547',
          'Organization Name': 'UWE Bristol'
        },
        {
          'City Coordinates': '51.498,-0.178',
          'Organization Name': 'Imperial College London'
        },
        {
          'City Coordinates': '51.456,-2.596',
          'Organization Name': 'TechTalent Academy'
        },
        {
          'City Coordinates': '51.448,5.491',
          'Organization Name': 'TU Eindhoven'
        },
        {
          'City Coordinates': '51.055,3.723',
          'Organization Name': 'Arteveldehogeschool'
        },
        { 'City Coordinates': '50.848,4.356', 'Organization Name': 'Odisee' },
        {
          'City Coordinates': '50.795,-1.093',
          'Organization Name': 'University of Portsmouth'
        },
        {
          'City Coordinates': '49.865,8.632',
          'Organization Name': 'Hochschule Darmstadt'
        },
        { 'City Coordinates': '49.405,8.674', 'Organization Name': 'GFN' },
        {
          'City Coordinates': '49.276,-122.924',
          'Organization Name': 'Simon Fraser University'
        },
        // {"City Coordinates":"49.264,-123.104","Organization Name":"Hootsuite"},
        {
          'City Coordinates': '49.201,16.603',
          'Organization Name': 'VUT v Brně'
        },
        { 'City Coordinates': '48.793,2.474', 'Organization Name': 'Epitech' },
        {
          'City Coordinates': '48.714,2.211',
          'Organization Name': 'École polytechnique'
        },
        {
          'City Coordinates': '48.579,7.766',
          'Organization Name': 'University of Strasbourg'
        },
        {
          'City Coordinates': '48.458,2.156',
          'Organization Name': 'Université Paris-Saclay'
        },
        {
          'City Coordinates': '48.157,16.381',
          'Organization Name': 'FH Campus Wien'
        },
        {
          'City Coordinates': '48.148,11.568',
          'Organization Name': 'TU München'
        },
        {
          'City Coordinates': '48.025,13.972',
          'Organization Name': 'htl-leonding.at'
        },
        {
          'City Coordinates': '47.667,-117.401',
          'Organization Name': 'Gonzaga University'
        },
        {
          'City Coordinates': '47.65,-122.308',
          'Organization Name': 'University of Washington'
        },
        {
          'City Coordinates': '47.505,19.057',
          'Organization Name': 'Codecool'
        },
        {
          'City Coordinates': '47.497,19.04',
          'Organization Name': 'Green Fox Academy'
        },
        {
          'City Coordinates': '47.481,8.211',
          'Organization Name': 'Fachhochschule Nordwestschweiz FHNW'
        },
        {
          'City Coordinates': '47.01,28.863',
          'Organization Name': 'Technical University of Moldova'
        },
        { 'City Coordinates': '46.317,7.983', 'Organization Name': 'FFHS' },
        { 'City Coordinates': '46.227,2.213', 'Organization Name': 'EPITA' },
        {
          'City Coordinates': '45.758,11.004',
          'Organization Name': 'Associazione MindsHub'
        },
        {
          'City Coordinates': '45.753,21.225',
          'Organization Name': 'Universitatea Politehnica Timisoara'
        },
        {
          'City Coordinates': '45.502,-73.615',
          'Organization Name': 'Polytechnique Montréal'
        },
        {
          'City Coordinates': '45.497,-73.578',
          'Organization Name': 'Concordia University'
        },
        {
          'City Coordinates': '45.425,-75.684',
          'Organization Name': 'uOttawa'
        },
        {
          'City Coordinates': '45.069,7.696',
          'Organization Name': 'Università di Torino'
        },
        {
          'City Coordinates': '45.062,7.662',
          'Organization Name': 'Politecnico di Torino'
        },
        {
          'City Coordinates': '44.827,20.456',
          'Organization Name': 'Univerzitet Metropolitan'
        },
        {
          'City Coordinates': '44.782,20.478',
          'Organization Name': 'Singidunum University'
        },
        {
          'City Coordinates': '44.443,26.053',
          'Organization Name': 'Universitatea POLITEHNICA din București'
        },
        {
          'City Coordinates': '43.82,-111.782',
          'Organization Name': 'BYU-Idaho'
        },
        {
          'City Coordinates': '43.66,-79.395',
          'Organization Name': 'University of Toronto'
        },
        {
          'City Coordinates': '43.648,-79.397',
          'Organization Name': 'Juno College of Technology'
        },
        {
          'City Coordinates': '43.472,-80.54',
          'Organization Name': 'University of Waterloo'
        },
        {
          'City Coordinates': '43.468,-79.699',
          'Organization Name': 'Sheridan College'
        },
        {
          'City Coordinates': '43.389,-80.404',
          'Organization Name': 'Conestoga College'
        },
        {
          'City Coordinates': '43.128,-77.63',
          'Organization Name': 'University of Rochester'
        },
        {
          'City Coordinates': '43.086,-77.674',
          'Organization Name': 'Rochester Institute of Technology'
        },
        {
          'City Coordinates': '42.722,-84.478',
          'Organization Name': 'Michigan State University'
        },
        {
          'City Coordinates': '42.671,-83.218',
          'Organization Name': 'Oakland University'
        },
        {
          'City Coordinates': '42.642,-87.856',
          'Organization Name': 'University of Wisconsin-Parkside'
        },
        {
          'City Coordinates': '42.592,-71.808',
          'Organization Name': 'Applewild School'
        },
        {
          'City Coordinates': '42.446,-76.483',
          'Organization Name': 'Cornell University'
        },
        {
          'City Coordinates': '42.339,-71.088',
          'Organization Name': 'Northeastern University'
        },
        {
          'City Coordinates': '42.335,-83.049',
          'Organization Name': 'Grand Circus'
        },
        {
          'City Coordinates': '42.306,-83.067',
          'Organization Name': 'University of Windsor'
        },
        {
          'City Coordinates': '42.05,-87.679',
          'Organization Name': 'Northwestern University'
        },
        {
          'City Coordinates': '41.986,-70.97',
          'Organization Name': 'Bridgewater State University'
        },
        {
          'City Coordinates': '41.873,-87.65',
          'Organization Name': 'University of Illinois at Chicago'
        },
        {
          'City Coordinates': '41.663,-91.534',
          'Organization Name': 'University of Iowa'
        },
        {
          'City Coordinates': '41.619,0.619',
          'Organization Name': 'ILERNA Formación'
        },
        { 'City Coordinates': '41.387,2.059', 'Organization Name': 'JEDI' },
        { 'City Coordinates': '41.385,2.173', 'Organization Name': 'ESADE' },
        {
          'City Coordinates': '41.306,-72.931',
          'Organization Name': 'Yale University'
        },
        {
          'City Coordinates': '41.258,-96.01',
          'Organization Name': 'University of Nebraska Omaha'
        },
        {
          'City Coordinates': '41.223,1.697',
          'Organization Name': 'Barcelona Code School'
        },
        {
          'City Coordinates': '41.164,-73.19',
          'Organization Name': 'University of Bridgeport'
        },
        {
          'City Coordinates': '41.117,29.003',
          'Organization Name': 'Beykent University'
        },
        {
          'City Coordinates': '40.915,-73.122',
          'Organization Name': 'Stony Brook University'
        },
        {
          'City Coordinates': '40.841,-73.941',
          'Organization Name': 'Columbia University'
        },
        {
          'City Coordinates': '40.816,-96.703',
          'Organization Name': 'University of Nebraska-Lincoln'
        },
        {
          'City Coordinates': '40.79,-77.861',
          'Organization Name': 'Penn State University'
        },
        {
          'City Coordinates': '40.782,30.32',
          'Organization Name': 'Sakarya Üniversitesi'
        },
        {
          'City Coordinates': '40.765,-111.848',
          'Organization Name': 'University of Utah'
        },
        {
          'City Coordinates': '40.76,-111.891',
          'Organization Name': 'Western Governors University'
        },
        { 'City Coordinates': '40.746,-73.981', 'Organization Name': 'IEEE' },
        {
          'City Coordinates': '40.746,-73.981',
          'Organization Name': 'Northwestern Polytechnic University'
        },
        {
          'City Coordinates': '40.732,-73.987',
          'Organization Name': 'New York University'
        },
        {
          'City Coordinates': '40.71,-74.004',
          'Organization Name': 'Pace University'
        },
        {
          'City Coordinates': '40.61,-74.693',
          'Organization Name': 'Raritan Valley Community College'
        },
        {
          'City Coordinates': '40.546,-3.694',
          'Organization Name': 'UAM Autónoma Madrid'
        },
        {
          'City Coordinates': '40.479,-74.424',
          'Organization Name': 'Rutgers University'
        },
        {
          'City Coordinates': '40.452,49.733',
          'Organization Name': 'Bakı Mühəndislik Universiteti'
        },
        {
          'City Coordinates': '40.444,-79.943',
          'Organization Name': 'Carnegie Mellon University'
        },
        {
          'City Coordinates': '40.429,-3.711',
          'Organization Name': 'Universidad Pontificia Comillas'
        },
        {
          'City Coordinates': '40.428,-86.914',
          'Organization Name': 'Purdue University'
        },
        {
          'City Coordinates': '40.317,-74.644',
          'Organization Name': 'Princeton University'
        },
        {
          'City Coordinates': '40.295,-111.729',
          'Organization Name': 'Utah Valley University'
        },
        {
          'City Coordinates': '40.193,44.504',
          'Organization Name': 'American University of Armenia'
        },
        {
          'City Coordinates': '40.006,-83.016',
          'Organization Name': 'The Ohio State University'
        },
        {
          'City Coordinates': '4.71,-74.072',
          'Organization Name': 'UniversidadDistrital'
        },
        {
          'City Coordinates': '4.634,-74.079',
          'Organization Name': 'Universidad Nacional de Colombia'
        },
        {
          'City Coordinates': '4.601,-74.066',
          'Organization Name': 'Universidad de los Andes'
        },
        {
          'City Coordinates': '39.98,-75.157',
          'Organization Name': 'Temple University'
        },
        {
          'City Coordinates': '39.966,-86.008',
          'Organization Name': 'Eleven Fifty Academy'
        },
        {
          'City Coordinates': '39.818,32.562',
          'Organization Name': 'Çankaya Üniversitesi'
        },
        {
          'City Coordinates': '39.803,-86.158',
          'Organization Name': 'Ivy Tech Community College'
        },
        {
          'City Coordinates': '39.766,-86.158',
          'Organization Name': 'Kenzie Academy'
        },
        { 'City Coordinates': '39.758,-94.794', 'Organization Name': 'Mizzou' },
        {
          'City Coordinates': '39.681,-104.962',
          'Organization Name': 'University of Denver'
        },
        { 'City Coordinates': '39.255,-76.715', 'Organization Name': 'UMBC' },
        {
          'City Coordinates': '39.131,-84.517',
          'Organization Name': 'University of Cincinnati'
        },
        {
          'City Coordinates': '39.033,-84.459',
          'Organization Name': 'Northern Kentucky University'
        },
        {
          'City Coordinates': '38.99,-76.944',
          'Organization Name': 'University of Maryland'
        },
        {
          'City Coordinates': '38.93,-77.218',
          'Organization Name': 'Cybertek'
        },
        { 'City Coordinates': '38.755,-9.117', 'Organization Name': 'ISEL' },
        {
          'City Coordinates': '38.539,-121.754',
          'Organization Name': 'UC Davis'
        },
        {
          'City Coordinates': '38.455,27.228',
          'Organization Name': 'Ege Üniversitesi'
        },
        {
          'City Coordinates': '38.052,-84.492',
          'Organization Name': 'Transylvania University'
        },
        {
          'City Coordinates': '38.034,-78.5',
          'Organization Name': 'University of Virginia'
        },
        {
          'City Coordinates': '37.979,-121.309',
          'Organization Name': 'University of the Pacific'
        },
        {
          'City Coordinates': '37.869,-122.258',
          'Organization Name': 'UC Berkeley'
        },
        {
          'City Coordinates': '37.792,-122.399',
          'Organization Name': 'Holberton School'
        },
        {
          'City Coordinates': '37.791,-122.402',
          'Organization Name': 'Lambda School'
        },
        {
          'City Coordinates': '37.725,-122.45',
          'Organization Name': 'City College of San Francisco'
        },
        {
          'City Coordinates': '37.644,-121.873',
          'Organization Name': 'Lodestone'
        },
        {
          'City Coordinates': '37.601,-93.411',
          'Organization Name': 'Southwest Baptist University'
        },
        {
          'City Coordinates': '37.551,-77.451',
          'Organization Name': 'Virginia Commonwealth University'
        },
        {
          'City Coordinates': '37.484,-122.145',
          'Organization Name': 'Georg-Simon-Ohm-Schule K�ln'
        },
        {
          'City Coordinates': '37.431,-78.656',
          'Organization Name': 'Lynchburg City Schools'
        },
        {
          'City Coordinates': '37.386,-122.06',
          'Organization Name': 'Coursera'
        },
        {
          'City Coordinates': '37.363,-120.431',
          'Organization Name': 'UC Merced'
        },
        {
          'City Coordinates': '37.349,-121.94',
          'Organization Name': 'Santa Clara University'
        },
        {
          'City Coordinates': '37.229,-80.42',
          'Organization Name': 'Virginia Tech'
        },
        {
          'City Coordinates': '36.148,-86.804',
          'Organization Name': 'Vanderbilt University'
        },
        {
          'City Coordinates': '36.132,-86.756',
          'Organization Name': 'Nashville Software School'
        },
        { 'City Coordinates': '36.105,-115.141', 'Organization Name': 'UNLV' },
        {
          'City Coordinates': '35.912,-79.055',
          'Organization Name': 'University of North Carolina at Chapel Hill'
        },
        {
          'City Coordinates': '35.907,127.766',
          'Organization Name': "Sookmyung Women's Univ."
        },
        {
          'City Coordinates': '35.751,104.286',
          'Organization Name': 'Lanzhou University'
        },
        {
          'City Coordinates': '35.303,-80.73',
          'Organization Name': 'The University of North Carolina at Charlotte'
        },
        {
          'City Coordinates': '35.077,-92.457',
          'Organization Name': 'University of Central Arkansas'
        },
        {
          'City Coordinates': '34.966,-85.374',
          'Organization Name': 'Covenant College'
        },
        {
          'City Coordinates': '34.682,-82.781',
          'Organization Name': 'Clemson University'
        },
        {
          'City Coordinates': '34.141,-118.133',
          'Organization Name': 'University of the People'
        },
        {
          'City Coordinates': '34.135,-118.127',
          'Organization Name': 'Caltech'
        },
        {
          'City Coordinates': '33.97,-118.419',
          'Organization Name': 'Loyola Marymount University'
        },
        {
          'City Coordinates': '33.905,-83.314',
          'Organization Name': 'University of Georgia'
        },
        {
          'City Coordinates': '33.898,35.477',
          'Organization Name': 'American University of Beirut'
        },
        {
          'City Coordinates': '33.881,-117.895',
          'Organization Name': 'California State University, Fullerton'
        },
        {
          'City Coordinates': '33.782,-118.122',
          'Organization Name': 'California State University, Long Beach'
        },
        {
          'City Coordinates': '33.778,76.576',
          'Organization Name': 'SMVD University'
        },
        // {"City Coordinates":"33.778,76.576","Organization Name":"Microbus"},
        {
          'City Coordinates': '33.771,-84.389',
          'Organization Name': 'Georgia Tech'
        },
        {
          'City Coordinates': '33.753,-84.385',
          'Organization Name': 'Georgia State University'
        },
        {
          'City Coordinates': '33.691,-112.538',
          'Organization Name': 'Arizona State University'
        },
        {
          'City Coordinates': '33.674,-117.167',
          'Organization Name': 'Mt. San Jacinto College'
        },
        {
          'City Coordinates': '33.666,73.15',
          'Organization Name': 'COMSATS University Islamabad'
        },
        {
          'City Coordinates': '33.636,-117.836',
          'Organization Name': 'University of California, Irvine'
        },
        {
          'City Coordinates': '32.978,-96.748',
          'Organization Name': 'University of Texas at Dallas'
        },
        {
          'City Coordinates': '32.729,-97.115',
          'Organization Name': 'The University of Texas at Arlington'
        },
        {
          'City Coordinates': '32.609,74.147',
          'Organization Name': 'University of Gujrat'
        },
        {
          'City Coordinates': '31.147,75.341',
          'Organization Name': 'NIT Jalandhar'
        },
        {
          'City Coordinates': '30.981,76.542',
          'Organization Name': 'Indian Institute of Technology Ropar'
        },
        {
          'City Coordinates': '30.612,-96.34',
          'Organization Name': 'Texas A\u0026M University'
        },
        {
          'City Coordinates': '30.517,76.659',
          'Organization Name': 'Chitkara University'
        },
        { 'City Coordinates': '30.415,77.966', 'Organization Name': 'UPES' },
        {
          'City Coordinates': '30.354,76.362',
          'Organization Name': 'Thapar University'
        },
        {
          'City Coordinates': '30.287,-97.739',
          'Organization Name': 'The University of Texas at Austin'
        },
        {
          'City Coordinates': '30.066,79.019',
          'Organization Name': 'nituk.ac.in'
        },
        {
          'City Coordinates': '29.994,31.435',
          'Organization Name': 'Ain Shams University'
        },
        {
          'City Coordinates': '29.72,-95.396',
          'Organization Name': 'Rice University'
        },
        {
          'City Coordinates': '29.651,-82.324',
          'Organization Name': 'University of Florida'
        },
        {
          'City Coordinates': '29.058,76.085',
          'Organization Name': 'Engifest'
        },
        {
          'City Coordinates': '29.058,76.085',
          'Organization Name': 'Ajay Kumar Garg Engg. College'
        },
        {
          'City Coordinates': '28.718,77.108',
          'Organization Name': 'JIMS Sec 5 Rohini'
        },
        {
          'City Coordinates': '28.704,77.102',
          'Organization Name': 'ipu.ac.in'
        },
        {
          'City Coordinates': '28.704,77.102',
          'Organization Name': 'Sri Guru Gobind Singh College of Commerce'
        },
        {
          'City Coordinates': '28.704,77.102',
          'Organization Name': 'Netaji Subhas Institute of Technology'
        },
        {
          'City Coordinates': '28.704,77.102',
          'Organization Name': 'Bennett University'
        },
        {
          'City Coordinates': '28.704,77.102',
          'Organization Name':
            'Indira Gandhi Delhi Technical University for Women'
        },
        {
          'City Coordinates': '28.678,77.191',
          'Organization Name': 'University of Delhi'
        },
        {
          'City Coordinates': '28.524,77.574',
          'Organization Name': 'Shiv Nadar University'
        },
        {
          'City Coordinates': '28.365,77.541',
          'Organization Name': 'Galgotias University'
        },
        {
          'City Coordinates': '28.363,75.586',
          'Organization Name': 'BITS Pilani'
        },
        {
          'City Coordinates': '28.291,79.493',
          'Organization Name': 'Invertis University'
        },
        {
          'City Coordinates': '27.625,85.556',
          'Organization Name': 'Kathmandu University'
        },
        {
          'City Coordinates': '26.848,75.805',
          'Organization Name':
            'Malaviya National Institute of Technology Jaipur'
        },
        {
          'City Coordinates': '26.846,80.946',
          'Organization Name': 'Amity University'
        },
        {
          'City Coordinates': '26.846,80.946',
          'Organization Name': 'Training and Placement Office NITH'
        },
        {
          'City Coordinates': '26.846,80.946',
          'Organization Name':
            'Institute of Engineering and Technology, Lucknow'
        },
        {
          'City Coordinates': '26.823,75.867',
          'Organization Name': 'SKIT Jaipur'
        },
        {
          'City Coordinates': '26.768,75.85',
          'Organization Name': 'Poornima College'
        },
        {
          'City Coordinates': '25.65,-100.289',
          'Organization Name': 'Tecnológico de Monterrey'
        },
        {
          'City Coordinates': '25.096,85.313',
          'Organization Name': 'IIIT Ranchi #StayHome #StaySafe'
        },
        {
          'City Coordinates': '23.799,90.456',
          'Organization Name': 'United International University'
        },
        {
          'City Coordinates': '23.754,90.376',
          'Organization Name': 'Daffodil International University'
        },
        {
          'City Coordinates': '23.634,-102.552',
          'Organization Name': 'TecMilenio University'
        },
        { 'City Coordinates': '23.61,85.279', 'Organization Name': 'IIT' },
        {
          'City Coordinates': '23.585,58.405',
          'Organization Name': 'National University of Science and Technology'
        },
        {
          'City Coordinates': '23.404,85.422',
          'Organization Name': 'Birla Institute of Technology'
        },
        {
          'City Coordinates': '23.173,80.042',
          'Organization Name': 'IIITDM Jabalpur'
        },
        {
          'City Coordinates': '23.064,72.439',
          'Organization Name': 'Indus University'
        },
        {
          'City Coordinates': '22.986,87.854',
          'Organization Name': 'NIT Sikkim'
        },
        {
          'City Coordinates': '22.986,87.854',
          'Organization Name': 'Heritage Institute of Technology'
        },
        {
          'City Coordinates': '22.973,78.656',
          'Organization Name': 'Medi-Caps University'
        },
        {
          'City Coordinates': '22.973,78.656',
          'Organization Name': 'iiitm.ac.in'
        },
        {
          'City Coordinates': '22.318,114.179',
          'Organization Name': 'City University of Hong Kong'
        },
        {
          'City Coordinates': '22.314,87.309',
          'Organization Name': 'Indian Institute of Technology Kharagpur'
        },
        {
          'City Coordinates': '22.304,114.179',
          'Organization Name': 'The Hong Kong Polytechnic University'
        },
        {
          'City Coordinates': '22.258,71.192',
          'Organization Name': 'Prompt Softech'
        },
        {
          'City Coordinates': '22.258,71.192',
          'Organization Name': 'A D Patel Institute Of Technology'
        },
        { 'City Coordinates': '21.296,-157.817', 'Organization Name': 'ALU' },
        {
          'City Coordinates': '21.278,81.866',
          'Organization Name':
            'Shri Shankaracharya Institute of Professional Management and Technology'
        },
        {
          'City Coordinates': '20.951,85.098',
          'Organization Name': 'VIT Bhopal'
        },
        {
          'City Coordinates': '20.951,85.098',
          'Organization Name': 'NIT Rourkela'
        },
        {
          'City Coordinates': '20.678,-103.361',
          'Organization Name': 'Universidad de Guadalajara'
        },
        { 'City Coordinates': '20.671,-101.372', 'Organization Name': 'UVEG' },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'NIT Durgapur'
        },
        { 'City Coordinates': '20.593,78.962', 'Organization Name': 'BMSCE' },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'Acharya Institutes'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'Academy of Technology'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'gbpec.edu.in'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'IIEST, Shibpur'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'NIT SIlchar'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'Parul University'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'IIITSurat'
        },
        { 'City Coordinates': '20.593,78.962', 'Organization Name': 'PDPU' },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'Guru Nanak Dev University'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'IIIT Bhubaneswar'
        },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'Visvesvaraya National Institute of Technology'
        },
        { 'City Coordinates': '20.593,78.962', 'Organization Name': 'BBDEC' },
        {
          'City Coordinates': '20.593,78.962',
          'Organization Name': 'TKMCE Mirror'
        },
        { 'City Coordinates': '20.593,78.962', 'Organization Name': 'AKTU' },
        { 'City Coordinates': '20.593,78.962', 'Organization Name': 'ksit' },
        {
          'City Coordinates': '20.358,85.833',
          'Organization Name': 'KIIT University'
        },
        {
          'City Coordinates': '20.251,85.812',
          'Organization Name':
            'Centurion University of Technology \u0026 Management'
        },
        { 'City Coordinates': '2.194,102.249', 'Organization Name': 'UTeM' },
        {
          'City Coordinates': '19.751,75.713',
          'Organization Name':
            "Marathwada Mitra Mandal's College of Engineering"
        },
        {
          'City Coordinates': '19.751,75.713',
          'Organization Name': 'Ajeenkya DY Patil University'
        },
        {
          'City Coordinates': '19.52,-96.917',
          'Organization Name': 'Comunicación UV'
        },
        {
          'City Coordinates': '19.188,72.819',
          'Organization Name':
            'Atharva College of Engineering, Malad - W, Mumbai'
        },
        {
          'City Coordinates': '19.123,72.909',
          'Organization Name': 'IIT Bombay'
        },
        {
          'City Coordinates': '19.103,72.836',
          'Organization Name':
            'NMIMS Global Access School for Continuing Education'
        },
        {
          'City Coordinates': '19.047,83.831',
          'Organization Name': 'GIET University'
        },
        {
          'City Coordinates': '19.021,72.87',
          'Organization Name': 'Vidyalankar Institute of Technology'
        },
        {
          'City Coordinates': '19.021,72.87',
          'Organization Name': 'Vidyalankar School Of Information Technology'
        },
        { 'City Coordinates': '18.994,72.816', 'Organization Name': 'UpGrad' },
        { 'City Coordinates': '18.552,73.826', 'Organization Name': 'CDAC' },
        {
          'City Coordinates': '18.52,73.856',
          'Organization Name': 'MIT World Peace University'
        },
        {
          'City Coordinates': '18.508,73.835',
          'Organization Name': 'Sinhgad Institutes'
        },
        { 'City Coordinates': '18.486,-69.931', 'Organization Name': 'ITLA' },
        {
          'City Coordinates': '18.461,-69.916',
          'Organization Name': 'Universidad Autónoma de Santo Domingo'
        },
        {
          'City Coordinates': '18.112,79.019',
          'Organization Name': 'Chaitanya Bharathi Institute of Technology'
        },
        {
          'City Coordinates': '18.112,79.019',
          'Organization Name': 'NIT Warangal'
        },
        {
          'City Coordinates': '18.112,79.019',
          'Organization Name': 'uceou.edu'
        },
        {
          'City Coordinates': '17.444,78.383',
          'Organization Name': 'The Narayana Group'
        },
        {
          'City Coordinates': '17.438,78.175',
          'Organization Name': 'ICFAI Business School'
        },
        { 'City Coordinates': '17.395,78.489', 'Organization Name': 'KMIT' },
        {
          'City Coordinates': '16.791,80.822',
          'Organization Name':
            'Rajiv Gandhi University of Knowledge Technologies Nuzvid'
        },
        {
          'City Coordinates': '15.912,79.739',
          'Organization Name': 'GITAM Deemed University'
        },
        { 'City Coordinates': '15.912,79.739', 'Organization Name': 'VIT-AP' },
        {
          'City Coordinates': '15.814,74.487',
          'Organization Name': 'Rotaract Club of GIT'
        },
        {
          'City Coordinates': '15.317,75.713',
          'Organization Name': 'Indian Institute of Technology Dharwad'
        },
        {
          'City Coordinates': '15.317,75.713',
          'Organization Name': 'Cochin University'
        },
        {
          'City Coordinates': '15.317,75.713',
          'Organization Name': 'B.M.S. Institute of Technology'
        },
        {
          'City Coordinates': '15.317,75.713',
          'Organization Name': 'msrit.edu'
        },
        { 'City Coordinates': '15.317,75.713', 'Organization Name': 'IRIS' },
        {
          'City Coordinates': '15.317,75.713',
          'Organization Name': 'Indian Institute Of Technology'
        },
        // {"City Coordinates":"13.622,79.409","Organization Name":"Weather of South India"},
        { 'City Coordinates': '13.349,74.786', 'Organization Name': 'Manipal' },
        {
          'City Coordinates': '13.151,77.609',
          'Organization Name': 'Sir M. Visvesvaraya Institute of Technology'
        },
        {
          'City Coordinates': '12.966,77.712',
          'Organization Name': 'CMR Institute of Technology'
        },
        {
          'City Coordinates': '12.93,77.543',
          'Organization Name': 'PES University'
        },
        { 'City Coordinates': '12.918,77.591', 'Organization Name': 'Jain' },
        {
          'City Coordinates': '12.916,79.132',
          'Organization Name': 'Vellore Institute of Technology'
        },
        {
          'City Coordinates': '12.879,121.774',
          'Organization Name': 'Bohol Island State University'
        },
        {
          'City Coordinates': '12.856,79.727',
          'Organization Name':
            'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya'
        },
        {
          'City Coordinates': '12.752,80.195',
          'Organization Name': 'SSN Institutions'
        },
        {
          'City Coordinates': '11.127,78.656',
          'Organization Name': 'SRM Institute of Science and Technology'
        },
        {
          'City Coordinates': '11.102,76.965',
          'Organization Name': 'Sri Ramakrishna Engineering College'
        },
        { 'City Coordinates': '11.056,76.984', 'Organization Name': 'KCT' },
        {
          'City Coordinates': '11.054,106.665',
          'Organization Name': 'Vietnamese-German University'
        },
        { 'City Coordinates': '10.85,76.271', 'Organization Name': 'NITC' },
        {
          'City Coordinates': '10.78,78.694',
          'Organization Name': 'Jamal Mohamed College'
        },
        {
          'City Coordinates': '10.144,-85.453',
          'Organization Name': 'Universidad Nacional Costa Rica'
        },
        {
          'City Coordinates': '1.341,103.963',
          'Organization Name': 'Singapore University of Technology and Design'
        },
        {
          'City Coordinates': '-8.099,-79.019',
          'Organization Name': 'Universidad Privada del Norte'
        },
        {
          'City Coordinates': '-8.047,-34.876',
          'Organization Name': 'cesarschool'
        },
        {
          'City Coordinates': '-7.536,112.238',
          'Organization Name': 'Brawijaya University'
        },
        { 'City Coordinates': '-7.131,-34.881', 'Organization Name': 'IFPB' },
        {
          'City Coordinates': '-6.973,107.63',
          'Organization Name': 'Telkom University'
        },
        {
          'City Coordinates': '-6.926,107.774',
          'Organization Name': 'Universitas Padjadjaran'
        },
        {
          'City Coordinates': '-37.91,145.136',
          'Organization Name': 'Monash University'
        },
        {
          'City Coordinates': '-36.88,174.707',
          'Organization Name': 'Unitec Institute of Technology'
        },
        {
          'City Coordinates': '-36.854,174.765',
          'Organization Name': 'Auckland University of Technology'
        },
        {
          'City Coordinates': '-36.848,174.763',
          'Organization Name': 'MISSION READY'
        },
        {
          'City Coordinates': '-34.562,-58.456',
          'Organization Name': 'Plataforma 5 - Coding Bootcamp'
        },
        { 'City Coordinates': '-33.917,151.236', 'Organization Name': 'UNSW' },
        {
          'City Coordinates': '-33.888,151.187',
          'Organization Name': 'University of Sydney'
        },
        {
          'City Coordinates': '-33.227,21.856',
          'Organization Name': '#IamUWC'
        },
        { 'City Coordinates': '-25.252,-52.021', 'Organization Name': 'UTFPR' },
        {
          'City Coordinates': '-23.963,-46.321',
          'Organization Name': 'unisantaoficial'
        },
        { 'City Coordinates': '-23.574,-46.623', 'Organization Name': 'FIAP' },
        {
          'City Coordinates': '-23.524,-46.622',
          'Organization Name': 'Instituto Federal de Educação'
        },
        {
          'City Coordinates': '-1.31,36.825',
          'Organization Name': 'Kenyatta University'
        },
        {
          'City Coordinates': '-0.789,113.921',
          'Organization Name': 'Humas PNJ'
        },
        {
          'City Coordinates': '-0.789,113.921',
          'Organization Name': 'Politeknik Negeri Semarang'
        },
        { 'City Coordinates': '-0.302,-78.456', 'Organization Name': 'ESPE' }
      ]
    };
  }

  componentDidMount() {
    const { rawUniversitiesData } = this.state;
    const universities = [];

    // Loop over raw JSON data in state
    // Create working JS object
    rawUniversitiesData.forEach((item) => {
      const coords = item['City Coordinates'].split(',');
      universities.push({
        organizationName: item['Organization Name'],
        coordinates: {
          latitude: parseFloat(coords[0]),
          longitude: parseFloat(coords[1])
        }
      });
    });

    const script2 = document.createElement('script');
    script2.src = 'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.js';
    script2.async = true;
    document.body.appendChild(script2);

    const checker = () => {
      if (window.mapboxgl) {
        mapboxgl.accessToken =
          'pk.eyJ1IjoicG0tbWFya2VuZyIsImEiOiJja250OG5vZnIwMGk3MnBycTR5bDVuNG90In0.SQ92XcrZPwDE1DfNoIUX5A';
        const map = new mapboxgl.Map({
          container: 'map-2',
          // style: 'mapbox://styles/pm-markeng/ckokhbypp1py617quugfkb6u4',
          style: 'mapbox://styles/mapbox/light-v10',
          center: [14, 23],
          zoom: 1.4,
          maxZoom: 9.5,
          minZoom: 1.4
        });

        const nav = new mapboxgl.NavigationControl({ showCompass: false });
        map.addControl(nav, 'top-left');

        map.scrollZoom.disable();

        universities.forEach((item) => {
          const el = document.createElement('div');
          el.className = 'marker';

          new mapboxgl.Marker(el)
            .setLngLat([item.coordinates.longitude, item.coordinates.latitude])
            .setPopup(
              new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
                `<p class='mb-0 px-3'><strong>${item.organizationName}<strong></p>`
              )
            )
            .addTo(map);
        });
      }
    };

    setTimeout(checker, 1000);
  }

  render() {
    return (
      <MapContainer className="">
        <div id="map-2" />
      </MapContainer>
    );
  }
}

const mapVisualizationSection = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Helmet>
              <link
                href="https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css"
                rel="stylesheet"
              />
            </Helmet>
            <Map />
          </div>
        </div>
      </div>
    </section>
  );
};

export default mapVisualizationSection;
