import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dcidQuery from '../../../utils/dcidQuery';

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

export const PrimaryButtonStyles = styled.a`
  appearance: none;
  border: 1px solid transparent;
  border-radius: ${(props) => props.theme.borderRadius.small};
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  line-height: 1.5;
  position: relative;
  transition: ${(props) => props.theme.transitions.all};
  user-select: none;
  color: ${(props) => props.theme.colors.white} !important;
  background-color: ${(props) => props.theme.colors.orange_50};
  border-color: ${(props) => props.theme.colors.orange_50};
  padding: 8px 16px;
  text-align: center;
  margin: ${(props) => (props.left ? '0 16px 0 0' : '0')};

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &:disabled,
  &.isDisabled {
    color: ${(props) => props.theme.colors.grey_2};
    cursor: not-allowed;
    background-image: none !important;
    background: ${(props) => props.theme.colors.grey_2};
    border-color: transparent;
  }
`;

class RefLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      dcid: ''
    };
  }

  componentDidMount() {
    this.setState({
      dcid: dcidQuery()
    });
  }

  render() {
    const {
      dcid,
      category,
      label,
      id,
      href,
      text,
      className,
      rel,
      target,
      ariaLabel
    } = this.state;
    return (
      <PrimaryButtonStyles
        href={`${href}${dcid}`}
        rel={rel}
        target={target}
        onClick={() => {
          triggerGA(category, label);
        }}
        id={id}
        className={className}
        aria-label={ariaLabel && ariaLabel}
      >
        {text}
      </PrimaryButtonStyles>
    );
  }
}

function PrimaryButton(props) {
  const { className, ariaLabel = null } = props;
  const { data } = props;
  let rel = '';
  let target = '';
  if (data.target === 'new-tab-postman') {
    rel = 'noopener';
    target = '_blank';
  } else if (data.target === 'new-tab-external-company') {
    rel = 'noopener noreferrer';
    target = '_blank';
  }
  const button = (
    <RefLink
      href={`${data.url}`}
      rel={rel}
      target={target}
      category={data.category}
      label={data.label}
      id={data.id}
      className={className}
      text={data.text}
      ariaLabel={ariaLabel && ariaLabel}
    />
  );
  return { button };
}

PrimaryButton.propTypes = {
  ariaLabel: PropTypes.string,
  data: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
    category: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

PrimaryButton.defaultProps = {
  ariaLabel: '',
  className: ''
};

export default PrimaryButton;
