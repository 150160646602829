import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Paragraph } from 'aether-marketing';
import CardSquareWrapper from './CardSquareWrapper';

const CardBody = styled.div`
  padding: 16px;

  div > p {
    &.grey {
      color: ${(props) => props.theme.colors.grey_50} !important;
    }
  }
`;

const ViewCardHover = styled.a`
  &:hover {
    text-decoration: none;
    transform: translateY(-0.5rem);
  }
  -webkit-transition: ${(props) => props.theme.transitions.all};
  transition: ${(props) => props.theme.transitions.all};
  &:focus {
    outline: none;
  }
`;

const CardImage = styled.img`
  min-width: 100%;
`;

function CardSpeakerFeature({
  col, // bootstrap classes for col width
  media,
  header,
  titleTalk,
  date,
  time,
  text,
  logo,
  className,
  link,
  marginBottom
}) {
  return (
    <div
      className={`${col || 'col-lg-6'} ${className && className}`}
      style={{
        marginBottom: marginBottom || null
      }}
    >
      <CardSquareWrapper className="h-100 d-flex flex-column secondary-border">
        <ViewCardHover href={link && link.href ? link.href : null}>
          <CardImage className="img-fluid" src={media.src} alt={media.alt} />
          <CardBody>
            {date && (
              <div className="row p-0">
                <div className="col-5 text-left">
                  <Paragraph textColor="grey_50">{date}</Paragraph>
                </div>
                <div className="col-7 text-right">
                  <Paragraph textColor="grey_50">{time}</Paragraph>
                </div>
              </div>
            )}
            {titleTalk && (
              <div className="text-left">
                <h3>{titleTalk}</h3>
              </div>
            )}
            <div className="d-flex">
              {logo && (
                <img
                  src={logo}
                  alt={media.alt}
                  fetchpriority="low"
                  loading="lazy"
                  style={{
                    height: '40px',
                    paddingRight: '16px'
                  }}
                />
              )}
              <div>
                <Paragraph className="h4 mb-1">{header}</Paragraph>
                <Paragraph>{text}</Paragraph>
              </div>
            </div>
          </CardBody>
        </ViewCardHover>
      </CardSquareWrapper>
    </div>
  );
}

CardSpeakerFeature.propTypes = {
  col: PropTypes.string.isRequired,
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  link: PropTypes.shape({
    href: PropTypes.string
  }),
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  logo: PropTypes.string,
  className: PropTypes.string,
  marginBottom: PropTypes.string,
  titleTalk: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string
};

CardSpeakerFeature.defaultProps = {
  className: null,
  marginBottom: null,
  link: null,
  logo: null,
  titleTalk: null,
  date: null,
  time: null
};

export default CardSpeakerFeature;
