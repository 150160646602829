import React from 'react';
import { Paragraph } from 'aether-marketing';
import PropTypes from 'prop-types';
// This component is the Aired and Postman Version text

function AiredText(props) {
  const { data } = props;
  /* eslint-disable */
  return (
    <div>
      {data.alternative ? (
        data.event.appVersion ? (
          <>
            <Paragraph className="small mb-2">
              {data.event.date}
            </Paragraph>
            <Paragraph className="small mb-2">
              {data.event.appVersion}
            </Paragraph>
          </>
        ) : (
          <Paragraph className="small mb-2">
            {data.event.date}
          </Paragraph>
        )
      ) : data.event.appVersion ? (
        <>
          <Paragraph className="small mb-2">
            {data.event.date}
          </Paragraph>
          <Paragraph className="small mb-2">
            {data.event.appVersion}
          </Paragraph>
        </>
      ) : (
        <Paragraph className="small mb-2">
          {data.event.date}
        </Paragraph>
      )}
    </div>
  );
  /* eslint-enable */
}

export default AiredText;
AiredText.propTypes = {
  data: PropTypes.shape({
    alternative: PropTypes.string,
    event: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.array
      ])
    )
  }).isRequired
};
