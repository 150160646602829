/* eslint-disable react/prop-types */
import React from 'react';
import FormCheckbox from '../components/FormCheckbox';
import FormLegalText from '../components/FormLegalText';
import FormDropdown from '../components/FormDropdown';
import FormText from '../components/FormText';
import FormTextArea from '../components/FormTextArea';
import FormRadio from '../components/FormRadio';

function handleFormFields({ ...props }) {
  const { type } = props;
  return (
    <>
      {type === 'text' && FormText(props)}
      {type === 'email' && FormText(props)}
      {type === 'number' && FormText(props)}
      {type === 'phone' && FormText(props)}
      {type === 'url' && FormText(props)}
      {type === 'tel' && FormText(props)}
      {type === 'radio' && FormRadio(props)}
      {type === 'select' && FormDropdown(props)}
      {type === 'textarea' && FormTextArea(props)}
      {type === 'checkbox' && FormCheckbox(props)}
      {type === 'htmltext' && FormLegalText(props)}
      {type === 'hidden' && null}
    </>
  );
}
export default handleFormFields;
