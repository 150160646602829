import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SectionStyles, BaseLink } from 'aether-marketing';

function TimeAPI(data) {
  const [dateAPI, setShow] = useState({ timeAPI: null });
  const [contentfulAPI, setContenDate] = useState({
    contentfulAPIstart: null,
    contentfulAPIend: null,
    contenfulAPItext: null,
    contentfulAPIlinkUrl: null,
    contentfulAPIlinkType: null,
    contentfulAPIlinkText: null
  });

  // fetch today's date
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios
      .get(`https://worldtimeapi.org/api/timezone/${tz}`)
      .then((res) => setShow({ timeAPI: res.data.datetime.substring(0, 10) }));
  }, []);

  // fetch runtime BFF content for trustcenteralert
  useEffect(() => {
    window.pmt('bff', [
      'trustcenteralert',
      (d) => {
        const { startDate, endDate, text, link } = JSON.parse(d).shift();
        const { url, text: linkText, type: linkType } = link;
        setContenDate({
          contentfulAPIstart: startDate,
          contentfulAPIend: endDate,
          contenfulAPItext: text,
          contentfulAPIlinkUrl: url,
          contentfulAPIlinkType: linkType,
          contentfulAPIlinkText: linkText
        });
      },
      true
    ]);
  }, []);

  const dateFrom = contentfulAPI.contentfulAPIstart;
  const dateTo = contentfulAPI.contentfulAPIend;
  const dateToday = dateAPI.timeAPI;
  const { backgroundColor, messageType } = data;

  // return true if today's date is between the start and end date set in Contentful
  const showNotification = dateToday > dateFrom && dateToday < dateTo;

  return showNotification ? (
    <SectionStyles backgroundColor={backgroundColor} paddingBottom="0">
      <div className="container">
        <div className="row">
          <div
            className={
              messageType === 'alert'
                ? 'alert alert-warning w-100'
                : 'alert alert-primary w-100'
            }
            role="alert"
          >
            <p className="mb-0">
              {contentfulAPI.contenfulAPItext}&nbsp;
              <BaseLink
                src={contentfulAPI.contentfulAPIlinkUrl}
                target="same-tab"
              >
                {contentfulAPI.contentfulAPIlinkText}
              </BaseLink>
            </p>
          </div>
        </div>
      </div>
    </SectionStyles>
  ) : (
    ''
  );
}
const alertV6 = (data) => <TimeAPI data={data} />;

export default alertV6;
