import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PricingParagraph } from './PricingParagraph';
import PricingTableLarge from './pricingTableLarge';
import PricingTableSmallContainer from './pricingTableSmallContainer';
import { InlineLinkStyles } from './PricingLinks';

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 30px 0;

  .table {
    thead {
      th {
        padding: 24px 16px;
      }
      p {
        color: ${(props) => props.theme.colors.grey_50};
      }
      p.plan-price {
        color: ${(props) => props.theme.colors.grey_90};
      }
    }

    tbody {
      th,
      td {
        padding: 1.6rem;
        position: relative;
        .feature-text {
          margin-right: 0.5rem;
          color: ${(props) => props.theme.colors.grey_90};
        }
      }
      p {
        color: ${(props) => props.theme.colors.grey_90};
      }
    }

    .tr-style {
      border-top: 1px solid #dee2e6;
      &:hover {
        .feature-link {
          ${InlineLinkStyles.componentStyle.rules}
        }
      }
    }
  }
`;

const runtime = typeof document === 'object';
class PlatformPricing extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const { data } = this.state;
    const { event } = data;
    const { viewed } = event;
    const target = document.getElementById('PlanComparison');

    target.setAttribute('data-event-view', viewed);
    if (runtime) {
      [...document.querySelectorAll('[data-toggle="tooltip"]')].forEach(
        (dom) => {
          // eslint-disable-next-line no-undef
          $(dom).tooltip({
            html: true,
            trigger: 'hover focus'
          });
        }
      );
    }
  }

  render() {
    const { data } = this.state;
    const anchorTag =
      data.title && data.title.replace(/\s+/g, '-').toLowerCase();
    return (
      <Section
        id="PlanComparison"
        style={{ backgroundColor: data.backgroundColor }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 id={anchorTag} className="mb-5">
                {data.title}
              </h2>
            </div>
            <div className="col-12 text-center">
              <PricingTableLarge data={data} />
              <PricingTableSmallContainer data={data} />
              {data.subtext && (
                <PricingParagraph className="small text-left">
                  {data.subtext}
                </PricingParagraph>
              )}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

PlatformPricing.propTypes = {
  data: PropTypes.shape({}).isRequired
};

function PlatformPricingSection(data) {
  return (
    <div>
      <PlatformPricing data={data} />
    </div>
  );
}

export default PlatformPricingSection;
