import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImageGridStyles = styled.div`
  background: linear-gradient(180deg, #160720 0%, #210b30 100%);

  overflow-x: clip;
  h2 {
    font-size: 60px;
    font-weight: 600;
    position: relative;
    z-index: 200;
  }

  .img-grid {
    z-index: 999;
    position: relative;
    margin: 10px;
  }

  .borderWrapper {
    background: linear-gradient(45deg, #ff6b00, #ffb422cf, #9027a1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 100;
  }

  .boxTop {
    background-color: #160720;
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 2px;
  }
  .sun {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    background: rgba(255, 180, 34, 0.81);
    background: radial-gradient(circle, #ff6d38 40%, #ffb422);
    position: absolute;
    top: 1400px;
    left: -130px;
    overflow: hidden;
    -webkit-box-shadow: 0 10px 6px -6px #ff6c37;
    -moz-box-shadow: 0 10px 6px -6px #ff6c37;
    box-shadow: 0 0px 26px 6px #ff6c37;
    z-index: 2;
  }

  .duck {
    width: 88%;
  }
  .planet-container {
    display: none;
  }
  .planet {
    background: linear-gradient(180deg, #160720 0%, #210b30 100%);

    background-image: url('https://voyager.postman.com/post-con/postcon-planet.svg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    bottom: -140px;
    right: -910px;
    width: 50%;
    height: 700px;
  }
  @media (min-width: 1850px) {
    .planet {
      bottom: -140px;
      right: -1610px;
    }
  }
  .in-view.initial {
    -webkit-text-fill-color: rgba(95, 25, 87, 1);
  }
  .scrolled-text {
    -webkit-text-fill-color: transparent;
  }
  .in-view {
    background-image: linear-gradient(
      45deg,
      #ff6c37 3.2%,
      #ff8d1e 17%,
      #c74eda 56%
    );
    transition: background-image 8s ease-in-out;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 140px;
  }

  @media (min-width: 968px) {
    /* height: 80vh; */
    h2 {
      margin-left: 70px;
    }
    .img-grid {
      margin-left: 55px;
    }
    .planet-container {
      display: block;
      background: transparent;
      margin-top: -340px;
      height: 500px;
      overflow: hidden;
    }
  }

  @media (max-width: 968px) {
    .duck {
      width: 100%;
    }
    .sun {
      display: none;
    }
    .borderPurple {
      padding: 0px 0px;
    }
  }
  @media (max-width: 367px) {
    h2.text-center.text-lg-left.mb-5.mb-lg-0.in-view.initial {
      height: 200px;
    }
  }
`;
const ImageContainer = styled.img`
  border-radius: 10px;
`;
const GameImage = styled.div`
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  border-radius: 8px 0 8px 0; // Only top left and bottom left corners are rounded
  @media (min-width: 768px) {
    height: 100%;
    background-size: cover;
    background-position: left center;
    border-radius: 10px;
  }
`;
const GameWrapper = styled.div`
  background: #1a0a25;

  margin: 0;
  h2 {
    font-size: 45px;
    margin-left: 12px;
  }

  .button {
    width: 200px;
    padding: 5px 8px;
    border-radius: 8px;
    border: 2px solid #ff6c37;
    background-color: #000000;
  }
  .button a {
    text-decoration: none;
  }
  .button:first-child {
    margin: 0 25px 0 15px;
  }
  .button p {
    font-size: 22px;
    color: white;
    margin-bottom: 0px;
  }
  .button span {
    /* font-size: 28px; */
    font-weight: 600;
  }
  .button:active {
    border: 2px solid #ffff;
  }
  .button:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
    -webkit-box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
    -moz-box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
  }
  .google {
    width: 185px;
  }
  .google span {
    margin-left: 5px;
  }
  @media (max-width: 868px) {
    .button {
      margin: 0 auto;
    }
  }
  @media (min-width: 968px) {
    margin: 0px auto 20px auto;
  }
`;

function ImageGrid({ media1, media2, media3, media4, media6 }) {
  const word1 = useRef(null);
  const word2 = useRef(null);
  const word3 = useRef(null);

  useEffect(() => {
    let lastY = 0;
    const currentWord1 = word1.current;
    const currentWord2 = word2.current;
    const currentWord3 = word3.current;
    const callback1 = (entries) => {
      entries.forEach((entry) => {
        const currentY = entry.boundingClientRect.top;
        const isScrollingUp = currentY > lastY;
        lastY = currentY;

        if (entry.isIntersecting) {
          entry.target.classList.add('scrolled-text');
          entry.target.classList.remove('initial');
        } else if (isScrollingUp && !entry.isIntersecting) {
          entry.target.classList.remove('scrolled-text');
          entry.target.classList.add('initial');
        }
      });
    };
    const callback2 = callback1; // Replace with a different function if needed
    const callback3 = callback1; // Replace with a different function if needed

    const observer1 = new IntersectionObserver(callback1, {
      rootMargin: '0px 0px -175px 0px',
      threshold: 0.5
    });
    const observer2 = new IntersectionObserver(callback2, {
      rootMargin: '0px 0px -150px 0px',
      threshold: 0.5
    });
    const observer3 = new IntersectionObserver(callback3, {
      rootMargin: '0px 0px -100px 0px',
      threshold: 0.5
    });

    if (currentWord1) {
      observer1.observe(currentWord1);
    }
    if (currentWord2) {
      observer2.observe(currentWord2);
    }
    if (currentWord3) {
      observer3.observe(currentWord3);
    }

    return () => {
      if (currentWord1) {
        observer1.unobserve(currentWord1);
      }
      if (currentWord2) {
        observer2.unobserve(currentWord2);
      }
      if (currentWord3) {
        observer3.unobserve(currentWord3);
      }
    };
  }, []);

  return (
    <ImageGridStyles>
      <div className="sun " />
      <div className="row">
        <div className="col-lg-10 order-0 order-lg-0 align-self-center m-lg-auto ">
          <h2 className="text-center text-lg-left mb-5 mb-lg-0 in-view initial">
            <span id="word3" className="initial" ref={word3}>
              Learn,
            </span>{' '}
            <span id="word2" className="initial" ref={word2}>
              connect,
            </span>{' '}
            <span id="word1" className="initial" ref={word1}>
              and have fun
            </span>
          </h2>
          <div className="img-grid mt-3 row row-flex justify-content-center align-content-center">
            <div className="col-lg-8 ">
              <div className="row justify-content-center">
                <ImageContainer
                  className="col-md-6 mb-4 img-fluid"
                  src={media1.src}
                  backgroundSize="cover"
                  height={media1.height ? media1.height : 'null'}
                  marginBottom={media1.marginBottom ? media1.marginBottom : 0}
                  aria-label={media1.alt || ''}
                />

                <ImageContainer
                  className="col-md-6 mb-4 img-fluid"
                  src={media2.src}
                  backgroundSize="cover"
                  height={media2.height ? media2.height : 'null'}
                  marginBottom={media2.marginBottom ? media2.marginBottom : 0}
                  aria-label={media2.alt || ''}
                />
              </div>
              <div className="row">
                <ImageContainer
                  className="col-md-6 mb-4 img-fluid"
                  src={media3.src}
                  backgroundSize="cover "
                  height={media3.height ? media3.height : ''}
                  marginBottom={media3.marginBottom ? media3.marginBottom : 0}
                  aria-label={media3.alt || ''}
                />
                <ImageContainer
                  className="col-md-6 mb-4 img-fluid"
                  src={media4.src}
                  backgroundSize="cover"
                  height={media4.height ? media4.height : ''}
                  marginBottom={media4.marginBottom ? media4.marginBottom : 0}
                  aria-label={media4.alt || ''}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <ImageContainer
                className="img-fluid duck"
                src={media6.src}
                backgroundSize="cover"
                height={media6.height ? media6.height : ''}
                marginBottom={media6.marginBottom ? media6.marginBottom : 0}
                aria-label={media6.alt || ''}
              />
            </div>
          </div>
          <GameWrapper className="col-lg-11 justify-content-center">
            <div className="mt-5 borderWrapper">
              <div className="boxTop">
                <div className="row rounded">
                  <div className="col-md-7">
                    <GameImage
                      style={{
                        backgroundImage:
                          'url(https://voyager.postman.com/illustration/postman-game-image.png)'
                      }}
                    />
                  </div>
                  <div className="col-md-5 p-lg-5">
                    <h2 className="text-white ml-md-0  pr-lg-3">
                      Explore the API-first world
                    </h2>
                    <div className="col-lg-10 px-md-0">
                      <p
                        className="text-white pl-lg-0 "
                        style={{ fontSize: '20px' }}
                      >
                        Begin your journey to the API-first world in our mobile
                        game. Destroy bugs, collect APIs, and have fun.
                      </p>
                    </div>

                    <div className="row mt-lg-5">
                      <button
                        type="button"
                        className="button mb-4 ml-4 ml-lg-3"
                      >
                        <a
                          href="https://apps.apple.com/us/app/postman-api-first-journey/id6470000113"
                          target="new-tab-external-nofollow"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src="https://voyager.postman.com/logo/external/apple-logo-white.svg"
                              alt="Apple Store Logo"
                              className="img-fluid mr-1"
                            />
                            <p className="">
                              Download on the <span>App store</span>
                            </p>
                          </div>
                        </a>
                      </button>

                      <button
                        type="button"
                        className="button mb-4 ml-4 ml-lg-3"
                      >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.Postman.PostmanAPIFirstJourney"
                          target="new-tab-external-nofollow"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src="https://voyager.postman.com/logo/external/google-play-logo.svg"
                              alt="Google Store Logo"
                              className="img-fluid "
                              width="38px"
                            />
                            <p className="google">
                              Get it on <span>Google Play</span>
                            </p>
                          </div>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </GameWrapper>
        </div>
      </div>
      <div className="planet-container">
        <div className="planet" />
      </div>
    </ImageGridStyles>
  );
}

export default ImageGrid;
ImageGrid.propTypes = {
  media1: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string
  }),
  media2: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string
  }),
  media3: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string
  }),
  media4: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string
  }),
  media6: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.string
  })
};
ImageGrid.defaultProps = {
  media1: null,
  media2: null,
  media3: null,
  media4: null,
  media6: null
};
