import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  Paragraph,
  BaseButton
} from 'aether-marketing';

const HeroImg = styled.div`
  background-position: bottom 0px right -65px;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 1202px) {
    background-size: 80%;
  }
  @media (max-width: 992px) {
    background-image: none !important;
  }
`;

function HeroWithLargeImageSectionV6({
  h1,
  titleNote,
  subtitle,
  buttonLeft,
  buttonRight,
  media,
  modal,
  divider
}) {
  // Determine column widths
  // let leftColWidth;
  // let rightColWidth;
  // if (layout === 2) {
  //   leftColWidth = 'col-lg-5';
  //   rightColWidth = 'col-lg-6';
  // } else if (layout === 3) {
  //   leftColWidth = 'col-lg-7';
  //   rightColWidth = 'col-lg-4';
  // } else if (layout === 4) {
  //   leftColWidth = 'col-lg-4';
  //   rightColWidth = 'col-lg-8';
  // } else {
  //   // defaults to layout 1
  //   leftColWidth = 'col-lg-4';
  //   rightColWidth = 'col-lg-7';
  // }

  return (
    <HeroImg
      style={{
        backgroundImage: `url('${media.src}')`
      }}
    >
      <SectionStyles className="position-relative" style={{ zIndex: '-1' }}>
        <div className="container mt-5">
          <div className="row">
            {/* Left Col */}
            <div className="col-12 col-lg-5 order-1 order-lg-0">
              <div className="text-center text-lg-left">
                <h1>{h1}</h1>
                {titleNote && (
                  <Paragraph className="mb-4">{titleNote}</Paragraph>
                )}
                {subtitle && (
                  <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                )}
                {buttonLeft && buttonLeft.text && buttonLeft.src && (
                  <BaseButton
                    buttonType={buttonLeft.buttonType}
                    as="a"
                    src={buttonLeft.src}
                    target={buttonLeft.target}
                    id={buttonLeft.id}
                    gaCategory={buttonLeft.gaCategory}
                    gaLabel={buttonLeft.gaLabel}
                    className={
                      buttonLeft.buttonType === 'inline' ? 'pl-0' : null
                    }
                    modal={modal || null}
                    icon={buttonLeft.icon}
                  >
                    {buttonLeft.text}
                  </BaseButton>
                )}
                {buttonRight && buttonRight.text && buttonRight.src && (
                  <BaseButton
                    buttonType={buttonRight.buttonType}
                    as="a"
                    className="ml-3"
                    src={buttonRight.src}
                    target={buttonRight.target}
                    id={buttonRight.id}
                    gaCategory={buttonRight.gaCategory}
                    gaLabel={buttonRight.gaLabel}
                    icon={buttonRight.icon}
                  >
                    {buttonRight.text}
                  </BaseButton>
                )}
              </div>
            </div>
            {/* <div className='col-12 col-lg-6 d-sm-none d-lg-block'>
                <img className="img-fluid" src={media.src} alt={media.alt} />
              </div> */}
          </div>
        </div>
      </SectionStyles>
      {(divider !== false && <Divider fullWidth />) || null}
    </HeroImg>
  );
}

function HeroWithLargeImageV6(data) {
  const {
    heroHeight,
    layout,
    background,
    h1,
    titleNote,
    media,
    buttonLeft,
    buttonRight,
    subtitle,
    modal,
    divider
  } = data;

  return (
    <HeroWithLargeImageSectionV6
      layout={layout}
      heroHeight={heroHeight}
      background={background}
      h1={h1}
      titleNote={titleNote}
      subtitle={subtitle}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      media={media}
      modal={modal}
      divider={divider}
    />
  );
}

HeroWithLargeImageSectionV6.propTypes = {
  background: PropTypes.shape({
    type: PropTypes.string,
    color: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    position: PropTypes.string,
    repeat: PropTypes.string,
    size: PropTypes.string
  }),
  h1: PropTypes.string.isRequired,
  titleNote: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.string),
  buttonLeft: PropTypes.shape({
    buttonType: PropTypes.string,
    text: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string,
    icon: PropTypes.string
  }),
  buttonRight: PropTypes.shape({
    buttonType: PropTypes.string,
    text: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string,
    icon: PropTypes.string
  }),
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    class: PropTypes.string,
    mediaWidth: PropTypes.string
  }).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    src: PropTypes.string
  }),
  divider: PropTypes.bool
};

HeroWithLargeImageSectionV6.defaultProps = {
  background: {
    type: 'solid',
    color: [`${(props) => props.theme.colors.grey_90}`],
    image: null,
    repeat: null,
    position: null,
    size: 'cover'
  },
  titleNote: null,
  subtitle: null,
  buttonLeft: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  buttonRight: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  modal: null,
  divider: null
};

// Use "aboveSplit": true, to render hero above col split on splitTemplate.
// This key is handled on the template level and NOT in this jsx component.

// Example :
// "type": "HeroWithLargeImageV6",
// "layout": 2, (options are 1 through 3)
// "textColor": 1, (1 = white, 2 or not provided makes default black text)
// "background": {
//   "type": "solid", (can be "solid" or "gradient")
//   "color": ["#1C272B"], ("gradient, provided two colors.  If ")
//   "image": "https://voyager.postman.com/illustration/stars-background.svg" (optional, can be used with "gradient")
// },
// "h1": "Lorem Ipsum Dolor Sit", (required)
// "subtitle": "Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.", (optional)
// "buttonLeft": { (optional)
//   "buttonType": "primary", ("primary" or "inline", refer to v5_Button for more options)
//   "text": "Primary",
//   "href": "/",
//   "target": "same-tab", (internal, same tab, new-tab-postman = _blank, noopener, new-tab-external-company = _blank, noopener noreferrer)
//   "id": "testingID",
//   "gaCategory": "primary-ga-category",
//   "gaLabel": "primary-ga-label"
// },
// "buttonRight": { (optional, see above)
//   "buttonType": "primary",
//   "text": "Secondary Button",
//   "href": "/customers/",
//   "target": new-tab-postman",
//   "id": "secondaryTestingID",
//   "gaCategory": "secondary-ga-category",
//   "gaLabel": "secondary-ga-label"
// },
// "media": { (required)
//   "src": "https://voyager.postman.com/post-con/postcon-hero.svg", (required)
//   "alt": "Postman alt" (required)
// },
// aboveSplit: true

export default HeroWithLargeImageV6;
