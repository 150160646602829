import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  UnorderedListStyles,
  OrderedListStyles,
  Paragraph
} from 'aether-marketing';

import { BlockQuoteStyles } from './case-studies/quoteLarge';

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }

  blockquote {
    ${BlockQuoteStyles.componentStyle.rules}
  }
`;

function CaseStudySectionV6Section({
  title,
  body,
  subtitle,
  topical,
  divider,
  colSize
}) {
  // Determine section topic
  let topicImage;
  if (topical === 'challenge') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-challenge.svg';
  } else if (topical === 'solution') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-solution.svg';
  } else if (topical === 'outcome') {
    topicImage =
      'https://voyager.postman.com/illustration/case-studies-outcome.svg';
  } else {
    // collapse image section
    // eslint-disable-next-line no-unused-expressions
    null;
  }

  const anchorTag = title.replace(/\s+/g, '-').toLowerCase();

  const mediaSection = (
    <div className="col-12 mb-5">
      <img
        alt=""
        height="343"
        src={topicImage}
        width="1176"
        className="img-fluid"
        fetchpriority="low"
        loading="lazy"
      />
    </div>
  );

  const textSection = (
    <div className={colSize || 'col-12 col-md-8 offset-md-2'}>
      <div className="text-left">
        <ContentContainer>
          <div className={`${colSize}` ? '' : `col-12 col-md-8 offset-md-2`}>
            {title && <h2 id={anchorTag}>{title}</h2>}
            {subtitle && (
              <Paragraph className="subtitle mb-4">{subtitle}</Paragraph>
            )}
            {body && (
              <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
            )}
          </div>
        </ContentContainer>
      </div>
    </div>
  );

  return (
    <>
      {topicImage && (
        <SectionStyles paddingBottom="0">
          <div className="container">{mediaSection}</div>
        </SectionStyles>
      )}
      <SectionStyles>
        <div className="container">{textSection}</div>
      </SectionStyles>
      {(divider !== false && <Divider />) || null}
    </>
  );
}

const caseStudySectionV6 = (data) => {
  const { title, body, subtitle, topical, divider, colSize } = data;

  return (
    <CaseStudySectionV6Section
      title={title}
      subtitle={subtitle}
      body={body}
      topical={topical}
      divider={divider}
      colSize={colSize}
    />
  );
};

CaseStudySectionV6Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.bool,
  topical: PropTypes.string,
  colSize: PropTypes.string
};

CaseStudySectionV6Section.defaultProps = {
  title: null,
  body: null,
  subtitle: null,
  divider: null,
  topical: null,
  colSize: null
};

export default caseStudySectionV6;
