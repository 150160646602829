import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  width: 100%;
  opacity: ${(props) => (props.isLoaded ? 1 : 0)};
  transition: opacity 0.1s ease-in;

  @media (min-width: 2000px) {
    margin: 0 auto;
    right: 25vw;
    max-width: 1200px;
  }
`;

function PostconResponsiveImage({
  backgroundImageMobile,
  backgroundImage,
  alt
}) {
  const [deviceType, setDeviceType] = useState('desktop');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      const resolution = window.innerWidth;
      const isMobile = resolution <= 600;
      setDeviceType(isMobile ? 'mobile' : 'desktop');
      setIsLoaded(true);
    }

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const imageSrc =
    deviceType === 'mobile' ? backgroundImageMobile : backgroundImage;

  return <BackgroundImage src={imageSrc} alt={alt} isLoaded={isLoaded} />;
}

PostconResponsiveImage.propTypes = {
  backgroundImageMobile: PropTypes.string,
  backgroundImage: PropTypes.string,
  alt: PropTypes.string
};

PostconResponsiveImage.defaultProps = {
  backgroundImageMobile: '',
  backgroundImage: '',
  alt: ''
};

export default PostconResponsiveImage;
