import React from 'react';
import { BaseLink } from 'aether-marketing';
import styled from 'styled-components';
import FeatureHighlights from './featureHighlights';
import Button from '../v5_buttons/Button';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Heading = styled.div`
  p.content {
    font-size: 14px !important;
  }
  a {
    color: #0265d2;
    -webkit-text-decoration: none;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
  a:hover,
  a:active {
    border-bottom: 1px solid #0265d2;
    color: #0265d2;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

const PricingColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  .plan-price {
    & sup {
      position: relative;
      top: 0rem;
      font-size: 28px;
    }
  }

  p {
    margin-bottom: 0;
    color: #212121;
    font-family: 'Inter';
  }
  p.small {
    color: #6b6b6b;
    font-size: 12px;
  }
  p.plan-price {
    font-size: 24px;
  }
`;

const Tag = styled.p`
  font-size: 12px;
  color: #623497;
  border: 1px solid #e4d8f6;
  padding: 0 4px;
  border-radius: 4px;
`;

const FooterNote = styled.div`
  margin-top: 24px;

  p.small {
    color: #212121;
    font-size: 12px;
  }
`;

const ButtonSmall = styled(Button)`
  font-size: 14px;
  i {
    padding: 4px 12px !important;
  }
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

// eslint-disable-next-line react/function-component-definition
/* eslint-disable react/prop-types */
function CTASection({ card }) {
  return (
    <div className="row d-flex align-items-center mt-4">
      <div
        className={
          card.ctaTwo ? 'col-12 col-sm-5 col-md-4 col-lg-6 px-0 pl-2' : 'col-12'
        }
      >
        <ButtonSmall
          buttonType={card.cta.buttonType ? card.cta.buttonType : 'primary'}
          id={card.cta.id}
          href={card.cta.href}
          rel={card.cta.rel}
          target={card.cta.target}
          data-url={card.cta.test}
          dataTest={card.cta.test}
          event={card.cta.event}
          eventProp={card.cta.eventProp}
          onClick={() => {
            triggerGA(card.cta.category, card.cta.label);
          }}
          aria-label={card.cta.ariaLabel}
        >
          {card.cta.title}
        </ButtonSmall>
      </div>

      {card.ctaTwo && (
        <div className="col-12 col-sm-6 pl-md-0 mt-2 mt-xl-0 pl-2 pl-md-0">
          <BaseLink
            linkType="arrowLink"
            id={card.ctaTwo.id}
            dataTest={card.ctaTwo.test}
            aria-label={card.ctaTwo.ariaLabel}
            src={card.ctaTwo.href}
            target={card.ctaTwo.target}
            title={card.ctaTwo.title}
            rel={card.ctaTwo.rel}
            event={card.ctaTwo.event}
            eventProp={card.ctaTwo.eventProp}
            onClick={() => {
              triggerGA('pricing-page', `${card.ctaTwo.id}`);
            }}
          >
            {card.ctaTwo.title}
          </BaseLink>
        </div>
      )}
    </div>
  );
}

function cardIconChecklistSection(data) {
  const { cards, tag } = data;
  // const anchorTag = data.title.replace(/\s+/g, '-').toLowerCase();
  return (
    <div className="row">
      {tag && <Tag>{tag}</Tag>}
      {cards.map((card) => (
        <div className="col-12" key={pmUuid()}>
          <div>
            {card.title && (
              <Heading
                dangerouslySetInnerHTML={{
                  __html: card.title.join('')
                }}
              />
            )}
          </div>
          <FeatureHighlights data={card.items} />
          <PricingColumn>
            <div
              dangerouslySetInnerHTML={{
                __html: card.body.join('')
              }}
            />
            {card.cta && <CTASection card={card} />}
          </PricingColumn>
          {data.footer && (
            <FooterNote
              dangerouslySetInnerHTML={{
                __html: data.footer.join('')
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default cardIconChecklistSection;
