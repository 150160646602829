/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { BaseLinkStyles } from 'aether-marketing';

import FormError from './FormError';
import { LabelStyled } from './FormLabel';

/*
 * Single and Multicheckboxes
 *
 * Marketo type: "single_checkbox" or "checkbox"
 * Standard type: "checkbox"
 *
 * Need to add a label to a multi-checklist? (e.g. postman-v10.json)
 *
 *  "addOnCheckboxLabels":[
 *    {
 *     "name": "partnerWorkspace",
 *     "text": [
 *          "<p>Which areas interest you?</p>"
 *       ]
 *     }
 *   ]
 */

export const CheckboxContainer = styled.div`
  color: ${(props) => props.theme.forms.text};
  display: inline-flex;
  font-size: 14px !important;
  margin-bottom: 0px;
  padding: 0;
  text-align: left;
  width: 100%;

  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  @media screen and (max-width: 992px) {
    display: inline-flex;
    margin: 0;
  }

  &.form-check {
    padding-left: 0;
  }
`;

export const CheckboxInput = styled.input`
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  &:hover {
    cursor: pointer;
  }

  &[type='checkbox'] {
    display: none;
  }
  &[type='checkbox'] + label:before {
    display: inline-block;
  }

  /* unchecked icon */
  &[type='checkbox'] + label:before {
    margin-right: 16px;
    margin-bottom: 10px;
  }

  /* checked icon */
  &[type='checkbox']:checked + label:before {
    color: #049d40;
    content: url('https://voyager.postman.com/icon/checkbox-outline-green-icon.svg');
  }

  &:focus + label:before {
    border-color: #0265d2;
    box-shadow: 0 0 0 2px #adcdfb;
  }
  &:checked + label:before {
    border: none;
    background-size: 10px;
  }
  // checkbox
  + label:before {
    border: 1px solid ${(props) => props.theme.forms.border};
    border-radius: 2px;
    color: ${(props) => props.theme.colors.grey_90};
    content: '';
    display: inline-block;
    height: 16px;
    position: absolute;
    top: 2px;
    left: 0;
    transition: background-size 0.2s ease-in-out;
    width: 16px;
  }

  + label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    padding-left: 24px;
    position: relative;
    width: 100% !important;
    span {
      font-size: 14px !important;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const CheckboxTitle = styled.label`
  p {
    ${LabelStyled.componentStyle.rules};
  }
`;

function FormCheckbox(props) {
  const [field, meta] = useField({
    ...props
  });

  const {
    name,
    inputlabel,
    type,
    selectValues,
    accessibleTitle,
    VisibilityRule,
    mkId,
    addOnCheckboxLabels
  } = props;
  return (
    <div
      id={VisibilityRule ? VisibilityRule.rules[0].subjectField : ''}
      className={
        name === 'honeypot' || VisibilityRule
          ? 'd-none form-row'
          : 'form-row pl-1'
      }
    >
      {addOnCheckboxLabels &&
        addOnCheckboxLabels.map(
          (customLabel) =>
            customLabel.name === name && (
              <div key={customLabel}>
                <CheckboxTitle
                  className="mb-2"
                  dangerouslySetInnerHTML={{ __html: customLabel.text }}
                />
              </div>
            )
        )}

      {selectValues.length > 1 && (
        <>
          <div className="form-group col-12">
            <CheckboxContainer className="form-check d-block">
              <div className="mt-1 mb-1">
                {inputlabel !== 'GDPR_OptIn:' &&
                  inputlabel !== 'GDPR Consent:' &&
                  inputlabel}
              </div>
              {selectValues.map((item, idx) =>
                item.value === 'NULL' ? null : (
                  <span key={item.value}>
                    <CheckboxInput
                      id={`mktoCheckbox_${mkId}_${idx}`}
                      role="group"
                      type={type === 'hidden' ? 'hidden' : 'checkbox'}
                      className={`form-check-input mktoField ${
                        (VisibilityRule && VisibilityRule.rules[0].values[0]) ||
                        ''
                      }`}
                      name={name}
                      aria-labelledby={name}
                      value={item.value}
                    />
                    <label
                      className="form-check-label"
                      value={item.value}
                      htmlFor={`mktoCheckbox_${mkId}_${idx}`}
                    >
                      {item.label === 'Opt Me In' ? (
                        <span>
                          Yes, sign me up to receive product updates, news,
                          event information, and other marketing communications,
                          at which I can unsubscribe from at a later time.
                        </span>
                      ) : (
                        item.label
                      )}
                    </label>
                  </span>
                )
              )}
            </CheckboxContainer>
          </div>
          <div className="col-12">
            {meta.touched && meta.error ? (
              <FormError className="error">{meta.error}</FormError>
            ) : null}
          </div>
        </>
      )}
      {selectValues.length <= 1 && (
        <>
          <div className="form-group col-12">
            <CheckboxContainer className="form-check">
              <CheckboxInput
                className={`form-check-input mktoField ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`}
                type={type === 'hidden' ? 'hidden' : 'checkbox'}
                name={name}
                title={accessibleTitle}
                id={`${name}_`}
                autocomplete="off"
                {...field}
              />

              <label
                className="form-check-label"
                htmlFor={`${name}_`}
                dangerouslySetInnerHTML={{
                  __html:
                    inputlabel !== '' &&
                    inputlabel !== 'GDPR_OptIn:' &&
                    inputlabel !== 'GDPR Consent:'
                      ? inputlabel
                      : 'Yes, sign me up to receive product updates, news, event information, and other marketing communications, at which I can unsubscribe from at a later time.'
                }}
              />
            </CheckboxContainer>
          </div>
          <div className="col-12">
            {meta.touched && meta.error ? (
              <FormError className="error">{meta.error}</FormError>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default FormCheckbox;
