import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import { Divider, SectionStyles, BaseLink, Paragraph } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const FeatureWrapper = styled.div`
  margin-bottom: 5rem;
`;

const ImageWrapper = styled.div`
  margin-bottom: 3rem;

  &.spot {
    height: 12rem;
  }
  &.icon {
    height: 5rem;
  }
`;

const DisabledLink = styled(BaseLink)`
  color: #666 !important;
  cursor: not-allowed;
  background-image: none !important;
  border-color: transparent;
  pointer-events: none;

  &:hover {
    transition: none;
  }
`;

function Card({ data }) {
  const linkData = data.link ? data.link : '';
  if (linkData) {
    linkData.alternative = false;
  }
  const colWidth = data.colWidth ? data.colWidth : 'col-lg-6 col-xl-4';

  return (
    <FeatureWrapper
      className={`${colWidth} pr-md-5 flex-column justify-content-between`}
    >
      <div>
        {data.media && data.imageSize !== null && (
          <ImageWrapper
            className={data.imageSize}
            style={{
              backgroundImage: `url("${data.media.url}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center left'
            }}
          />
        )}
        {data.title && <h3 className="h4 card-title">{data.title}</h3>}
        {data.subtitle && (
          <Paragraph dangerouslySetInnerHTML={{ __html: data.subtitle }} />
        )}
        {data.body && (
          <Paragraph dangerouslySetInnerHTML={{ __html: data.body }} />
        )}
      </div>
      {data.link && (
        <div>
          {/* USE THIS KEY IN YOUR JSON IF YOU WANT A DISABLED LINK (For Webinars) */}
          {data.link.isComingSoon ? (
            <DisabledLink href={linkData.src} target="new-tab-postman">
              {linkData.text}
            </DisabledLink>
          ) : (
            <BaseLink
              linkType="arrowLink"
              src={linkData.src}
              target={linkData.target ? linkData.target : 'new-tab-postman'}
            >
              {linkData.text}
            </BaseLink>
          )}
        </div>
      )}
    </FeatureWrapper>
  );
}

Card.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    colWidth: PropTypes.string,
    imageSize: PropTypes.number,
    link: PropTypes.shape({
      isComingSoon: PropTypes.bool
    }),
    media: PropTypes.shape({
      url: PropTypes.string
    }),
    subtitle: PropTypes.string,
    title: PropTypes.string
  }).isRequired
};

const featureWithSpotV6 = (data) => {
  //  If nesting this component in a splitTemplate,
  //  Use containerClass to give it container-fluid - full width 100%
  const containerClass = data.containerClass
    ? data.containerClass
    : 'container';
  const centerHeader = data.centerHeader ? 'text-center' : '';
  const anchorTag = data.title.replace(/\s+/g, '-').toLowerCase();
  return (
    <>
      <SectionStyles backgroundColor={data.backgroundColor}>
        <div className={containerClass} id={data.id ? data.id : ''}>
          <div className="row">
            <div className="col-sm-12 col-lg-8 pad-md-right pad-lg-right align-self-center">
              <div className="mb-5">
                {data.title && (
                  <h2 className={centerHeader} id={`${anchorTag}`}>
                    {data.title}
                  </h2>
                )}
                {data.body && (
                  <Paragraph
                    className={data.cta ? 'subtitle mb-5' : 'subtitle'}
                    dangerouslySetInnerHTML={{ __html: data.body }}
                  />
                )}
                {data.cta && (
                  <div className="mb-5">
                    <BaseLink
                      linkType="arrowLink"
                      src={data.cta.src}
                      target="new-tab-postman"
                    >
                      {data.cta.text}
                    </BaseLink>
                  </div>
                )}
              </div>
              <div className="row">
                {data.items.map((item) => (
                  <Card key={pmUuid()} data={item} />
                ))}
              </div>
            </div>
            <div className="col-sm-6 offset-sm-3 offset-md-3 offset-lg-0 col-lg-4 align-self-center">
              {data.media && <img src={data.media.url} alt={data.media.alt} />}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(data.divider !== false && <Divider />) || null}
    </>
  );
};

featureWithSpotV6.propTypes = {
  data: PropTypes.shape({
    backgroundColor: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    items: PropTypes.shape([]),
    link: PropTypes.shape({}),
    links: PropTypes.shape([]),
    media: PropTypes.shape({}),
    subtitle: PropTypes.string,
    title: PropTypes.string,
    containerClass: PropTypes.string,
    divider: PropTypes.bool
  }).isRequired
};

export default featureWithSpotV6;
