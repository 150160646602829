// Plain Header (H3) and P tag for sections

import React from 'react';
/* eslint-enable */

import styled from 'styled-components';
import { BaseLink, SectionHeader } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

export const BlockQuoteStyles = styled.blockquote`
  border: 0;
  display: block;
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 60px;
  margin-top: 60px;
  position: relative;
  z-index: 10;

  div {
    position: relative;
  }

  .start-quote,
  .end-quote {
    position: absolute;
    height: fit-content;
    width: fit-content;
    color: ${(props) => props.theme.colors.orange_40};
    font-size: 4.8rem;
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1;
  }

  .start-quote {
    left: -20px;
    top: -3px;
  }

  cite p {
    font-size: 1.6rem; /* 16px */
    font-style: normal;
    margin-top: 24px;
    & strong {
      font-family: ${(props) => props.theme.fonts.Inter};
      font-weight: 600;
    }
  }
`;

const quoteLarge = (data) => (
  <div style={{ paddingTop: '0', paddingBottom: '30px' }}>
    <div className="container">
      {data.title ? (
        <div className="row">
          <div
            className="col-12 offset-lg-3 mb-5 pl-5"
            style={{ marginTop: '60px' }}
          >
            <SectionHeader
              title={data.title}
              body={data.body}
              link={data.link}
              textAlign={data.textAlign}
              bottomMargin="0"
            />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <BlockQuoteStyles>
            <span>{data.quote}</span>
            <cite>
              <p>
                <strong>{data.citationName}</strong>
                {`, ${data.citationTitle}`}
              </p>
            </cite>
          </BlockQuoteStyles>
          {data.ctaLinks && (
            <span>
              {data.ctaLinks &&
                Array.isArray(data.ctaLinks) &&
                data.ctaLinks.map((cta) => (
                  <p className="ml-5" key={pmUuid()}>
                    <BaseLink
                      linkType={cta.type}
                      src={cta.src}
                      className={cta.className && cta.className}
                      alternative={cta.alternative ? cta.alternative : false}
                      target={cta.target ? cta.target : 'same-tab'}
                      gaCategory={cta.gaCategory && cta.gaCategory}
                      gaLabel={cta.gaLabel && cta.gaLabel}
                      id={cta.id && cta.id}
                      ariaLabel={cta.ariaLabel && cta.ariaLabel}
                    >
                      {cta.text}
                    </BaseLink>
                  </p>
                ))}
            </span>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default quoteLarge;
