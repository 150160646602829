import React from 'react';
import styled from 'styled-components';
import FormV7 from './FormV7';

const Section = styled.div`
  padding: 60px 0;
`;

// kitchen-sink
function ExampleForm(data) {
  const { form } = data;
  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12">
            <FormV7 form={form} />
          </div>
        </div>
      </div>
    </Section>
  );
}
export default ExampleForm;
