import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import { BaseLink, ParagraphStyles } from 'aether-marketing';

import CardWrapper from './v5_cards/CardWrapper';
import ImageContainer from './v5_images/ImageContainer';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardText = styled(ParagraphStyles)`
  padding-bottom: 60px;
`;

const dataShape = {
  body: PropTypes.string,
  date: PropTypes.string,
  items: PropTypes.array,
  url: PropTypes.string,
  logo: PropTypes.string,
  leftlinks: PropTypes.array,
  link: PropTypes.object,
  title: PropTypes.string
};

function Card({ data }) {
  return (
    <div className="col-12 col-md-6 col-xl-4 mt-4">
      <CardWrapper className="h-100 justify-content-between text-center">
        <div>
          {data.logo && <ImageContainer alt="" src={data.logo} />}
          {data.media && <ImageContainer alt="" src={data.media.url} />}
          {data.title && <h3 className="h4">{data.title}</h3>}
          {data.body && <CardText>{data.body}</CardText>}
        </div>
        <div>
          {data.date && <p>{data.date}</p>}
          {data.link && (
            <BaseLink src={data.link.src} target={data.link.target}>
              {data.link.text}
            </BaseLink>
          )}
        </div>
      </CardWrapper>
    </div>
  );
}

// type: 'inlineLink',
// alternative: false,
// href: null,
// target: '',
// className: '',
// id: null,
// gaCategory: null,
// gaLabel: null,
// as: 'a',
// ariaLabel: null,
// onClick: false

Card.propTypes = {
  data: PropTypes.shape(dataShape)
};
Card.defaultProps = {
  data: {}
};

class PressReleases extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  render() {
    const { data } = this.state;
    const padding = '60px 0 30px 0';
    const backgroundColor = data.backgroundColor
      ? data.backgroundColor
      : '#FFFFFF';
    //  If nesting this component in a splitTemplate,
    //  Use containerClass to give it container-fluid - full width 100%
    return (
      <section style={{ padding, backgroundColor }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="text-center">{data.title}</h2>
              <div className="row">
                {data.items.map((val) => (
                  <Card data={val} key={pmUuid()} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

PressReleases.propTypes = {
  data: PropTypes.shape(dataShape).isRequired
};

const generalNewsGrid = (data) => (
  <div>
    <PressReleases data={data} />
  </div>
);

export default generalNewsGrid;
