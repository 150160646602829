// Google FAQ Schema: https://developers.google.com/search/docs/appearance/structured-data/generate-structured-data-with-javascript#custom-javascript

//  { "type": "meta", "googleFaqSchema": true }

// checks for H2 element
function findQuestion(header, index) {
  let question;
  if (
    header[index].tagName === 'H2' &&
    header[index].innerText.slice(-1) === '?'
  ) {
    question = `${header[index].innerText}`;
  }
  return question;
}

// checks for paragraph tag next to H2 or nested div
function findAnswer(header, index) {
  let answer;
  if (
    header[index].nextElementSibling &&
    header[index].nextElementSibling.tagName === 'P'
  ) {
    answer = `${header[index].nextElementSibling.innerText}`;
  } else if (
    header[index].nextElementSibling &&
    header[index].nextElementSibling.tagName === 'DIV' &&
    header[index].nextElementSibling.firstChild.tagName === 'P'
  ) {
    answer = `${header[index].nextElementSibling.firstChild.innerText}`;
  }
  return answer;
}

function generateFAQSchema() {
  if (typeof document !== 'undefined') {
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: []
    };
    // get all divs with a child h2 element and filter
    const headers = document.querySelectorAll('div > h2');
    for (let index = 0; index < headers.length; index += 1) {
      const hasQuestion = findQuestion(headers, index);
      const hasAnswer = findAnswer(headers, index);
      if (hasQuestion && hasAnswer) {
        const faqObj = {
          '@type': 'Question',
          name: hasQuestion,
          acceptedAnswer: {
            '@type': 'Answer',
            text: hasAnswer
          }
        };
        schema.mainEntity.push(faqObj);
      }
    }

    // Google limits search results to 7
    if (schema.mainEntity.length >= 8) {
      schema.mainEntity.length = 7;
    }

    // ********* for testing ************
    // console.log(schema);

    // load script
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify(schema);
    document.head.appendChild(script);
  }
}

module.exports = generateFAQSchema;
