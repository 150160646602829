/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-enable */

import updateDisplay from '../../../utils/suppress';
import { PricingParagraph } from './PricingParagraph';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const HighlightsContainer = styled.ul`
  margin: 24px 0 0 0;
  padding-inline-start: 0px;
`;

const Feature = styled.li`
  ${PricingParagraph.componentStyle.rules};
  list-style-type: disc;
  margin-bottom: 8px;
  padding-left: 32px;
  text-align: left;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};

  &.success {
    background-image: url('https://voyager.postman.com/icon/checkbox-outline-green-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 5px;
  }

  &.disabled {
    padding: 0;
    margin-top: 24px;
  }
`;

const Tooltip = styled.a`
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin-left: 6px;
  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const runtime = typeof document === 'object';

// eslint-disable-next-line react/function-component-definition
const FeatureHighlights = ({ data }) => {
  if (runtime) {
    const oPriceToggle = window.priceToggle;

    window.priceToggle = (value) => {
      if (typeof oPriceToggle === 'function') {
        oPriceToggle(value);
      }

      const display = (value === 'month' && 'none') || 'block';

      updateDisplay('featureHighlight', display);
    };
  }
  return (
    <HighlightsContainer>
      {data.map((item) =>
        item.included ? (
          <Feature
            className={item.className ? `${item.className} success` : 'success'}
            data-suppress={(item.suppress && 'featureHighlight') || null}
            key={pmUuid()}
          >
            {item.html ? (
              <div dangerouslySetInnerHTML={{ __html: item.html.join('') }} />
            ) : (
              item.text
            )}
            {item.tooltip && (
              <Tooltip
                tabIndex="0"
                href="##"
                className="pm-tooltip"
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                title={item.tooltip}
              >
                <img
                  src="https://voyager.postman.com/icon/i-info-icon-postman.svg"
                  alt="Question. Icon."
                  style={{ height: '15px', opacity: '0.7' }}
                />
              </Tooltip>
            )}
          </Feature>
        ) : (
          <Feature
            className={
              item.className
                ? `${item.className} disabled font-weight-bold`
                : 'disabled font-weight-bold'
            }
            data-suppress={(item.suppress && 'featureHighlight') || null}
            key={pmUuid()}
          >
            {item.html ? (
              <div dangerouslySetInnerHTML={{ __html: item.html.join('') }} />
            ) : (
              item.text
            )}
          </Feature>
        )
      )}
    </HighlightsContainer>
  );
};

FeatureHighlights.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      included: PropTypes.bool,
      tooltip: PropTypes.string,
      text: PropTypes.string,
      html: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired
};

export default FeatureHighlights;
