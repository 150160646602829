import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { Divider, SectionStyles, SectionHeader } from 'aether-marketing';
import RunInPostmanButton from './RunInPostmanButton';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CardContainer = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  text-align: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
`;

const VisualizerScreenshotContainer = styled.div`
  img {
    -webkit-filter: drop-shadow(${(props) => props.theme.shadows.card_shadow});
    filter: drop-shadow(${(props) => props.theme.shadows.card_shadow});
  }
`;

function pmCollectionGrid(data) {
  return (
    <>
      <SectionStyles backgroundColor={data.backgroundColor}>
        <div className="container">
          <div className="row">
            <SectionHeader
              title={data.title}
              body={data.body}
              link={data.link}
              textAlign={data.textAlign}
              bottomMargin="30px"
            />
          </div>
          <div className="row">
            <CardsGroup>
              {data.items.map((card) => (
                <div
                  className="col-sm-12 col-md-6 text-center mb-5"
                  key={pmUuid()}
                >
                  <CardContainer>
                    <VisualizerScreenshotContainer>
                      <img
                        className="mb-2"
                        src={card.media.url}
                        alt={card.media.alt}
                      />
                    </VisualizerScreenshotContainer>
                    <h4 className="mb-2">{card.name}</h4>
                    <RunInPostmanButton
                      workspaceId={card.button.workspaceId}
                      collectionId={card.button.collectionId}
                    />
                  </CardContainer>
                </div>
              ))}
            </CardsGroup>
          </div>
        </div>
      </SectionStyles>
      {(data.divider === true && <Divider />) || null}
    </>
  );
}

export default pmCollectionGrid;
