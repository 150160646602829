import * as Yup from 'yup';
import { isValidString, isMatchingEmail, isValidUrl } from './ValidationSchema';

// Reference Guide
// parent key indicates that its a dropdown menu with toggable children

// General: ['FirstName', 'LastName']
// StudentExpertPage: {
//   parent: "enrolledinSchool",
//     value: "Yes",
//     children: ['university', 'universityDomain', 'enrolledCourse', 'graduationYear'],
// },
// StudentExpertPage: {
//   parent: "enrolledinSchool",
//     value: "No",
//     children: ['organization']
// },
// StudentEducatorPage: {
//   parent: "IsPostmanUser__c",
//   value: "Yes",
//   children: ['Use_Case__c']
// }

// Declare custom name attribute and assign const variable
const CustomValidation = {
  FirstName: {
    required: isValidString.required('Please enter a valid first name'),
    optional: isValidString
  },
  LastName: {
    required: isValidString.required('Please enter a valid last name'),
    optional: isValidString
  },
  // Student Program Forms (EnrolledInSchool)
  enrolledinSchool: {
    required: Yup.string().required('Please select a valid option'),
    optional: Yup.string()
  },
  university: {
    required: Yup.string().when('enrolledinSchool', {
      is: 'Yes',
      then: isValidString.required('Please enter a school or university'),
      otherwise: Yup.string().when('enrolledinSchool', {
        is: undefined,
        then: isValidString.required('Please enter a school or university'),
        otherwise: isValidString
      })
    }),
    optional: isValidString
  },
  universityDomain: {
    required: Yup.string().when('enrolledinSchool', {
      is: 'Yes',
      then: Yup.string()
        .matches(isValidUrl, 'Please enter valid URL. https://www.example.com/')
        .required('Please enter valid URL. http://www.example.com/'),
      otherwise: Yup.string().when('enrolledinSchool', {
        is: undefined,
        then: Yup.string()
          .matches(
            isValidUrl,
            'Please enter valid URL. https://www.example.com/'
          )
          .required('Please enter valid URL. https://www.example.com/'),
        otherwise: Yup.string()
      })
    }),
    optional: Yup.string().matches(
      /^(https?)(:\/\/|(\/%3A%2F%2F))/,
      'Please enter valid URL. https://www.example.com/'
    )
  },
  enrolledCourse: {
    required: Yup.string().when('enrolledinSchool', {
      is: 'Yes',
      then: isValidString.required('Enter current course or program'),
      otherwise: Yup.string().when('enrolledinSchool', {
        is: undefined,
        then: isValidString.required('Enter current course or program'),
        otherwise: isValidString
      })
    }),
    optional: isValidString
  },
  graduationYear: {
    required: Yup.number().when('enrolledinSchool', {
      is: 'Yes',
      then: Yup.number()
        .positive()
        .integer()
        .label('This field')
        .min(2022, 'Must be a valid year. 2024, 2025, etc.')
        .max(2099, 'Must be a valid year.  2024, 2025, etc.')
        .required('Please enter a valid graduation year'),
      otherwise: Yup.number().when('enrolledinSchool', {
        is: undefined,
        then: Yup.number().positive().integer().label('This field'),
        otherwise: Yup.number().positive().integer().label('This field')
      })
    }),
    optional: Yup.number()
  },
  organization: {
    required: isValidString.when('enrolledinSchool', {
      is: 'No',
      then: isValidString.required(
        'Please enter current organization or institution'
      ),
      otherwise: Yup.string().when('enrolledinSchool', {
        is: undefined,
        then: isValidString.required(
          'Please enter current organization or institution'
        ),
        otherwise: isValidString
      })
    }),
    optional: isValidString
  },
  IsPostmanUser__c: {
    required: Yup.string().required('Please select a valid option'),
    optional: Yup.string()
  },
  Use_Case__c: {
    required: Yup.string().when('IsPostmanUser__c', {
      is: 'Yes',
      then: isValidString.required('Please provide additional information'),
      otherwise: Yup.string().when('IsPostmanUser__c', {
        is: undefined,
        then: isValidString.required('This field is required'),
        otherwise: isValidString
      })
    }),
    optional: isValidString
  },
  referrerEmail: {
    required: isValidString.when('LeadSource', {
      is: 'Current Student Leader',
      then: isValidString
        .required('Email is required')
        .email('Please enter a valid email address.'),
      otherwise: Yup.string().when('LeadSource', {
        is: false,
        then: isValidString,
        otherwise: isValidString
      })
    }),
    optional: isValidString
  },

  // Enterprise and Contact Sales (Email)
  Email: {
    required: isValidString
      .required('Email is required')
      .email('Please enter a valid email address. e.g., name@domain.com'),
    optional: isValidString
  },
  Company: {
    required: Yup.string().when('Email', {
      is: (Email) => isMatchingEmail.test(Email),
      then: isValidString.required('Please provide a company name'),
      otherwise: isValidString.when('Email', {
        is: false,
        then: isValidString.required('Please provide a company name'),
        otherwise: isValidString.required('Please provide a company name')
      })
    }),
    optional: Yup.string().when('Email', {
      is: (Email) => isMatchingEmail.test(Email),
      then: isValidString.required('Please provide a company name'),
      otherwise: isValidString.when('Email', {
        is: false,
        then: isValidString,
        otherwise: isValidString
      })
    })
  }
};

export default CustomValidation;
