import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */

import styled from 'styled-components';
import { Paragraph } from 'aether-marketing';
/* eslint react/prop-types: 0 */
import cards from '../homepage/speakers';
import AspectRatioImagePlugin from '../../v5_images/AspectRatioImagePlugin';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

// We are not using SpeakerCards.jsx because the
// functionality is different and does this component does not need the filter
/* eslint react/prop-types: 0 */

const CardSection = styled.a`
  &:hover {
    text-decoration: none;
    transform: translateY(-0.5rem);
    cursor: pointer;
  }
  -webkit-transition: ${(props) => props.theme.transitions.all};
  transition: ${(props) => props.theme.transitions.all};
`;

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: ${(props) => props.theme.colors.grey_00};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  box-shadow: ${(props) => props.theme.shadows.card_shadow};
  -webkit-box-shadow: ${(props) => props.theme.shadows.card_shadow};
  -moz-box-shadow: ${(props) => props.theme.shadows.card_shadow};
  border-collapse: separate;
`;

const CardBody = styled.div`
  color: ${(props) => props.theme.colors.grey_90};
  padding: 24px;
`;

const Section = styled.section`
  padding: 60px 0;
  background: linear-gradient(180deg, #eff8fe 0%, #ffffff 100%);
`;

function SpeakerCards({ data }) {
  const { card } = data;

  return (
    <>
      {/* /* eslint-disable */}
      {card.active && (
        <CardSection
          className="col-xs-12 col-md-6 col-lg-4 mb-4"
          href={card.urlSlug.href}
        >
          <CardContainer className="h-100 d-flex flex-column">
            <AspectRatioImagePlugin
              src={card.media.src}
              role="img"
              ratio="12/6"
              aria-label={card.title || ''}
              marginBottom={card.marginBottom ? card.marginBottom : 0}
            />
            <CardBody className="mb-0">
              <h4 className="mb-3">{card.title}</h4>
              {card.subtitle && (
                <Paragraph className="mb-0">{card.subtitle}</Paragraph>
              )}
            </CardBody>
          </CardContainer>
        </CardSection>
      )}
    </>
  );
}

class RandomSpeakerCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCards: []
    };
  }

  componentDidMount() {
    // const sessionTitle = document.getElementById('sessionTitle').innerHTML;
    const newCards = cards.filter((card) => card.active && card.title);
    const returnedCards = [];
    for (let i = 0; i < 3; i += 1) {
      const index = Math.floor(Math.random() * newCards.length);
      const removed = newCards.splice(index, 1);
      returnedCards.push(removed[0]);
      this.setState({ filteredCards: returnedCards });
    }
  }

  render() {
    const { filteredCards } = this.state;
    return (
      <Section>
        <div className="container">
          <div className="row mb-3">
            <div className="col-12 text-center">
              <h2>View More Talks from Postman Galaxy</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {filteredCards.map((card) => (
              <SpeakerCards data={{ card }} key={pmUuid()} />
            ))}
          </div>
        </div>
      </Section>
    );
  }
}

RandomSpeakerCards.propTypes = {
  data: PropTypes.shape({}).isRequired
};

const recapShowThreeSpeakers = (data) => <RandomSpeakerCards data={data} />;

export default recapShowThreeSpeakers;
