/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PricingTableSmall from './pricingTableSmall';

const TabGroup = styled.ul`
  border: 1px solid #e6e6e6;
  background-color: ${(props) => props.theme.colors.grey_00};
  border-radius: 4px;
  position: sticky;
  transition: all 0.2s ease-in-out;
  top: -1px;
  width: 100%;
  z-index: 10;

  .show > .nav-link {
    // skips the blue background on page load
    background-color: ${(props) => props.theme.colors.grey_00} !important;
    color: ${(props) => props.theme.colors.grey_90};
    font-weight: 600;
    font-size: 16px;
    &:focus {
      outline: 0;
    }
    &:hover {
      color: ${(props) => props.theme.colors.grey_90};
      cursor: pointer;
    }
    .active {
      background-color: ${(props) => props.theme.colors.grey_00} !important;
    }
  }
`;

const PricingTableDropdownMenu = styled.li`
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  width: 100%;
  a {
    white-space: normal;
  }
  a,
  a.active {
    color: #212121;
  }

  a.dropdown-item {
    padding: 8px 16px;
  }

  .nav-link {
    padding: 12px 16px;
  }

  .nav-link.active,
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:focus {
    background-color: ${(props) => props.theme.colors.grey_00};
    color: ${(props) => props.theme.colors.grey_90};
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
  }

  .nav-link {
    > svg {
      display: inline-block;
      margin-left: 6px;
      width: 10px;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      /* Arrow Transitions Up on .show */
      &.show {
        display: inline-block;
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
      }

      @media screen and (max-width: 992px) {
        float: right;
        position: relative;
        top: 6px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

const PricingTableLinkWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grey_00};
  border: 1px solid ${(props) => props.theme.colors.grey_30};
  border-radius: 4px;
  margin-top: 4px;
  padding: 12px;
  text-align: left;

  a {
    padding: 0;
    white-space: normal;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

function PricingTableSmallContainer({ data }) {
  const { event } = data;
  const viewed = (event && event.viewed) || '';

  const [activePlan, setPlan] = useState({
    viewed: '',
    id: (data.plans.length > 3 && data.plans[3].id) || data.plans[1].id,
    name: (data.plans.length > 3 && data.plans[3].name) || data.plans[1].name
  });

  const updatePlan = (e) => {
    const PLAN_ID = e.target.innerText.replace(/\s+/g, '-').toLowerCase();
    setPlan({
      id: PLAN_ID,
      name: e.target.innerText
    });
  };

  const { name } = activePlan;

  return (
    <div className="d-lg-none d-xs-block pricing-table-container-small position-relative">
      <TabGroup
        className="nav nav-pills sticky-top"
        id="myTab"
        role="tablist"
        style={{ top: '51px' }}
      >
        <PricingTableDropdownMenu className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="##"
            role="button"
            aria-expanded="false"
          >
            {name}
            <svg
              className="arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="#212121"
            >
              <g>
                <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
              </g>
            </svg>
          </a>
          <PricingTableLinkWrapper className="dropdown-menu w-100">
            {data.plans[0] && (
              <a
                onClick={updatePlan}
                className="dropdown-item border-none"
                id={`${data.plans[0].id}-tab`}
                data-toggle="tab"
                data-event={`${viewed}:${data.plans[0].id}:0`}
                href={`#${data.plans[0].id}-pricing-tab`}
                role="tab"
                aria-controls={`${data.plans[0].id}-tab`}
                aria-selected="true"
              >
                <p
                  className="text-left"
                  data-event={`${viewed}:${data.plans[0].name}:0`}
                >
                  {data.plans[0].name}
                </p>
              </a>
            )}
            {data.plans[1] && (
              <a
                onClick={updatePlan}
                className={`dropdown-item border-none ${
                  data.plans[1].isActiveOnPageLoad === true ? 'active' : ''
                }`}
                id={`${data.plans[1].id}-tab`}
                data-toggle="tab"
                data-event={`${viewed}:${data.plans[1].id}:1`}
                href={`#${data.plans[1].id}-pricing-tab`}
                role="tab"
                aria-controls={`${data.plans[1].id}-tab`}
                aria-selected="true"
              >
                <p
                  className="text-left"
                  data-event={`${viewed}:${data.plans[1].name}:1`}
                >
                  {data.plans[1].name}
                </p>
              </a>
            )}
            {data.plans[2] && (
              <a
                onClick={updatePlan}
                className="dropdown-item"
                id={`${data.plans[2].id}-tab`}
                data-toggle="tab"
                data-event={`${viewed}:${data.plans[2].id}:2`}
                href={`#${data.plans[2].id}-pricing-tab`}
                role="tab"
                aria-controls={`${data.plans[2].name}-tab`}
                aria-selected="false"
              >
                <p
                  className="text-left"
                  data-event={`${viewed}:${data.plans[2].name}:2`}
                >
                  {data.plans[2].name}
                </p>
              </a>
            )}
            {data.plans[3] && (
              <a
                onClick={updatePlan}
                className="dropdown-item"
                id={`${data.plans[3].id}-tab`}
                data-toggle="tab"
                data-event={`${viewed}:${data.plans[3].id}:3`}
                href={`#${data.plans[3].id}-pricing-tab`}
                role="tab"
                aria-controls={`${data.plans[3].name}-tab`}
                aria-selected="false"
              >
                <p
                  className="v5_bold-sm v5_small-sm text-left"
                  data-event={`${viewed}:${data.plans[3].name}:3`}
                >
                  {data.plans[3].name}
                </p>
              </a>
            )}
          </PricingTableLinkWrapper>
        </PricingTableDropdownMenu>
      </TabGroup>

      <div className="tab-content" id={data.tabContentID}>
        {data.plans[0] && (
          <div
            className="tab-pane fade"
            id={`${data.plans[0].id}-pricing-tab`}
            role="tabpanel"
            aria-labelledby={`${data.plans[0].id}-tab`}
          >
            <PricingTableSmall data={data} planName={`${data.plans[0].id}`} />
          </div>
        )}
        {data.plans[1] && (
          <div
            className={`tab-pane fade ${
              data.plans.length === 2 ? 'show active' : ''
            }`}
            id={`${data.plans[1].id}-pricing-tab`}
            role="tabpanel"
            aria-labelledby={`${data.plans[1].id}-tab`}
          >
            <PricingTableSmall data={data} planName={`${data.plans[1].id}`} />
          </div>
        )}
        {data.plans[2] && (
          <div
            className={`tab-pane fade ${
              data.plans.length === 3 ? 'show active' : ''
            }`}
            id={`${data.plans[2].id}-pricing-tab`}
            role="tabpanel"
            aria-labelledby={`${data.plans[2].id}-tab`}
          >
            <PricingTableSmall data={data} planName={`${data.plans[2].id}`} />
          </div>
        )}
        {data.plans[3] && (
          <div
            className={`tab-pane fade ${
              data.plans.length === 4 ? 'show active' : ''
            }`}
            id={`${data.plans[3].id}-pricing-tab`}
            role="tabpanel"
            aria-labelledby={`${data.plans[3].id}-tab`}
          >
            <PricingTableSmall data={data} planName={`${data.plans[3].id}`} />
          </div>
        )}
      </div>
    </div>
  );
}

PricingTableSmallContainer.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.shape({
      viewed: PropTypes.string,
      sectionViewed: PropTypes.string
    }),
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        isActiveOnPageLoad: PropTypes.bool
      })
    )
  }).isRequired
};

export default PricingTableSmallContainer;
