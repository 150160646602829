import React from 'react';
import Helmet from 'react-helmet';

export default function bodyTagId(id) {
  if (Array.isArray(id) && id.length > 0) {
    const idString = id.join(' ');
    return (
      <Helmet>
        <body id={idString} />
      </Helmet>
    );
  }
  return null;
}
