import * as Yup from 'yup';

// Custom regex validators
const InvalidCharactersAndWords =
  /^(?!CONCAT|CONCATENATE|JOIN|TEXTJOIN|ISURL|IMPORTXML|=IMPORTXML\b)[^=<>{}`]+$/i;

export const isMatchingEmail = // will match any of the following domains
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(gmail|aol|outlook|yahoo|hotmail|icloud|inbox|mail|GMAIL|AOL|OUTLOOK|YAHOO|HOTMAIL|ICLOUD|INBOX|MAIL)\.com$/;

export const isValidUrl = new RegExp(
  '^(http:\\/\\/|https:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // 1.2.3.4 (IP Address)
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
    '(\\#[-a-z\\d_]*)?$', // URI Fragment #
  'i'
);

// Yup validation schema

export const isValidString = Yup.string()
  .trim() // remove whitespace
  .matches(InvalidCharactersAndWords, 'Please enter valid characters')
  .max(255, 'Must be 255 characters or less');

export function ValidationField(
  requiredMessage,
  optionalValidator = Yup.string()
) {
  return {
    required: isValidString.required(requiredMessage),
    optional: optionalValidator
  };
}
