import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import getAnchorTag from '../../../utils/getAnchorTag';
import getHref from '../../../utils/getHref';

// Google Analytics actions (Applied to Link or Button)
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

// Can import JUST the styles (if need to style dangerouslySet a tags for example) by importing the styled component below
// Blue link
export const InlineLinkStyles = styled.a`
  color: ${(props) =>
    props.alternative
      ? props.theme.colors.white
      : props.theme.colors.blue_60} !important;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: ${(props) => props.theme.transitions.all};

  &:hover {
    color: ${(props) =>
      props.alternative
        ? props.theme.colors.white
        : props.theme.colors.blue_60} !important;
    text-decoration: none;
    border-bottom: 1px solid;
    cursor: pointer;
  }
`;

// Arrow Link
export const ArrowLinkStyles = styled.a`
  color: ${(props) =>
    props.alternative
      ? props.theme.colors.white
      : props.theme.colors.blue_60} !important;
  border-bottom: 1px solid transparent;
  transition: ${(props) => props.theme.transitions.all};

  &:hover,
  :active {
    border-bottom: 1px solid;
    color: ${(props) =>
      props.alternative
        ? props.theme.colors.white
        : props.theme.colors.blue_60} !important;
    text-decoration: none;
  }
`;
// Arrow Link
export const ReversedArrowLinkStyles = styled.a`
  color: ${(props) =>
    props.alternative
      ? props.theme.colors.white
      : props.theme.colors.blue_60} !important;
  border-bottom: 1px solid transparent;
  transition: ${(props) => props.theme.transitions.all};

  &:hover,
  :active {
    border-bottom: 1px solid;
    color: ${(props) =>
      props.alternative
        ? props.theme.colors.white
        : props.theme.colors.blue_60} !important;
    text-decoration: none;
  }
`;
// Helper function for rel attribute in link or button
function relStringGenerator(hrefType) {
  if (hrefType === 'internal') {
    return null;
  }
  if (hrefType === 'external-other') {
    return 'noopener noreferrer';
  }
  if (hrefType === 'external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (hrefType === 'external-postman') {
    return 'noopener';
  }
  return null;
}
// Helper function for target attribute in link or button
function targetStringGenerator(hrefType) {
  if (hrefType === 'internal' || hrefType === 'external-postman') {
    return null;
  }
  if (hrefType === 'external-other' || hrefType === 'external-nofollow') {
    return '_blank';
  }
  return null;
}

function PricingLink({
  type = 'inlineLink',
  href, // href
  className, // Optional additional css classes
  alternative, // affects color, alternative = true is white
  hrefType, // Applies rel or target attributes using targetStringGenerator() or relStringGenerator()
  gaCategory, // for GA
  gaLabel, // for GA
  id, // for GA
  as, // Optionalvalue of styled-components 'as' prop.  Can render as element other than a tag, such as p tag
  ariaLabel = null,
  onClick,
  event,
  eventProp,
  children // React Children
}) {
  const isAlternative = alternative || false;

  const onClickHandler = (e) => {
    const hreference = getHref(getAnchorTag(e.target));

    if (hreference.isLocal) {
      navigate(hreference.url);
      e.preventDefault();
    }

    if (gaCategory && gaLabel) {
      triggerGA(gaCategory, gaLabel);
    }
    // Helper function for Click events
    // Don't pass in variables via the onClick function since it grabs from props provided
    // If we need additional scripts to fire off onClick in the future,
    // We can add a key to the json, check for them, and add the needed logic
    if (onClick) {
      onClick();
    }
  };
  // Arrow Link
  if (type === 'arrowLink') {
    return (
      <ArrowLinkStyles
        alternative={isAlternative}
        href={href}
        className={className || null}
        id={id}
        onClick={onClickHandler}
        rel={relStringGenerator(hrefType)}
        target={targetStringGenerator(hrefType)}
        as={as || 'a'}
        aria-label={ariaLabel}
        data-event={event}
        data-event-prop={eventProp}
      >
        {`${children} →`}
      </ArrowLinkStyles>
    );
  }
  if (type === 'reversedArrowLink') {
    return (
      <ReversedArrowLinkStyles
        alternative={isAlternative}
        href={href}
        className={className || null}
        id={id}
        onClick={onClickHandler}
        rel={relStringGenerator(hrefType)}
        target={targetStringGenerator(hrefType)}
        as={as || 'a'}
        aria-label={ariaLabel}
        data-event={event}
        data-event-prop={eventProp}
      >
        {`← ${children}`}
      </ReversedArrowLinkStyles>
    );
  }
  // Inline Link
  return (
    <InlineLinkStyles
      alternative={isAlternative}
      href={href}
      className={className || null}
      id={id}
      onClick={onClickHandler}
      rel={relStringGenerator(hrefType)}
      target={targetStringGenerator(hrefType)}
      as={as || 'a'}
      aria-label={ariaLabel}
      data-event={event}
      data-event-prop={eventProp}
    >
      {children}
    </InlineLinkStyles>
  );
}

PricingLink.propTypes = {
  type: PropTypes.string,
  alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  href: PropTypes.string,
  hrefType: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  gaCategory: PropTypes.string,
  gaLabel: PropTypes.string,
  as: PropTypes.string,
  ariaLabel: PropTypes.string,
  event: PropTypes.string,
  eventProp: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

PricingLink.defaultProps = {
  type: 'inlineLink',
  alternative: false,
  href: null,
  hrefType: '',
  className: '',
  id: null,
  gaCategory: null,
  gaLabel: null,
  as: 'a',
  ariaLabel: null,
  event: null,
  eventProp: null,
  onClick: false
};

// export default PricingLink;
export { PricingLink };
