import { useState, useEffect } from 'react';

const runtime = typeof document === 'object';

const stub = {
  ampli: () => {},
  ApiKey: () => {},
  DefaultConfig: () => {},
  loggingMiddleware: () => {}
};

function downloadsPagePageViewed() {
  window.pmt('ampli').downloadsPagePageViewed = () => {
    /* eslint-disable no-console */
    console.info('`downloadsPagePageViewed` needs to be defined by Amplitude;');
    /* eslint-enable */
  };
}

function headerViewed(properties) {
  window.pmt('ampli').headerViewed = () => {
    /* eslint-disable no-console */
    console.info(
      '`headerViewed` needs to be defined by Amplitude;',
      properties && JSON.stringify(properties, null, 4)
    );
    /* eslint-enable */
  };
}

function downloadsPageTryWebClicked(properties) {
  window.pmt('ampli').downloadsPageTryWebClicked = () => {
    /* eslint-disable no-console */
    console.info(
      '`downloadsPageTryWebClicked` needs to be defined by Amplitude;',
      properties && JSON.stringify(properties, null, 4)
    );
    /* eslint-enable */
  };
}

function getDnt() {
  return (
    (runtime && parseInt(navigator.doNotTrack, 10) === 1) ||
    parseInt(window.doNotTrack, 10) === 1 ||
    parseInt(navigator.msDoNotTrack, 10) === 1 ||
    navigator.doNotTrack === 'yes'
  );
}

/* eslint-disable global-require */
const { ampli, ApiKey, DefaultConfig } =
  (runtime && require('../../ampli/index')) || stub;
const { loggingMiddleware } =
  (runtime && require('../../middleware/index')) || stub;
/* eslint-enable */

let pageViewed;

const ampliPageViewed = {
  '/pricing/': () => {
    if (getDnt() || pageViewed) return;

    window.pmt('amplitudeEvent', ['pricingPageViewed', `{"url":"/pricing/"}`]);

    pageViewed = true;
  },
  '/downloads/': () => {
    if (getDnt() || pageViewed) return;

    window.pmt('amplitudeEvent', ['downloadsPagePageViewed']);

    pageViewed = true;
  },
  '/company/contact-sales/': () => {
    if (pageViewed) return;

    window.pmt('amplitudeEvent', [
      'contactSalesPageViewed',
      `{"url":"/company/contact-sales/"}`
    ]);

    pageViewed = true;
  },
  '/pricing/get-started-postman-plans/': () => {
    if (pageViewed) return;

    window.pmt('amplitudeEvent', [
      'contactSalesEnterprisePageViewed',
      `{"url":"/pricing/get-started-postman-plans/"}`
    ]);

    pageViewed = true;
  }
};

/**
 * Set of common properties needed across all events.
 * @returns object
 */
const getBaseProperties = () => ({
  user_agent: window && window.navigator && window.navigator.userAgent,
  referrer: window && window.document && window.document.referrer,
  signed_in:
    window.pmt && window.pmt('userId') && !window.pmt('userId').match('PM.')
});

export default () => {
  const [amplitudeKey, setAmplitudeKey] = useState('');

  useEffect(() => {
    if (typeof ampliPageViewed[document.location.pathname] === 'function') {
      ampliPageViewed[document.location.pathname]();
    }
  });

  if (runtime && !amplitudeKey && !document.body.getAttribute('data-ampli')) {
    if (!getDnt() && typeof ampli.load === 'function') {
      const env = window.pmt('getEnv');
      const isProd = env === 'production';
      const key = (isProd && ApiKey.production) || ApiKey.development;
      const amplitudeOptions = {
        apiEndpoint: isProd ? 'events.gw.postman.com' : ''
      };

      /**
       * Specify AmplitudeClient 'config'
       */
      ampli.load({
        client: {
          apiKey: key,
          config: { ...DefaultConfig, logLevel: 'INFO' },
          options: amplitudeOptions
        }
      });

      /**
       * Logging
       */
      if (!isProd) {
        ampli.addEventMiddleware(loggingMiddleware);
      }

      window.pmt('set', ['ampli', ampli]);

      window.pmt('ampli', 'identify', [null, { requiredBoolean: true }]);

      setAmplitudeKey(key);

      window.pmt('set', [
        'amplitudeEvent',
        (event, prop, e) => {
          const events = (event && event.split(',')) || [];
          const props = (prop && prop.split('|')) || [];

          let isFunc;

          events.forEach((ev, i) => {
            const eventParts = ev.split(':');
            const eventTitle = eventParts[0].trim();
            const itemIndex =
              eventParts[1] &&
              parseInt(
                (eventParts[2] && eventParts[2].trim()) ||
                  (eventParts[1] && eventParts[1].trim()),
                10
              );
            const itemName =
              eventParts[1] &&
              !eventParts[1].match(/[0-9]/) &&
              eventParts[1].trim();
            const properties =
              (props[i] &&
                props[i].match('{') &&
                props[i].match('}') &&
                JSON.parse(props[i])) ||
              {};

            if (
              eventTitle === 'downloadsPagePageViewed' &&
              typeof window.pmt('ampli')[eventTitle] !== 'function'
            ) {
              downloadsPagePageViewed(properties);
            }

            if (
              eventTitle === 'headerViewed' &&
              typeof window.pmt('ampli')[eventTitle] !== 'function'
            ) {
              headerViewed(properties);
            }

            if (
              eventTitle === 'downloadsPageTryWebClicked' &&
              typeof window.pmt('ampli')[eventTitle] !== 'function'
            ) {
              downloadsPageTryWebClicked(properties);
            }

            isFunc = typeof window.pmt('ampli')[eventTitle] === 'function';

            if (isFunc) {
              Object.assign(properties, getBaseProperties());

              if (itemIndex >= 0) {
                properties.item_index = itemIndex;
              }

              if (itemName) {
                properties.item_title = itemName;
              }

              window.pmt('log', [
                `[ampli] eventTitle='${eventTitle}' properties=${JSON.stringify(
                  properties
                )}`
              ]);

              if (properties && properties.cta_text === 'Buy Now') {
                const { href } = e.target;
                const refKey = window.pmt('ampli').amplitude.getDeviceId();
                const delim = (href.indexOf('?') === -1 && '?') || '&';

                if (document.location.href.indexOf('device_id') === -1) {
                  e.target.href = `${href}${delim}ref_key=${refKey}`;
                }
              }

              window.pmt('ampli')[eventTitle](properties);
            } else {
              window.pmt('log', [`[not-a-func] pm.ampli['${eventTitle}']`]);
            }
          });

          return isFunc;
        }
      ]);

      /**
       * Amplitude Tracker
       */
      document.body.addEventListener(
        'mousedown',
        (e) => {
          const dataEvent =
            e.target.getAttribute('data-event') ||
            e.target.parentNode.getAttribute('data-event');
          const dataEventProp =
            e.target.getAttribute('data-event-prop') ||
            e.target.parentNode.getAttribute('data-event-prop');

          window.pmt('amplitudeEvent', [dataEvent, dataEventProp, e]);
        },
        true
      );

      const viewed = {};

      const observeHandler = (entries) => {
        entries.forEach((entry, ind) => {
          const dataEventView = entry.target.getAttribute('data-event-view');
          const hasUndefinedDefinedEvent = dataEventView === 'undefined';
          const hasDefinedEvent = dataEventView && !hasUndefinedDefinedEvent;

          if (hasUndefinedDefinedEvent) {
            window.pmt('log', [
              {
                [`[ampli] hasUndefinedDefinedEvent${ind}`]: entry.target
              }
            ]);
          } else if (
            hasDefinedEvent &&
            !viewed[dataEventView] &&
            entry.intersectionRatio
          ) {
            window.pmt('amplitudeEvent', [dataEventView, `{}`]);
            viewed[dataEventView] = dataEventView;
          }
        });
      };

      const startObserving = () => {
        const eventViews = [...document.querySelectorAll('[data-event-view]')];
        const observer = new IntersectionObserver(observeHandler);

        eventViews.forEach((eventView) => {
          observer.observe(eventView);
        });
      };

      if (!window.IntersectionObserver) {
        window.load('/prefetch/intersection-observer-polyfill.js', () => {
          startObserving(true);
        });
      } else {
        setTimeout(startObserving, 1000);
      }

      document.body.setAttribute('data-ampli', 'true');
    }
  }

  return null;
};
