import React from 'react';
/* eslint-enable */

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router';

import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles
} from 'aether-marketing';
import FormV7 from './forms/layout/FormV7';
import UnsubscribeForm from './forms/special/UnsubscribeForm';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const path = globalHistory.location.pathname;

const ContentContainer = styled.div`
  margin-bottom: 30px;
  @media (min-width: 992px) {
    margin-bottom: 100px;
  }
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

function SideBySideFormV6Section({
  backgroundColor,
  body,
  media,
  divider,
  title,
  form,
  legal,
  scrollID,
  parentlink,
  logos,
  endStickyAttribute,
  isUnsubscribeForm
}) {
  // Automatically apply text as anchorTag to H2
  const isTitle = title || false;
  const anchorTag = isTitle ? title.replace(/\s+/g, '-').toLowerCase() : null;

  // If path is /postman-enterprise/ wrap component in a scroll behaviour CSS handler
  const ScrollWrap = ({ condition, renderWrapper, children }) =>
    condition ? renderWrapper(children) : children;
  const Epath = path === '/postman-enterprise/' || 'newsletter';

  return (
    <ScrollWrap
      condition={Epath}
      renderWrapper={(children) => <div>{children}</div>}
    >
      <SectionStyles
        backgroundColor={backgroundColor}
        id={scrollID}
        data-end-sticky={endStickyAttribute}
      >
        <div className="container">
          <div className="row">
            {parentlink && (
              <div className="col-12 mb-4">
                <BaseLink src={parentlink.src} linkType="reversedArrowLink">
                  {parentlink.text}
                </BaseLink>
              </div>
            )}
            <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-5 mb-lg-0 pr-lg-5">
              <ContentContainer className="text-left">
                {title && <h2 id={`${anchorTag}`}>{title}</h2>}
                {body && (
                  <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
                )}
              </ContentContainer>
              {logos ? (
                <div className="row d-flex justify-content-center align-items-center">
                  {logos.map((logo) => (
                    <div
                      key={pmUuid()}
                      className="col-6 col-sm-6 col-md-3 mb-5"
                    >
                      <img
                        className="img-fluid"
                        src={logo.src}
                        alt={logo.alt}
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              {media && media.src && (
                <div className="text-center">
                  <img
                    alt={media.alt}
                    className="img-fluid"
                    src={media.src}
                    loading="lazy"
                    fetchpriority="low"
                  />
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 pl-lg-5">
              {form && <FormV7 form={form} />}
              {isUnsubscribeForm && <UnsubscribeForm />}
              {legal ? (
                <div
                  className="d-sm-block d-md-none"
                  dangerouslySetInnerHTML={{ __html: legal.join('') }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </ScrollWrap>
  );
}

const sideBySideFormV6 = (data) => {
  const {
    backgroundColor,
    body,
    divider,
    form,
    isUnsubscribeForm,
    media,
    title,
    scrollID,
    parentlink,
    logos,
    endStickyAttribute
  } = data;

  return (
    <SideBySideFormV6Section
      backgroundColor={backgroundColor}
      body={body}
      divider={divider}
      form={form}
      isUnsubscribeForm={isUnsubscribeForm}
      media={media}
      title={title}
      scrollID={scrollID}
      parentlink={parentlink}
      logos={logos}
      endStickyAttribute={endStickyAttribute}
    />
  );
};

SideBySideFormV6Section.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.bool,
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    shadow: PropTypes.bool
  }),
  form: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    marketoForm: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  title: PropTypes.string.isRequired,
  scrollID: PropTypes.string,
  legal: PropTypes.arrayOf(PropTypes.string),
  parentlink: PropTypes.shape({
    text: PropTypes.string,
    src: PropTypes.string
  }),
  logos: PropTypes.arrayOf(
    PropTypes.shape({ src: PropTypes.string, alt: PropTypes.string })
  ),
  endStickyAttribute: PropTypes.string,
  isUnsubscribeForm: PropTypes.bool
};

SideBySideFormV6Section.defaultProps = {
  backgroundColor: null,
  body: null,
  divider: false,
  media: {
    src: '',
    alt: ''
  },
  form: null,
  scrollID: '',
  legal: [],
  parentlink: null,
  logos: [],
  endStickyAttribute: null,
  isUnsubscribeForm: null
};

export default sideBySideFormV6;
