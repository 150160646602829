/* global $ */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BaseButton, Primary, Secondary } from 'aether-marketing';

import FormV7 from '../forms/layout/FormV7';

const ModalButton = styled(BaseButton)`
  ${(props) =>
    props.isSecondary
      ? Secondary.componentStyle.rules
      : Primary.componentStyle.rules};
  padding: 10px 16px;
  border-radius: ${(props) => props.theme.borderRadius.small};
  font-weight: 600;
  &:hover,
  &:active {
    border: 1px solid ${(props) => props.theme.colors.grey_60};
  }

  .show-modal {
    ${(props) =>
      props.isSecondary
        ? Secondary.componentStyle.rules
        : Primary.componentStyle.rules};
  }
`;

const ModalBody = styled.div`
  &.form-modal {
    padding: 16px 0 0;
    display: flex;
    justify-content: center;
  }
`;

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1;
        opacity: 1;
        color: #ffffff;
        font-weight: 400;
        text-shadow: none;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 0.5rem 1rem 0.9rem;
        border-radius: 4px;
        margin-bottom: 0.5rem;

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 24px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
    &.form-modal {
      background-color: white;
      border: 0;
      padding: 0;
      color: ${(props) => props.theme.colors.grey_90};

      @media screen and (max-width: 992px) {
        padding: 12px;
      }

      & .close {
        line-height: 1;
        opacity: 1;
        color: #ffffff;
        font-weight: 400;
        text-shadow: none;
        position: relative;
        left: -25px;

        :focus {
          outline: none;
        }

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 24px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 0.75;
    }
  }
`;

const Span = styled.span`
  &.video {
    color: #ffffff;
  }
  &.form-modal {
    color: #000000;
  }
`;

const Icon = styled.img`
  height: 18px;
  display: inline-block;
  vertical-align: sub;
  margin-right: 6px;
`;

class GlobalModal extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data
    };
  }

  componentDidMount() {
    // This stops video when modal is turned off
    // For this to work, bootstrap requires this event to be jQuery, and use .on
    const data = this.state;
    if (data.data.modal.type === 'youtube') {
      $(`#modal-${data.data.form.marketoForm.id}`).on('hidden.bs.modal', () => {
        $('#modalVideoSrc').attr('src', data.data.modal.url);
      });
    } else if (data.data.modal.type === 'video') {
      $('#modal-video').on('hidden.bs.modal', () => {
        $('#modalVideoSrc').attr('src', '');
      });
      $('#modal-video').on('show.bs.modal', () => {
        $('#modalVideoSrc').attr('src', data.data.modal.url);
        $('#modalVideoSrc').attr('allow', data.data.modal.url);
      });
    }
  }

  render() {
    const { data } = this.state;
    const { modal, button, form, className } = data;

    return (
      <div className="mt-4 mt-sm-0 mr-3 d-block d-sm-inline">
        <ModalButton
          as="a"
          className={`show-modal ${className || ''}`}
          role="button"
          isSecondary={data.button.isSecondary}
          data-toggle="modal"
          data-target={
            modal.type === 'form'
              ? `#modal-${form.marketoForm.id}`
              : '#modal-video'
          }
        >
          {modal.icon && modal.type === 'video' && !data.button.isSecondary && (
            <Icon
              src="https://voyager.postman.com/icon/play-button-primary.svg"
              alt=""
              aria-hidden="true"
            />
          )}
          {modal.icon && modal.type === 'video' && data.button.isSecondary && (
            <Icon src={modal.icon} alt="" aria-hidden="true" />
          )}
          {button.text}
        </ModalButton>
        <div
          className="modal fade"
          id={
            modal.type === 'form'
              ? `modal-${form.marketoForm.id}`
              : 'modal-video'
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ paddingTop: '50px' }}
        >
          <div
            className={`modal-dialog modal-dialog-centered ${
              modal.type === 'video' ? 'modal-xl' : 'modal-lg'
            }`}
            role="document"
          >
            <ModalContent
              className={
                modal.type === 'video'
                  ? 'modal-content video'
                  : 'modal-content form-modal rounded-0'
              }
            >
              <ModalBody
                className={modal.type === 'video' ? null : 'row form-modal'}
              >
                <div className="col-10 col-md-9 col-lg-6 ml-auto mt-2">
                  {modal.title && (
                    <h4
                      className="text-center"
                      dangerouslySetInnerHTML={{ __html: modal.title }}
                    />
                  )}
                </div>

                <div className="ml-auto">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-test="same-tab"
                  >
                    <Span
                      className={
                        modal.type === 'video'
                          ? 'close-icon video'
                          : 'close-icon form-modal'
                      }
                      aria-hidden="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <title>e-remove</title>
                        <g
                          strokeWidth="1"
                          fill="#212121"
                          stroke="#212121"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="13.5" y1="2.5" x2="2.5" y2="13.5" />
                          <line x1="2.5" y1="2.5" x2="13.5" y2="13.5" />
                        </g>
                      </svg>
                    </Span>
                  </button>
                </div>
              </ModalBody>
              {modal.type === 'video' && (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src={`${modal.url}?rel=0&origin=http://www.postman.com`}
                    id="modalVideoSrc"
                    allowscriptaccess="always"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="Video demo popup"
                    allowFullScreen
                  />
                </div>
              )}
              {modal.type === 'form' && <FormV7 form={form} />}
            </ModalContent>
          </div>
        </div>
      </div>
    );
  }
}
GlobalModal.propTypes = {
  data: PropTypes.shape({}).isRequired
};

function ModalComponent(data) {
  return <GlobalModal data={data} />;
}
export default ModalComponent;
