import React from 'react';
import PropTypes from 'prop-types';

function Text(props) {
  const { data } = props;

  return (
    <div
      className="mb-4"
      alternative={data.alternative}
      italic={data.italic}
      dangerouslySetInnerHTML={{ __html: data.items.join('') }}
    />
  );
}

export default Text;

Text.propTypes = {
  data: PropTypes.shape({
    alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    italic: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};
