/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SectionStyles, Divider } from 'aether-marketing';
import caseStudiesData from './case-studies-data';
import integrationsData from './partner-program-directory-data';
import CardBasicV6 from '../v6_cards/CardBasicV6';

const FilterColumn = styled.div`
  &.sticky {
    height: 100%;
    min-height: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 75px;
  }
`;

const ContentColumn = styled.div`
  min-height: 100vh;
  height: 100%;

  &.adjust-height-for-less-content {
    height: 50vh;
    min-height: 100vh;
  }
`;

const Checkbox = styled.input`
  &[type='checkbox'] {
    appearance: none;
    content: url(https://voyager.postman.com/icon/checkbox-outline-grey-icon.svg);
    margin-right: 16px;
  }

  &:hover,
  & + label:hover {
    cursor: pointer;
  }

  &[type='checkbox']:checked {
    color: #049d40;
    background-color: none;
    content: url(https://voyager.postman.com/icon/checkbox-outline-green-icon.svg);
  }
`;

function Cards({ card }) {
  const {
    roundedCorners,
    media,
    header,
    subtitle,
    paragraph,
    body,
    hover,
    clickableCardLink,
    link,
    ratio
  } = card;
  return (
    <CardBasicV6
      roundedCorners={roundedCorners}
      as="a"
      className="mb-5"
      col="col-12 col-sm-6 col-md-4 col-lg-6"
      media={media}
      header={header}
      subtitle={subtitle}
      paragraph={paragraph}
      body={body}
      link={link}
      hover={hover}
      clickableCardLink={clickableCardLink}
      href={link && link.href && link.href}
      target={link && link.target && link.target}
      type={link.type}
      ratio={ratio}
      fullWidthImage={false}
    />
  );
}
function FilterCards({ data }) {
  const { options, filter } = data;
  const { name, fileName } = filter;
  let pageData;

  // add new data to reference here
  (function assignData() {
    if (fileName === 'case-studies-homepage') {
      pageData = caseStudiesData;
    } else if (fileName === 'product-integrations') {
      pageData = integrationsData;
    }
  })();

  const [cards, setCards] = useState(pageData);
  const [categories, setCategories] = useState([]);

  const toggleCards = (id) => {
    let currentCards = [];
    // add cards based on single category
    if (!categories.includes(id)) {
      currentCards = [...categories, id];
      setCategories([...categories, id]);
    } else if (categories.includes(id)) {
      // remove cards based on single category
      const newCards = categories.filter((type) => type !== id);
      currentCards = newCards;
      setCategories(newCards);
    }

    // multi-filter: add cards to current stack when multiple categories are selected
    let newCards;
    if (currentCards.length !== 0) {
      newCards = pageData.filter((card) =>
        card.tags.some((tag) => currentCards.includes(tag))
      );
    } else {
      // display all cards on page load or when all categories are unselected
      newCards = pageData;
    }
    setCards(newCards);
  };

  return (
    <div className="row">
      <FilterColumn
        className={
          cards.length <= 2 && cards.length !== 0
            ? 'd-lg-block d-none col-md-4 col-lg-3 sticky adjust-height-for-less-content'
            : 'd-lg-block d-none col-md-4 col-lg-3 sticky'
        }
      >
        <p className="subtitle">Filter by {name}:</p>

        {options.map((val) => (
          <div className="d-flex mb-3" key={val}>
            <Checkbox
              id={val}
              type="checkbox"
              role="button"
              onClick={(e) => toggleCards(val, e)}
              key={val}
            />
            <label className="list-group-item-text mb-0" htmlFor={val}>
              {val}
            </label>
          </div>
        ))}
      </FilterColumn>
      <ContentColumn
        className={
          cards.length <= 2 && cards.length !== 0
            ? 'offset-0 col-lg-8 offset-lg-1 adjust-height-for-less-content'
            : 'offset-0 col-lg-8 offset-lg-1'
        }
      >
        <div className="row justify-content-center align-content-center">
          {cards.map((card) => (
            <Cards key={card.id} card={card} cards={cards} />
          ))}
        </div>
      </ContentColumn>
    </div>
  );
}

FilterCards.propTypes = {
  data: PropTypes.shape({}).isRequired
};

function FilterSection(data) {
  const { divider } = data;
  return (
    <>
      <SectionStyles>
        <div className="container">
          <FilterCards data={data} />
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

export default FilterSection;
