import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1.9;
        opacity: 1;
        color: #ffffff;
        font-weight: 400;
        text-shadow: none;

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 19px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 0.75;
    }
  }
`;

function ModalOverlay({ type = 'youtube', src }) {
  return (
    <div
      className="modal fade bd-example-modal-lg"
      id="ModalOverlay"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg mt-10"
        role="document"
      >
        <ModalContent className="modal-content video">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="close-label">CLOSE</span>
              <span className="close-icon" aria-hidden="true">
                X
              </span>
            </button>
            {/* <!-- 16:9 aspect ratio --> */}
            <div className="embed-responsive embed-responsive-16by9">
              {type === 'youtube' && (
                <iframe
                  className="embed-responsive-item"
                  src={`${src}?rel=0&origin=http://www.postman.com`}
                  id="video"
                  allowscriptaccess="always"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="Postman video series"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        </ModalContent>
      </div>
    </div>
  );
}

ModalOverlay.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default ModalOverlay;
