/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

/*
 * Labels displayed above each form field
 *
 * Marketo type: "picklist"
 * Standard type: "select"
 */

export const LabelStyled = styled.label`
  color: ${(props) =>
    props.mode === 'light' ? '#FFFFFF' : props.theme.forms.text};
  font-size: 14px;
  font-weight: ${(props) => (props.mode === 'light' ? 400 : 600)};
  margin: 4px 0;
  text-align: left !important;
  width: 100% !important;
`;

function FormLabel(props) {
  const { name, required, label, mode, inputlabel } = props;
  const FIELD_REQUIRED = required ? '*' : '';
  const text = `${
    (inputlabel && inputlabel.replace(/:$/, '')) ||
    (label && label.replace(/:$/, ''))
  }${FIELD_REQUIRED}`;
  return (
    <LabelStyled
      htmlFor={name}
      mode={mode}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

export default FormLabel;
