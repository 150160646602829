import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'aether-marketing';

const Section = styled.div`
  &.bg-gradient-purple {
    background: -moz-linear-gradient(
      150deg,
      rgba(27, 1, 67, 1) 0%,
      rgba(45, 15, 77, 1) 48%,
      rgba(102, 82, 121, 1) 100%
    );
    background: -webkit-linear-gradient(
      150deg,
      rgba(27, 1, 67, 1) 0%,
      rgba(45, 15, 77, 1) 48%,
      rgba(102, 82, 121, 1) 100%
    );
    background: linear-gradient(
      150deg,
      rgba(27, 1, 67, 1) 0%,
      rgba(45, 15, 77, 1) 48%,
      rgba(102, 82, 121, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b0143",endColorstr="#665279",GradientType=1);
  }
  &.bg-gradient-blue {
    background: -moz-linear-gradient(
      150deg,
      rgba(1, 27, 67, 1) 0%,
      rgba(31, 72, 99, 1) 48%,
      rgba(39, 153, 178, 1) 100%
    );
    background: -webkit-linear-gradient(
      150deg,
      rgba(1, 27, 67, 1) 0%,
      rgba(31, 72, 99, 1) 48%,
      rgba(39, 153, 178, 1) 100%
    );
    background: linear-gradient(
      150deg,
      rgba(1, 27, 67, 1) 0%,
      rgba(31, 72, 99, 1) 48%,
      rgba(39, 153, 178, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#011b43",endColorstr="#2799b2",GradientType=1);
  }
  &.bg-gradient-green {
    background: -moz-linear-gradient(
      150deg,
      rgba(58, 122, 119, 1) 0%,
      rgba(71, 136, 130, 1) 48%,
      rgba(145, 203, 176, 1) 100%
    );
    background: -webkit-linear-gradient(
      150deg,
      rgba(58, 122, 119, 1) 0%,
      rgba(71, 136, 130, 1) 48%,
      rgba(145, 203, 176, 1) 100%
    );
    background: linear-gradient(
      150deg,
      rgba(58, 122, 119, 1) 0%,
      rgba(71, 136, 130, 1) 48%,
      rgba(145, 203, 176, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3a7a77",endColorstr="#91cbb0",GradientType=1);
  }
  &.bg-gradient-dark-blue {
    background: rgb(9, 23, 45);
  }
`;

const HeroWrapper = styled.div`
  padding: 120px 0;
  background-size: cover;
  background-position: top center;
  h1 {
    color: ${(props) => props.theme.colors.grey_00};
  }
`;

const heroTitlesStacked = ({
  media,
  col,
  h1,
  h1ClassName,
  h1Hidden,
  subtitle,
  body,
  background
}) => {
  return (
    <Section className={`bg-gradient-${background.color}`}>
      <HeroWrapper
        style={{
          backgroundImage: `url("${background.image}")`
        }}
      >
        <div className="container">
          <div className="row">
            <div className={`${col || 'col-11'} mx-auto text-center`}>
              <div className="row mb-5">
                <div className={`mx-auto ${media.col && media.col}`}>
                  <img
                    className="img-fluid"
                    alt={media.alt && media.alt}
                    src={media.src && media.src}
                  />
                </div>
              </div>
              {/* title */}
              <h1
                tag="h1"
                className={`mb-3 ${h1ClassName || ''}`}
                style={h1Hidden ? { textIndent: '-99999px', height: '0' } : {}}
              >
                {h1}
              </h1>
              {/* subtitle */}
              {subtitle && (
                <Paragraph className="mb-4 subtitle text-white">
                  {subtitle}
                </Paragraph>
              )}
              {body && body.text && (
                <Paragraph
                  className="text-white subtitle"
                  dangerouslySetInnerHTML={{ __html: body.text }}
                />
              )}
            </div>
          </div>
        </div>
      </HeroWrapper>
    </Section>
  );
};
export default heroTitlesStacked;
