/* global $ */
// Need refactoring
// Below is for ToC mobile button on state-of-api
export default function stickyElement(
  elementId,
  startPoint,
  padding,
  endElementId
) {
  // elementId = string, ex. '#NavButton'
  // startPoint = number. Where elementId top initially sits. (For example 55, the height of the nav bar if the element is to sit just under the nav)
  // padding = number.  Amount of top and bottom padding
  // endElementId = string, ex. '#Footer'.  Where you want to stickiness to stop
  $(window).on('scroll', () => {
    // Example = If we are less than '31' <---- The nav height (55px) - button top padding (24px)
    if ($(window).scrollTop() < startPoint - padding) {
      $(elementId).css('position', 'absolute');
      $(elementId).css('top', startPoint); // <-- 55px is nav height
    }
    if (
      $(window).scrollTop() > startPoint - padding &&
      $(elementId).css('position') !== 'fixed'
    ) {
      $(elementId).css('position', 'fixed');
      $(elementId).css('top', padding);
    }
    // We times 24px by 2, for top AND bottom padding
    // If the top of the screen is larger than the very top bit of padding on the button
    if (
      $(window).scrollTop() >
      $(endElementId).offset().top - $(elementId).height() - padding * 2
    ) {
      $(elementId).css('position', 'absolute');
      // And make the button rest 24px above the footer
      $(elementId).css(
        'top',
        $(endElementId).offset().top - $(elementId).height() - padding
      );
    }
  });
}
// Side nav
export function addFixed(elementId, parentContainerId, padding, endElementId) {
  const parentContainer = $(parentContainerId);

  function scrollLogic() {
    // If the user scroll is less than the top of the stickys parent container
    if ($(window).scrollTop() < parentContainer.offset().top) {
      $(elementId).css('position', 'relative');
      $(elementId).css('top', '0');
      $(parentContainer).css('width', parentContainer.width());
      $(elementId).css('width', parentContainer.width());
    }
    // Scroll top is GREATER or EQUAL than top of the elements container (meaning the user has scrolled past the point where the sticky should start)
    //  Or position is absolute - it would only be absolute if it made it to the last condition (meaning remove stickiness and stay where it's at)).
    if (
      $(window).scrollTop() >= parentContainer.offset().top + padding ||
      $(elementId).css('position') === 'absolute'
    ) {
      $(parentContainer).css('width', parentContainer.width());
      $(elementId).css('position', 'fixed');
      $(elementId).css('top', padding);
      $(elementId).css('width', parentContainer.width());
    }
    if (
      $(window).scrollTop() >
        $(endElementId).offset().top - $(elementId).height() - padding * 2 &&
      $(elementId).css('position') === 'fixed'
    ) {
      $(elementId).css('position', 'absolute');
      $(elementId).css(
        'top',
        parentContainer.height() - $(elementId).height() - padding
      );
    }
  }

  $(window).on('scroll', () => {
    scrollLogic();
  });
  // Below adjusts the sticky items width to avoid overflow on resize
  $(window).on('resize', () => {
    scrollLogic();
  });
}

export function eyeBrowSticky(
  elementId,
  parentContainerId,
  underlineId,
  padding,
  endElementId
) {
  let topPosition = padding;
  const originalPadding = padding;
  const parentContainer = $(parentContainerId);
  function drawStickyHeader() {
    $(elementId).css('position', 'fixed');
    $(elementId).css('top', '0');
    $(elementId).css('width', parentContainer.width());
    $(elementId).css('max-width', parentContainer.width());

    $(elementId).find('h2').css('font-size', '16px');
    $(elementId).find('h2').css('padding-top', topPosition);
  }
  function scrollLogic() {
    if ($(window).scrollTop() < parentContainer.offset().top) {
      $(underlineId).css('opacity', '0');
      $(underlineId).css('width', '0');

      $(elementId).css('position', 'relative');
      $(elementId).css('top', '0');

      $(elementId).find('h2').css('font-size', '48px');
      $(elementId).find('h2').css('padding-top', '0px');
    }
    // Add 20 to make sure we don't transition when a user lands at this point from a deep link
    if (
      $(window).scrollTop() >= parentContainer.offset().top + 20 ||
      $(elementId).css('position') === 'absolute'
    ) {
      drawStickyHeader();
      // setTimeout time needs to be longer than title transition
      // To make sure the orangeLine grows to the right size
      if ($(underlineId).css('width') !== $(elementId).find('h2').width()) {
        setTimeout(() => {
          if ($(elementId).css('position') === 'fixed') {
            $(underlineId).css('width', $(elementId).find('h2').width());
            $(underlineId).css('opacity', '1');

            // We need to check to make sure we "clear" the ToC button if we have a long title.
            // The width of the screen - 15px * 2 (left right bootstrap padding)
            // Gives us the content width.  Content width - button width = the title area width
            //  If title width > content width - button width.  Add padding to clear
            // I used 60 instead of 15px * 2 to give it a smoother aesthetic transition point
            if (
              $(elementId).find('h2').width() >
              $(window).width() - 60 - $('#NavButton').width()
            ) {
              topPosition = 100;
            } else {
              topPosition = originalPadding;
            }
            drawStickyHeader();
          }
        }, 350);
      }
    }

    if (
      $(window).scrollTop() >
        $(endElementId).offset().top - $(elementId).height() &&
      $(elementId).css('position') === 'fixed'
    ) {
      $(elementId).css('position', 'absolute');
      // 80 is the padding bottom from a parent container
      $(elementId).css(
        'top',
        parentContainer.height() -
          $(elementId).height() -
          $(endElementId).height() -
          80
      );
    }
  }

  $(window).on('scroll', () => {
    scrollLogic();
  });
  $(window).on('resize', () => {
    scrollLogic();
  });
}

export function eyeBrowStickySOTA2021(
  elementId,
  parentContainerId,
  underlineId,
  padding,
  endElementId,
  eyebrowBodyContainerId
) {
  let topPosition = padding;
  const originalPadding = padding;
  const parentContainer = $(parentContainerId);
  function drawStickyHeader() {
    $(elementId).css('position', 'fixed');
    $(elementId).css('top', '0');
    $(elementId).css('width', parentContainer.width() - 2);
    // $(elementId).css('border-top', '1px solid black');
    // Added -5 to clean left border box SOTA 2021 design
    $(elementId).css('max-width', parentContainer.width() - 2);

    // Added this if, for SOTA 2021 design. Padding reset that was not needed on SOTA2020 design
    if (eyebrowBodyContainerId) {
      $(eyebrowBodyContainerId).css('padding-top', '0px');
    }

    $(elementId)
      .find('h2')
      .css('font-size', '16px')
      .css('line-height', '32px')
      .css('padding-top', topPosition);
  }
  function scrollLogic() {
    if ($(window).scrollTop() < parentContainer.offset().top) {
      $(underlineId).css('opacity', '0');
      $(underlineId).css('width', '0');

      $(elementId).css('position', 'relative');
      $(elementId).css('top', '0');

      $(elementId).css('max-width', parentContainer.width() - 2);
      $(elementId).css('border-top', 'none');
      // Added this "if" below, for SOTA 2021 design. Padding reset that was not needed on SOTA2020 design
      $(eyebrowBodyContainerId);
      if (eyebrowBodyContainerId) {
        $(eyebrowBodyContainerId).css('padding-top', '60px');
      }

      $(elementId).find('h2').css('font-size', '48px');
      $(elementId).find('h2').css('padding-top', '0px');
    }

    if (
      $(window).scrollTop() >= parentContainer.offset().top ||
      $(elementId).css('position') === 'absolute'
    ) {
      drawStickyHeader();
      // setTimeout time needs to be longer than title transition
      // To make sure the orangeLine grows to the right size
      if ($(underlineId).css('width') !== $(elementId).find('h2').width()) {
        setTimeout(() => {
          if ($(elementId).css('position') === 'fixed') {
            $(underlineId).css('width', $(elementId).find('h2').width());
            $(underlineId).css('opacity', '1');

            if ($(window).width() < 992) {
              topPosition = 112;
            } else {
              topPosition = originalPadding;
            }
            drawStickyHeader();
          }
        }, 350);
      }
    }

    if (
      $(window).scrollTop() > $(endElementId).offset().top - 200 &&
      // $(endElementId).offset().top - $(elementId).height() - 65 &&
      $(elementId).css('position') === 'fixed'
    ) {
      $(elementId).css('position', 'absolute');
      // 80 is the padding bottom from a parent container
      $(elementId).css(
        'top',
        // $(endElementId).offset().top - 200
        parentContainer.height() -
          $(elementId).height() -
          $(endElementId).height() -
          50
      );
    }
  }

  $(window).on('scroll', () => {
    scrollLogic();
  });
  $(window).on('resize', () => {
    scrollLogic();
  });
}
// Below is for ToC mobile button on state-of-api 2021
export function stickyElementSOTA2021(
  elementId, // ToC ID
  startPoint, // Initial top value of ToC
  padding, // little bit of space above and below
  endElementId
) {
  $(window).on('scroll', () => {
    // This first if represents the initial position of the ToC button.
    // Remember, we may need to clear the hello (bar).  Need to figure out dynamic solution
    if ($(window).scrollTop() < startPoint - padding) {
      $(elementId).css('position', 'absolute');
      $(elementId).css('top', startPoint);
    }
    if (
      $(window).scrollTop() > startPoint - padding &&
      $(elementId).css('position') !== 'fixed'
    ) {
      $(elementId).css('position', 'fixed');
      $(elementId).css('top', startPoint);
    }

    if (
      $(window).scrollTop() >
      $(endElementId).offset().top - $(elementId).height()
    ) {
      $(elementId).css('position', 'absolute');
      // And make the button rest 24px above the footer
      $(elementId).css(
        'top',
        $(endElementId).offset().top + $(elementId).height() + 10
      );
    }
  });
}
