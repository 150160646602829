import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from './StickyModalNavModal';
import { SidebarNavLinks } from '../../sidebars/stickySideBar';
// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
const topValIfHello = 150;

const Section = styled.div`
  position: absolute;
  top: 55px;
  right: 30px;
  background-color: ${(props) => props.theme.colors.grey_90};
`;

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1.9;
        opacity: 1;
        color: #ffffff;
        text-shadow: none;

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 19px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 1;
    }
  }
`;

const navData = {
  type: 'stickySideBar',
  padding: '60px auto auto auto',
  leftSide: true,
  inModal: true,
  sidetitle: 'TABLE OF CONTENTS',
  textAlign: 'text-left',
  leftLinks: [
    {
      title: 'Key Findings',
      url: '/state-of-api/#key-findings'
    },
    {
      title: 'The Rise of API-First',
      url: '/state-of-api/the-rise-of-api-first/#the-rise-of-api-first'
    },
    {
      title: 'Who Works with APIs',
      url: '/state-of-api/who-works-with-apis/#who-works-with-apis'
    },
    {
      title: 'A Day, Week, or Year in the Life',
      url: '/state-of-api/a-day-week-or-year-in-the-life/#a-day-week-or-year-in-the-life'
    },
    {
      title: 'API Strategies',
      url: '/state-of-api/api-strategies/#api-strategies'
    },
    {
      title: 'Executing on APIs',
      url: '/state-of-api/executing-on-apis/#executing-on-apis'
    },
    {
      title: 'Tooling for APIs and Development',
      url: '/state-of-api/tooling-for-apis-and-development/#tooling-for-apis-and-development'
    },
    {
      title: 'API Technologies',
      url: '/state-of-api/api-technologies/#api-technologies'
    },
    {
      title: 'The Future of APIs',
      url: '/state-of-api/the-future-of-apis/#the-future-of-apis'
    },
    {
      title: 'APIs & Business initiatives',
      url: '/state-of-api/apis-and-business-initiatives/#apis-and-business-initiatives'
    },
    {
      title: 'Learning about APIs',
      url: '/state-of-api/learning-about-apis/#learning-about-apis'
    },
    {
      title: 'Report Methodology',
      url: '/state-of-api/report-methodology/#report-methodology'
    },
    {
      title: 'Download Report',
      url: 'https://voyager.postman.com/doc/postman-state-of-api-2020.pdf',
      category: 'state-of-api-page',
      label: 'download-report-nav-link'
    }
  ]
};

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      topVal: '55px',
      data
    };
    this.setTopVal = this.setTopVal.bind(this);
  }

  setTopVal() {
    this.setState({
      topVal: `${topValIfHello}px`
    });
  }

  render() {
    const { data, topVal } = this.state;
    return (
      <>
        <Section
          id="NavButton"
          className="d-inline d-lg-none"
          style={{ top: topVal, zIndex: 50 }}
        >
          <Modal data={data} setTopVal={this.setTopVal} />
        </Section>
        <div
          className="modal fade bd-example-modal-lg"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: 'rgb(28, 39, 43)', zIndex: 2010 }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <ModalContent className="modal-content video">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close-label">CLOSE</span>
                  <span className="close-icon" aria-hidden="true">
                    X
                  </span>
                </button>
                <div>
                  <SidebarNavLinks data={navData} />
                </div>
              </div>
            </ModalContent>
          </div>
        </div>
      </>
    );
  }
}

const stickyModalNav = (data) => <ModalWrapper data={data} />;

ModalWrapper.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default stickyModalNav;
