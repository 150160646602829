import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Divider,
  SectionStyles,
  BaseLinkStyles,
  UnorderedListStyles,
  ParagraphStyles,
  SectionHeader
} from 'aether-marketing';
import suppress from '../../utils/suppress';

const FAQWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const AnswerContainer = styled.div`
  overflow: hidden;
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  p {
    padding-right: 30px;
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
    margin-top: 16px !important;
  }

  li {
    margin-bottom: 16px;
  }

  &.collapsed-answer {
    max-height: 0;
    transition: max-height 0.25s ease-out;
  }

  &.collapse-answer {
    overflow: hidden;
    transition: max-height 0.5s ease-in; // must transition max height
    height: auto;
    max-height: 600px;

    @media screen and (max-width: 576px) {
      max-height: 100vh;
    }
  }
`;

const AccordionCard = styled.div`
  box-shadow: none;
  border: 0;
`;

const Icon = styled.svg`
  height: 12px;
  width: auto;

  &.arrow-up {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  &.arrow-down {
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
`;

const QuestionContainer = styled.div`
  padding: 24px 0;

  &:focus {
    outline: none;
  }
`;

const Question = styled(ParagraphStyles)`
  cursor: pointer;
`;

const runtime = typeof document === 'object';

let priceToggle;
let planTypeToggle;

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      items: data.items,
      opened: 'accordion-1'
    };

    if (runtime) {
      const oPlanTypeToggle = window.planTypeToggle;

      window.planTypeToggle = (value) => {
        if (typeof oPlanTypeToggle === 'function') {
          oPlanTypeToggle(value);
        }

        planTypeToggle = value;

        const display =
          ((priceToggle === 'month' || planTypeToggle === 'tab2') && 'none') ||
          'block';

        suppress('faqSection', display);
      };

      const oPriceToggle = window.priceToggle;

      window.priceToggle = (value) => {
        if (typeof oPriceToggle === 'function') {
          oPriceToggle(value);
        }

        priceToggle = value;

        const display =
          ((priceToggle === 'month' || planTypeToggle === 'tab2') && 'none') ||
          'block';

        suppress('faqSection', display);
      };

      if (window.pmt('ampli')) {
        setTimeout(() => {
          suppress();

          document
            .getElementById('FaqSection')
            .setAttribute('data-event-view', data.event);
        }, 1);
      }
    }
  }

  colRenderer(item, index) {
    const { opened } = this.state;
    return (
      <AccordionCard key={`accordion-${index}`}>
        <QuestionContainer
          role="button"
          className="d-flex justify-content-between"
          tabIndex={0}
          id={`accordion-${index}`}
          onKeyDown={() => {
            this.toggleAnswerContainer(index);
          }}
          onClick={() => {
            if (window.pmt('ampli')) {
              window.pmt('amplitudeEvent', [item.event]);
            }
            this.toggleAnswerContainer(index);
          }}
        >
          <Question className="strong mb-0 align-self-center">
            {item.question}
          </Question>
          <div className="ml-4 align-self-center">
            <Icon
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              alt="Chevron orange"
              className={
                opened === `accordion-${index}` ? 'arrow-up' : 'arrow-down'
              }
              data-icon="caret-down"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 12"
              width="22"
              height="12"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7071 11.7071C21.3166 12.0976 20.6834 12.0976 20.2929 11.7071L11 2.41421L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292894 11.7071C-0.0976314 11.3166 -0.0976313 10.6834 0.292895 10.2929L10.2929 0.292892C10.6834 -0.0976315 11.3166 -0.0976314 11.7071 0.292893L21.7071 10.2929C22.0976 10.6834 22.0976 11.3166 21.7071 11.7071Z"
                fill="#FF8E64"
              />
            </Icon>
          </div>
        </QuestionContainer>
        <AnswerContainer
          className={
            opened === `accordion-${index}`
              ? 'collapse-answer'
              : 'collapsed-answer'
          }
          dangerouslySetInnerHTML={{ __html: item.answer }}
        />
        <Divider fullWidth />
      </AccordionCard>
    );
  }

  toggleAnswerContainer(index) {
    const { opened } = this.state;
    const stateOpened =
      `accordion-${index}` === opened ? false : `accordion-${index}`;

    this.setState({
      opened: stateOpened
    });
  }

  render() {
    const { items } = this.state;
    let counter = 0;
    return (
      <div className="row">
        <div className="col-12">
          <FAQWrapper>
            {items.map((item) => {
              counter += 1;
              return this.colRenderer(item, counter);
            })}
          </FAQWrapper>
        </div>
      </div>
    );
  }
}

const faqSection = (data) => (
  <>
    <SectionStyles
      paddingTop="60px"
      paddingBottom="60px"
      id="FaqSection"
      data-suppress={(data.suppress && 'faqSection') || null}
    >
      <div className="container">
        <div className="row">
          <div
            className={
              data.className ? data.className : 'col-12 col-md-8 offset-md-2'
            }
          >
            <div className="row">
              <SectionHeader
                title={data.title}
                body={data.body}
                link={data.link}
                textAlign={data.textAlign}
                paddingBottom="30px"
              />
            </div>
            <FAQ data={data} />
          </div>
        </div>
      </div>
    </SectionStyles>
    {(data.divider === true && <Divider />) || null}
  </>
);

FAQ.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    event: PropTypes.string
  }).isRequired
};

export default faqSection;
