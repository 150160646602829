/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import FormError from './FormError';
import FormLabel from './FormLabel';
/*
 * Input fields
 *
 * Marketo types: ["string", "email", "phone", "url", "number"]
 * Standard type: ["text", "email", "tel", "url", "number"
 */

export const InputStyled = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.forms.border};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-sizing: border-box;
  box-shadow: none;
  color: ${(props) => props.theme.forms.text};
  font-size: 16px;
  height: 40px;
  margin: 0;
  padding: 8px 1px 8px 14px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;

  /* Removes arrows from number fields
  Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &.form-control {
    :focus {
      border: 1px solid ${(props) => props.theme.colors.blue_30};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue_30};
      outline: none;
    }
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::placeholder,
    :-ms-input-placeholder {
      color: ${(props) => props.theme.forms.text};
    }
    &.is-invalid {
      padding-left: 0;
      background-color: ${(props) => props.theme.colors.white};
      background-image: none;
      border: 1px solid ${(props) => props.theme.colors.red_error};
      &:focus {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.red_error};
      }
    }
  }
`;

function FormText(props) {
  const [field, meta] = useField({
    ...props
  });

  const { name, type, placeholder, label, required, VisibilityRule, isEmail } =
    props;

  let colWidth;
  if (type === 'htmltext' || type === 'hidden' || type === 'email') {
    colWidth = 'col-12';
  } else {
    colWidth = 'col-6';
  }

  return (
    <div
      className={`form-group ${colWidth} ${
        VisibilityRule || name === 'honeypot' ? 'd-none form-row' : ''
      }`}
    >
      {FormLabel(props)}
      <InputStyled
        name={name}
        type={type}
        title={placeholder || ''}
        label={label}
        required={!!required}
        placeholder={placeholder || ''}
        className={
          meta.touched && meta.error
            ? `form-control is-invalid ${
                (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
              }`
            : `form-control ${
                (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
              }`
        }
        /* eslint-disable react/jsx-props-no-spreading */
        {...field}
        {...props}
      />
      <div className="col-12 pl-0">
        {meta.touched && meta.error ? (
          <FormError className="error" isEmail={isEmail}>
            {meta.error}
          </FormError>
        ) : null}
      </div>
    </div>
  );
}

export default FormText;
