import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ColorSpan = styled.span`
  display: inline-block;
  min-height: 30px;
  max-height: 30px;

  min-width: 30px;
  max-width: 30px;
`;

const TextSpan = styled.span`
  display: inline-flex;
  align-items: center;

  .mobile-bold {
    @media screen and (max-width: 768px) {
      font-weight: bold;
    }
  }
`;

function ChartKey({
  labels,
  dataset,
  backgroundColor = [],
  displayLabelOnlyAndOmitValues, // Global Growth, bottom two pies with top 10. 2022
  centered,
  fontFamily = "'Inter', sans-serif",
  fontColor = '#212529',
  isPercent = true,
  insertBrAfterItemName = false,
  className = ''
}) {
  const chartKey = labels.map((label, i) => {
    let itemString;
    let value;

    const sanitizedLabel = label.toString().replace(/,/g, ' ');

    if (dataset[i] >= 1000000) {
      value = `${(dataset[i] / 1000000).toFixed(1)} million`;
    } else if (!isPercent) {
      // Add commas to large numbers
      value = dataset[i].toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(',');
    } else {
      value = dataset[i];
    }

    if (insertBrAfterItemName) {
      itemString = `<div><span class="mobile-bold ">${sanitizedLabel}:</span><br />${value}${
        isPercent ? '%' : ''
      }</div>`;
    } else if (displayLabelOnlyAndOmitValues) {
      itemString = `<span class="mobile-bold">${sanitizedLabel}</span>`;
    } else {
      itemString = `<span class="mobile-bold">${sanitizedLabel}</span>: ${value}${
        isPercent ? '%' : ''
      }`;
    }

    return (
      <div
        key={pmUuid()}
        style={{ fontFamily, color: fontColor }}
        className={`d-flex  align-items-center text-left mb-2 ${
          centered ? 'justify-content-center' : 'pl-2'
        }`}
      >
        {backgroundColor[i] && (
          <ColorSpan
            className="mr-2"
            style={{ backgroundColor: backgroundColor[i] }}
          />
        )}
        {sanitizedLabel && (
          <TextSpan
            alternative
            dangerouslySetInnerHTML={{ __html: itemString }}
          />
        )}
      </div>
    );
  });

  return <div className={`d-inline-block ${className}`}>{chartKey}</div>;
}

export default ChartKey;

ChartKey.defaultProps = {
  backgroundColor: [],
  centered: null,
  isPercent: true,
  fontFamily: '',
  insertBrAfterItemName: false,
  displayLabelOnlyAndOmitValues: false,
  labels: null,
  dataset: null,
  fontColor: '',
  className: ''
};

ChartKey.propTypes = {
  backgroundColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.objectOf
  ]),
  isPercent: PropTypes.bool,
  insertBrAfterItemName: PropTypes.bool,
  displayLabelOnlyAndOmitValues: PropTypes.bool,
  centered: PropTypes.bool,
  fontFamily: PropTypes.string,
  labels: PropTypes.instanceOf(Array),
  dataset: PropTypes.instanceOf(Array),
  fontColor: PropTypes.string,
  className: PropTypes.string
};
