/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DOMPurify from 'dompurify';
import FormContainer from '../layout/FormContainer';
import { InputStyled } from '../components/FormText';
import { CheckboxInput } from '../components/FormCheckbox';
import { ButtonStyled } from '../components/FormButton';
import { FormErrorStyled } from '../components/FormError';

const EmailField = styled(Field)`
  ${InputStyled.componentStyle.rules}
`;

const CheckboxField = styled(Field)`
  ${CheckboxInput.componentStyle.rules}
`;

const RequiredAsterik = styled.span`
  color: ${(props) => props.theme.colors.red_error};
`;

const SubmitButton = styled.button`
  ${ButtonStyled.componentStyle.rules}
  &:disabled {
    color: ${(props) => props.theme.colors.grey_20};
    cursor: not-allowed;
    background-image: none !important;
    border-color: transparent;
    opacity: 0.7;
  }
`;

const ErrorDiv = styled(ErrorMessage)`
  margin-top: 16px;
  ${FormErrorStyled.componentStyle.rules}
`;

const CheckboxError = styled.div`
  margin-top: 16px;
  ${FormErrorStyled.componentStyle.rules}
`;
const API_URL = 'https://c9u7a2tij9.execute-api.us-east-1.amazonaws.com/user';
const HEADERS = { 'Content-Type': 'application/json; charset=utf-8' };

function UnsubscribeForm() {
  const [unsubscribeAll, setUnsubscribeAll] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [encryptedToken, setEncryptedToken] = useState('');
  const PUBLIC_RECAPTCHA_KEY =
    process.env.GATSBY_GOOGLE_RECAPTCHA_ENTERPRISE_KEY;

  const initCaptcha = useCallback(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${PUBLIC_RECAPTCHA_KEY}`;
    script.onload = () => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(PUBLIC_RECAPTCHA_KEY, { action: 'opt_out' })
          .then((token) => {
            setEncryptedToken(token);
          });
      });
    };
    document.body.appendChild(script);
  }, [PUBLIC_RECAPTCHA_KEY]);

  useEffect(() => {
    initCaptcha();
  }, [initCaptcha]);

  const apiCall = async (endpoint, body) => {
    try {
      const response = await axios.post(
        `${API_URL}/${endpoint}`,
        { body },
        { headers: HEADERS }
      );

      if (response.status !== 200) {
        throw new Error(`Error: Received status code ${response.status}`);
      }
      return response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  };

  const submitForm = async (val) => {
    const validEmail = DOMPurify.sanitize(val.Email);

    try {
      const response = await apiCall('verify', {
        Email: validEmail,
        expectedAction: 'OPT_OUT',
        token: encryptedToken
      });
      if (response && response.body.event.riskAnalysis.score > 0.1) {
        const { score } = response.body.event.riskAnalysis;
        const { tokenProperties } = response.body.event;

        await apiCall('preferences', {
          Email: validEmail,
          ProductUpdates: val.ProductUpdates,
          Enterprise: val.Enterprise,
          Events: val.Events,
          StudentPrograms: val.StudentPrograms,
          Unsubscribe_All: val.Unsubscribe_All,
          AssessScore: score,
          AssessTokenProps: tokenProperties
        });

        setFormSubmitted(true);
      } else {
        const formEl = document.querySelector('form');
        const p = document.createElement('p');
        p.textContent =
          'Sorry. We could not verify that you are a human. Please refresh the page and try again.';
        p.style.color = 'red';
        formEl.replaceWith(p);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  };
  return (
    <FormContainer id="formContainer">
      {formSubmitted ? (
        <div>
          <h2>Success!</h2>
          <p className="mb-2">
            We have received your request and will unsubscribe you from the
            email lists you indicated.
          </p>
          <p>
            Note that as a Postman user, you will still receive emails about
            changes to prices and plans.
          </p>
          <a href="/">← Return to Postman</a>
          <p className="small mt-3">
            For more information about your data, read our{' '}
            <a href="/legal/privacy-policy/">privacy policy</a>.
          </p>
        </div>
      ) : (
        <>
          <h2 id="formTitle">Email unsubscribe center</h2>

          <p id="formBody">
            If you no longer wish to receive marketing emails from Postman,
            please enter your email address and select the lists you would like
            to unsubscribe from below.
          </p>
          <Formik
            className="g-recaptcha"
            sitekey={PUBLIC_RECAPTCHA_KEY}
            size="normal"
            initialValues={{
              Email: '',
              ProductUpdates: false,
              Enterprise: false,
              Events: false,
              StudentPrograms: false,
              Unsubscribe_All: false
            }}
            onSubmit={(values, { setSubmitting }) => {
              const button = document.getElementById('submitButton');
              button.innerHTML = 'Submitting...';
              button.disabled = true;
              submitForm(values);
              setSubmitting(true);
            }}
            validate={(values) => {
              const errors = {};
              if (!values.Email) {
                errors.Email = 'Email is required. e.g., name@domain.com.';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
              ) {
                errors.Email = 'Please enter a valid email address.';
              }
              if (
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email) &&
                !values.ProductUpdates &&
                !values.Enterprise &&
                !values.Events &&
                !values.StudentPrograms &&
                !values.Unsubscribe_All
              ) {
                errors.checkbox =
                  'Select from the following options below to unsubscribe.';
              }
              return errors;
            }}
          >
            {({ setFieldValue, isSubmitting, errors }) => (
              <Form data-test="unsubscribeForm">
                <label htmlFor="Email">
                  Email<RequiredAsterik>*</RequiredAsterik>
                </label>
                <EmailField
                  type="email"
                  name="Email"
                  label="Email"
                  id="Email"
                />
                <ErrorDiv name="Email" component="div" />
                {errors.checkbox && (
                  <CheckboxError className="mb-0">
                    {errors.checkbox}
                  </CheckboxError>
                )}
                <CheckboxField
                  type="checkbox"
                  id="ProductUpdates"
                  name="ProductUpdates"
                />
                <label htmlFor="ProductUpdates" className="mt-3">
                  Product updates
                  <p className="mt-1 mb-0 small">
                    New Postman products and features
                  </p>
                </label>

                <ErrorDiv name="ProductUpdates" component="div" />

                <CheckboxField
                  type="checkbox"
                  id="Enterprise"
                  name="Enterprise"
                />
                <label htmlFor="Enterprise" className="mt-3">
                  Enterprise
                  <p className="mt-1 mb-0 small">
                    New offerings and features for large organizations
                  </p>
                </label>
                <ErrorDiv name="Enterprise" component="div" />

                <CheckboxField type="checkbox" id="Events" name="Events" />
                <label htmlFor="Events" className="mt-3">
                  Events
                  <p className="mt-1 mb-0 small">
                    Upcoming events such as online tutorials and conferences
                  </p>
                </label>
                <ErrorDiv name="Events" component="div" />

                <CheckboxField
                  type="checkbox"
                  id="StudentPrograms"
                  name="StudentPrograms"
                />
                <label htmlFor="StudentPrograms" className="mt-3">
                  Student Program
                  <p className="mt-1 mb-0 small">
                    Receive information about educational programs and
                    certifications
                  </p>
                </label>
                <ErrorDiv name="StudentPrograms" component="div" />
                <CheckboxField
                  type="checkbox"
                  id="Unsubscribe_All"
                  name="Unsubscribe_All"
                  onClick={() => {
                    setUnsubscribeAll(!unsubscribeAll);
                    setFieldValue('ProductUpdates', !unsubscribeAll);
                    setFieldValue('Enterprise', !unsubscribeAll);
                    setFieldValue('Events', !unsubscribeAll);
                    setFieldValue('StudentPrograms', !unsubscribeAll);
                  }}
                />
                <label htmlFor="Unsubscribe_All" className="mt-3">
                  Unsubscribe from all
                  <p className="mt-1 mb-0 small">
                    You will still receive important transactional and
                    billing-related emails
                  </p>
                </label>
                <ErrorDiv name="Unsubscribe_All" component="div" />
                <SubmitButton
                  id="submitButton"
                  type="submit"
                  className="mt-4"
                  disabled={!!(isSubmitting || errors.checkbox || errors.Email)}
                >
                  Submit
                </SubmitButton>
              </Form>
            )}
          </Formik>
          <p className="small mt-3">
            For more information about your data, read our{' '}
            <a href="/legal/privacy-policy/">privacy policy</a>.
          </p>
        </>
      )}
    </FormContainer>
  );
}

export default UnsubscribeForm;
