import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import { Link } from 'gatsby';
import { Paragraph, BaseLink, SideLink } from 'aether-marketing';
import platform from './data/api-platform-nav-data.json';
import legal from './data/legal-nav-data.json';
import glossary from './data/glossary-nav-data.json';
import whatIsAnApi from './data/what-is-an-api-data.json';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

const Card = styled.div`
  background: none;
  border: none;
`;

const CardHeader = styled.div`
  border: none;
  background: none;
`;

const NavLink = styled.a`
  appearance: none;
  border: none;
  display: block;
  color: ${(props) => props.theme.colors.grey_90};
  font-size: 16px;
  font-weight: 600;
  &.dropdown-item.active {
    background-color: #f2f2f2;
    color: ${(props) => props.theme.colors.grey_90};
  }
  &:focus {
    box-shadow: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.grey_90};
  }

  &.active {
    background-color: #f2f2f2;
    color: ${(props) => props.theme.colors.grey_90};
  }
`;

const SideNavLink = styled.a`
  ${SideLink.componentStyle.rules}
  display: block;
  width: 100%;
  font-weight: normal;
  color: ${(props) => props.theme.colors.grey_90};
  font-size: 16px;
  &:focus {
    box-shadow: none;
  }

  &.dropdown-item.active {
    background-color: #f2f2f2 !important;
    color: ${(props) => props.theme.colors.grey_90} !important;
  }

  @media (min-width: 991px) {
    margin-inline-start: 0px;
  }
`;

const GatsbyLink = styled(Link)`
  ${NavLink.componentStyle.rules}
`;

const ArrowIcon = styled.svg`
  float: right;
  &.arrow-icon {
    display: inline-block;
    cursor: pointer;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    &:active,
    &:focus {
      outline: none;
    }
  }
  &.show,
  .show {
    display: inline-block;
    cursor: pointer;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -webkit-transition: 0.1s linear;
    -o-transition: 0.1s linear;
    transition: 0.1s linear;
  }
`;

const CallToActionLink = styled(BaseLink)`
  font-weight: normal;
`;

const templates = [platform, legal, glossary, whatIsAnApi];

function CTALink({ callToActionLink }) {
  return (
    <div className="mt-3">
      <CallToActionLink
        linkType="arrowLink"
        src={callToActionLink.src}
        target={
          callToActionLink.type ? callToActionLink.type : 'new-tab-postman'
        }
      >
        Try Postman for free
      </CallToActionLink>
    </div>
  );
}

function DropdownNav({ template, address }) {
  const { title, callToActionLink } = template;
  useEffect(() => {
    $('body').scrollspy({ target: '#pm-sidenav', offset: 300 });

    function showBsCollapse() {
      const isToggled = $(this)
        .prev('div')
        .find('a[aria-expanded]')
        .attr('aria-expanded');
      if (isToggled) {
        $(this).prev('div').find('a i svg').addClass('show');
      }
    }

    function hideBsCollapse() {
      const isToggled = $(this)
        .prev('div')
        .find('a[aria-expanded]')
        .attr('aria-expanded');
      if (isToggled) {
        $(this).prev('div').find('a i svg').removeClass('show');
      } else {
        showBsCollapse();
      }
    }

    $('.collapse').on('show.bs.collapse', showBsCollapse);
    $('.collapse').on('hide.bs.collapse', hideBsCollapse);
  });

  return (
    <div id="pm-sidenav">
      <Paragraph className="subtitle mb-3">{title}</Paragraph>
      <div id="accordion">
        {template.categories.map((category) => (
          <Card className="card" key={pmUuid()}>
            <CardHeader className="card-header" id={category.id}>
              <NavLink
                role="button"
                className={category.active ? '' : 'collapsed'}
                data-toggle="collapse"
                href="#"
                data-target={`#collapse-${category.id}`}
                aria-expanded={category.active ? 'true' : 'false'}
                aria-controls={`collapse-${category.id}`}
              >
                {category.section}
                <i>
                  <ArrowIcon
                    aria-labelledby={category.id}
                    className={
                      category.active ? 'arrow-icon show' : 'arrow-icon'
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="#6b6b6b"
                  >
                    <g>
                      <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                    </g>
                  </ArrowIcon>
                </i>
              </NavLink>
            </CardHeader>
            <div
              id={`collapse-${category.id}`}
              className={
                category.active
                  ? 'collapse collapse-menu__side show'
                  : 'collapse collapse-menu__side'
              }
              aria-labelledby={category.id}
              data-parent="#accordion"
            >
              <div className="card-body pt-0">
                {category.links.map((link) =>
                  link.url.includes('#') ? (
                    <SideNavLink
                      key={link.url}
                      href={link.url}
                      className="dropdown-item"
                    >
                      {link.title}
                    </SideNavLink>
                  ) : (
                    <SideNavLink
                      key={pmUuid()}
                      href={link.url}
                      className={
                        link.url === address || link.active
                          ? 'dropdown-item current-page'
                          : 'dropdown-item'
                      }
                    >
                      {link.title}
                    </SideNavLink>
                  )
                )}
              </div>
            </div>
          </Card>
        ))}
      </div>
      {callToActionLink && callToActionLink.isVisible && (
        <CTALink callToActionLink={callToActionLink} />
      )}
    </div>
  );
}

function ListNav({ template, address }) {
  const { title, callToActionLink } = template;
  useEffect(() => {
    $('body').scrollspy({ target: '#pm-sidenav', offset: 300 });
    $('[data-spy="scroll"]').each(function refreshScroll() {
      $(this).scrollspy('refresh');
    });
  });
  return (
    <div id="pm-sidenav">
      <Paragraph className="subtitle mb-3">{title}</Paragraph>
      <div className="list-group">
        {template.categories[0].links.map((link) =>
          link.url.includes('#') ? (
            <NavLink
              href={link.url}
              key={pmUuid()}
              className={`${
                link.url === address || link.active
                  ? 'list-group-item list-group-item-action font-weight-normal active'
                  : 'list-group-item list-group-item-action font-weight-normal'
              }`}
            >
              {link.title}
            </NavLink>
          ) : (
            <GatsbyLink
              to={link.url}
              key={pmUuid()}
              className={`${
                link.url === address || link.active
                  ? 'list-group-item list-group-item-action font-weight-normal active'
                  : 'list-group-item list-group-item-action font-weight-normal'
              }`}
            >
              {link.title}
            </GatsbyLink>
          )
        )}
      </div>
      {callToActionLink && callToActionLink.isVisible && (
        <CTALink callToActionLink={callToActionLink} />
      )}
    </div>
  );
}

function NavRender({ props }) {
  const { refKey, includes } = props;
  const template = templates.find((temp) => temp.key === refKey);

  const [address, setAddress] = useState('');
  useEffect(() => {
    let currentUrl;
    if (window.location.hash) {
      currentUrl = window.location.hash;
      setAddress(currentUrl);
    } else {
      currentUrl = window.location.pathname.endsWith('/')
        ? window.location.pathname
        : `${window.location.pathname}/`;
      setAddress(currentUrl);
    }
  }, []);

  template.categories.map((category) => {
    category.id = category.section
      .toLowerCase()
      .replaceAll(' ', '-')
      .replaceAll('&', 'and');
    category.links.map((link) =>
      link.url === address || link.url === includes
        ? ((link.active = true), (category.active = true))
        : (link.active = false)
    );
    return template;
  });

  return template.dropdown ? (
    <DropdownNav address={address} template={template} includes={includes} />
  ) : (
    <ListNav address={address} template={template} includes={includes} />
  );
}
function LeftNav({ ...props }) {
  return <NavRender props={props} />;
}
export default LeftNav;
