import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function RunInPostmanButton(data) {
  /* eslint-disable */
  const { collectionId, workspaceId } = data;
    useEffect(() => {
      (function (p, o, s, t, m, a, n) {
        !p[s] && (p[s] = function () { (p[t] || (p[t] = [])).push(arguments); });
        !o.getElementById(s + t) && o.getElementsByTagName("head")[0].appendChild((
          (n = o.createElement("script")),
          (n.id = s + t), (n.async = 1), (n.src = m), n
        ));
        }(
        window,
        document,
        '_pm',
        'PostmanRunObject',
        'https://run.pstmn.io/button.js',
      ));
    },[]);
  /* eslint-enable */
  return (
    <div
      className="postman-run-button"
      data-postman-action="collection/import"
      data-postman-var-1={workspaceId}
      data-postman-collecton-url={`entityId=${workspaceId}&entityType=collection&workspaceId=${collectionId}`}
    />
  );
}

export default RunInPostmanButton;

RunInPostmanButton.propTypes = {
  data: PropTypes.shape({
    collection_id: PropTypes.string,
    workspace_id: PropTypes.string
  })
};

RunInPostmanButton.defaultProps = {
  data: {}
};
