import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  BaseLinkStyles,
  UnorderedListStyles,
  OrderedListStyles
} from 'aether-marketing';

const Section = styled.section`
  padding: 60px 0 5px 0;

  & .divider-line {
    border-bottom: 1px solid #ededed;
    padding-bottom: 30px;
  }

  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }

  & .floating-header,
  .floating-body {
    z-index: 0; /* to keep under the blurred navbar */
  }

  @media (min-width: 992px) {
    padding: 5px 0 40px 0;
    & .floating-header,
    .floating-body {
      padding-top: 75px;
    }

    & .floating-header {
      float: left;
      width: 33%;
    }

    & .floating-body {
      float: right;
      width: 58%;
    }
  }
`;

const floatingHeaderSectionV6 = (data) => {
  const anchorTag = data.title.replace(/\s+/g, '-').toLowerCase();

  const bodyContent = data.items ? data.items : [];

  return (
    <>
      <Section>
        <div className="container">
          <div className="row mb-3" id={`${anchorTag}`}>
            <div className="col-sm-12 text-left">
              <h2 className="mb-4 sticky-top floating-header">{data.title}</h2>
              <div
                className="floating-body"
                dangerouslySetInnerHTML={{ __html: bodyContent.join('') }}
              />
            </div>
          </div>
        </div>
      </Section>
      {(data.divider === true && <Divider />) || null}
    </>
  );
};

export default floatingHeaderSectionV6;
