/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull marketing'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 264
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/postman/Postman/implementation/marketing)
 */

import amplitude from 'amplitude-js';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * Data to be processed by middleware
 * @typedef MiddlewarePayload
 * @type {object}
 * @property {BaseEvent} event
 * @property {MiddlewareExtra} [extra]
 */

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 * @typedef MiddlewareNext
 * @type {function}
 * @param {MiddlewarePayload} payload
 *
 * @return
 */

/**
 * A function to run on the Event stream (each logEvent call)
 * @typedef Middleware
 * @type {function}
 * @param {MiddlewarePayload} payload The event and extra data being sent
 * @param {MiddlewareNext} next Function to run the next middleware in the chain, not calling next will end the middleware chain
 * @return
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [options]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef {Object} MiddlewareExtra
 * @type {Object.<string, *>}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: '56d4a7f42486e1c4ec95a892fd96c402',
  development: '0cc34f31d293487891b42be4c7b3fcd2'
};

/**
 * @typedef {Object} CallToAction
 * @param {string} [cta_image_alt] If the CTA was an image, what was the alt text?
 * @param {string} [cta_image_src] If the CTA is an image, what was the image src?
 * @param {string} [cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
 * @param {string} [cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
 * @param {string} [cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
 * @param {'button'|'link'|'image'|'other'|'icon'} cta_type What kind of CTA was it? Button? Link? Image? Other?
 */

/**
 * @typedef {Object} CommonEventProperties
 * @param {*[]} [experiment_started] If the given event demarcates the start of an experiment, you should include the details of that experiment in this property. If the event demarcates the start of two separate experiments, then we can include them both in the array.   ``` [   {      experiment_id: 'experiment_1',     variation_id: 'control',   },   {     experiment_id: 'experiment_2',     variation_id: 'variation_a',   } ] ```
 * @param {*[]} [feature_flags] An array of all the feature flags that have been evaluated for this user:  ``` feature_flags: [   { flag_name: 'a', variation: 'foo' },   { flag_name: 'b', variation: 'bar' } ] ```
 * @param {'desktop'|'browser'} [platform] desktop or browser
 * @param {string} team_id Property has no description in tracking plan.
 * @param {string} url Property has no description in tracking plan.
 * @param {string} user_email_domain Property has no description in tracking plan.
 * @param {string} [user_id] Property has no description in tracking plan.
 * @param {'admin'|'billing'|'developer'|'editor'} [user_role] What is the user's role within the context of their current team in which they're trying to perform an action?   It should only be required if the action is on a team-level entitiy. But, if the user is doing something in their personal workspace, it shouldn't be required.
 * @param {string} version The semver app version number
 * @param {string} [workspace_id] Property has no description in tracking plan.
 */

/**
 * @typedef {Object} MarketingBaseEventProperties
 * @param {string} [referrer] Property has no description in tracking plan.
 * @param {boolean} [signed_in] Property has no description in tracking plan.
 * @param {string} [user_agent] Property has no description in tracking plan.
 */

/**
 * @typedef {Object} PageViewed
 * @param {string} url The current URL of the page
 */

export const SpecialEventType = {
  Identify: "$identify",
  Group: "$groupidentify"
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions = {
  plan: {
    version: '264',
    branch: 'main',
    source: 'marketing',
    versionId: 'f6ec58f4-486a-484d-a2ec-5d1ade02fec1'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '1.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = SpecialEventType.Identify;
    this.event_properties = properties;
  }
}

export class Group {
  constructor(properties) {
    this.event_type = SpecialEventType.Group;
    this.event_properties = properties;
  }
}

export class ContactSalesSubmitClicked {
  constructor(properties) {
    this.event_type = 'Contact Sales - Submit - Clicked';
    this.event_properties = properties;
  }
}

export class ContactSalesEnterpriseSubmitClicked {
  constructor(properties) {
    this.event_type = 'Contact Sales Enterprise - Submit - Clicked';
    this.event_properties = properties;
  }
}

export class ContactSalesEnterprisePageViewed {
  constructor(properties) {
    this.event_type = 'Contact Sales Enterprise Page - Viewed';
    this.event_properties = properties;
  }
}

export class ContactSalesPageViewed {
  constructor(properties) {
    this.event_type = 'Contact Sales Page - Viewed';
    this.event_properties = properties;
  }
}

export class DownloadsPageDownloadButtonClicked {
  constructor(properties) {
    this.event_type = 'Downloads Page - Download Button - Clicked';
    this.event_properties = properties;
  }
}

export class DownloadsPagePageViewed {
  constructor(properties) {
    this.event_type = 'Downloads Page - Page Viewed';
    this.event_properties = properties;
  }
}

export class DownloadsPageTryWebClicked {
  constructor(properties) {
    this.event_type = 'Downloads Page - Try Web - Clicked';
    this.event_properties = properties;
  }
}

export class HeaderLaunchAppClicked {
  constructor(properties) {
    this.event_type = 'Header - Launch App - Clicked';
    this.event_properties = properties;
  }
}

export class HeaderSignInClicked {
  constructor(properties) {
    this.event_type = 'Header - Sign In - Clicked';
    this.event_properties = properties;
  }
}

export class HeaderSignUpClicked {
  constructor(properties) {
    this.event_type = 'Header - Sign Up - Clicked';
    this.event_properties = properties;
  }
}

export class HeaderViewed {
  constructor(properties) {
    this.event_type = 'Header - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageAddOnsLearnMoreClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Add Ons - Learn More - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageAddOnsSectionClicked{
  constructor(properties) {
    this.event_type = 'Pricing Page - Add Ons Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageAddOnsSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Add Ons Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageBasicCardBuyNowClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Basic Card - Buy Now - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageCaseStudiesItemClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Case Studies - Item - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageCaseStudiesSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Case Studies Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageContactSalesClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Contact Sales - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageCustomersBuyNowClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Customers - Buy Now - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageCustomersContactSalesClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Customers - Contact Sales - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageCustomersSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Customers Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageDownloadRoiClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Download ROI - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageEnterpriseCardContactSalesClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Enterprise Card - Contact Sales - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqAutoflexClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ - Autoflex - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqEmailSalesClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ - Email Sales - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqNonprofitsClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ - Nonprofits - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqStudentProgramClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ - Student Program - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqItemClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ Item - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqAddOnsItemClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ Add-Ons Item - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageFaqSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageFaqAddOnsSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - FAQ Add Ons Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageFreeCardSignUpClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Free Card - Sign Up - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPagePlanComparisonItemClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Plan Comparison - Item - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPagePlanComparisonItemTooltipViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Plan Comparison - Item Tooltip - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPagePlanComparisonSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Plan Comparison Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPagePlansSectionAddOnsViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Plans Section - Add Ons - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPagePlansSectionViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Plans Section - Viewed';
    this.event_properties = properties;
  }
}

export class PricingPageProfessionalCardBuyNowClicked {
  constructor(properties) {
    this.event_type = 'Pricing Page - Professional Card - Buy Now - Clicked';
    this.event_properties = properties;
  }
}

export class PricingPageViewed {
  constructor(properties) {
    this.event_type = 'Pricing Page - Viewed';
    this.event_properties = properties;
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
    /* @type {Middleware[]} */
    this.middlewares = [];
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...(options?.client?.options ?? options?.client?.config) });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} properties The user's properties.
   * @param {*} [properties.app_version] Property has no description in tracking plan.
   * @param {string} [properties.company_size] Property has no description in tracking plan.
   * @param {string} [properties.current_url] Property has no description in tracking plan.
   * @param {string} [properties.email_domain] Property has no description in tracking plan.
   * @param {string} [properties.event_source] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_102_onboarding_checklist] This trait will store the LD variation enabled for the user for GROWTH-102 experiment
   * @param {string} [properties.exp_growth_118_collection_share_trial_modal] This trait will store the LD variation enabled for the user for GROWTH-118 experiment
   * @param {string} [properties.exp_growth_122_video_bootcamp_popover] This trait will store the LD variation enabled for the user for GROWTH-122 experiment
   * @param {string} [properties.exp_growth_127_entity_share_modal_role_nudge] This trait will store the LD variation enabled for the user for GROWTH-127 experiment
   * @param {string} [properties.exp_growth_128_auto_start_3_member_trial] When a user is exposed to the growth 128 auto start trial experiment
   * @param {string} [properties.exp_growth_128_auto_start_3_member_trial_v2] v2 for GR-128
   * @param {string} [properties.exp_growth_132_basic_plan_sso_nudge] This trait will store the LD variation enabled for the user for GROWTH-132 experiment
   * @param {string} [properties.exp_growth_133_collection_copy_link_button] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_133_collection_link_share_modal] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_153_add_resources] This trait will store the LD variation enabled for the user for GROWTH-153 experiment
   * @param {string} [properties.exp_growth_157_members_count_in_team_discovery] This trait will store the LD variation enabled for the user for GROWTH-157 experiment
   * @param {string} [properties.exp_growth_159_cart_abandonment_limited_trial] This trait will store the LD variation enabled for the user for GROWTH-159 experiment
   * @param {string} [properties.exp_growth_173_cart_abandonment_email_banner] This trait will store the LD variation enabled for the user for GROWTH-173 experiment
   * @param {string} [properties.exp_growth_182_personalised_onboarding] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_187_import_collection_nudge_v2] This trait will store the variation of growth-187 used for the user
   * @param {string} [properties.exp_growth_195_invite_all_on_domain] User property for GR-195 experiment
   * @param {string} [properties.exp_growth_218_value_of_collaboration_in_team_onboarding] Property to identify the GROWTH-218 variation
   * @param {string} [properties.exp_growth_226_create_visual_distinction_in_workspaces] This trait will store the LD variation enabled for the user for GROWTH-226 experiment
   * @param {string} [properties.exp_growth_232_join_modal] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_258_recommend_a_list_of_users_to_create_a_team] This trait will store the LD variation enabled for the user for GROWTH-258 experiment
   * @param {string} [properties.exp_growth_263_share_collection_link_in_the_export_modal] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_267_collection_templates_to_facilitate_use_case_adoption] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_289_enable_team_discovery] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_89_team_full_warning_billing] This trait will store the LD variation enabled for the user for GROWTH-89 experiment
   * @param {string} [properties.exp_growth_grw_1555_recommended_templates_in_create_collection_dropdown] This trait will store the LD variation enabled for the user for GRW_1555 experiment
   * @param {string} [properties.exp_growth_grw_1682_grid_collection_template_banner] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_grw_358_improved_onboarding] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth_grw_425_show_pro_tip_to_save_response] User property for showing protips to Save responses as example (GROWTH-425)
   * @param {string} [properties.exp_growth_grw_652_collection_run_paywall] Property has no description in tracking plan.
   * @param {string} [properties.exp_growth-258-recommend-a-list-of-users-to-create-a-team] Property has no description in tracking plan.
   * @param {string} [properties.exp_grw_468_create_collection_from_history_requests] This trait will store the LD variation enabled for the user for GRW-468 experiment
   * @param {string} [properties.exp_vc-3261-show-primary-fork-button] Property has no description in tracking plan.
   * @param {*[]} [properties.experiment_started] Experiment started events
   * @param {string} [properties.multi_team_enabled] Property has no description in tracking plan.
   * @param {string} [properties.plan_type] Property has no description in tracking plan.
   * @param {string} [properties.platform] Property has no description in tracking plan.
   * @param {string} [properties.release_channel] Property has no description in tracking plan.
   * @param {string} [properties.releaseChannel] Property has no description in tracking plan.
   * @param {*} [properties.team] Property has no description in tracking plan.
   * @param {string} [properties.team_id] Property has no description in tracking plan.
   * @param {string} [properties.team_user_id] Property has no description in tracking plan.
   * @param {string} [properties.teamId] Property has no description in tracking plan.
   * @param {boolean} [properties.trial_already_taken] whether user/team has already taken trial or not
   * @param {string} [properties.user_activation_date] Property has no description in tracking plan.
   * @param {string} [properties.user_activation_flag_15_day] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] User-agent property for the user to weed out the bot users.
   * @param {string} [properties.user_domain_professional] Property has no description in tracking plan.
   * @param {string} [properties.user_email_verified] Property has no description in tracking plan.
   * @param {string} [properties.user_email_verified_flag] Property has no description in tracking plan.
   * @param {string} [properties.user_id] Property has no description in tracking plan.
   * @param {string} [properties.user_onboarding_experience] How experienced of a developer the user is. Options could consist of "advanced", "comfortable", or "little-knowledge". This is an experimental field used in the onboarding flow.
   * @param {string} [properties.user_onboarding_role] Property has no description in tracking plan.
   * @param {string} [properties.user_onboarding_used_before] Whether or not they've used postman before. Either "yes" or "no". This is an experimental field used in the onboarding flow.
   * @param {string} [properties.user_professional_domain_flag] Property has no description in tracking plan.
   * @param {string} [properties.user_signup_time] Property has no description in tracking plan.
   * @param {string} [properties.user_team_join_time] Property has no description in tracking plan.
   * @param {string} [properties.user_team_plan] Property has no description in tracking plan.
   * @param {string} [properties.user_team_size] Property has no description in tracking plan.
   * @param {string} properties.userId Property has no description in tracking plan.
   * @param {string} [properties.webfn_1926_switch_to_workspace_from_onboarding] Property has no description in tracking plan.
   * @param {IdentifyOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  identify(userId, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  _identify(event, options) {
    const ampIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        ampIdentify.set(key, value);
      }
    }
    this.amplitude.identify(
      ampIdentify,
      options?.callback,
      options?.errorCallback
    );
  }

  /**
   * Set Group for the current user
   *
   * @param {String} name
   * @param {String|String[]} value
   * @param {GroupOptions} [options]
   * @param {MiddlewareExtra} [extra]
   */
  setGroup(name, value, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Identify a group and set or update that group's properties.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {Object} [properties] The group's properties.
   * @param {*} [properties.[Amplitude] Group ID] Property has no description in tracking plan.
   * @param {*} [properties.[Amplitude] Group name] Property has no description in tracking plan.
   * @param {*} [properties.$set] Property has no description in tracking plan.
   * @param {string} [properties.externalId] External ID of the user (with respect to multi-teams)
   * @param {string} [properties.team] Team ID of the user
   * @param {*} [properties.team_activation_date] Property has no description in tracking plan.
   * @param {*} [properties.team_activation_flag_15_day] Property has no description in tracking plan.
   * @param {*} [properties.team_conversion_flag_30_day] Property has no description in tracking plan.
   * @param {*} [properties.team_created_time] Property has no description in tracking plan.
   * @param {*} [properties.team_first_paid_date] Property has no description in tracking plan.
   * @param {*} [properties.team_invites_accepted] Property has no description in tracking plan.
   * @param {*} [properties.team_invites_pending] Property has no description in tracking plan.
   * @param {*} [properties.team_invites_sent] Property has no description in tracking plan.
   * @param {*} [properties.team_plan] Property has no description in tracking plan.
   * @param {*} [properties.team_professional_domain_flag] Property has no description in tracking plan.
   * @param {*} [properties.team_purchase_route] Property has no description in tracking plan.
   * @param {*} [properties.team_serve_type] Property has no description in tracking plan.
   * @param {*} [properties.team_size] Property has no description in tracking plan.
   * @param {*} [properties.team_trial_start_date] Property has no description in tracking plan.
   * @param {*} [properties.trial_duration] Property has no description in tracking plan.
   * @param {*} [properties.trial_end_date] Property has no description in tracking plan.
   * @param {*} [properties.trial_ended_on] Property has no description in tracking plan.
   * @param {*} [properties.trial_id] Property has no description in tracking plan.
   * @param {*} [properties.trial_new_plan] Property has no description in tracking plan.
   * @param {*} [properties.trial_old_plan] Property has no description in tracking plan.
   * @param {*} [properties.trial_start_date] Property has no description in tracking plan.
   * @param {*} [properties.trial_upgraded_on] Property has no description in tracking plan.
   * @param {*} [properties.trial_upgraded_plan] Property has no description in tracking plan.
   * @param {GroupOptions} [options] Options for this groupIdentify call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  groupIdentify(groupType, groupName, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Group,
      event_properties: properties,
      user_id: options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          amplitudeIdentify.set(key, value);
        }
      }
      this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify, options?.callback);
    });
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  track(event, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = payload.event.user_properties;
      if (userProperties) {
        const identifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        options?.errorCallback,
      );
    });
  }

  /**
   * Contact Sales - Submit - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Contact%20Sales%20-%20Submit%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  contactSalesSubmitClicked(properties, options, extra) {
    this.track(new ContactSalesSubmitClicked(properties), options, extra);
  }

  /**
   * Contact Sales Enterprise - Submit - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Contact%20Sales%20Enterprise%20-%20Submit%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  contactSalesEnterpriseSubmitClicked(properties, options, extra) {
    this.track(new ContactSalesEnterpriseSubmitClicked(properties), options, extra);
  }

  /**
   * Contact Sales Enterprise Page - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Contact%20Sales%20Enterprise%20Page%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url The current URL of the page
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  contactSalesEnterprisePageViewed(properties, options, extra) {
    this.track(new ContactSalesEnterprisePageViewed(properties), options, extra);
  }

  /**
   * Contact Sales Page - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Contact%20Sales%20Page%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url The current URL of the page
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  contactSalesPageViewed(properties, options, extra) {
    this.track(new ContactSalesPageViewed(properties), options, extra);
  }

  /**
   * Downloads Page - Download Button - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Downloads%20Page%20-%20Download%20Button%20-%20Clicked)
   *
   *
   *
   *
   *
   * This event tracks when a user clicks on the download button on the downloads page
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {*[]} [properties.experiment_started] If the given event demarcates the start of an experiment, you should include the details of that experiment in this property. If the event demarcates the start of two separate experiments, then we can include them both in the array.   ``` [   {      experiment_id: 'experiment_1',     variation_id: 'control',   },   {     experiment_id: 'experiment_2',     variation_id: 'variation_a',   } ] ```
   * @param {*[]} [properties.feature_flags] An array of all the feature flags that have been evaluated for this user:  ``` feature_flags: [   { flag_name: 'a', variation: 'foo' },   { flag_name: 'b', variation: 'bar' } ] ```
   * @param {'desktop'|'browser'} [properties.platform] desktop or browser
   * @param {string} properties.team_id Property has no description in tracking plan.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {string} properties.user_email_domain Property has no description in tracking plan.
   * @param {string} [properties.user_id] Property has no description in tracking plan.
   * @param {'admin'|'billing'|'developer'|'editor'} [properties.user_role] What is the user's role within the context of their current team in which they're trying to perform an action?   It should only be required if the action is on a team-level entitiy. But, if the user is doing something in their personal workspace, it shouldn't be required.
   * @param {string} properties.version The semver app version number
   * @param {string} [properties.workspace_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  downloadsPageDownloadButtonClicked(properties, options, extra) {
    this.track(new DownloadsPageDownloadButtonClicked(properties), options, extra);
  }

  /**
   * Downloads Page - Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Downloads%20Page%20-%20Page%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  downloadsPagePageViewed(properties, options, extra) {
    this.track(new DownloadsPagePageViewed(properties), options, extra);
  }

  /**
   * Downloads Page - Try Web - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Downloads%20Page%20-%20Try%20Web%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  downloadsPageTryWebClicked(properties, options, extra) {
    this.track(new DownloadsPageTryWebClicked(properties), options, extra);
  }

  /**
   * Header - Launch App - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Header%20-%20Launch%20App%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  headerLaunchAppClicked(properties, options, extra) {
    this.track(new HeaderLaunchAppClicked(properties), options, extra);
  }

  /**
   * Header - Sign In - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Header%20-%20Sign%20In%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  headerSignInClicked(properties, options, extra) {
    this.track(new HeaderSignInClicked(properties), options, extra);
  }

  /**
   * Header - Sign Up - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Header%20-%20Sign%20Up%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  headerSignUpClicked(properties, options, extra) {
    this.track(new HeaderSignUpClicked(properties), options, extra);
  }

  /**
   * Header - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Header%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  headerViewed(properties, options, extra) {
    this.track(new HeaderViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Add Ons - Learn More - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Add%20Ons%20-%20Learn%20More%20-%20Clicked)
   *
   * Learn more clicked on Monitoring/Mock/Custom Domains under Add-ons section
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.item_title Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageAddOnsLearnMoreClicked(properties, options, extra) {
    this.track(new PricingPageAddOnsLearnMoreClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Add Ons Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Add%20Ons%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageAddOnsSectionViewed(properties, options, extra) {
    this.track(new PricingPageAddOnsSectionViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Basic Card - Buy Now - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Basic%20Card%20-%20Buy%20Now%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageBasicCardBuyNowClicked(properties, options, extra) {
    this.track(new PricingPageBasicCardBuyNowClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Case Studies - Item - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Case%20Studies%20-%20Item%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} properties.item_title Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageCaseStudiesItemClicked(properties, options, extra) {
    this.track(new PricingPageCaseStudiesItemClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Case Studies Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Case%20Studies%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageCaseStudiesSectionViewed(properties, options, extra) {
    this.track(new PricingPageCaseStudiesSectionViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Contact Sales - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Contact%20Sales%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageContactSalesClicked(properties, options, extra) {
    this.track(new PricingPageContactSalesClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Customers - Buy Now - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Customers%20-%20Buy%20Now%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageCustomersBuyNowClicked(properties, options, extra) {
    this.track(new PricingPageCustomersBuyNowClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Customers - Contact Sales - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Customers%20-%20Contact%20Sales%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageCustomersContactSalesClicked(properties, options, extra) {
    this.track(new PricingPageCustomersContactSalesClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Customers Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Customers%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageCustomersSectionViewed(properties, options, extra) {
    this.track(new PricingPageCustomersSectionViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Download ROI - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Download%20ROI%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageDownloadRoiClicked(properties, options, extra) {
    this.track(new PricingPageDownloadRoiClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Enterprise Card - Contact Sales - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Enterprise%20Card%20-%20Contact%20Sales%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageEnterpriseCardContactSalesClicked(properties, options, extra) {
    this.track(new PricingPageEnterpriseCardContactSalesClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ - Autoflex - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20-%20Autoflex%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqAutoflexClicked(properties, options, extra) {
    this.track(new PricingPageFaqAutoflexClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ - Email Sales - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20-%20Email%20Sales%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqEmailSalesClicked(properties, options, extra) {
    this.track(new PricingPageFaqEmailSalesClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ - Nonprofits - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20-%20Nonprofits%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqNonprofitsClicked(properties, options, extra) {
    this.track(new PricingPageFaqNonprofitsClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ - Student Program - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20-%20Student%20Program%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqStudentProgramClicked(properties, options, extra) {
    this.track(new PricingPageFaqStudentProgramClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ Item - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20Item%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.item_index Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqItemClicked(properties, options, extra) {
    this.track(new PricingPageFaqItemClicked(properties), options, extra);
  }

  pricingPageFaqAddOnsItemClicked(properties, options, extra) {
    this.track(new PricingPageFaqAddOnsItemClicked(properties), options, extra);
  }

  /**
   * Pricing Page - FAQ Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFaqSectionViewed(properties, options, extra) {
    this.track(new PricingPageFaqSectionViewed(properties), options, extra);
  }

    /**
   * Pricing Page - FAQ FlowsSection - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20FAQ%20Section%20-%20Viewed)
   *
   *
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
    pricingPageFaqAddOnsSectionViewed(properties, options, extra) {
      this.track(new PricingPageFaqAddOnsSectionViewed(properties), options, extra);
    }
  /**
   * Pricing Page - Free Card - Sign Up - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Free%20Card%20-%20Sign%20Up%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageFreeCardSignUpClicked(properties, options, extra) {
    this.track(new PricingPageFreeCardSignUpClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Plan Comparison - Item - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Plan%20Comparison%20-%20Item%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {*} [properties.item_index] Property has no description in tracking plan.
   * @param {*} [properties.item_title] Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPagePlanComparisonItemClicked(properties, options, extra) {
    this.track(new PricingPagePlanComparisonItemClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Plan Comparison - Item Tooltip - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Plan%20Comparison%20-%20Item%20Tooltip%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.item_title Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPagePlanComparisonItemTooltipViewed(properties, options, extra) {
    this.track(new PricingPagePlanComparisonItemTooltipViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Plan Comparison Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Plan%20Comparison%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {number} [properties.item_index] Property has no description in tracking plan.
   * @param {string} [properties.item_title] Property has no description in tracking plan.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPagePlanComparisonSectionViewed(properties, options, extra) {
    this.track(new PricingPagePlanComparisonSectionViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Plans Section - Add Ons - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Plans%20Section%20-%20Add%20Ons%20-%20Viewed)
   *
   * When a user views the Add Ons dropdown in the initial Plan section. They might not see it on page load depending on their screen size so this will capture when it is visible in their viewport if they have to scroll to see it.
   *
   * Owner: Dimitri Gorgan
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPagePlansSectionAddOnsViewed(properties, options, extra) {
    this.track(new PricingPagePlansSectionAddOnsViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Plans Section - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Plans%20Section%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPagePlansSectionViewed(properties, options, extra) {
    this.track(new PricingPagePlansSectionViewed(properties), options, extra);
  }

  /**
   * Pricing Page - Professional Card - Buy Now - Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Professional%20Card%20-%20Buy%20Now%20-%20Clicked)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.cta_image_alt] If the CTA was an image, what was the alt text?
   * @param {string} [properties.cta_image_src] If the CTA is an image, what was the image src?
   * @param {string} [properties.cta_link_to] If the CTA linked to a specific URL, fragment, anchor, etc provide the link details here.
   * @param {string} [properties.cta_style] What styling was used for the CTA? Was it primary, secondary, etc?
   * @param {string} [properties.cta_text] If the CTA was a button or a link and had human-readable text, what was the text visible to the user?
   * @param {'button'|'link'|'image'|'other'|'icon'} properties.cta_type What kind of CTA was it? Button? Link? Image? Other?
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageProfessionalCardBuyNowClicked(properties, options, extra) {
    this.track(new PricingPageProfessionalCardBuyNowClicked(properties), options, extra);
  }

  /**
   * Pricing Page - Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/postman/Postman/events/main/latest/Pricing%20Page%20-%20Viewed)
   *
   * Owner: Sivcan Singh
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.referrer] Property has no description in tracking plan.
   * @param {boolean} [properties.signed_in] Property has no description in tracking plan.
   * @param {string} properties.url The current URL of the page
   * @param {string} [properties.user_agent] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  pricingPageViewed(properties, options, extra) {
    this.track(new PricingPageViewed(properties), options, extra);
  }

  /**
   * Add new middleware to end of chain
   *
   * @param {Middleware} middleware
   */
  addEventMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all middleware
   *
   * @param {MiddlewarePayload} payload
   * @param {MiddlewareNext} next The method to run after all middleware.
   *
   * @protected
   */
  runMiddleware(payload, next) {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();
