import React from 'react';
import styled from 'styled-components';
// import LottiAnimationContainer from '../LottiAnimationContainer';
import { Divider, BaseButton } from 'aether-marketing';

const HeroWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 200px !important;
  margin-right: 0px !important;
  .img-container {
    padding: 0px 0px 30px 0 !important;
  }
  @media (max-width: 992px) {
    margin-left: 0 !important;
    text-align: center;
    img {
      margin-left: 0;
    }
  }
`;
const HeroImg = styled.div`
  @media (max-width: 992px) {
    background-image: none !important;
  }
`;

const heroFullWidth = ({
  title,
  subtitle,
  button,
  divider,
  media,
  bgMedia
}) => {
  return (
    <HeroImg
      className="hero mt-0"
      style={{
        backgroundImage: `url('${bgMedia.src}')`,
        backgroundPosition: 'top -10px left 0px',
        backgroundSize: '115px 150px',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <HeroWrapper className="row justify-content-between mt-5 mt-lg-0">
        <div className="col-lg-3 order-1 order-lg-0 align-self-center mb-3 mb-lg-0 mt-3 mt-lg-0">
          <h1>{title}</h1>
          {subtitle && (
            <div dangerouslySetInnerHTML={{ __html: subtitle.join('') }} />
          )}
          <BaseButton
            buttonType={button.buttonType}
            as="a"
            src={button.src}
            target={button.target}
            id={button.id}
            gaCategory={button.gaCategory}
            gaLabel={button.gaLabel}
            dataTest={button.id}
            className="bg-white"
          >
            {button.text}
          </BaseButton>
        </div>
        <div className="col-lg-9 order-0 order-lg-1 img-container">
          <img
            className="img-fluid"
            alt="Postman State Of The API Report Postmanauts researching ontop of graphs. Illustration."
            src={media.src}
            height="400px"
          />
        </div>
      </HeroWrapper>

      {divider && <Divider />}
    </HeroImg>
  );
};

export default heroFullWidth;
