const killServiceWorker = () => {
  if (typeof document === 'object') {
    /* eslint-disable no-restricted-globals */
    self.addEventListener('activate', (event) => {
      if (typeof event.waitUntil === 'function') {
        event.waitUntil(
          caches.keys().then((cacheNames) =>
            Promise.all(
              cacheNames.forEach((cacheName) => {
                caches.delete(cacheName);
              })
            )
          )
        );
      }
    });
    /* eslint-enable */
  }
};

module.exports = killServiceWorker;
