import React from 'react';
import styled from 'styled-components';

const BodyContainer = styled.div``;

const headerRow = (data) => {
  // Custom inline styles
  // const fontWeight = data.fontWeight ? data.fontWeight : '';
  // const fontFamily = data.fontFamily ? data.fontFamily : '';
  // const paddingBottom = data.paddingBottom ? data.paddingBottom : null;
  // Custom bootstrap classes
  // const textAlign = data.textAlign ? data.textAlign : 'text-left';
  let headerType;
  // Even though we are dangerouslySettingHTML.  Do NOT wrap data.text copy in HTML elements in the JSON.
  // Since there is no HTML tags, when it "sets" the HTML, it is simply be just a string, but this catches and renders &mdash; and renders appropriately.

  if (data.headerType === 'h2') {
    headerType = (
      <h2
        className={`mb-5 ${data.alternative === true ? 'text-white' : ''}`}
        dangerouslySetInnerHTML={{ __html: data.text }}
      />
    );
  } else if (data.headerType === 'h4') {
    headerType = (
      <h4
        className={`mb-3 font-weight-normal ${
          data.alternative === true ? 'text-white' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: data.text }}
      />
    );
  } else if (data.headerType === 'h5') {
    headerType = (
      <h5
        className={`mb-3 ${data.alternative === true ? 'text-white' : ''}`}
        dangerouslySetInnerHTML={{ __html: data.text }}
      />
    );
  }

  return (
    <BodyContainer>
      <div className="container">
        <div className="row">
          <div className="col-12">{headerType}</div>
        </div>
      </div>
    </BodyContainer>
  );
};

export default headerRow;
