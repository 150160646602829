import React from 'react';
import styled from 'styled-components';
import { Divider, UnorderedListStyles, BaseButton } from 'aether-marketing';
import PropTypes from 'prop-types';
import LottiAnimationContainer from './LottiAnimationContainer';

const Section = styled.section`
  padding: 60px 0px;
  margin: auto;
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  .fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 4px solid ${(props) => props.theme.colors.grey_30};
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }
  @media (prefers-reduced-motion: no-preference) {
    .special {
      color: transparent;
      --bg-size: 400%;
      --color-one: hsl(
        267.6595744680851,
        76.21621621621621%,
        63.725490196078425%
      );
      --color-two: hsl(0, 0%, 0%);
      /* font-size: clamp(3rem, 25vmin, 8rem); */
      background: linear-gradient(
          90deg,
          var(--color-one),
          var(--color-two),
          var(--color-one)
        )
        0 0 / var(--bg-size) 100%;
      text-fill-color: transparent;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      animation: move-bg 18s infinite linear reverse;
    }
    @keyframes move-bg {
      to {
        background-position: var(--bg-size) 0;
      }
    }
  }
`;

function SideBySideLottie({
  layout,
  title,
  body,
  divider,
  media,
  button,
  backgroundColor
}) {
  // Determine column widths
  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-6 offset-lg-0 mb-5 mb-lg-0';
      colText = 'col-lg-5 offset-lg-1 col-md-8 offset-md-2';
      break;
    case '1L':
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
      break;
    default:
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
  }
  const textSection = (
    <div className={`${colText}`}>
      <h2>{title}</h2>
      {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
      {button && (
        <div className="mb-4 mr-3 d-inline">
          <BaseButton
            buttonType={button.buttonType}
            as="a"
            src={button.src}
            target={button.target}
            id={button.id}
            gaCategory={button.gaCategory}
            gaLabel={button.gaLabel}
            className={button.type === 'inline' ? 'pl-0' : null}
            dataTest={button.id}
          >
            {button.text}
          </BaseButton>
        </div>
      )}
    </div>
  );

  const mediaSection = (
    <div
      className={media.border ? `${colMedia} ${media.border}` : `${colMedia}`}
    >
      {media.isLottie ? (
        <LottiAnimationContainer
          autoplay={media.autoplay}
          loop={media.loop}
          animationFileName={media.lottieFileName}
        />
      ) : (
        <img alt={media.alt} src={media.src} />
      )}
    </div>
  );

  return (
    <>
      <Section
        style={{
          backgroundColor
        }}
      >
        <div className="container">
          {layoutString === '1R' && (
            <div className="row align-items-center">
              {mediaSection}
              {textSection}
            </div>
          )}
          {layoutString === '1L' && (
            <div className="row align-items-center">
              {textSection}
              {mediaSection}
            </div>
          )}
        </div>
      </Section>
      {divider && <Divider />}
    </>
  );
}

export default SideBySideLottie;
SideBySideLottie.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  divider: PropTypes.bool,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string,
    border: PropTypes.string,
    isLottie: PropTypes.bool,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    lottieFileName: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ])
};

SideBySideLottie.defaultProps = {
  layout: '1L',
  title: null,
  body: null,
  backgroundColor: '#FFFFFF',
  divider: false,
  button: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  media: null
};
