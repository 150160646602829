import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { eyeBrowSticky } from '../../../utils/stickyElement';
// This component has hardcoded values specific to the 2020 Api Survey
// If needed for another page, see if you can "dry" this up and resuse before making a new component
const Section = styled.div`
  z-index: 10;
`;

const OrangeLine = styled.div`
  /* width: 97px; */
  width: 0px;
  height: 2px;
  background-color: rgb(242, 107, 58);
  margin: 0 0 30px 0;
  opacity: 0;
  transition: 0.3s;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  transition: 0.3s;
`;

const BodyContainer = styled.div`
  /* padding-bottom: 30px; */
`;

class HeaderRow extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const { data } = this.state;
    const {
      elementId,
      parentContainerId,
      underlineId,
      fixedTopValue,
      endElementId
    } = data;
    // Requires that IDs include # sign in string.. ex ('#elementId')
    eyeBrowSticky(
      elementId,
      parentContainerId,
      underlineId,
      fixedTopValue,
      endElementId
    );
  }

  render() {
    const { data } = this.state;
    const fontWeight = data.fontWeight ? data.fontWeight : '';

    return (
      <BodyContainer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Title
                style={{ fontWeight }}
                className="d-inline-block text-white"
              >
                {data.text}
              </Title>
              <OrangeLine id="OrangeLineDivider" />
              {/* {headerType} */}
            </div>
          </div>
        </div>
      </BodyContainer>
    );
  }
}

const headerRowStickyShrink = (data) => (
  <Section
    id={`${data.elementId && data.elementId.replace('#', '')}`}
    style={{ backgroundColor: data.backgroundColor }}
  >
    <HeaderRow data={data} />
  </Section>
);

export default headerRowStickyShrink;

HeaderRow.propTypes = {
  data: PropTypes.shape({
    fontWeight: PropTypes.string,
    elementId: PropTypes.string,
    parentContainerId: PropTypes.string,
    underlineId: PropTypes.string,
    fixedTopValue: PropTypes.string,
    endElementId: PropTypes.string,
    alternative: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    text: PropTypes.string
  }).isRequired
};
