import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CardStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  background-color: ${(props) => props.theme.colors.grey_00};
  box-shadow: ${(props) => props.theme.shadows.card_shadow};
`;

function CardSquareWrapper({ className, children }) {
  return <CardStyles className={className}>{children}</CardStyles>;
}

CardSquareWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

CardSquareWrapper.defaultProps = {
  className: ''
};

export default CardSquareWrapper;
