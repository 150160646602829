import React from 'react';
import styled from 'styled-components';
import { BaseLinkStyles } from 'aether-marketing';

/*
 * User-defined text displayed below checkboxes for additional information
 *
 * form: {
 *  "addOnLegalText": [
 *    "<p>Text goes here</p>"
 *    ]
 *  }
 */

const Div = styled.div`
  color: ${(props) =>
    props.mode === 'light' ? '#FFFFFF' : props.theme.colors.grey_90};
  text-align: left;

  > p {
    color: ${(props) =>
      props.mode === 'light' ? '#FFFFFF' : props.theme.colors.grey_90};
    font-size: 14px !important;
    letter-spacing: 0.24px;
    line-height: 20px;
    margin-top: 8px;

    a {
      ${BaseLinkStyles.componentStyle.rules}
    }
  }
`;

function FormLegalAdditionalText(data) {
  const { addOnLegalText, mode } = data;
  return (
    <div className="col-lg-12 col-md-10 col-12">
      <div className="form-row">
        <div className="form-group col-12">
          <Div
            mode={mode}
            className="FormLegalAdditionalText"
            dangerouslySetInnerHTML={{
              __html:
                addOnLegalText.length >= 1
                  ? addOnLegalText.join('')
                  : addOnLegalText
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FormLegalAdditionalText;
