import React from 'react';
import styled from 'styled-components';
import {
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton
} from 'aether-marketing';

const DescriptionContainer = styled.div`
  padding: 30px 0;
  margin-left: auto;
  margin-right: auto;
  h3 {
    line-height: 30px;
    font-size: 36px;
    margin: 0 0 16px;
    color: ${(props) => props.theme.colors.grey_90};
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

function Job(data) {
  const { applicationUrl, description, structuredData } = data;
  return (
    <DescriptionContainer>
      <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <div
          dangerouslySetInnerHTML={{
            __html: `${Buffer.from(description, 'base64').toString('utf-8')}`
          }}
        />
        <BaseButton
          buttonType="primary"
          as="a"
          target="new-tab-external-company"
          ariaLabel="Apply now"
          src={applicationUrl}
        >
          Apply Now
        </BaseButton>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `${Buffer.from(structuredData, 'base64').toString('utf-8')}`
        }}
      />
    </DescriptionContainer>
  );
}

export default Job;
