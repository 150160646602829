import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PricingParagraph } from './PricingParagraph';
import FeatureHighlights from './featureHighlights';
import Button from '../v5_buttons/Button';
import CardWrapper from '../v5_cards/CardWrapper';
import updateDisplay from '../../../utils/suppress';

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const PricingCardWrap = styled(CardWrapper)`
  border-radius: 0;
  margin-right: -1px;
  div.free-card {
    @media screen and (max-width: 1199px) {
      margin-bottom: 43px;
    }
  }
  & p {
    // Enterprise column
    &.plan-enterprise-month {
      margin-bottom: 79px;
      @media screen and (min-width: 1199px) {
        margin-bottom: 36px;
      }
    }
    &.plan-price {
      font-family: ${(props) => props.theme.fonts.Inter};
      font-size: 24px;
      display: inline;

      @media screen and (max-width: 992px) {
        margin-top: 2rem;
      }
    }

    span.small {
      color: #6b6b6b;
      font-size: 12px !important;
      display: inline;
      padding-left: 4px;
      letter-spacing: -0.16px;
      margin-bottom: 24px;
      @media screen and (max-width: 1199px) {
        display: block;
      }
    }
  }
`;

const PricingButton = styled(Button)`
  margin-right: 12px;
  height: auto;
  @media screen and (min-width: 992px) and (max-width: 1055px) {
    margin-right: 2px;
  }
  i {
    padding: 4px 12px !important;
    font-size: 14px;
  }
`;
const PricingButtonTwo = styled(Button)`
  @media screen and (min-width: 992px) and (max-width: 1015px) {
    margin-top: 8px;
  }
  i {
    padding: 4px 12px !important;
    font-size: 14px;
    color: #212121;
  }
`;

const Banner = styled.div`
  &.alert__banner {
    background-color: #e7f0ff;
    padding: 12px;
    margin-top: 32px;
  }
`;

const SubtitleWrapper = styled.div`
  height: 90px;
`;

const PricingCol = styled.div`
  margin-left: -1px;
`;

const runtime = typeof document === 'object';

export function PricingCard({
  title = '',
  subtitle = '',
  body = [],
  link = {},
  featureHighlights = [],
  addOnText = []
}) {
  if (runtime) {
    const oPriceToggle = window.priceToggle;

    window.priceToggle = (value) => {
      if (typeof oPriceToggle === 'function') {
        oPriceToggle(value);
      }

      if (value === 'month') {
        updateDisplay('basic-annual', 'none');
        updateDisplay('pro-annual', 'none');
        updateDisplay('enterprise-annual', 'none');
        updateDisplay('basic-month', 'block');
        updateDisplay('pro-month', 'block');
        updateDisplay('enterprise-month', 'block');
      } else {
        updateDisplay('basic-annual', 'block');
        updateDisplay('pro-annual', 'block');
        updateDisplay('enterprise-annual', 'block');
        updateDisplay('basic-month', 'none');
        updateDisplay('pro-month', 'none');
        updateDisplay('enterprise-month', 'none');
      }
    };
  }

  return (
    <PricingCardWrap className="h-100">
      <div id={title}>
        <div>
          <h2 className="h3">{title}</h2>
        </div>
        <div
          className="d-flex flex-column justify-content-between price mb-3 body"
          dangerouslySetInnerHTML={{ __html: body.join('') }}
        />
        <div
          className={link.isEnterprise ? 'price row annual' : 'row'}
          data-suppress={link.isEnterprise ? 'pro-annual' : ''}
        >
          <div className="col-12 pr-0">
            <PricingButton
              isEnterprise={link.isEnterprise}
              isSecondaryButton={link.buttonTwo}
              buttonType={link.type ? link.type : 'primary'}
              id={link.id}
              href={link.href}
              rel={link.rel}
              target={link.target}
              data-url={link.test}
              dataTest={link.test}
              event={link.event}
              eventProp={link.eventProp}
              onClick={() => {
                triggerGA(link.category, link.label);
              }}
              aria-label={link.ariaLabel}
            >
              {link.text}
            </PricingButton>

            {link.buttonTwo && (
              <PricingButtonTwo
                isSecondaryButton={link.buttonTwo}
                buttonType={link.buttonTwo.type}
                id={link.buttonTwo.id}
                dataTest={link.buttonTwo.test}
                aria-label={link.buttonTwo.ariaLabel}
                href={link.buttonTwo.url}
                target={link.buttonTwo.target}
                title={link.buttonTwo.title}
                rel={link.buttonTwo.rel}
                event={link.event}
                eventProp={link.buttonTwo.eventProp}
                onClick={() => {
                  triggerGA('pricing-page', `${link.buttonTwo.id}`);
                }}
              >
                {link.buttonTwo.cta}
              </PricingButtonTwo>
            )}
          </div>
        </div>
        <SubtitleWrapper className="row">
          <PricingParagraph
            className="subtext ml-3 mt-4 mb-0"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </SubtitleWrapper>
        <FeatureHighlights data={featureHighlights} />
        {addOnText.length > 0 && (
          <Banner
            className="annual alert__banner"
            data-suppress="pro-annual"
            dangerouslySetInnerHTML={{ __html: addOnText.join('') }}
          />
        )}
      </div>
    </PricingCardWrap>
  );
}

PricingCard.defaultProps = {
  title: '',
  subtitle: '',
  body: [],
  link: {},
  linkTwo: {},
  featureHighlights: [],
  addOnText: []
};

PricingCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.shape({}),
  linkTwo: PropTypes.shape({}),
  featureHighlights: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      included: PropTypes.bool.isRequired
    })
  ),
  addOnText: PropTypes.arrayOf(PropTypes.string)
};

const platformPricingCards = ({ data }) => {
  const {
    title,
    subtitle,
    body,
    url,
    col,
    cta,
    type,
    target,
    category,
    id,
    isEnterprise,
    label,
    featureHighlights,
    test,
    ctaLinkStyle = null,
    ariaLabel = null,
    event = null,
    eventProp = null,
    buttonTwo,
    addOnText
  } = data;

  return (
    <PricingCol className={col}>
      <PricingCard
        title={title}
        subtitle={subtitle}
        body={body ? data.body : []}
        url={url}
        link={{
          href: url,
          isEnterprise,
          text: cta,
          ctaLinkStyle,
          type,
          category,
          id,
          label,
          test,
          target,
          ariaLabel,
          event,
          eventProp,
          buttonTwo
        }}
        featureHighlights={featureHighlights}
        addOnText={addOnText}
      />
    </PricingCol>
  );
};

platformPricingCards.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.arrayOf(PropTypes.string),
    url: PropTypes.string,
    featureHighlights: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        included: PropTypes.bool.isRequired
      })
    ),
    cta: PropTypes.string,
    subtitle: PropTypes.string
  }).isRequired
};

export default platformPricingCards;
