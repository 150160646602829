/* eslint-disable no-param-reassign */
import React from 'react';
// import styled from 'styled-components';
import AnnouncementSlim from './AnnouncementSlim';
// import AnnouncementSupreme from './announcementSupreme';

const { allow } = require('../../../../package.json');

const { announcement: allowedAnnouncement } = allow;
// For allow list - define the paths in package.json announcement array
// Runbook: https://postmanlabs.atlassian.net/wiki/spaces/MT/pages/4473422614/Runbook+Hellobar+Supreme+and+Slim+components+with+Lottie+animations

// const AnnouncementSupremeContainer = styled.div`
//   background: #e6eeff;
//   background-position: center;
//   background-repeat: no-repeat;
//   height: 525px; // tweak as needed based on content

//   @media screen and (min-width: 768px) and (max-width: 992px) {
//     height: 800px;
//   }

//   @media screen and (max-width: 768px) {
//     height: 720px;
//   }

//   opacity: 1;
//   position: -webkit-sticky;
//   position: sticky;
//   top: 55px; // how close it is to navbar
//   transition: opacity 0.8s linear; // transition options
//   -moz-transition: opacity 0.8s linear;
//   -webkit-transition: opacity 0.8s linear;
//   z-index: 0;
// `;
// text, links and image data for supreme version
// const data = {
//   layout: '1L',
//   divider: false,
//   backgroundColor: '#E6EEFF',
//   title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//   body: ["<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.!</p>"],
//   ctaButton: [
//     {
//       src: '#',
//       text: 'Register Now',
//       buttonType: 'secondary',
//       id: '',
//       gaCategory: '',
//       gaLabel: ''
//     }
//   ],
//   media: {
//     lottie: false,
//     src: '',
//     alt: ''
//   }
// };

// tracks onscroll event to trigger opacity and index
function onScroll(expectedScrollPosition, scrollAnimation) {
  window.addEventListener('scroll', () => {
    let opacity;
    let zIndex;
    const currentScroll = window.pageYOffset;
    if (currentScroll <= expectedScrollPosition) {
      opacity = 1 - currentScroll / expectedScrollPosition;
    } else {
      opacity = 0;
    }
    if (currentScroll > 0) {
      zIndex = -1;
    } else {
      zIndex = 0;
    }
    scrollAnimation.style.opacity = opacity;
    scrollAnimation.style.zIndex = zIndex;
  });
}
class HandleAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPathAllowed: false,
      toggleSlimBar: false,
      // toggleSupremeBar: false,
      ...props
    };
  }

  componentDidMount() {
    // allow list defined in the package.json announcement array
    const isAllowed =
      typeof document !== 'undefined' &&
      allowedAnnouncement.reduce(
        (returnValue, path) =>
          (!returnValue && document.location.pathname.indexOf(path) !== -1) ||
          returnValue,
        false
      );

    // if browser path equals any of the paths in the array, toggle state and set localStorage
    if (isAllowed) {
      this.setState({
        isPathAllowed: true
      });
    } else {
      this.setState({
        isPathAllowed: false
      });
    }
    // This section covers the number of times the hello bar supreme vs slim displays

    // check number of visits
    let barCount = JSON.parse(localStorage.getItem('announcement_count')) || 0;

    // toggle supreme component if less than assigned number
    // if (barCount < 3) {
    //   barCount += 1;
    //   localStorage.setItem('announcement_count', JSON.stringify(barCount));
    //   this.setState({
    //     toggleSupremeBar: true,
    //     toggleSlimBar: false
    //   });
    // }
    // toggles slim component if more than assigned number
    // persistent until user clicks x
    if (barCount < 99) {
      barCount += 1;
      localStorage.setItem('announcement_count', JSON.stringify(barCount));
      this.setState({
        // toggleSupremeBar: false,
        toggleSlimBar: true
      });
    } else {
      this.setState({
        // toggleSupremeBar: false,
        toggleSlimBar: false
      });
    }

    // supreme component animations
    const setScrollAnimation =
      document.getElementById('pm-announce-bar') || null;
    if (setScrollAnimation !== null) {
      // y-axis value to trigger animation earlier (lower value) or later (higher value)
      onScroll(340, setScrollAnimation);
    }
  }

  render() {
    // const { isPathAllowed, toggleSlimBar, toggleSupremeBar } = this.state;
    const { isPathAllowed, toggleSlimBar } = this.state;
    // const {
    //   backgroundColor,
    //   body,
    //   ctaButton,
    //   ctaLinks,
    //   divider,
    //   layout,
    //   media,
    //   title
    // } = data;
    return (
      <>
        {/* <AnnouncementSupremeContainer
          id="pm-announce-bar"
          className={toggleSupremeBar && isPathAllowed ? '' : 'd-none'}
        >
          <AnnouncementSupreme
            backgroundColor={backgroundColor}
            body={body}
            ctaButton={ctaButton}
            ctaLinks={ctaLinks}
            divider={divider}
            layout={layout}
            media={media}
            title={title}
          />
        </AnnouncementSupremeContainer> */}
        <span className={toggleSlimBar && isPathAllowed ? '' : 'd-none'}>
          <AnnouncementSlim toggleSlimBar={toggleSlimBar} />
        </span>
      </>
    );
  }
}

function AnnouncementSection() {
  return <HandleAnnouncement />;
}

export default AnnouncementSection;
