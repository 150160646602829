/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import FormError from './FormError';
import FormLabel from './FormLabel';

/*
 * Textarea field
 *
 * Marketo types: "textarea"
 * Standard type: "textarea"
 */

const TextAreaStyled = styled.textarea`
  background: ${(props) =>
    props.mode === 'light' ? 'transparent' : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.forms.border};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-shadow: none;
  color: ${(props) =>
    props.mode === 'light' ? '#FFFFF' : props.theme.forms.text};
  font-size: 14px;
  height: 40px;
  margin-top: 4px;
  padding: 8px 1px 8px 14px;

  &.form-control {
    :focus {
      background-color: ${(props) =>
        props.mode === 'light' ? 'transparent' : props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.blue_30};
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue_30};
      outline: none;
    }
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::placeholder,
    :-ms-input-placeholder {
      color: ${(props) =>
        props.mode === 'light' ? '#FFFFF' : props.theme.forms.text};
    }
    &.is-invalid {
      background-color: ${(props) =>
        props.mode === 'light' ? 'transparent' : props.theme.colors.white};
      background-image: none;
      border: 1px solid
        ${(props) =>
          props.mode === 'light' ? '#FF0A00 ' : props.theme.colors.red_error};
      &:focus {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.red_error};
      }
    }
  }
`;

function FormTextArea(props) {
  const [field, meta] = useField({
    ...props
  });
  const {
    name,
    mode,
    type,
    placeholder,
    label,
    required,
    VisibilityRule,
    rows
  } = props;
  return (
    <div
      id={VisibilityRule ? VisibilityRule.rules[0].subjectField : ''}
      className={VisibilityRule ? 'd-none form-row' : 'form-row'}
    >
      <div className="form-group col-12">
        {FormLabel(props)}
        <TextAreaStyled
          type={type}
          name={name}
          mode={mode}
          rows={rows}
          label={label}
          required={!!required}
          placeholder={placeholder}
          autoComplete="off"
          className={
            meta.touched && meta.error
              ? `form-control is-invalid ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
              : `form-control ${
                  (VisibilityRule && VisibilityRule.rules[0].values[0]) || ''
                }`
          }
          /* eslint-disable react/jsx-props-no-spreading */
          {...field}
          {...props}
        />
      </div>
      <div className="col-12">
        {meta.touched && meta.error ? (
          <FormError className="error" mode={mode}>
            {meta.error}
          </FormError>
        ) : null}
      </div>
    </div>
  );
}

export default FormTextArea;
