import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import CardSpeakerFeature from '../../galaxy-2021/cards/CardSpeakerFeature';

const GradientWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`;

const FeaturedWrapper = styled.div`
  top: -140px;
  position: relative;

  .slick-prev,
  .slick-next {
    border-radius: 180px;
    height: 37px;
    width: 37px;
    opacity: 1;
    @media screen and (max-width: 768px) {
      top: 107%;
    }
    &:before {
      font-size: 35px;
    }
  }
  .slick-prev {
    left: -50px;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
    @media screen and (max-width: 768px) {
      left: 40%;
    }
    @media screen and (max-width: 476px) {
      left: 35%;
    }
  }
  .slick-next {
    right: -50px;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
    @media screen and (max-width: 768px) {
      right: 40%;
    }
    @media screen and (max-width: 476px) {
      right: 35%;
    }
  }

  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;

    & > div {
      /* // Need to make height 100% to have all cards be the same height */
      height: 100%;
      /* // Padding bottom to clear the box shadow */
      padding-bottom: 5px;
    }
  }
  .slider-wrap {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }
  .slide:not(:first-of-type) {
    display: none;
  }
  .slider.slick-initialized .slide:not(:first-of-type) {
    display: block;
  }
  .slick-prev:before {
    content: url('https://voyager.postman.com/icon/arrow-left.svg');
  }
  .slick-next:before {
    content: url('https://voyager.postman.com/icon/arrow-right.svg');
  }
`;

class SpeakerPage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      ...data
    };
  }

  componentDidMount() {
    const css1 = document.createElement('link');
    css1.rel = 'stylesheet';
    css1.type = 'text/css';
    css1.href =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css';
    document.getElementsByTagName('head')[0].appendChild(css1);

    const css2 = document.createElement('link');
    css2.rel = 'stylesheet';
    css2.type = 'text/css';
    css2.href =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css';
    document.getElementsByTagName('head')[0].appendChild(css2);
  }

  render() {
    return (
      <GradientWrapper>
        <div className="container">
          {/* Below is the featured speakers card, modal */}
          <FeaturedWrapper>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="text-white">On-demand key sessions</h2>
              </div>
              <div className="col-12 h-100 d-lg-block d-none d-sm-none mt-3">
                <Slider
                  infinite
                  // autoplay
                  speed={500}
                  slidesToShow={3}
                  swipe
                  dots={false}
                  arrows
                >
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Abhinav Asthana"
                    text="Co-founder and CEO"
                    titleTalk="Postman Keynote"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/keynote-with-postman-cofounder-and-ceo-abhinav-asthana/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/abhinav-asthana-founder-postman.jpg',
                      alt: 'Abhinav Asthana',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Steve Wozniak"
                    titleTalk="Fireside Chat"
                    text="Apple Co-founder"
                    logo="https://voyager.postman.com/logo/external/appl-logo-grey.svg"
                    link={{
                      href: '/postman-galaxy/fireside-chat-with-woz/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/steve-crop.jpg',
                      alt: 'Steve Wozniak',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide  h-100 mx-2"
                    className="h-100"
                    header="Ankit Sobti"
                    text="Co-founder and CTO"
                    titleTalk="Postman Keynote"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/keynote-with-postman-cofounder-ankit-sobti/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/ankit-sobti-founder-postman.jpg',
                      alt: 'Ankit Sobti, Co-Founder and CTO at Postman',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Kin Lane"
                    text="Chief Evangelist"
                    titleTalk="API-First Session"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/postman-platform-overview-be-api-first-not-api-last/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/kin-lane.jpg',
                      alt: 'Kin Lane',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Sanjiv Karani"
                    titleTalk="Featured Keynote"
                    text="Head of Platform Domain Product Group"
                    logo="https://voyager.postman.com/logo/external/kroger-logo.svg"
                    link={{
                      href: '/postman-galaxy/building-krogers-first-billion-platform-startup/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/sanjiv.jpg',
                      alt: 'Sanjiv Karani',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Jeff Barr"
                    text="Chief Evangelist"
                    titleTalk="Fireside Chat"
                    logo="https://voyager.postman.com/logo/external/amazon-aws-logo.svg"
                    link={{
                      href: '/postman-galaxy/fireside-chat-with-jeff-barr-of-aws/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/jeff-barr.jpg',
                      alt: 'Jeff Barr, Chief Evangelist at AWS.',
                      className: 'img-fluid'
                    }}
                  />
                </Slider>
              </div>
              {/* MOBILE  */}
              <div className="col-12 col-lg-10 h-100 d-md-block d-lg-none">
                <Slider
                  infinite
                  // autoplay
                  speed={500}
                  slidesToShow={1}
                  // adaptiveHeight
                  // centerMode
                  // fade
                  // pauseOnFocus={false}
                  swipe
                  touchMove
                  dots={false}
                  arrows
                >
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Abhinav Asthana"
                    text="Co-founder and CEO"
                    titleTalk="Postman Keynote"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/keynote-with-postman-cofounder-and-ceo-abhinav-asthana/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/abhinav-asthana-founder-postman.jpg',
                      alt: 'Abhinav Asthana',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Steve Wozniak"
                    text="Apple Co-founder"
                    titleTalk="Fireside Chat"
                    logo="https://voyager.postman.com/logo/external/appl-logo-grey.svg"
                    link={{
                      href: '/postman-galaxy/fireside-chat-with-woz/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/steve-crop.jpg',
                      alt: 'Steve Wozniak',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide  h-100 mx-2"
                    className="h-100"
                    header="Ankit Sobti"
                    titleTalk="Postman Keynote"
                    text="Co-founder and CTO"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/keynote-with-postman-cofounder-ankit-sobti/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/ankit-sobti-founder-postman.jpg',
                      alt: 'Ankit Sobti, Co-Founder and CTO at Postman',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Kin Lane"
                    text="Chief Evangelist"
                    titleTalk="API-First Session"
                    logo="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                    link={{
                      href: '/postman-galaxy/postman-platform-overview-be-api-first-not-api-last/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/kin-lane.jpg',
                      alt: 'Kin Lane',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide  h-100 mx-2"
                    className="h-100"
                    header="Sanjiv Karani"
                    titleTalk="Featured Keynote"
                    text="Head of Platform Domain Product Group"
                    logo="https://voyager.postman.com/logo/external/kroger-logo.svg"
                    link={{
                      href: '/postman-galaxy/building-krogers-first-billion-platform-startup/'
                    }}
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/sanjiv.jpg',
                      alt: 'Sanjiv Karani',
                      className: 'img-fluid'
                    }}
                  />
                  <CardSpeakerFeature
                    col="slide h-100 mx-2"
                    className="h-100"
                    header="Jeff Barr"
                    text="Chief Evangelist"
                    titleTalk="Fireside Chat"
                    link={{
                      href: '/postman-galaxy/fireside-chat-with-jeff-barr-of-aws/'
                    }}
                    logo="https://voyager.postman.com/logo/external/amazon-aws-logo.svg"
                    media={{
                      src: 'https://voyager.postman.com/photo/galaxy/jeff-barr.jpg',
                      alt: 'Jeff Barr, Chief Evangelist at AWS.',
                      className: 'img-fluid'
                    }}
                  />
                </Slider>
              </div>
            </div>
          </FeaturedWrapper>
        </div>
      </GradientWrapper>
    );
  }
}

SpeakerPage.propTypes = {
  data: PropTypes.shape({}).isRequired
};

const recapSlider = (data) => <SpeakerPage data={data} />;

export default recapSlider;
