/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styled from 'styled-components';
import Button from '../v5_buttons/Button';
import updateDisplay from '../../../utils/suppress';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

/* eslint-disable no-useless-escape, react/prop-types,jsx-a11y/mouse-events-have-key-events  */
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const Heading = styled.div`
  p.content {
    font-size: 14px !important;
  }
  a {
    color: #0265d2;
    -webkit-text-decoration: none;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
  a:hover,
  a:active {
    border-bottom: 1px solid #0265d2;
    color: #0265d2;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

const PricingButton = styled(Button)`
  font-size: 14px;
  padding: 9px 20px;
  width: 100%;
`;

const TableHeaderColumn = styled.th`
  vertical-align: top !important;
  border-top: none !important;
  border-bottom: none !important;
  p {
    color: #6b6b6b;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    &.plan-price {
      font-family: ${(props) => props.theme.fonts.Inter};
      display: inline;
      font-size: 24px !important;

      @media screen and (max-width: 992px) {
        margin-top: 2rem;
      }
    }
  }
  div.price {
    margin-bottom: 0;
  }

  a {
    position: relative;
    bottom: 0;
  }
`;

const PricingColumn = styled.div``;

const TableRow = styled.tr`
  background: ${(props) => {
    let color;
    if (props.feature.highlightedSubGroup) {
      color = '#FDFDFE';
    } else if (props.feature.highlightedGroup) {
      color = '#F9F9F9';
    }
    return color;
  }};
`;

const TableBody = styled.tbody`
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  th {
    padding: 12px;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom: none !important;
    margin-top: 24px;
  }
  span.plan-price {
    font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', Helvetica, Arial,
      sans-serif;
    display: inline;
    font-size: 24px !important;
    font-weight: 600;
    color: #212121;
  }
  span.small {
    font-size: 12px;
  }
`;

const FooterNote = styled.div`
  p.small {
    font-size: 12px;
    color: #212121;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const Div = styled.div`
  span.beta-tag {
    font-size: 12px;
    color: #0053b8;
    border: 1px solid #aecdfb;
    padding: 0 4px;
    border-radius: 4px;
  }
  span.new-tag {
    font-size: 12px;
    color: #623497;
    border: 1px solid #e4d8f6;
    padding: 0 4px;
    border-radius: 4px;
  }
`;
const Tooltip = styled.a`
  color: black;
  text-decoration: none;
  font-size: 12px;
  margin-left: 6px;
  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const runtime = typeof document === 'object';

class PricingAddonsTable extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  render() {
    const { data } = this.state;
    const { event } = data;
    const itemTooltip = (event && event.itemTooltip) || '';
    const itemClicked = (event && event.itemClicked) || '';
    if (runtime) {
      const oPriceToggle = window.priceToggle;

      window.priceToggle = (value) => {
        if (typeof oPriceToggle === 'function') {
          oPriceToggle(value);
        }

        if (value === 'month') {
          updateDisplay('basic-annual', 'none');
          updateDisplay('pro-annual', 'none');
          updateDisplay('enterprise-annual', 'none');
          updateDisplay('basic-month', 'block');
          updateDisplay('pro-month', 'block');
          updateDisplay('enterprise-month', 'block');
        } else {
          updateDisplay('basic-annual', 'block');
          updateDisplay('pro-annual', 'block');
          updateDisplay('enterprise-annual', 'block');
          updateDisplay('basic-month', 'none');
          updateDisplay('pro-month', 'none');
          updateDisplay('enterprise-month', 'none');
        }
      };
    }
    return (
      <div>
        <Heading dangerouslySetInnerHTML={{ __html: data.title.join('') }} />
        <div className="rounded">
          <table
            className="table rounded"
            style={{ borderCollapse: 'separate', borderSpacing: '0' }}
          >
            <thead className="text-left" style={{ top: '53px' }}>
              <tr>
                <TableHeaderColumn
                  scope="col"
                  className="v5_mt-3 bg-white rounded text-left"
                  style={{ width: '40%' }}
                >
                  <p className="small">Feature Description</p>
                </TableHeaderColumn>
                {data.plans &&
                  data.plans.map((plan) => {
                    return (
                      <TableHeaderColumn
                        scope="col"
                        className="bg-white"
                        key={pmUuid()}
                        style={{ width: '25%' }}
                      >
                        <p className="small">{plan.name}</p>
                        <PricingColumn
                          className="d-flex align-items-center price"
                          plan={plan.id}
                        >
                          <div
                            className="body"
                            dangerouslySetInnerHTML={{
                              __html: plan.body.join('')
                            }}
                          />
                        </PricingColumn>
                        {plan.additionalText ? (
                          <p className="small mb-4">{plan.additionalText}</p>
                        ) : (
                          <div />
                        )}
                        {plan.button && (
                          <div>
                            <PricingButton
                              className={plan.buttonTwo ? 'mr-1 mb-lg-2' : ''}
                              buttonType={plan.button.buttonType}
                              id={plan.button.id}
                              dataTest={plan.button.test}
                              aria-label={plan.button.ariaLabel}
                              href={plan.button.href}
                              target={plan.button.target}
                              rel={plan.button.rel}
                              event={itemClicked}
                              eventProp={`{\"cta_link_to\":\"${plan.button.href}\",\"cta_style\":\"${plan.button.buttonType}\",\"cta_text\":\"${plan.button.title}\",\"cta_type\":\"button\"}`}
                              onClick={() => {
                                triggerGA('pricing-page', `${plan.button.id}`);
                              }}
                            >
                              {plan.button.title}
                            </PricingButton>
                            {plan.buttonTwo && (
                              <div className="mt-2">
                                <PricingButton
                                  className="font-weight-normal"
                                  buttonType={plan.buttonTwo.buttonType}
                                  src={plan.buttonTwo.href}
                                  target={
                                    plan.buttonTwo.target
                                      ? plan.buttonTwo.target
                                      : 'same-tab'
                                  }
                                  gaCategory="pricing-page"
                                  gaLabel={plan.buttonTwo.id}
                                  id={plan.buttonTwo.id}
                                  ariaLabel={plan.buttonTwo.ariaLabel}
                                  dataTest={plan.buttonTwo.test}
                                  rel={plan.buttonTwo.rel}
                                  event={itemClicked}
                                  eventProp={`{\"cta_link_to\":\"${plan.buttonTwo.href}\",\"cta_style\":\"${plan.buttonTwo.buttonTwoType}\",\"cta_text\":\"${plan.buttonTwo.title}\",\"cta_type\":\"button\"}`}
                                  onClick={() => {
                                    triggerGA(
                                      'pricing-page',
                                      `${plan.buttonTwo.id}`
                                    );
                                  }}
                                >
                                  {plan.buttonTwo.title}
                                </PricingButton>
                              </div>
                            )}
                          </div>
                        )}
                      </TableHeaderColumn>
                    );
                  })}
              </tr>
            </thead>
            <TableBody className="text-left">
              {data.features &&
                data.features.map((feature) => {
                  const cells = feature.plans.map((plan) => {
                    // All Columns except Enterprise, need border-right
                    if (plan.name !== 'postman-enterprise') {
                      // Is it boolean or is it string?
                      if (typeof plan.included === 'string') {
                        const planIncludedCheck =
                          (plan.addCheckmarkNextToText &&
                            `<img
                          style={{ height: '16px' }}
                          alt="Available"
                          src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                        /> &nbsp; ${plan.included}<br>${
                              plan.includedTwo || ''
                            }`) ||
                          `${plan.included}<br>${plan.includedTwo || ''}`;

                        return (
                          <td
                            className={
                              plan.topAlign ? 'align-top' : 'align-middle'
                            }
                            key={pmUuid()}
                          >
                            <p
                              className="mb-0 small"
                              dangerouslySetInnerHTML={{
                                __html: planIncludedCheck
                              }}
                            />
                          </td>
                        );
                      }
                      if (plan.included && !plan.tooltip) {
                        // if plan.included === true, green checkmark
                        return (
                          <td className="align-middle" key={pmUuid()}>
                            <p className="mb-0 small  ml-3">
                              <img
                                style={{ height: '16px' }}
                                alt="Available"
                                src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                              />
                            </p>
                          </td>
                        );
                      }
                      if (plan.included && plan.tooltip) {
                        // if plan.included === true, green checkmark
                        return (
                          <td className="align-middle" key={pmUuid()}>
                            <a
                              tabIndex="0"
                              href="##"
                              className="pm-tooltip"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={plan.tooltip}
                            >
                              <img
                                src="https://voyager.postman.com/icon/plus-outline-green-icon.svg"
                                alt="Plus. Icon."
                                style={{ height: '13px' }}
                                onMouseOver={() => {
                                  window.pmt('amplitudeEvent', [
                                    `${itemTooltip}:${plan.tooltip}`
                                  ]);
                                }}
                              />
                            </a>
                          </td>
                        );
                      }
                      return (
                        <td className="align-middle" key={pmUuid()}>
                          <p className="mb-0 small ml-3">
                            <img
                              style={{ opacity: '1', height: '16px' }}
                              alt="Unavailable"
                              src="https://voyager.postman.com/icon/x-icon-grey.svg"
                            />
                          </p>
                        </td>
                      );
                    }
                    // Enterprise Column, Do not need border-right
                    if (
                      plan.addCheckmarkNextToText &&
                      typeof plan.included === 'string'
                    ) {
                      const planIncludedCheck =
                        (plan.addCheckmarkNextToText &&
                          `<img
                        style={{ height: '16px' }}
                        alt="Available"
                        src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                      /> &nbsp; ${plan.included}`) ||
                        plan.included;

                      return (
                        <td className="align-middle" key={pmUuid()}>
                          <div className="d-flex">
                            <p
                              className="mb-0 small"
                              dangerouslySetInnerHTML={{
                                __html: planIncludedCheck
                              }}
                            />
                          </div>
                        </td>
                      );
                    }
                    // content
                    if (typeof plan.included === 'string') {
                      return (
                        <td
                          className={
                            plan.topAlign ? 'align-top' : 'align-middle'
                          }
                          key={pmUuid()}
                        >
                          <p
                            className="mb-0 small"
                            dangerouslySetInnerHTML={{ __html: plan.included }}
                          />
                        </td>
                      );
                    }

                    if (plan.included && !plan.tooltip) {
                      // if plan.included === true, green checkmark
                      return (
                        <td className="align-middle" key={pmUuid()}>
                          <p className="mb-0 small ">
                            <img
                              style={{ height: '16px' }}
                              alt="Available"
                              src="https://voyager.postman.com/icon/checkbox-outline-green-icon.svg"
                            />
                          </p>
                        </td>
                      );
                    }
                    if (plan.included && plan.tooltip) {
                      // if plan.included === true, green checkmark
                      return (
                        <td className="align-middle" key={pmUuid()}>
                          <a
                            tabIndex="0"
                            href="##"
                            className="pm-tooltip"
                            role="button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={plan.tooltip}
                          >
                            <img
                              src="https://voyager.postman.com/icon/plus-outline-green-icon.svg"
                              alt="Plus. Icon."
                              style={{ height: '13px' }}
                              onMouseOver={() => {
                                window.pmt('amplitudeEvent', [
                                  `${itemTooltip}:${plan.tooltip}`
                                ]);
                              }}
                            />
                          </a>
                        </td>
                      );
                    }
                    return (
                      <td
                        className="border-right-0 align-middle"
                        key={pmUuid()}
                      >
                        <p className="mb-0 small ml-3">
                          <img
                            style={{ opacity: '1', height: '16px' }}
                            alt="Unavailable"
                            src="https://voyager.postman.com/icon/x-icon-grey.svg"
                          />
                        </p>
                      </td>
                    );
                  });
                  return (
                    <TableRow
                      key={pmUuid()}
                      className="tr-style"
                      feature={feature}
                    >
                      {feature.highlightedGroup && (
                        /* Highlighted Category Title (Add tooltip if needed ) */
                        <td colSpan="12" className="text-left align-middle">
                          <div className="row">
                            <div className="col-md-10">
                              <p className="mb-0 feature-text text-left strong">
                                {feature.highlightedTitle}
                              </p>
                            </div>
                          </div>
                        </td>
                      )}

                      {!feature.highlightedGroup && (
                        /* Plan features inside category ( A tag and P element ) */
                        <th
                          scope="row"
                          className="text-left font-weight-normal align-middle"
                        >
                          {feature.href && feature.popover && (
                            <div
                              className="row"
                              data-test="feature-and-popover"
                            >
                              <div
                                className="col-sm d-flex flex-row align-items-center"
                                data-test="link"
                              >
                                <a
                                  type="tertiary"
                                  href={feature.href}
                                  className="mb-0 feature-text feature-link text-left"
                                  aria-label={feature.ariaLabel || null}
                                  data-event={feature.event}
                                  data-event-prop={feature.eventProp}
                                >
                                  {feature.title}
                                </a>
                              </div>
                              <div className="col-sm-2" data-test="tooltip">
                                <Tooltip
                                  tabIndex="0"
                                  href="##"
                                  className="pm-tooltip"
                                  role="button"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={feature.popover}
                                >
                                  <img
                                    src="https://voyager.postman.com/icon/i-info-icon-postman.svg"
                                    alt="Information. Icon."
                                    style={{ height: '15px', opacity: '0.7' }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          )}
                          {!feature.href && feature.popover && (
                            <div
                              className="row"
                              data-test="feature-and-popover"
                            >
                              <div className="col-sm d-flex flex-row align-items-center">
                                {Array.isArray(feature.title) ? (
                                  <Div
                                    dangerouslySetInnerHTML={{
                                      __html: feature.title.join(' ')
                                    }}
                                  />
                                ) : (
                                  <p className="mb-0">{feature.title}</p>
                                )}
                                <div className="col-sm-2" data-test="tooltip">
                                  <Tooltip
                                    tabIndex="0"
                                    href="##"
                                    className="pm-tooltip"
                                    role="button"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={feature.popover}
                                  >
                                    <img
                                      src="https://voyager.postman.com/icon/i-info-icon-postman.svg"
                                      alt="Question. Icon."
                                      style={{ height: '15px', opacity: '0.7' }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          )}
                          {feature.href && !feature.popover && (
                            <div className="row" data-test="feature-no-popover">
                              <div className="col-sm">
                                <a
                                  type="tertiary"
                                  href={feature.href}
                                  className="mb-0 feature-text feature-link text-left"
                                  aria-label={feature.ariaLabel}
                                  data-event={feature.event}
                                  data-event-prop={feature.eventProp}
                                >
                                  {Array.isArray(feature.title) ? (
                                    <Div
                                      dangerouslySetInnerHTML={{
                                        __html: feature.title.join(' ')
                                      }}
                                    />
                                  ) : (
                                    feature.title
                                  )}
                                </a>
                              </div>
                            </div>
                          )}
                          {!feature.href && !feature.popover && (
                            <div
                              className="row"
                              data-test="no-feature-no-popover"
                            >
                              <div className="col-sm">
                                {Array.isArray(feature.title) ? (
                                  <Div
                                    dangerouslySetInnerHTML={{
                                      __html: feature.title.join(' ')
                                    }}
                                  />
                                ) : (
                                  <p className="mb-0">{feature.title}</p>
                                )}
                              </div>
                            </div>
                          )}
                        </th>
                      )}
                      {cells}
                    </TableRow>
                  );
                })}
            </TableBody>
          </table>
          {data.footer && (
            <FooterNote
              className="d-flex flex-row align-items-center col-12"
              dangerouslySetInnerHTML={{ __html: data.footer.join('') }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PricingAddonsTable;
