const getAnchorTag = (target) => {
  let a = target;
  let tagName;

  for (let i = 0; i < 10; i += 1) {
    tagName = a.tagName.toLowerCase();

    if (tagName === 'a') {
      i = 10;
    } else {
      a = a.parentNode;
    }
  }

  return a;
};

export default getAnchorTag;
