import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton,
  SectionHeader
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const Section = styled.section`
  padding-bottom: ${(props) => props.paddingBottom};
  padding-top: ${(props) => props.paddingTop};
  background-color: ${(props) => props.backgroundColor};
`;

const ContentContainer = styled.div`
  a {
    color: #3d99ff;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid #3d99ff;
      cursor: pointer;
      text-decoration: none;
      transition: 'all 0.2s ease-in-out';
    }
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
  .text-orange {
    color: #ff8e64 !important;
    text-transform: uppercase;
    font-weight: 600;
  }
`;
const BackgroundImageLeft = styled.img`
  position: absolute;

  @media screen and (max-width: 968px) {
    display: none;
    visibility: hidden;
  }
`;

const BackgroundImageRight = styled.img`
  position: absolute;

  @media screen and (max-width: 968px) {
    display: none;
    visibility: hidden;
  }
`;
const HeaderSection = styled.div`
  padding: 60px 0;
`;
const ArrowLink = styled.a`
  color: #3d99ff;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid #3d99ff;
    cursor: pointer;
    text-decoration: none;
    transition: 'all 0.2s ease-in-out';
  }
`;

function SideBySideSpecial({
  backgroundColor,
  backgroundImageLeft,
  backgroundImageRight,
  body,
  className,
  ctaButton,
  ctaLinks,
  divider,
  fullWidth,
  headerBody,
  headerTitle,
  media,
  modal,
  paddingBottom,
  paddingTop,
  textAlign,
  title
}) {
  const textSection = (
    <div className="col-lg-4 offset-lg-0 order-lg-0 order-1 offset-md-1 col-md-8 offset-md-2 ">
      <div className="text-left">
        <ContentContainer>
          {body && (
            <div
              className={className}
              dangerouslySetInnerHTML={{ __html: body.join('') }}
            />
          )}
        </ContentContainer>
        {ctaLinks &&
          Array.isArray(ctaLinks) &&
          ctaLinks.map((cta) => (
            <p key={pmUuid()}>
              <ArrowLink
                linkType={cta.type}
                src={cta.src}
                className={cta.className}
                alternative={cta.alternative ? cta.alternative : false}
                target={cta.target ? cta.target : 'same-tab'}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                id={cta.id}
                ariaLabel={cta.ariaLabel}
              >
                `${cta.text} →`
              </ArrowLink>
            </p>
          ))}
        {ctaButton &&
          Array.isArray(ctaButton) &&
          ctaButton.map((cta) => (
            <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
              <BaseButton
                buttonType={cta.buttonType}
                as="a"
                src={cta.src}
                target={cta.target}
                id={cta.id}
                gaCategory={cta.gaCategory}
                gaLabel={cta.gaLabel}
                className={cta.type === 'inline' ? 'pl-0' : null}
                modal={modal || null}
                icon={cta.icon}
                dataTest={cta.id}
              >
                {cta.text}
              </BaseButton>
            </div>
          ))}
      </div>
    </div>
  );
  /* eslint-disable jsx-a11y/media-has-caption */
  const mediaSection = (
    <div className="col-lg-7 offset-lg-1 order-lg-1 order-0 mb-5 mb-lg-0">
      {media && (
        <img
          src={media.src}
          className="img-fluid"
          alt={media.alt}
          fetchpriority="auto"
          loading="lazy"
        />
      )}
    </div>
  );
  /* eslint-enable jsx-a11y/media-has-caption */
  return (
    <>
      <Section
        backgroundColor={backgroundColor}
        className={className}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <HeaderSection className="container">
          <div className="position-relative">
            {backgroundImageLeft && (
              <BackgroundImageLeft
                style={{
                  top: backgroundImageLeft.position.top,
                  left: backgroundImageLeft.position.left
                }}
                src={backgroundImageLeft.src}
                alt={backgroundImageLeft.alt}
              />
            )}
            {backgroundImageRight && (
              <BackgroundImageRight
                style={{
                  top: backgroundImageRight.position.top,
                  right: backgroundImageRight.position.right
                }}
                src={backgroundImageRight.src}
                alt={backgroundImageRight.alt}
              />
            )}{' '}
          </div>
          <div className="row">
            <div className="text-white col-lg-8 ml-3">
              <h2>{headerTitle}</h2>
              <div dangerouslySetInnerHTML={{ __html: headerBody.join('') }} />
            </div>
          </div>
        </HeaderSection>

        <div className="container">
          {title ? (
            <div className="row mb-5">
              <SectionHeader
                title={title}
                body={body}
                textAlign={textAlign}
                bottomMargin="0"
              />
            </div>
          ) : null}
          <div className="row">
            {textSection}
            {mediaSection}
          </div>
        </div>
      </Section>

      {(divider === true && <Divider fullWidth={fullWidth} />) || null}
    </>
  );
}

SideBySideSpecial.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImageLeft: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    position: PropTypes.shape({
      top: PropTypes.string,
      left: PropTypes.string
    })
  }),
  backgroundImageRight: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    position: PropTypes.shape({
      top: PropTypes.string,
      right: PropTypes.string
    })
  }),
  body: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  ctaButton: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  ctaLinks: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  headerBody: PropTypes.arrayOf(PropTypes.string),
  headerTitle: PropTypes.string,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string
  }),
  modal: PropTypes.bool,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  textAlign: PropTypes.string,
  title: PropTypes.string
};

SideBySideSpecial.defaultProps = {
  backgroundColor: null,
  backgroundImageLeft: null,
  backgroundImageRight: null,
  body: null,
  className: null,
  ctaButton: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  ctaLinks: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  divider: false,
  fullWidth: false,
  headerBody: null,
  headerTitle: null,
  media: null,
  modal: null,
  paddingBottom: null,
  paddingTop: null,
  textAlign: null,
  title: null
};

export default SideBySideSpecial;
