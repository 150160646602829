import React from 'react';
import styled from 'styled-components';
import {
  BaseLinkStyles,
  UnorderedListStyles,
  OrderedListStyles
} from 'aether-marketing';
import ReactMarkdown from 'react-markdown';

import bffLegalData from '../../../bff-data/legalterms.json';

/* eslint-disable import/no-extraneous-dependencies */
const pmRr = require('@postman/rr');
/* eslint-enable */ // root relative link

const legalData = pmRr(bffLegalData);

const content = legalData.markdown;

const Section = styled.section`
  .alternative p {
    color: white;
  }
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
function LegalMarkdown() {
  const generateSlug = (string) => {
    let str = string.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    return str;
  };

  const generateNumberSlug = (string) => {
    let str = string.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
    str = str
      .replace(/[^a-z0-9 -.]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    return str;
  };

  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* In order to update the legal/terms content please update the Mock Server Payload in the legalterms request */}
            <ReactMarkdown
              components={{
                h2: ({ node, ...props }) => (
                  <h2 id={generateSlug(props.children[0])} {...props} />
                ),
                h3: ({ node, ...props }) => (
                  <h3 id={generateNumberSlug(props.children[0])} {...props} />
                ),
                strong: ({ node, ...props }) => (
                  <strong
                    id={generateNumberSlug(props.children[0])}
                    {...props}
                  />
                )
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Section>
  );
}
/* eslint-enable jsx-a11y/heading-has-content */
/* eslint-enable react/no-unstable-nested-components */
/* eslint-enable react/prop-types */
/* eslint-enable react/jsx-props-no-spreading */

export default LegalMarkdown;
