import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */

import styled from 'styled-components';
import {
  BaseLink,
  VideoComponent,
  ParagraphStyles,
  Paragraph
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SectionOver = styled.section`
  padding: 60px;
  background: url('https://voyager.postman.com/illustration/bg-hero-full-reveal.svg')
    #06162f;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -175px;

  @media screen and (max-width: 992px) {
    padding: 30px 0px;
    margin-bottom: 0px;
  }
`;

const SectionUnder = styled.section`
  padding: 100px 60px 30px 60px;
  background: linear-gradient(180deg, #eff8fe 0%, #ffffff 100%);

  @media screen and (max-width: 992px) {
    padding: 30px 0;
  }
`;

const SpeakerContainer = styled.div`
  @media screen and (max-width: 992px) {
    display: flex;
  }
`;

const SpeakerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 0 15px;
`;

const SpeakerImage = styled.img`
  height: 86px;
  width: 86px;
  max-width: 100%;
  object-fit: cover;
`;

const SpeakerTextWrapper = styled.div`
  padding: 0 12px;
`;

// const CardContainer = styled.div`
//   padding: 8px 24px 24px;
// `;

const Subtitle = styled(ParagraphStyles)`
  font-size: 20px;
`;

function SessionInfo({ data }) {
  const { speakers, title, subtitle } = data;

  return (
    <div className="col-12">
      <div className="row mt-lg-3 mb-lg-1 mb-0 mt-0">
        <div className="col-12">
          <h2 className="mb-3">{title}</h2>
          <Subtitle
            className="mb-4"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </div>
      </div>
      {/* eslint-disable */ }
      {speakers && (
      <SpeakerContainer className="col-12">
        <div className="row">
          {speakers.length === 3 || 6 ? (
            speakers.map((speaker) => (
              <React.Fragment key={pmUuid()}>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <div className="row pt-1">
                    <SpeakerWrapper className="col-12 my-auto">
                      <SpeakerImage className="img-fluid rounded-circle" src={speaker.media.src} alt={speaker.media.alt} />
                      <SpeakerTextWrapper className="col-sm-8 col-md-10 col-lg-8">
                        <Paragraph className="strong mt-3 mb-0">{speaker.name}</Paragraph>
                        <Paragraph className="mb-0">{speaker.title}</Paragraph>
                        {speaker.company && (
                        <Paragraph className="mb-0">{speaker.company}</Paragraph>
                        )}
                      </SpeakerTextWrapper>
                    </SpeakerWrapper>
                  </div>
                </div>
              </React.Fragment>
            ))
          ) : (
            speakers.map((speaker) => (
              <React.Fragment key={pmUuid()}>
                <div className="col-12 mb-3 col-lg-6">
                  <div className="row pt-1">
                    <SpeakerWrapper className="col-12 my-auto">
                      <SpeakerImage className="img-fluid rounded-circle" src={speaker.media.src} alt={speaker.media.alt} />
                      <SpeakerTextWrapper className="col-sm-8 col-md-10">
                        <Paragraph className="strong mt-3 mb-0">{speaker.name}</Paragraph>
                        <Paragraph className="mb-0" >{speaker.title}</Paragraph>
                        {speaker.company && (
                        <Paragraph className="mb-0">{speaker.company}</Paragraph>
                        )}
                      </SpeakerTextWrapper>
                    </SpeakerWrapper>
                  </div>
                </div>
              </React.Fragment>
            )))}
        </div>
      </SpeakerContainer>
      )}
    {/* eslint-enable */ }
    </div>
  );
}

SessionInfo.propTypes = {
  data: PropTypes.shape({
    speakers: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
    subtitle: PropTypes.string
  }).isRequired
};

class SpeakerCards extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data
    };
  }

  render() {
    const { data } = this.state;
    const { speakers, title, subtitle, parentlink, video } = data;

    return (
      <>
        <SectionOver>
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <span className="d-flex flex-row flex-wrap">
                  <div className="row">
                    <div className="col-12 mb-4">
                      {parentlink && (
                        <BaseLink
                          className={parentlink.className}
                          src={parentlink.src}
                          linkType="reversedArrowLink"
                        >
                          {parentlink.text}
                        </BaseLink>
                      )}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <VideoComponent
                  src={video.src}
                  controls={video.controls}
                  autoPlay={video.autoPlay}
                  hostType={video.hostType}
                  caption={video.caption}
                  paddingBottom={video.paddingBottom}
                  paddingTop={video.paddingTop}
                  loop={video.loop}
                  muted={video.muted}
                  container={video.container}
                  videoTitle={video.videoTitle}
                  aspectRatio={video.aspectRatio}
                  id={video.id}
                  allowFullScreen={video.allowFullScreen}
                  poster={video.poster}
                  border={video.border}
                  mediaLayout={video.mediaLayout}
                />
              </div>
            </div>
          </div>
        </SectionOver>
        <SectionUnder>
          <div className="container">
            <div className="row">
              <SessionInfo
                data={{ speakers, title, subtitle }}
                key={pmUuid()}
              />
            </div>
          </div>
        </SectionUnder>
      </>
    );
  }
}

SpeakerCards.propTypes = {
  data: PropTypes.shape({}).isRequired,
  parentlink: PropTypes.shape({
    text: PropTypes.string,
    src: PropTypes.string,
    alternative: PropTypes.string
  })
};

SpeakerCards.defaultProps = {
  parentlink: {
    text: null,
    src: null,
    alternative: 'white'
  }
};

const galaxyVideoHero = (data) => <SpeakerCards data={data} />;

export default galaxyVideoHero;
