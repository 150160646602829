import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UnorderedListStyles } from 'aether-marketing';
import PostconSectionHeader from './PostconSectionHeader';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const StylesWrapper = styled.section`
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  background: ${(props) => props.backgroundColor};

  p {
    color: white;
  }

  ul {
    padding-left: 0;
  }
  li {
    color: white !important;
  }
  a {
    color: white;
    border-bottom: 1px solid white;
  }
  a:hover {
    color: #ff6c37;
    text-decoration: none;
    border-bottom: 1px solid #ff6c37;
  }

  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }

  .gradientLine {
    margin: 30px 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #ff6c37 -5.31%, #c74eda 100%);
  }
  .event-time {
    color: #ff6c37;
    font-weight: bold;
  }
  @media (max-width: 425px) {
    padding-top: 20px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: sticky;
  top: 60px;
  z-index: 1;
  .nav-item {
    margin: 10px;
  }

  .nav-link {
    border: none;
    border-bottom: 1px solid #b09dcd;
    background-color: transparent;
    color: #b09dcd;
    font-weight: bold;
  }

  .nav-link.active {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #ff6c37 -5.31%, #c74eda 100%);
    border-image-slice: 1;
    width: 100%;
  }

  @media (max-width: 768px) {
    .nav-link,
    .nav-link.active {
      width: 200px;
    }
  }

  @media (max-width: 577px) {
    ul {
      justify-content: center;
    }

    .nav-link,
    .nav-link.active {
      width: 350px;
    }
  }

  @media (max-width: 425px) {
    .nav-link,
    .nav-link.active {
      width: 300px;
    }
  }
`;

const EventContainer = styled.div`
  display: flex;
  color: white;

  .event-time {
    color: #ff6c37;
    font-weight: bold;
    text-align: right;
    padding-top: 3px;
  }
  .event-room {
    color: #ff6c37;
    font-weight: bold;
    text-align: right;
    padding-top: 3px;
    @media (max-width: 1070px) and (min-width: 992px) {
      font-size: 14px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    .event-time,
    .event-room {
      text-align: left;
    }
  }
`;

const EventDetailsContainer = styled.div`
  ul {
    ${UnorderedListStyles.componentStyle.rules}
    padding-left: 0;
  }

  .event-title {
    font-size: 22px;
    margin-bottom: 0;
  }

  .event-type {
    font-weight: bold;
  }

  .event-description {
    margin-top: 24px;
    color: #b09dcd;
  }

  .no-image {
    margin-left: -2rem;
  }

  .speakers-container {
    margin-top: 24px;
  }
`;

function Agenda({ tabs, trackOne, trackTwo, trackThree, textColor }) {
  const [activeId, setActiveId] = useState('1');
  const handleClick = (id) => {
    setActiveId(id);
  };

  return (
    <div className="container">
      <div className="row">
        {tabs && (
          <TabsContainer className="col-lg-12">
            <ul className="nav nav-pills">
              {tabs.map((tab) => (
                <li className="nav-item" key={pmUuid()}>
                  <button
                    onClick={() => handleClick(tab.index)}
                    type="button"
                    className={`nav-link ${
                      activeId === tab.index ? 'active' : ''
                    }`}
                  >
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          </TabsContainer>
        )}
      </div>

      <div className="row">
        <div className="col-lg-12">
          {/* TRACK ONE */}
          {activeId === '1' && (
            <>
              {trackOne.eyebrow && (
                <h3 className="gradientText mb-0">{trackOne.eyebrow}</h3>
              )}
              {trackOne.title && (
                <h2 className={textColor || 'text-white'}>{trackOne.title}</h2>
              )}
              {trackOne.subtitle && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: trackOne.subtitle.join('')
                  }}
                />
              )}
              <div className="gradientLine" />
              {trackOne.items &&
                trackOne.items.map((item) => (
                  <React.Fragment key={pmUuid()}>
                    <EventContainer>
                      <div className="col-lg-2">
                        {item.time && (
                          <p className="event-time mb-0">{item.time}</p>
                        )}
                        {item.time !== '7:30am - 8:45am' &&
                          item.title !== 'After-party' && (
                            <p className="event-room">Continental Ballroom</p>
                          )}
                        {item.time === '7:30am - 8:45am' && (
                          <p className="event-room">Golden Gate</p>
                        )}
                      </div>
                      <EventDetailsContainer className="col-lg-10">
                        {item.title && (
                          <p className="event-title">{item.title}</p>
                        )}
                        {item.type && (
                          <span className="event-type gradientText">
                            {item.type}
                          </span>
                        )}
                        {item.description && (
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item.description.join('')
                            }}
                          />
                        )}
                        {item.speakers && (
                          <div className="speakers-container">
                            {item.speakers.map((speaker) => (
                              <div className="speakers" key={pmUuid()}>
                                {speaker.name && (
                                  <span className="font-weight-bold">
                                    {speaker.name}
                                  </span>
                                )}
                                {speaker.title && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: speaker.title
                                    }}
                                  />
                                )}
                                {speaker.company && (
                                  <span
                                    className={
                                      speaker.company === 'The Daily Show'
                                        ? 'font-italic'
                                        : ''
                                    }
                                  >
                                    {speaker.company}
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </EventDetailsContainer>
                    </EventContainer>
                    <div className="gradientLine" />
                  </React.Fragment>
                ))}
            </>
          )}

          {/* TRACK TWO */}
          {activeId === '2' && (
            <>
              {trackTwo.eyebrow && (
                <h3 className="gradientText mb-0">{trackTwo.eyebrow}</h3>
              )}
              {trackTwo.title && (
                <h2 className={textColor || 'text-white'}>{trackTwo.title}</h2>
              )}
              {trackTwo.subtitle && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: trackTwo.subtitle.join('')
                  }}
                />
              )}
              <div className="gradientLine" />
              {trackTwo.items &&
                trackTwo.items.map((item) => (
                  <React.Fragment key={pmUuid()}>
                    <EventContainer>
                      <div className="col-lg-2">
                        {item.time && (
                          <p className="event-time mb-0">{item.time}</p>
                        )}
                        {item.title !==
                          'Conversation with Trevor Noah and Casey Newton' &&
                          item.title !== 'After-party' && (
                            <p className="event-room">Imperial Ballroom A</p>
                          )}
                        {item.title ===
                          'Conversation with Trevor Noah and Casey Newton' &&
                          item.title !== 'After-party' && (
                            <p className="event-room">Continental Ballroom</p>
                          )}
                      </div>
                      <EventDetailsContainer className="col-lg-10">
                        {item.title && (
                          <p className="event-title">{item.title}</p>
                        )}
                        {item.type && (
                          <span className="event-type gradientText">
                            {item.type}
                          </span>
                        )}
                        {item.description && (
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item.description.join('')
                            }}
                          />
                        )}
                        <div className="speakers-container">
                          {item.speakers &&
                            item.speakers.map((speaker) => (
                              <div className="speakers" key={pmUuid()}>
                                {speaker.headshot.src !== '' && (
                                  <img
                                    src={speaker.headshot.src}
                                    alt={speaker.headshot.alt}
                                    height="55"
                                    className="mr-3 mb-3"
                                  />
                                )}
                                {speaker.name && (
                                  <span className="font-weight-bold">
                                    {speaker.name}
                                  </span>
                                )}

                                {speaker.title && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: speaker.title
                                    }}
                                  />
                                )}

                                {speaker.company && (
                                  <span> {speaker.company}</span>
                                )}
                              </div>
                            ))}
                        </div>
                      </EventDetailsContainer>
                    </EventContainer>
                    <div className="gradientLine" />
                  </React.Fragment>
                ))}
            </>
          )}

          {/* TRACK THREE */}
          {activeId === '3' && (
            <>
              {trackThree.eyebrow && (
                <h3 className="gradientText mb-0">{trackThree.eyebrow}</h3>
              )}
              {trackThree.title && (
                <h2 className={textColor || 'text-white'}>
                  {trackThree.title}
                </h2>
              )}
              {trackThree.subtitle && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: trackThree.subtitle.join('')
                  }}
                />
              )}
              <div className="gradientLine" />
              {trackThree.items &&
                trackThree.items.map((item) => (
                  <React.Fragment key={pmUuid()}>
                    <EventContainer>
                      <div className="col-lg-2">
                        {item.time && (
                          <p className="event-time mb-0">{item.time}</p>
                        )}
                        {item.title !==
                          'Conversation with Trevor Noah and Casey Newton' &&
                          item.title !== 'After-party' && (
                            <p className="event-room">Imperial Ballroom B</p>
                          )}
                        {item.title ===
                          'Conversation with Trevor Noah and Casey Newton' &&
                          item.title !== 'After-party' && (
                            <p className="event-room">Continental Ballroom</p>
                          )}
                      </div>
                      <EventDetailsContainer className="col-lg-10">
                        {item.title && (
                          <p className="event-title">{item.title}</p>
                        )}
                        {item.type && (
                          <span className="event-type gradientText">
                            {item.type}
                          </span>
                        )}
                        {item.description && (
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item.description.join('')
                            }}
                          />
                        )}
                        <div className="speakers-container">
                          {item.speakers &&
                            item.speakers.map((speaker) => (
                              <div className="speakers" key={pmUuid()}>
                                {speaker.headshot.src !== '' && (
                                  <img
                                    src={speaker.headshot.src}
                                    alt={speaker.headshot.alt}
                                    height="55"
                                    className="mr-3 mb-3"
                                  />
                                )}
                                {speaker.name && (
                                  <span className="font-weight-bold">
                                    {speaker.name}
                                  </span>
                                )}

                                {speaker.title && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: speaker.title
                                    }}
                                  />
                                )}

                                {speaker.company && (
                                  <span> {speaker.company}</span>
                                )}
                              </div>
                            ))}
                        </div>
                      </EventDetailsContainer>
                    </EventContainer>
                    <div className="gradientLine" />
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

Agenda.propTypes = {
  textColor: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string,
      name: PropTypes.string
    })
  ),
  trackOne: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.arrayOf(PropTypes.string),
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  }),
  trackTwo: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.arrayOf(PropTypes.string),
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  }),
  trackThree: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.arrayOf(PropTypes.string),
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  })
};

Agenda.defaultProps = {
  tabs: null,
  trackOne: null,
  trackTwo: null,
  trackThree: null,
  textColor: ''
};

/* eslint-disable react/prop-types */
function PostconAgenda({
  paddingTop,
  paddingBottom,
  backgroundColor,
  tabs,
  trackOne,
  trackTwo,
  trackThree,
  headerTitle,
  preTitle,
  headerBody,
  paddingBottomHeader,
  headerClass
}) {
  const anchorId = headerTitle
    .replace(/\s+/g, '-')
    .replace(/\?/g, '')
    .replace(/,/g, '')
    .toLowerCase();

  return (
    <StylesWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColor={backgroundColor}
      id={anchorId}
    >
      <div className="container">
        {(headerTitle || preTitle) && (
          <div className="row">
            <PostconSectionHeader
              title={headerTitle}
              preTitle={preTitle}
              body={headerBody}
              paddingBottom={paddingBottomHeader}
              headerClass={headerClass}
            />
          </div>
        )}
      </div>
      <Agenda
        tabs={tabs}
        trackOne={trackOne}
        trackTwo={trackTwo}
        trackThree={trackThree}
      />
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default PostconAgenda;

PostconAgenda.propTypes = {
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  backgroundColor: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.string,
      name: PropTypes.string
    })
  ),
  trackOne: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  }),
  trackTwo: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  }),
  trackThree: PropTypes.shape({
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string,
            headshot: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string
            })
          })
        )
      })
    )
  })
};

PostconAgenda.defaultProps = {
  paddingTop: '',
  paddingBottom: '',
  backgroundColor: '',
  tabs: null,
  trackOne: null,
  trackTwo: null,
  trackThree: null
};
