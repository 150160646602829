import React from 'react';
import { SectionStyles } from 'aether-marketing';
import PropTypes from 'prop-types';
import SignUpPostmanEmailField from './SignUpPostmanEmailField';

function SignupInputBox({
  title,
  backgroundColor,
  paddingBottom,
  paddingTop,
  media
}) {
  return (
    <SectionStyles
      backgroundColor={backgroundColor}
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-7 col-lg-5 offset-lg-0 mb-5 mb-lg-0">
            <h2>{title}</h2>
            <SignUpPostmanEmailField />
          </div>

          <div className="col-12 col-lg-6 offset-lg-1">
            <img className="img-fluid w-100" alt={media.alt} src={media.src} />
          </div>
        </div>
      </div>
    </SectionStyles>
  );
}

export default SignupInputBox;

SignupInputBox.propTypes = {
  backgroundColor: PropTypes.bool,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  title: PropTypes.string,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool
  })
};

SignupInputBox.defaultProps = {
  media: {
    src: null,
    alt: null,
    shadow: null
  },
  title: '',
  backgroundColor: null,
  paddingBottom: null,
  paddingTop: null
};
