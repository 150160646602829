import React from 'react';
import styled from 'styled-components';
import { Paragraph, BaseButton } from 'aether-marketing';
import ModalComponent from '../v5_modal-overlays/ModalComponent';

const Section = styled.div`
  padding: 60px 0 30px 0;
`;

const HeroImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (max-width: 576px) {
    top: 10px;
    width: 50vw;
  }

  @media screen and (max-width: 992px) {
    position: relative;
  }
`;

const eventsHero = ({
  modal,
  media,
  padding = null,
  col,
  h1,
  h1ClassName,
  h1Hidden,
  subtitle,
  expDate,
  body,
  button,
  buttonOnly,
  form,
  recap,
  background
}) => {
  return (
    <Section
      style={{
        padding,
        backgroundColor: background.color || '',
        backgroundImage: `url("${background.src}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center'
      }}
    >
      <HeroImage
        className="d-lg-block d-none"
        src={media.desktopSrc}
        alt={media.alt}
      />
      <div className="container">
        <div className="row">
          <div className={`${col || 'col-lg-8'}`}>
            {/* title */}
            <h1
              className={`mb-3 ${h1ClassName || ''}`}
              style={h1Hidden ? { textIndent: '-99999px', height: '0' } : {}}
            >
              {h1}
            </h1>
            {/* subtitle */}
            {subtitle && <h4 className="mb-4">{subtitle}</h4>}
            {body && body.text && (
              <Paragraph dangerouslySetInnerHTML={{ __html: body.text }} />
            )}
            {modal && recap === false && (
              <ModalComponent
                modal={modal}
                button={button}
                form={form}
                className={button.className}
              />
            )}
            {new Date(expDate) > new Date() &&
              buttonOnly &&
              buttonOnly.text &&
              buttonOnly.src && (
                <BaseButton
                  buttonType={buttonOnly.buttonType}
                  as="a"
                  src={buttonOnly.src}
                  target={buttonOnly.target}
                  id={buttonOnly.id}
                  gaCategory={buttonOnly.gaCategory}
                  gaLabel={buttonOnly.gaLabel}
                  className={
                    buttonOnly.buttonType === 'inline' ? 'mb-3' : 'mb-3'
                  }
                  modal={modal || null}
                  icon={buttonOnly.icon}
                >
                  {buttonOnly.text}
                </BaseButton>
              )}
          </div>
        </div>
      </div>
      <div className="text-right d-md-block d-lg-none">
        <HeroImage
          className="img-fluid"
          src={media.mobileSrc}
          alt={media.alt}
        />
      </div>
    </Section>
  );
};
export default eventsHero;
